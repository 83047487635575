import React from 'react';
import { AppBar, Toolbar, IconButton, Grid, Typography, Button, Tooltip } from '@material-ui/core';
import {
  ArrowBack as ArrowBackIcon,
  Close as CloseIcon,
  PictureAsPdf as PictureAsPdfIcon,
  Save as SaveIcon,
  Mail as MailIcon,
  Repeat as RepeatIcon,
  Edit as EditIcon,
  Schedule as ScheduleIcon
} from '@material-ui/icons';
import InfoIcon from '@material-ui/icons/Info';
import CheckIcon from '@material-ui/icons/Check';

import { useTranslation } from 'react-i18next';

import { RETURN_DATE } from '../../../_functions/DATES';
import { RETURN_HOURS } from '../../../_functions/DATES';
import { RETURN_OVERDUE_DAYS } from '../../../_functions/DATES';

const InvoiceAppBar = ({
  setOpenInvoiceDialogAtom,
  openInvoiceDialogAtom,
  selectedClient,
  isLoading,
  setSendMode,
  setOpen,
  invoiceHandler,
  values,
  saveNewInvoiceSchema,
  setSettingsAtom,
  sendMode,
  newlyCreated,
  invoiceDateError,
  dueDateError,
  invoiceNumberError,
  checkInvoiceNumberChange,
  setValues,
  handleCancelSend,
  setDisabledEditing,
  isFetching // Check invoice Number
}) => {
  const { t } = useTranslation();

  return (
    <AppBar
      elevation={0}
      style={{
        backgroundColor: "white",
        zIndex: 99999,
        boxShadow: "rgba(84, 94, 165, 0.2) 0px 1px 64px"
      }}>
      <Toolbar style={{ justifyContent: "space-between", position: 'relative' }}>
        {/* Left Section: Title */}
        <Grid container alignItems="center" className="appbar-left">
          <IconButton
            edge="start"
            style={{
              display: "flex",
              width: "35px",
              height: "35px",
              marginRight: 10,
              border: "1px solid rgba(0, 0, 0, 0.04)"
            }}
            onClick={() => {
              setOpenInvoiceDialogAtom({
                ...openInvoiceDialogAtom,
                open: false,
                invoiceId: "",
                duplicate: false
              });
            }}
            aria-label={`${t("BUTTONS.AL_close")}`}
          >
            <ArrowBackIcon color="primary" />
          </IconButton>
          <Typography variant="h6" component="h1" className="invoice-dialog-headline-appbar" style={{ textTransform: "none", fontWeight: 600, color: "rgb(44, 83, 125)", marginRight: 10 }}>
            {openInvoiceDialogAtom.recurring ? "Wiederkehrende Rechnungslegung" : "Rechnung"}
          </Typography>
          <Grid item className="invoice-dialog-status-appbar">
            {/* NEW */}
            {!values.loading && !selectedClient &&
              <div
                style={{
                  position: "relative",
                  display: "flex",
                  alignItems: "center",
                  marginLeft: 5,
                  marginRight: 5,
                  width: "100%"
                }}
              >
                <div>
                  <Typography
                    style={{
                      padding: 8,
                      background: "white",
                      fontSize: 10,
                      border: "1px solid rgb(44, 83, 125)",
                      borderRadius: 60,
                      color: "rgb(44, 83, 125)",
                      fontWeight: 500,
                    }}>
                    Erstellen
                  </Typography>
                </div>
              </div>
            }
            {!values.loading && selectedClient &&
              <div>
                {/* Draft */}
                {(values.sendState !== "sent" && values.sendState !== "scheduled" &&
                  (values.payState === "notPayed" || values.payState === undefined)) && (
                    <div
                      style={{
                        position: "relative",
                        display: "flex",
                        alignItems: "center",
                        marginLeft: 5,
                        marginRight: 5,
                        width: "100%"
                      }}
                    >

                      <div
                        style={{
                          background: "rgb(229 232 239)",
                          padding: "0px 5px",
                          borderRadius: 60,
                          display: "flex",
                          justifyContent: "flex-start",
                          alignItems: "center",
                          position: "relative", // Added for badge positioning
                          color: "rgb(44, 83, 125)",
                          opacity: values.cancelled ? 0.5 : 1,
                        }}
                      >
                        <div style={{ position: "relative", marginTop: 5 }}>
                          <EditIcon
                            style={{
                              fontSize: "21px",
                              color: "#ffffff",
                              background: "rgb(172, 175, 197)",
                              borderRadius: 60,
                              padding: 2,
                              marginRight: 5,
                            }}
                          />
                        </div>

                        <Typography
                          component="div"
                          variant="body2"
                          style={{
                            textAlign: "left",
                            color: "inherit",
                            fontSize: "13px",
                            paddingRight: 5,
                            paddingTop: 6,
                            paddingBottom: 6,
                            lineHeight: 1.2,
                            fontWeight: 500,
                            display: "flex",
                            alignItems: "center"
                          }}
                        >
                          <span style={{
                            fontSize: 11,
                          }}>
                            Entwurf
                          </span>
                          {values.type === "cancellation" && (
                              <span style={{ fontWeight: 700, marginTop: -2, marginLeft: 5, paddingLeft: 5, borderLeft: "2px solid rgb(44 83 125 / 20%)" }}>
                                <span style={{ fontSize: 9, padding: "2px 0px 0px 0px", marginTop: -2, borderRadius: 60, display: "inline-block", }}>
                                  STORNORECHNUNG
                                </span>
                              {values.cancelledRefInvoiceNr &&
                              <span
                                style={{
                                  display: "block",
                                  overflow: "hidden",
                                  whiteSpace: "nowrap",
                                  textOverflow: "ellipsis",
                                  verticalAlign: "middle",
                                  fontSize: 9,
                                  fontWeight: "normal"
                                }}
                              >
                                Zu Nr. {JSON.parse(values.cancelledRefInvoiceNr).map(item => item.value).join("")}
                              </span>
                              }
                            </span>
                          )}
                        </Typography>

                        {(values.originalRef !== undefined) &&
                          <Tooltip title="Diese Rechnung wurde aufgrund einer wiederkehrenden Rechnungslegung erstellt.">
                            <div
                              onClick={(e) => {
                                e.stopPropagation();
                                setOpenInvoiceDialogAtom({ ...openInvoiceDialogAtom, recurring: true, open: true, invoiceId: values.originalRef, sendMode: false })
                              }}
                            >
                              <div className="recurring-info" style={{ borderRadius: "60px", display: "inline-grid", textAlign: "center", padding: "5px 5px", fontSize: "12px" }}>
                                <RepeatIcon className="recurring-info-icon" style={{ fontSize: 12 }} />
                              </div>
                            </div>
                          </Tooltip>
                        }
                      </div>

                      {values.cancelled &&
                        <div>
                          <Typography
                            style={{
                              padding: 8,
                              background: "white",
                              fontSize: 10,
                              border: "1px dashed rgb(214 216 237)",
                              borderRadius: 60,
                              color: "rgb(44, 83, 125)",
                              fontWeight: 500,
                              marginLeft: 5
                            }}>
                            Storniert
                          </Typography>
                        </div>
                      }
                    </div>
                )}

                {/* Scheduled */}
                {(values.sendState === "scheduled" && values.payState === "notPayed") && (
                  <div style={{ position: "relative", display: "inline-block", marginLeft: 5, marginRight: 5, width: "100%" }}
                  >
                    <Tooltip title={`Wird am ${RETURN_DATE(values.sendDate)} um ${RETURN_HOURS(values.sendDate)} gesendet`} arrow>
                      <div
                        style={{
                          background: "#ffe6c3",
                          padding: "0px 5px",
                          paddingRight: 1,
                          borderRadius: 60,
                          display: "flex",
                          justifyContent: "flex-start",
                          alignItems: "center",
                          position: "relative", // Added for badge positioning
                          marginBottom: 1,
                          color: "rgb(44, 83, 125)",
                        }}
                      >
                        <div style={{ position: "relative", marginTop: 5 }}>
                          <ScheduleIcon
                            style={{
                              fontSize: "21px",
                              color: "#ffffff",
                              background: "rgb(255, 183, 77)",
                              borderRadius: 60,
                              marginRight: 5,
                            }}
                          />
                        </div>
                        <Typography
                          component="div"
                          variant="body2"
                          style={{ textAlign: "left", fontSize: "13px", color: "inherit", paddingTop: 6, paddingBottom: 6, display: "flex", flexDirection: "row", alignItems: "center" }}
                        >
                          <span style={{ fontWeight: "500", fontSize: 11, lineHeight: 0.6 }}>
                            {`Versand geplant:`}
                            <span className="break-schedule-appbar-text"><br/></span>
                            {` ${RETURN_DATE(values.sendDate)} | ${RETURN_HOURS(values.sendDate)}`}
                          </span>

                          {values.type === "cancellation" && (
                          <span style={{ fontWeight: 700, marginTop: -2, marginLeft: 10, paddingLeft: 10, borderLeft: "2px solid rgb(44 83 125 / 20%)" }}>
                            <span style={{ fontSize: 9, padding: "2px 0px 0px 0px", marginTop: -2, borderRadius: 60, display: "inline-block", }}>
                              STORNORECHNUNG
                            </span>
                            {values.cancelledRefInvoiceNr &&
                            <span
                              style={{
                                display: "block",
                                width: "100px",
                                overflow: "hidden",
                                whiteSpace: "nowrap",
                                textOverflow: "ellipsis",
                                verticalAlign: "middle",
                                fontSize: 9,
                                fontWeight: "normal"
                              }}
                            >
                              Zu Nr. {JSON.parse(values.cancelledRefInvoiceNr).map(item => item.value).join("")}
                            </span>
                            }
                          </span>
                        )}

                          <span
                            style={{marginRight: -4}}
                            className="schedule-appbar-button"
                            onClick={(e) => {
                              handleCancelSend();
                              setValues({
                                ...values,
                                sendState: values.sentBlobs.length === 0 ? "notSent" : "sent",
                                sendDate: values.sentBlobs.length === 0 ? values.sendDate : JSON.parse(values.sentBlobs[values.sentBlobs.length - 1]).sendDate
                              });
                              setDisabledEditing(false);
                            }}
                          >
                            <span className="appbar-schedule-additional-text">VERSAND</span> ABBRECHEN
                          </span>
                        </Typography>
                      </div>
                    </Tooltip>
                  </div>
                )}

                {/* Sent */}
                {(values.sendState === "sent" && values.payState === "notPayed") && (
                <div style={{                         position: "relative",
                  display: "flex",
                  alignItems: "center",
                  marginLeft: 5,
                  marginRight: 5,
                  width: "100%" }}>
                  <div
                    style={{
                      background: values.cancelled ? "#f5f5f5" : "#d1e5ff",
                      padding: "0px 5px",
                      borderRadius: 60,
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "center",
                      position: "relative", // Added for badge positioning
                      paddingTop: 5,
                      paddingBottom: 5,
                      color: "rgb(44, 83, 125)",
                      opacity: values.cancelled ? 0.5 : 1,
                    }}
                  >

                    <div style={{ position: "relative", marginTop: 5 }}>
                      <MailIcon
                        style={{
                          fontSize: "23px",
                          color: "#ffffff",
                          background: "rgb(26, 123, 201)",
                          borderRadius: 60,
                          padding: 4,
                          marginRight: 5,
                        }}
                      />
                    </div>

                    {/* Typography Content */}
                    <Typography
                      component="div"
                      variant="body2"
                      style={{
                        textAlign: "left",
                        fontSize: "13px",
                        color: "inherit",
                        paddingTop: "1px",
                        lineHeight: 1.2,
                        display: "flex",
                        alignItems: "center"
                      }}
                    >

                    {values.type === "cancellation" && (
                          <span style={{ fontWeight: 700, marginTop: -2, marginRight: 10, paddingRight: 10, borderRight: "2px solid rgb(44 83 125 / 20%)" }}>
                            <span style={{ fontSize: 9, padding: "2px 0px 0px 0px", marginTop: -2, borderRadius: 60, display: "inline-block", }}>
                              GUTSCHRIFT
                            </span>
                            {values.cancelledRefInvoiceNr &&
                            <span
                              style={{
                                display: "block",
                                overflow: "hidden",
                                whiteSpace: "nowrap",
                                textOverflow: "ellipsis",
                                verticalAlign: "middle",
                                fontSize: 9,
                                fontWeight: "normal"
                              }}
                            >
                              Zu Nr. {JSON.parse(values.cancelledRefInvoiceNr).map(item => item.value).join("")}
                            </span>
                            }
                          </span>
                        )}

                      <span
                        style={{
                          fontWeight: "500",
                          fontSize: 11,
                          lineHeight: 1.3,
                          paddingRight: 8,
                        }}
                      >
                        {`Gesendet`}<br/>{`${RETURN_DATE(values.sendDate)}`}
                      </span>
                    </Typography>

                    {(values.originalRef !== undefined) &&
                        <Tooltip title="Diese Rechnung wurde aufgrund einer wiederkehrenden Rechnungslegung gesendet.">
                          <div
                            onClick={(e) => {
                              e.stopPropagation();
                              setOpenInvoiceDialogAtom({ ...openInvoiceDialogAtom, recurring: true, open: true, invoiceId: values.originalRef, sendMode: false })
                            }}
                          >
                            <div className="recurring-info" style={{ borderRadius: "60px", display: "inline-grid", textAlign: "center", padding: "5px 5px", fontSize: "12px" }}>
                              <RepeatIcon className="recurring-info-icon" style={{ fontSize: 12 }} />
                            </div>
                          </div>
                        </Tooltip>
                      }
                  </div>

                  {!values.cancelled && (RETURN_OVERDUE_DAYS(values.dueDate) > 0 && values.payState === "notPayed") && (
                    <div>
                      <Typography
                        style={{
                          paddingTop: 2,
                          paddingBottom: 2,
                          fontSize: 10,
                          borderRadius: 60,
                          paddingLeft: 5,
                          paddingRight: 5,
                          color: "rgb(44, 83, 125)",
                          // border: "1px solid rgb(237, 239, 255)",
                          fontWeight: 500,
                          display: "flex",
                          alignItems: "center",
                          marginTop: 3,
                        }}>
                          <InfoIcon fontSize="small" style={{marginRight: 3, color: "rgb(183, 28, 28)"}}/>
                        <span style={{paddingTop: 1}}>{`${RETURN_OVERDUE_DAYS(values.dueDate)} Tag${RETURN_OVERDUE_DAYS(values.dueDate) > 1 ? "e" : ""} überfällig!`}</span>
                      </Typography>
                    </div>
                  )}

                  {values.cancelled &&
                    <div>
                      <Typography
                        style={{
                          padding: 8,
                          background: "white",
                          fontSize: 10,
                          border: "1px dashed rgb(214 216 237)",
                          borderRadius: 60,
                          color: "rgb(44, 83, 125)",
                          fontWeight: 500,
                          marginLeft: 5
                        }}>
                        Storniert
                      </Typography>
                    </div>
                  }
                </div>
                )}

                {/* Payed */}
                {values.payState === "payed" && (
                <div style={{
                  position: "relative",
                  display: "flex",
                  alignItems: "center",
                  marginLeft: 5,
                  marginRight: 5,
                  width: "100%" }}>
                  <div
                    style={{
                      background: "rgb(190, 241, 192)",
                      padding: "0px 5px",
                      borderRadius: 60,
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "center",
                      position: "relative", // Added for badge positioning
                      paddingTop: 5,
                      paddingBottom: 5,
                      color: "rgb(44, 83, 125)",
                    }}
                  >

                    <div style={{ position: "relative", marginTop: 5 }}>
                      <CheckIcon
                        style={{
                          fontSize: "23px",
                          color: "#ffffff",
                          background: "rgb(76, 175, 80)",
                          borderRadius: 60,
                          padding: 4,
                          marginRight: 5,
                        }}
                      />
                    </div>

                    {/* Typography Content */}
                    <Typography
                      component="div"
                      variant="body2"
                      style={{
                        textAlign: "left",
                        fontSize: "13px",
                        color: "inherit",
                        paddingTop: "1px",
                        lineHeight: 1.2
                      }}
                    >

                      <span
                        style={{
                          fontWeight: "500",
                          fontSize: 11,
                          lineHeight: 1.3,
                          paddingRight: 8,
                        }}
                      >
                        {`Bezahlt`}<br/>{`${RETURN_DATE(values.payDate)}`}
                      </span>
                    </Typography>

                    {(values.originalRef !== undefined) &&
                        <Tooltip title="Diese Rechnung wurde aufgrund einer wiederkehrenden Rechnungslegung gesendet.">
                          <div
                            onClick={(e) => {
                              e.stopPropagation();
                              setOpenInvoiceDialogAtom({ ...openInvoiceDialogAtom, recurring: true, open: true, invoiceId: values.originalRef, sendMode: false })
                            }}
                          >
                            <div className="recurring-info" style={{ borderRadius: "60px", display: "inline-grid", textAlign: "center", padding: "5px 5px", fontSize: "12px" }}>
                              <RepeatIcon className="recurring-info-icon" style={{ fontSize: 12 }} />
                            </div>
                          </div>
                        </Tooltip>
                      }
                  </div>

                  {(values.cancelledRef !== undefined && values.type !== "cancellation") &&
                    <div>
                      <Typography
                        style={{
                          padding: 8,
                          background: "white",
                          fontSize: 10,
                          borderRadius: 60,
                          color: "rgb(44, 83, 125)",
                          fontWeight: 500,
                          marginLeft: 5
                        }}>
                        {values.cancelledRefInvoiceNr
                        ? `Gutschrift erstellt Nr.: ${JSON.parse(values.cancelledRefInvoiceNr).map(item => item.value).join("")}`
                        : "Gutschrift erstellt"}
                      </Typography>
                    </div>
                  }
                </div>
                )}


              </div>
            }
          </Grid>
        </Grid>

        {/* Right Section: Buttons and Close Icon */}
        <Grid container item direction="row" alignItems="center" justifyContent="flex-end" className="appbar-right">
          {selectedClient && !isFetching &&
            <>
              {/* SAVE */}
              {values.sendState !== "scheduled" &&
              <Button
                disabled={invoiceDateError !== "" || dueDateError !== "" || invoiceNumberError || isFetching}
                size="small"
                variant="outlined"
                color="primary"
                className="app-bar-button"
                style={{ marginRight: "10px" }}
                startIcon={<SaveIcon />}
                onClick={(e) => {
                  invoiceHandler(true);

                  if (saveNewInvoiceSchema) {
                    setSettingsAtom((prev) => ({
                      ...prev,
                      invoicesNumberItems: values.invoiceNr,
                    }));
                  }
                }}
              >
                <span className="app-bar-button-label">Speichern</span>
              </Button>
              }

              {/* PDF PREVIEW */}
              <Button
                disabled={invoiceDateError !== "" || dueDateError !== "" || invoiceNumberError || isFetching}
                size="small"
                variant="outlined"
                color="primary"
                className="app-bar-button"
                style={{ marginRight: "10px" }}
                startIcon={<PictureAsPdfIcon />}
                onClick={() => {
                  setSendMode(false);
                  setOpen(true);
                  invoiceHandler(false);

                  if (saveNewInvoiceSchema) {
                    setSettingsAtom((prev) => ({
                      ...prev,
                      invoicesNumberItems: values.invoiceNr,
                    }));
                  }
                }}
              >
                <span className="app-bar-button-label">PDF Vorschau</span>
              </Button>

              {/* SEND */}
              {values.sendState !== "scheduled" &&
              <Button
                disabled={invoiceDateError !== "" || dueDateError !== "" || invoiceNumberError || isFetching}
                size="small"
                variant="contained"
                color="primary"
                className="app-bar-button app-bar-button--send"
                startIcon={<MailIcon />}
                onClick={(e) => {
                  invoiceHandler(false);
                  setOpen(true);
                  setSendMode(true)

                  if (saveNewInvoiceSchema) {
                    setSettingsAtom((prev) => ({
                      ...prev,
                      invoicesNumberItems: values.invoiceNr,
                    }));
                  }
                }}
              >
                <span className="app-bar-button-label">Senden</span>
              </Button>
              }
            </>
          }

          {selectedClient && isFetching &&
          <div className="loading-dots">
            <span className="dot"></span>
            <span className="dot"></span>
            <span className="dot"></span>
          </div>
          }

          {/* CLOSE */}
          <IconButton
            edge="end"
            color="inherit"
            className="invoice-dialog-close-appbar"
            style={{
              marginLeft: "10px",
              display: "flex",
              width: "35px",
              height: "35px",
              border: "1px solid rgba(0, 0, 0, 0.04)"
            }}
            onClick={() => {
              setOpenInvoiceDialogAtom({
                ...openInvoiceDialogAtom,
                open: false,
                invoiceId: "",
                duplicate: false
              });
            }}
            aria-label={`${t("BUTTONS.AL_close")}`}
          >
            <CloseIcon color="primary" />
          </IconButton>
        </Grid>
      </Toolbar>
    </AppBar>
  );
};

export default InvoiceAppBar;
