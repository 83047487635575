import React, { useState, useContext } from "react";
import {
  IconButton,
  Menu,
  MenuItem,
  Checkbox,
  FormControlLabel,
  ListItemIcon,
  ListItemText,
  Modal,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button
} from "@material-ui/core";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import IndeterminateCheckBoxIcon from "@material-ui/icons/IndeterminateCheckBox";
import CloseIcon from "@material-ui/icons/Close";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import LockIcon from "@material-ui/icons/Lock";
import GetAppIcon from '@material-ui/icons/GetApp';

import { useHttpClient } from "../../shared/hooks/http-hook";
import { AuthContext } from "../../shared/context/auth-context";
import { useTranslation } from "react-i18next";


import JSZip from 'jszip';
import { enqueueSnackbar } from "notistack";



export default function ProductsBulkEdit({
  dataInitial,
  checkedItems,
  setCheckedItems,
  setBulkEdit
}) {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const auth = useContext(AuthContext);
  const { sendRequest } = useHttpClient();
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const { t } = useTranslation();

  const isAllChecked =
    dataInitial.length === checkedItems.length && checkedItems.length !== 0;
  const isSomeChecked = checkedItems.length > 0 && !isAllChecked;

  const handleChange = (e) => {
    e.stopPropagation();
    if (isSomeChecked) {
      setCheckedItems([]);
    } else if (e.target.checked) {
      const allIds = dataInitial.map((item) => item._id);
      setCheckedItems(allIds);
    } else {
      setCheckedItems([]);
    }
  };

  const handleMenuOpen = (event) => {
    event.stopPropagation(); // Prevent propagation to parent elements
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = (event) => {
    event.stopPropagation();
    setAnchorEl(null);
  };


const deleteProducts = async () => {
  try {
    const formData = new FormData();
    formData.append("userId", auth.userId);
    formData.append("productId", JSON.stringify(checkedItems)); // stringified Array of invoice ids

    // Send the request to the backend
    const response = await sendRequest(
      `${process.env.REACT_APP_BACKEND_URL_API}/products/deleteproducts`,
      "POST",
      formData,
      {
        Authorization: "Bearer " + auth.token,
      }
    );
    setBulkEdit(true);
    enqueueSnackbar("Produkte wurden gelöscht", { variant: "success" });
    setCheckedItems([]);
  } catch (error) {
  }
};
  
  
  
  

  return (
    <div
      className="checkbox-bulkedit"
      style={{
        border: isSomeChecked || isAllChecked ? "1px solid rgb(237, 239, 255)" : "1px solid transparent",
        borderRadius: 6,
      }}
    >
      <IconButton style={{ paddingRight: 0, color: "inherit", height: 38 }} disableRipple>
        <FormControlLabel
          style={{ marginRight: 0 }}
          control={
            <Checkbox
              checked={isAllChecked}
              onChange={handleChange}
              color="primary"
              style={{ zoom: 0.8, padding: 10 }}
              icon={
                isSomeChecked ? (
                  <IndeterminateCheckBoxIcon color="primary" />
                ) : (
                  <CheckBoxOutlineBlankIcon />
                )
              }
              checkedIcon={
                isAllChecked ? (
                  <CheckBoxIcon />
                ) : (
                  <IndeterminateCheckBoxIcon color="primary" />
                )
              }
            />
          }
        />
      </IconButton>

      {(isSomeChecked || isAllChecked) && (
        <>
          <IconButton
            aria-label="filters"
            onClick={handleMenuOpen} // Opens the menu
            style={{ padding: 5, color: "inherit", borderRadius: 60, marginRight: 3 }}
            disableRipple
          >
            <ArrowDropDownIcon color="primary" />
          </IconButton>

          <Menu
            anchorEl={anchorEl}
            open={open}
            onClose={handleMenuClose}
            PaperProps={{
              elevation: 1,
              style: {
                width: "200px",
              },
            }}
          >
            {/* Header with number of invoices and CloseIcon */}
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                padding: "5px 10px",
                borderBottom: "1px solid #ddd",
                cursor: "default",
              }}
              onClick={(e) => e.stopPropagation()}
            >
              <span style={{ fontSize: "13px", fontWeight: "bold" }}>
                {checkedItems.length} Rechnung{checkedItems.length > 1 ? "en" : ""}
              </span>
              <IconButton onClick={handleMenuClose} size="small">
                <CloseIcon fontSize="small" color="primary"/>
              </IconButton>
            </div>
            {/* Menu Items */}

            {/* Delete */}
            <MenuItem
              onClick={(e) => {
                e.stopPropagation();
                setShowDeleteModal(true);
                handleMenuClose(e);
              }}
              style={{ fontSize: "13px", marginLeft: -10 }}
            >
              <ListItemIcon>
                <DeleteForeverIcon color="primary" fontSize="small" style={{ marginRight: "5px" }} />
              </ListItemIcon>
              <ListItemText primary="Löschen" />
            </MenuItem>
          </Menu>
        </>
      )}

      {/* MODAL delete */}
      <Modal
        open={showDeleteModal}
        onClick={(e) => {
          e.stopPropagation(); // Prevent backdrop click propagation
          setShowDeleteModal(false)
        }}
      >
        <Dialog
          open={showDeleteModal}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle id="alert-dialog-slide-title">{checkedItems.length} Produkte löschen?</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              Sollen {checkedItems.length} Produkte gelöscht werden?
              <br />
              <span style={{ fontWeight: 500, color: "red" }}>Das Löschen der Produkte kann nicht mehr rückgängig gemacht werden.</span>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              size="small"
              variant="outlined"
              color="primary"
              style={{ marginRight: "10px" }}
              onClick={(e) => setShowDeleteModal(false)
              }
            >
              {t("BUTTONS.cancel")}
            </Button>
            <Button
              size="small"
              variant="contained"
              color="primary"
              onClick={(e) => {
                setShowDeleteModal(false);
                deleteProducts();
              }}
              startIcon={<DeleteForeverIcon />}
            >
              Löschen
            </Button>
          </DialogActions>
        </Dialog>
      </Modal>
    </div>
  );
}
