import React, { useRef, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { TextField, Grid, InputAdornment, IconButton } from "@material-ui/core";
import ClearIcon from '@material-ui/icons/Clear';
import SearchIcon from '@material-ui/icons/Search';

import "./ProductsSearch.scss";

export default function ProductsSearch({ 
  handleSearchClear,
  handleSearch,
  type,
  searchTerm,
  allInvoicesByClientAtom,
  setAllInvoicesByClientAtom,
  allInvoicesByOriginalRefAtom,
  setAllInvoicesByOriginalRefAtom,
  setCheckedItems
}) {
  const { t } = useTranslation();
  const searchInputRef = useRef(); // Ref to programmatically focus the input
  const [isFocused, setIsFocused] = useState(false); // State for tracking input focus
  const [localSearchTerm, setLocalSearchTerm] = useState(searchTerm); // Local state for debounced value

  const borderStyle = {
    borderWidth: isFocused || localSearchTerm !== "" ? "1px" : "1px", // Change border width
    borderColor: isFocused || localSearchTerm !== "" ? "rgb(44, 83, 125)" : "#edefff", // Change border color
  };

  // Debounce logic: Trigger handleSearch after a short delay
  useEffect(() => {
    const handler = setTimeout(() => {
      if (localSearchTerm !== searchTerm) {
        handleSearch({ target: { value: localSearchTerm } }); // Trigger the search
      }
    }, 300); // Adjust delay as needed

    return () => {
      clearTimeout(handler); // Clear the timeout on value change or unmount
    };
  }, [localSearchTerm, searchTerm]); // Depend only on terms, not the function

  const handleInputChange = (e) => {
    setCheckedItems && setCheckedItems([]);
    if(allInvoicesByClientAtom !== "") {
      setAllInvoicesByClientAtom("");
    }
    if(allInvoicesByOriginalRefAtom !== "") {
      setAllInvoicesByOriginalRefAtom("");
    }
    setLocalSearchTerm(e.target.value); // Update local state for input
  };

  const handleClear = () => {
    setLocalSearchTerm(""); // Clear local state
    handleSearchClear(); // Call the clear handler
  };

  return (
    <Grid 
      item 
      style={{
        border: 1,
        display: "flex",
        alignItems: "center",
        flex: 1,
        marginRight: "8px",
        maxHeight: "100%",
      }}
    >
      <TextField
        id="searchInput"
        variant="filled"
        type="text"
        className="search-field"
        // style={{backgroundColor: searchTerm !== "" ? "#1a7bc9 !important" : "transparent", borderRadius: 10}}
        placeholder={"Produktname oder Produktnummer suchen ..."}
        value={localSearchTerm.replace(/\{Ref:[a-fA-F0-9]{24}\}/, "").trim().replace(/\{RefRec:[a-fA-F0-9]{24}\}/, "").trim()} // Bind to local state
        onChange={handleInputChange} // Update input and debounce search
        onFocus={() => setIsFocused(true)} // Set focus state to true
        onBlur={() => setIsFocused(false)} // Set focus state to false
        inputRef={searchInputRef} // Attach the ref
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon style={{marginTop: 0, width: 22, height: 22, color: searchTerm === "" ? "rgb(44, 83, 125" : "white", background: searchTerm === "" ? "white" : "#E312CA", borderRadius: 4, padding: 1}}/>
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment position="end" style={{ marginRight: "5px" }}>
              {localSearchTerm !== "" && (
                <IconButton
                  aria-label="clear search"
                  onClick={()=> {handleClear(); setAllInvoicesByClientAtom(""); setAllInvoicesByOriginalRefAtom("")}} // Call the updated clear function
                  edge="end"
                  style={{ zoom: 0.8 }}
                >
                  <ClearIcon />
                </IconButton>
              )}
            </InputAdornment>
          ),
          inputProps: {
            style: {
              fontSize: "14px",
              background: "transparent", // Ensure transparent background
              color: "rgb(44, 83, 125)",
              fontWeight: 500,
              paddingTop: 16,
              paddingBottom: 15,
            },
          },
        }}
        hiddenLabel
        fullWidth
      />
    </Grid>
  );
}
