import React, { useEffect, useState, useContext } from "react";

// IMPORT hooks & context
import { useTranslation } from "react-i18next";
import { useHttpClient } from "../shared/hooks/http-hook";
import { AuthContext } from "../shared/context/auth-context";

// IMPORT recoil
import {
  useRecoilState,
} from 'recoil';
import userAtomState from "../_atoms/userAtom";
import clientsAtomState from "../_atoms/clientsAtom";
import openInvoiceDialogAtomState from "../_atoms/openInvoiceDialogAtom";
import settingsAtomState from "../_atoms/settingsAtom";
import recurringActivatedAtomState from "../_atoms/recurringActivatedAtom";
import allInvoicesByClientAtomState from "../_atoms/allInvoicesByClientAtom"
import allInvoicesByOriginalRefAtomState from "../_atoms/allInvoicesByOriginalRefAtom";
import selectedMenuItemAtomState from "../_atoms/selectedMenuItemAtom";
import subMenuOpenAtomState from "../_atoms/subMenuOpenAtom";
import subMenuInvoicesOpenAtomState from "../_atoms/subMenuInvoicesOpenAtom";
import mobileOpenState from "../_atoms/mobileOpenAtom";

import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';

// IMPORT components
import { useSnackbar } from 'notistack';
import { useNavigate } from "react-router-dom";

// IMPORT components MATERIAL UI
import {
  Typography,
  Box,
  Container,
  Grid,
  Tooltip,
  Button,
  Chip
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useTheme } from '@material-ui/core/styles';
import Fab from '@material-ui/core/Fab';
import Hidden from '@material-ui/core/Hidden';

// IMPORT icons MATERIAL UI
import ClientsIcon from "@material-ui/icons/RecentActors";
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';
import FilterListIcon from '@material-ui/icons/FilterList';
import ImportExportIcon from '@material-ui/icons/ImportExport';
import EditIcon from '@material-ui/icons/Edit';
import ScheduleIcon from '@material-ui/icons/Schedule';
import MailIcon from '@material-ui/icons/Mail';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import AddIcon from '@material-ui/icons/Add';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import GavelIcon from '@material-ui/icons/Gavel';
import SearchIcon from '@material-ui/icons/Search';
import ErrorIcon from '@material-ui/icons/Error';
import WatchLaterIcon from '@material-ui/icons/WatchLater';
import RepeatIcon from '@material-ui/icons/Repeat';
import PlaylistAddCheckIcon from '@material-ui/icons/PlaylistAddCheck';
import SubjectIcon from '@material-ui/icons/Subject';
import RotateLeftIcon from '@material-ui/icons/RotateLeft';
import InfoIcon from '@material-ui/icons/Info';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import CancelPresentationIcon from '@material-ui/icons/CancelPresentation';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import PauseIcon from '@material-ui/icons/Pause';
import PlayCircleFilledIcon from '@material-ui/icons/PlayCircleFilled';

// IMPORT own functions
import { SET_VALUE } from '../_functions/SET_VALUE';
import { DB_PATCH_SETTINGS } from '../_functions/DB_SETTINGS';
import { RETURN_DATE } from '../_functions/DATES';
import { RETURN_HOURS } from '../_functions/DATES';
import { RETURN_OVERDUE_DAYS } from '../_functions/DATES';

// IMPORT own components
import UserProfileIncompleteWarning from "../user/components/UserProfileIncompleteWarning";
import InvoicesSearch from "./components/InvoicesSearch";
import InvoicesFilterRecurring from "./components/InvoicesFilterRecurring";
import InvoicesSortRecurring from "./components/InvoicesSortRecurring";
import InvoicesSettings from "./components/InvoicesSettings";
import InvoicesMenuDots from "./components/InvoicesMenuDots";
import LogoMonogram from './../_assets/logo_monogram.svg';
import DateRange from "../shared/components/DateRange/DateRange";
import RecurringRefDialog from './components/RecurringRefDialog'

// IMPORT own CSS
import "./Invoices.scss"
import { FlashOnTwoTone } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  // necessary for content to be below app bar
  toolbar: {
    height: '0px',
    '@media (max-width: 959px)': {
      minHeight: '45px',
      height: '45px',
    },
    '@media (max-width: 599px)': {
      minHeight: '45px',
      height: '45px',
    },
    '@media (max-width: 455px)': {
      minHeight: '45px',
      height: '45px',
    },
  },
  table: {
    minWidth: 650,
  },
}));

const InvoicesRecurrings = ({ type }) => {
  // HOOKS & CONTEXT
  const { t } = useTranslation();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();
  const { isLoading, error, sendRequest } = useHttpClient();
  const auth = useContext(AuthContext);
  const navigate = useNavigate();

  // GLOBAL STATE (RECOIL)
  const [userAtom] = useRecoilState(userAtomState);
  const [openInvoiceDialogAtom, setOpenInvoiceDialogAtom] = useRecoilState(openInvoiceDialogAtomState);
  const [clientsAtom, setClientsAtom] = useRecoilState(clientsAtomState);
  const [recurringActivatedAtom, setRecurringActivatedAtom] = useRecoilState(recurringActivatedAtomState);
  const [settingsAtom, setSettingsAtom] = useRecoilState(settingsAtomState);
  const [allInvoicesByClientAtom, setAllInvoicesByClientAtom] = useRecoilState(allInvoicesByClientAtomState);
  const [allInvoicesByOriginalRefAtom, setAllInvoicesByOriginalRefAtom] = useRecoilState(allInvoicesByOriginalRefAtomState);
  const [selectedMenuItemAtom, setSelectedMenuItemAtom] = useRecoilState(selectedMenuItemAtomState);
  const [subMenuOpenAtom, setSubMenuOpenAtom] = useRecoilState(subMenuOpenAtomState);
  const [subMenuInvoicesOpenAtom, setSubMenuInvoicesOpenAtom] = useRecoilState(subMenuInvoicesOpenAtomState);
  const [mobileOpen, setMobileOpen] = useRecoilState(mobileOpenState);
  const [checkedItems, setCheckedItems] = useState([]);

  // LOCAL STATE (GLOBAL DEPENDENCY monitoring temporay CHANGES until SAVED)
  const [settings, setSettings] = useState(settingsAtom);

  // LOCAL STATE (PURE)
  const [recurring, setRecurring] = useState(true);
  const [values, setValues] = useState({
    submenuOpen: false, // values: false, search, filter, sort, settings
    invoicesFiltered: {},
    searchInput: "",
    chipDirectChange: false,
    save: false,
    loading: true,
  });
  const [recurringRef, setRecurringRef] = useState([]);
  const [recurringRefDialogOpen, setRecurringRefDialogOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  // Data variable
  const [dataInitial, setDataInitial] = useState([]);

  // Infinite Scroll Variables
  const INITIAL_ITEMS = 20; // Load 10 items initially
  const [offset, setOffset] = useState(0); // Track the current offset for fetching
  const [hasMoreInvoices, setHasMoreInvoices] = useState(true); // To know if more invoices exist
  const [isFetching, setIsFetching] = useState(false); // Track if a fetch is in progress

  // Search Variables
  const [searchTerm, setSearchTerm] = useState('');

  /***
  FETCH
  ***/
  const fetchData = async (currentOffset, limit, isInitialLoad = false, searchTerm = "", newRecurring) => {
    try {
      const storedUserData = localStorage.getItem('userData');
      if (!storedUserData) {
        throw new Error('No user data found in local storage');
      }

      const resUser = JSON.parse(storedUserData);

      setIsFetching(true); // Set fetching flag to true

      const response = await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL_API}/invoicesrecurrings/user/${resUser.userId}?limit=${limit}&offset=${currentOffset}&searchTerm=${searchTerm}&sortField=${settingsAtom.invoicesRecurringsSortField}&sortOrder=${settingsAtom.invoicesRecurringsSortOrder}&recurring=${JSON.stringify(newRecurring)}`,
        'GET',
        null,
        { Authorization: 'Bearer ' + resUser.token }
      );

      if (response && response.invoices) {
        if (response.invoices.length < limit) {
          setHasMoreInvoices(false); // No more invoices to fetch
        }

        if (isInitialLoad) {
          setDataInitial(response.invoices); // Replace existing data for initial load
        } else {
          // Append new invoices, making sure no duplicates
          setDataInitial(prevInvoices => [
            ...prevInvoices,
            ...response.invoices,
          ]);
        }
      }

      setIsFetching(false);
    } catch (err) {
      setIsFetching(false);
      console.error('Error fetching invoices:', err);
    }
  };

  /***
  INITIAL LOADING
  ***/
  useEffect(() => {
    // Initial load when the page loads or search term/recurring changes
    setOffset(0); // Reset offset
    fetchData(0, INITIAL_ITEMS, true, searchTerm); // Fetch the first 5 invoices with updated recurring value
  }, [searchTerm]); // Trigger on recurring or search term change

  // Store scroll position before refetching
  const [scrollPos, setScrollPos] = useState(0);

  // Save scroll position before dialog opens
  useEffect(() => {
    if (openInvoiceDialogAtom.open) {
      setScrollPos(window.scrollY); // Save current scroll position
    }
  }, [openInvoiceDialogAtom.open]);

  // Refetch all invoices and restore scroll position when the dialog closes
  useEffect(() => {
    if (!openInvoiceDialogAtom.open) {
      fetchData(0, dataInitial.length, true, searchTerm); // Refetch all data to ensure updates
      setTimeout(() => {
        window.scrollTo(0, scrollPos); // Restore scroll position
      }, 0);
    }
  }, [openInvoiceDialogAtom.open]);

  // Load more invoices when the user clicks the "Load More" button
  const loadMoreInvoices = () => {
    if (!isFetching && hasMoreInvoices) {
      const limit = INITIAL_ITEMS;
      const newOffset = offset + limit;
      setOffset(newOffset); // Update the offset
      fetchData(newOffset, limit, false, searchTerm); // Fetch more data based on new offset
    }
  };

  /***
  SEARCH
  ***/
  // Handle search input
  const handleSearch = (e) => {
    const term = e.target.value;
    setSearchTerm(term);

    // Scroll to top when the search term is updated
    window.scrollTo({
      top: 0,
    });

    if (term !== "") {
      // Add logic here to filter invoices or trigger a new fetch based on the search term
    } else {
      handleSearchClear(); // Clear search when input is empty
    }
  };

  const handleSearchClear = () => {
    window.scrollTo({
      top: 0,
    });

    setSearchTerm("");
    setDataInitial([]);
    setOffset(0); // Reset offset for new search
    setHasMoreInvoices(true); // Allow more invoices to load

    // Use setTimeout to wait for the state updates to complete
    setTimeout(() => {
      fetchData(0, INITIAL_ITEMS, true, ""); // Fetch the first 5 invoices with cleared search term and respect recurring state
    }, 0);
  };

  // Trigger fetch with search term when the user types
  useEffect(() => {
    if (searchTerm !== '') {
      setOffset(0); // Reset offset for new search
      fetchData(0, INITIAL_ITEMS, true, searchTerm); // Pass recurring state along with search term
      setHasMoreInvoices(true); // Allow more invoices to load
    } else {
      // If search is cleared, load initial invoices again respecting the recurring state
      fetchData(0, INITIAL_ITEMS, true, "");
    }
  }, [searchTerm]); // Also listen for recurring state changes

  /***
  CHANGE SETTINGS
  ***/
  const handleChange = (e) => {
    SET_VALUE(e, setSettings);
  };

  // ON SAVE: 1) Update settingsAtom
  const updateSettingsHandler = () => {
    // GLOBAL changes
    // setSettingsAtom(settings);

    setSettingsAtom((prevSettings) => {
      const { dateRangeStartValue, dateRangeEndValue, ...restPrevSettings } = prevSettings;

      return {
        ...restPrevSettings, // Update all other fields
        ...settings,         // Apply new settings
        dateRangeStartValue, // Keep these fields unchanged, because they are not change in settings but in settingsAtom
        dateRangeEndValue,
      };
    });

    // Save variable is only needed to trigger submenuClose when NOTHING changes in settings and save button is clicked
    SET_VALUE({ target: { id: "save", value: true } }, setValues);

    setDataInitial([]);
    setOffset(0); // Reset offset for new search
    setHasMoreInvoices(true); // Allow more invoices to load on scroll
    window.scrollTo({
      top: 0,
    });
  };

  // ON SAVE: 2) DB: PATCH SETTINGS
  useEffect(() => {
    if (auth.token) {
      DB_PATCH_SETTINGS(settingsAtom, auth, sendRequest, enqueueSnackbar, t, false);

      SET_VALUE({
        target: {
          "submenuOpen": false,
          "chipDirectChange": false,
          "save": false,
        }
      }, setValues, "multi")
    }
  }, [auth.token, settingsAtom, values.save]);

  // ON CANCEL:
  const cancelSettingsHandler = () => {
    // Re-set original values
    setSettings(settingsAtom);
    SET_VALUE({ target: { id: "submenuOpen", value: false } }, setValues);
  };

  useEffect(() => {
    setDataInitial([]);
    setOffset(0); // Reset offset for new search
    setHasMoreInvoices(true); // Allow more invoices to load on scroll
    fetchData(0, INITIAL_ITEMS, true, searchTerm); // Fetch filtered data with cleared search term
  }, [settingsAtom]);


  return (
    <div>
      <div className={classes.toolbar} />
      <div className="mobile-menu-section" style={{ borderBottom: "1px solid rgb(237, 239, 255)", position: "sticky", top: 47, zIndex: 10, width: "100%", padding: "2px 4px" }}>
        {/* <DateRange
          type={type === "invoices" ? "invoices" : "estimates"}
          mobile={true}
        /> */}

        <InvoicesFilterRecurring
          settings={settings}
          settingsAtom={settingsAtom}
          handleChange={handleChange}
          cancelSettingsHandler={cancelSettingsHandler}
          updateSettingsHandler={updateSettingsHandler}
          setValues={setValues}
          SET_VALUE={SET_VALUE}
          setSettings={setSettings}
          values={values}
          type={type}
          recurring={recurring}
          setOffset={setOffset}
          fetchData={fetchData}
          INITIAL_ITEMS={INITIAL_ITEMS}
          searchTerm={searchTerm}
          setHasMoreInvoices={setHasMoreInvoices}
          setSettingsAtom={setSettingsAtom}
          mobile={true}
        />

        <InvoicesSortRecurring
          settings={settings}
          handleChange={handleChange}
          cancelSettingsHandler={cancelSettingsHandler}
          updateSettingsHandler={updateSettingsHandler}
          setValues={setValues}
          SET_VALUE={SET_VALUE}
          setSettings={setSettings}
          values={values}
          type={type}
          recurring={false}
          mobile={true}
        />

        <InvoicesSettings
          settings={settings}
          handleChange={handleChange}
          cancelSettingsHandler={cancelSettingsHandler}
          updateSettingsHandler={updateSettingsHandler}
          setValues={setValues}
          SET_VALUE={SET_VALUE}
          setSettings={setSettings}
          values={values}
        />
      </div>

      <div className="not-mobile-menu-section" style={{ position: "sticky", top: 0, zIndex: 9, width: "100%" }}>
        <Grid container className="subpage-header" item direction="row" alignItems="center" alignContent="center" style={{ paddingBottom: 0 }}>
          <Grid className="main-headline-wrapper" item container direction="row" alignItems="center" style={{ marginRight: "10px", marginBottom: 10 }}>
            <Grid item >
              <Grid item container alignItems="center">
              <div style={{borderRadius: 4, width: 30, height: 30, background: "white", marginRight: "15px", background: "linear-gradient(to right top, rgb(38, 108, 189), rgb(36, 115, 192), rgb(33, 150, 243), #00bca7, #00bf72)", marginRight: 8, marginTop: -1, marginLeft: -1, display: "flex", justifyContent: "center", alignItems: "center"}}>
                    <RepeatIcon style={{color: "white"}}/>
                </div>
                <Typography variant="h6" component="h1" style={{ fontWeight: "600" }} className="headline">Wiederkehrende Rechnungslegung</Typography>

                {/* {(!values.loading || !isLoading) && userAtom.data.signupCompleted && (userAtom.clientscreated || userAtom.data.clients.length !== 0) && !isFetching && */}
                {userAtom.data.clients.length !== 0 &&
                <Hidden smDown>
                  <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    startIcon={<AddCircleIcon className="MuiSvgIcon-root" fontSize="large" />}
                    className="add-button" // Add the custom class
                    onClick={() => {
                      setOpenInvoiceDialogAtom({
                        ...openInvoiceDialogAtom,
                        open: true,
                        isEstimate: false,
                        recurring: true,
                      });
                    }}
                  >
                    Erstellen
                  </Button>
                </Hidden>
                }
              </Grid>
            </Grid>
          </Grid>

          <Grid
            item
            container
            alignItems="center"
            style={{ display: "flex", height: "48px" }} // Set consistent height for all children
          >
            {/* <DateRange
              type={type === "invoices" ? "invoices" : "estimates"}
            /> */}

            <InvoicesSearch
              handleSearchClear={handleSearchClear}
              handleSearch={handleSearch}
              type={type}
              searchTerm={searchTerm}
              allInvoicesByClientAtom={allInvoicesByClientAtom}
              setAllInvoicesByClientAtom={setAllInvoicesByClientAtom}
              allInvoicesByOriginalRefAtom={allInvoicesByOriginalRefAtomState}
              setAllInvoicesByOriginalRefAtom={setAllInvoicesByOriginalRefAtom}
            />

            <InvoicesSettings
              settings={settings}
              handleChange={handleChange}
              cancelSettingsHandler={cancelSettingsHandler}
              updateSettingsHandler={updateSettingsHandler}
              setValues={setValues}
              SET_VALUE={SET_VALUE}
              setSettings={setSettings}
              values={values}
            />
            {/* <InvoicesSettings
              settings={settings}
              handleChange={handleChange}
              cancelSettingsHandler={cancelSettingsHandler}
              updateSettingsHandler={updateSettingsHandler}
              setValues={setValues}
              SET_VALUE={SET_VALUE}
              setSettings={setSettings}
              values={values}
            /> */}
          </Grid>
        </Grid>

        <Grid container item xs={12} alignItems="center" className="chips" style={{ borderBottom: "1px solid rgb(237, 239, 255)", paddingBottom: 8  }}>
          <InvoicesFilterRecurring
            settings={settings}
            settingsAtom={settingsAtom}
            handleChange={handleChange}
            cancelSettingsHandler={cancelSettingsHandler}
            updateSettingsHandler={updateSettingsHandler}
            setValues={setValues}
            SET_VALUE={SET_VALUE}
            setSettings={setSettings}
            values={values}
            type={type}
            recurring={recurring}
            setOffset={setOffset}
            fetchData={fetchData}
            INITIAL_ITEMS={INITIAL_ITEMS}
            searchTerm={searchTerm}
            setHasMoreInvoices={setHasMoreInvoices}
            setSettingsAtom={setSettingsAtom}
          />

          <InvoicesSortRecurring
            settings={settings}
            handleChange={handleChange}
            cancelSettingsHandler={cancelSettingsHandler}
            updateSettingsHandler={updateSettingsHandler}
            setValues={setValues}
            SET_VALUE={SET_VALUE}
            setSettings={setSettings}
            values={values}
            type={type}
            recurring={false}
          />
        </Grid>
      </div>

      {userAtom.data.signupCompleted &&
        <React.Fragment>
          <Container maxWidth={false} disableGutters className="bg--1" style={{ paddingBottom: 70 }}>
            <Box
              display="flex"
              direction="column"
              className="list-box"
            >
              <Grid container item xs={12} >
                <div className="box-shadow--1 invoices-list">
                  <Grid
                    item
                    className="sticky-nav box-shadow--1 h-padding--5-5 tool-menu"
                    style={{ top: 149 }}
                  >
                    <Grid container>
                      <Grid
                        container
                        alignItems="center"
                        justifyContent="space-between"
                        item
                        xs={12}
                      >
                        <Grid container item xs={12} justifyContent="space-between">

                          <Grid xs={12} lg={2} container item justifyContent="flex-start" alignItems="center" style={{ paddingLeft: 15 }}>
                            <Typography>Status</Typography>
                          </Grid>

                          <Grid xs={12} lg={2}
                            container
                            item
                            justifyContent="center"
                            alignItems="center"
                            alignContent="center"
                            className="table-headline"
                            style={{ background: settings.invoicesRecurringsSortField === "invoiceCompany" && "rgb(237, 239, 255)", borderRadius: 6 }}
                            onClick={() => {
                              setSettings({ ...settings, invoicesRecurringsSortField: "invoiceCompany", invoicesRecurringsSortOrder: settings.invoicesRecurringsSortOrder === "asc" ? "desc" : "asc" });
                              setSettingsAtom({ ...settingsAtom, invoicesRecurringsSortField: "invoiceCompany", invoicesRecurringsSortOrder: settingsAtom.invoicesRecurringsSortOrder === "asc" ? "desc" : "asc" })
                            }}
                          >
                            <div style={{ display: "flex", alignItems: "flex-start" }}>
                              <Typography style={{ paddingLeft: 15 }}>Kunde</Typography>
                              {settingsAtom.invoicesRecurringsSortField === "invoiceCompany" && settings.invoicesRecurringsSortOrder === "desc" ? <ArrowDownwardIcon style={{ zoom: 0.6, marginLeft: 5 }} /> : ""}
                              {settingsAtom.invoicesRecurringsSortField === "invoiceCompany" && settings.invoicesRecurringsSortOrder === "asc" ? <ArrowUpwardIcon style={{ zoom: 0.6, marginLeft: 5 }} /> : ""}
                            </div>
                          </Grid>

                          <Grid xs={12} lg={8} container item justifyContent="flex-start" alignItems="center" >
                            <Grid container item xs={12} md={2} justifyContent="center">
                              <Typography>Intervall</Typography>
                            </Grid>

                            <Grid xs={4} md={3}
                              container
                              item
                              justifyContent="center"
                              alignItems="center"                              
                            >
                              <div style={{ display: "flex", alignItems: "center", justifyContent: "center", padding: "5px 10px" }}>
                                <Typography
                                  style={{
                                    textAlign: "center",
                                    whiteSpace: "normal",
                                    wordBreak: "break-word",
                                  }}
                                >
                                  Referenznummer
                                </Typography>
                              </div>
                            </Grid>

                            <Grid xs={12} md={2}
                              container
                              item
                              justifyContent="center"
                              alignItems="center"
                              className="table-headline"
                              style={{ background: settings.invoicesRecurringsSortField === "invoiceNet" && "rgb(237, 239, 255)", borderRadius: 6 }}
                              onClick={() => {
                                setSettings({ ...settings, invoicesRecurringsSortField: "invoiceNet", invoicesRecurringsSortOrder: settings.invoicesRecurringsSortOrder === "asc" ? "desc" : "asc" });
                                setSettingsAtom({ ...settingsAtom, invoicesRecurringsSortField: "invoiceNet", invoicesRecurringsSortOrder: settingsAtom.invoicesRecurringsSortOrder === "asc" ? "desc" : "asc" })
                              }}
                            >
                              <div style={{ display: "flex", alignItems: "center", justifyContent: "center", padding: "5px 10px" }}>
                                <Typography
                                  style={{
                                    textAlign: "center",
                                    whiteSpace: "normal",
                                    wordBreak: "break-word",
                                  }}
                                >
                                 Netto
                                </Typography>
                                {settingsAtom.invoicesRecurringsSortField === "invoiceNet" && settings.invoicesRecurringsSortOrder === "desc" && (
                                  <ArrowDownwardIcon style={{ zoom: 0.6, marginLeft: 5 }} />
                                )}
                                {settingsAtom.invoicesRecurringsSortField === "invoiceNet" && settings.invoicesRecurringsSortOrder === "asc" && (
                                  <ArrowUpwardIcon style={{ zoom: 0.6, marginLeft: 5 }} />
                                )}
                              </div>
                            </Grid>

                            <Grid xs={12} md={2}
                              container
                              item
                              justifyContent="center"
                              alignItems="center"
                              className="table-headline"
                              style={{ background: settings.invoicesRecurringsSortField === "invoiceGross" && "rgb(237, 239, 255)", borderRadius: 6 }}
                              onClick={() => {
                                setSettings({ ...settings, invoicesRecurringsSortField: "invoiceGross", invoicesRecurringsSortOrder: settings.invoicesRecurringsSortOrder === "asc" ? "desc" : "asc" });
                                setSettingsAtom({ ...settingsAtom, invoicesRecurringsSortField: "invoiceGross", invoicesRecurringsSortOrder: settingsAtom.invoicesRecurringsSortOrder === "asc" ? "desc" : "asc" })
                              }}
                            >
                              <div style={{ display: "flex", alignItems: "center", justifyContent: "center", padding: "5px 10px" }}>
                                <Typography
                                  style={{
                                    textAlign: "center",
                                    whiteSpace: "normal",
                                    wordBreak: "break-word",
                                  }}
                                >
                                 Brutto
                                </Typography>
                                {settingsAtom.invoicesRecurringsSortField === "invoiceGross" && settings.invoicesRecurringsSortOrder === "desc" && (
                                  <ArrowDownwardIcon style={{ zoom: 0.6, marginLeft: 5 }} />
                                )}
                                {settingsAtom.invoicesRecurringsSortField === "invoiceGross" && settings.invoicesRecurringsSortOrder === "asc" && (
                                  <ArrowUpwardIcon style={{ zoom: 0.6, marginLeft: 5 }} />
                                )}
                              </div>
                            </Grid>

                            <Grid xs={4} md={2}
                              container
                              item
                              justifyContent="center"
                              alignItems="center"
                            >
                              <div style={{ display: "flex", alignItems: "center", justifyContent: "center", padding: "5px 10px" }}>
                                <Typography
                                  style={{
                                    textAlign: "center",
                                    whiteSpace: "normal",
                                    wordBreak: "break-word",
                                  }}
                                >
                                  Rechnungen
                                </Typography>
                              </div>
                            </Grid>

                            {/* Optionen */}
                            <Grid container item xs={12} md={2} justifyContent="center"></Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <div style={{ minHeight: "100vh" }}>
                    {/* INVOICES already created */}
                    {dataInitial.length !== 0 && (
                      <>
                        {/* APPLIED FILTER after Loading doesn't result in list of length 0 */}
                        {Object.keys(dataInitial).length !== 0 &&
                          (dataInitial)
                            .map((invoice, i) => (
                              <Grid container key={i}>
                                <Grid
                                  container
                                  alignItems="center"
                                  justifyContent="space-between"
                                  item
                                  xs={12}
                                  className="h-bg--white user-clients"
                                  style={{
                                    borderBottom: "1px solid #e2ecfd",
                                    // background: recurring ? "#fbf8ff" : "inherit",
                                    borderLeft:
                                      (invoice.cancelled) ? "5px solid rgb(209, 103, 103)" :
                                        (invoice.sendState !== "sent" && invoice.sendState !== "scheduled" && (invoice.payState === "notPayed" || invoice.payState === undefined)) ? `5px solid rgb(172 175 197)` :
                                          (invoice.sendState === "scheduled" && invoice.payState === "notPayed") ? `5px solid rgb(76, 175, 80)` :
                                            (invoice.sendState === "sent" && invoice.payState === "notPayed") ? `5px solid ${theme.palette.primary.dark}` :
                                              `5px solid ${theme.palette.success.light}`
                                  }}
                                >

                                  <Grid container item xs={12} justifyContent="space-between" className="table-section"
                                    onClick={() => setOpenInvoiceDialogAtom({
                                      ...openInvoiceDialogAtom,
                                      recurring: true,
                                      open: true,
                                      invoiceId: invoice.id,
                                      isEstimate: false
                                    })}
                                  >
                                    {/* First Part using lg 4 */}
                                    {/* Status */}
                                    <Grid xs={12} lg={2}
                                      container
                                      item
                                      alignItems="center"
                                      justifyContent="flex-start"
                                      className="invoice-status"
                                      style={{ padding: "5px 0" }}
                                    >

                                      <div className="invoice-checkbox fallback-icon-recurring" style={{ position: "absolute", left: 408, width: 30, display: "inherit" }}>
                                        <RepeatIcon color="primary" style={{marginLeft: -4, height: 18, width: 18}}/> 
                                      </div>

                                      {/* Draft */}
                                      {(invoice.recurringRef.length === 0 && invoice.sendState !== "sent" && invoice.sendState !== "scheduled" &&
                                        (invoice.payState === "notPayed" || invoice.payState === undefined)) && (
                                          <div
                                            style={{
                                              position: "relative",
                                              display: "inline-block",
                                              marginLeft: 5,
                                              marginRight: 5,
                                              width: "100%"
                                            }}
                                          >
                                              <div
                                                style={{
                                                  padding: "10px 10px",
                                                  borderRadius: 60,
                                                  display: "flex",
                                                  justifyContent: "flex-start",
                                                  alignItems: "center",
                                                }}
                                              >
                                                <EditIcon
                                                  style={{
                                                    fontSize: "21px",
                                                    color: "#ffffff",
                                                    background: invoice.cancelled && invoice.payState ? "rgb(209, 103, 103)" : "rgb(172, 175, 197)",
                                                    borderRadius: 60,
                                                    padding: 2,
                                                    marginRight: 5,
                                                  }}
                                                />
                                                <Typography
                                                  component="div"
                                                  variant="body2"
                                                  style={{
                                                    textAlign: "left",
                                                    color: "inherit",
                                                    fontSize: "11px",
                                                    paddingTop: "1px",
                                                    color: "rgb(44, 83, 125)"
                                                  }}
                                                >
                                                  <span style={{ fontWeight: invoice.cancelled && invoice.payState === "notPayed" ? 500 : 500, }}>Entwurf</span>

                                                </Typography>
                                              </div>
                                          </div>
                                        )}

                                      {/* Scheduled */}
                                      {(invoice.sendState === "scheduled" && invoice.payState === "notPayed") && (
                                      // <div
                                      //   style={{
                                      //     position: "relative",
                                      //     display: "inline-block",
                                      //     marginLeft: 5,
                                      //     marginRight: 5,
                                      //     width: "100%",
                                      //   }}
                                      // >
                                      //   <Tooltip
                                      //     title={`Nächste Rechnung wird am ${RETURN_DATE(invoice.sendDate)} um ${RETURN_HOURS(invoice.sendDate)} gesendet`}
                                      //     arrow
                                      //   >
                                      //     <div
                                      //       style={{
                                      //         padding: "10px 10px",
                                      //         paddingTop: "5px",
                                      //         borderRadius: 60,
                                      //         display: "flex",
                                      //         justifyContent: "flex-start",
                                      //         alignItems: "center",
                                      //         position: "relative", // Added for badge positioning
                                      //       }}
                                      //     >
                                      //       {/* Schedule Icon with Badge */}
                                      //       <div style={{ position: "relative", display: "inline-block" }}>
                                      //         <FiberManualRecordIcon
                                      //           fontSize="small"
                                      //           style={{
                                      //             color: "rgb(76, 175, 80)",
                                      //             background: "rgb(76, 175, 80)",
                                      //             borderRadius: 60,
                                      //             padding: 2,
                                      //             marginRight: 5,
                                      //             marginTop: 10,
                                      //           }}
                                      //         />
                                      //       </div>

                                      //       {/* Typography Content */}
                                      //       <Typography
                                      //         component="div"
                                      //         variant="body2"
                                      //         style={{
                                      //           textAlign: "left",
                                      //           fontSize: "13px",
                                      //           color: "inherit",
                                      //           paddingTop: "1px",
                                      //         }}
                                      //       >
                                      //         <span style={{ fontWeight: "500" }}>
                                      //           <span
                                      //             style={{
                                      //               color: "rgb(76, 175, 80)",
                                      //               fontSize: 9,
                                      //               display: "flex",
                                      //               fontWeight: 700
                                      //             }}
                                      //           >
                                      //             AKTIV
                                      //           </span>
                                      //           <span style={{display: "flex", alignItems: "center", fontSize: 11, color: "rgb(44, 83, 125)"}}>Nächster Versand am</span>
                                      //           <span style={{display: "flex", alignItems: "flex-start",fontSize: 11, color: "rgb(44, 83, 125)"}}><ScheduleIcon style={{color: "rgb(255, 183, 77)", fontSize: 14, marginRight: 1}}/>{`${RETURN_DATE(invoice.sendDate)} um ${RETURN_HOURS(invoice.sendDate)}`}</span>
                                      //         </span>
                                      //       </Typography>
                                      //     </div>
                                      //   </Tooltip>
                                      // </div>

<div
style={{
  position: "relative",
  display: "inline-block",
  marginLeft: 5,
  marginRight: 5,
  width: "100%",
}}
>
<Tooltip
                                          title={`Nächste Rechnung wird am ${RETURN_DATE(invoice.sendDate)} um ${RETURN_HOURS(invoice.sendDate)} gesendet`}
                                          arrow
                                        >
  <div
    style={{
      padding: "10px 10px",
      paddingTop: "15px",
      borderRadius: 60,
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "center",
    }}
  >
    {/* Mail Icon with Badge */}
    <div style={{ position: "relative", display: "inline-block" }}>
      <ScheduleIcon
        style={{
          fontSize: "23px",
          color: "#ffffff",
          background: "rgb(255, 183, 77)",
          borderRadius: 60,
          marginRight: 5,
        }}
      />
        <FiberManualRecordIcon
          style={{
            position: "absolute",
            top: -9,
            right: -0,
            fontSize: 18,
            color: "rgb(76, 175, 80)",
            background: "white",
            borderRadius: "50%",
          }}
        />
    </div>

    {/* Typography Content */}
    <Typography
      component="div"
      variant="body2"
      style={{
        textAlign: "left",
        fontSize: "11px",
        color: "inherit",
        paddingTop: "1px",
        marginTop: "-11px"
      }}
    >
      <span style={{ fontWeight: "500" }}>
        <span
          style={{
            color: "rgb(76, 175, 80)",
            fontSize: 9,
          }}
        >
          AKTIV
        </span>
        <br />
        <span style={{display: "flex", alignItems: "center", fontSize: 11, color: "rgb(44, 83, 125)"}}>Nächster Versand am</span>
        <span style={{display: "flex", alignItems: "flex-start",fontSize: 11, color: "rgb(44, 83, 125)"}}>{`${RETURN_DATE(invoice.sendDate)} um ${RETURN_HOURS(invoice.sendDate)}`}</span>
      </span>
    </Typography>
  </div>
</Tooltip>
</div>
                                      )}

                                      {/* Sent SOME (Paused)*/}
                                      {(invoice.recurringRef.length !== invoice.recurringStopNumber && invoice.sendState === "notSent" && invoice.recurringRef.length !== 0 && invoice.payState === "notPayed") && (
                                        <div
                                          style={{
                                            position: "relative",
                                            display: "inline-block",
                                            marginLeft: 5,
                                            marginRight: 5,
                                            width: "100%",
                                          }}
                                        >
                                          <Tooltip title={`Wurde am ${RETURN_DATE(invoice.sendDate)} gesendet`} arrow>
                                            <div
                                              style={{
                                                padding: "10px 10px",
                                                paddingTop: "15px",
                                                borderRadius: 60,
                                                display: "flex",
                                                justifyContent: "flex-start",
                                                alignItems: "center",
                                              }}
                                            >
                                              {/* Mail Icon with Badge */}
                                              <div style={{ position: "relative", display: "inline-block" }}>
                                                <PauseIcon
                                                  fontSize="small"
                                                  style={{
                                                    color: "#ffffff",
                                                    background: "rgb(172, 175, 197)",
                                                    borderRadius: 60,
                                                    padding: 2,
                                                    marginRight: 5,
                                                  }}
                                                />
                                                
                                              </div>

                                              {/* Typography Content */}
                                              <Typography
                                                component="div"
                                                variant="body2"
                                                style={{
                                                  textAlign: "left",
                                                  fontSize: "11px",
                                                  color: "inherit",
                                                  marginTop: -5,
                                                }}
                                              >
                                                <span style={{ fontWeight: "500", color: "rgb(44, 83, 125)" }}>
                                                  {/* <span
                                                    style={{
                                                      color: "rgb(172, 175, 197)",
                                                      fontSize: 9,
                                                    }}
                                                  >
                                                    PAUSIERT
                                                  </span>
                                                  <br /> */}
                                                  Versand pausiert
                                                </span>
                                              </Typography>
                                            </div>
                                          </Tooltip>
                                        </div>
                                      )}

                                      {/* Sent ALL*/}
                                        {(invoice.sendState === "sent") && (
                                        <div
                                          style={{
                                            position: "relative",
                                            display: "inline-block",
                                            marginLeft: 5,
                                            marginRight: 5,
                                            width: "100%",
                                          }}
                                        >
                                          <Tooltip title={`Alle Rechnungen wurden gesendet`} arrow>
                                            <div
                                              style={{
                                                padding: "10px 10px",
                                                paddingTop: "15px",
                                                borderRadius: 60,
                                                display: "flex",
                                                justifyContent: "flex-start",
                                                alignItems: "center",
                                              }}
                                            >
                                              {/* Mail Icon with Badge */}
                                              <div style={{ position: "relative", display: "inline-block" }}>
                                                <MailIcon
                                                  style={{
                                                    fontSize: "23px",
                                                    color: "#ffffff",
                                                    background: "rgb(26, 123, 201)",
                                                    borderRadius: 60,
                                                    padding: 4,
                                                    marginRight: 5,
                                                  }}
                                                />
                                                {invoice.sendState === "sent" && (
                                                  <CheckCircleIcon
                                                    style={{
                                                      position: "absolute",
                                                      top: -9,
                                                      right: -0,
                                                      fontSize: 18,
                                                      color: "rgb(26, 123, 201)",
                                                      background: "white",
                                                      borderRadius: "50%",
                                                    }}
                                                  />
                                                )}
                                              </div>

                                              {/* Typography Content */}
                                              <Typography
                                                component="div"
                                                variant="body2"
                                                style={{
                                                  textAlign: "left",
                                                  fontSize: "11px",
                                                  color: "inherit",
                                                  paddingTop: "1px",
                                                  marginTop: "-11px"
                                                }}
                                              >
                                                <span style={{ fontWeight: "500" }}>
                                                  <span
                                                    style={{
                                                      color: "rgb(26, 123, 201)",
                                                      fontSize: 9,
                                                    }}
                                                  >
                                                    BEENDET
                                                  </span>
                                                  <br />
                                                  <span style={{color: "rgb(44, 83, 125)"}}>Versand beendet</span>
                                                </span>
                                              </Typography>
                                            </div>
                                          </Tooltip>
                                        </div>
                                      )}

                                    </Grid>

                                    {/* Client */}
                                    <Grid xs={12} lg={2}
                                      container
                                      item
                                      alignItems="center"
                                      className="image-name-company"
                                      style={{ padding: "5px", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", flexWrap: "nowrap" }}
                                      onClick={() => setOpenInvoiceDialogAtom({
                                        ...openInvoiceDialogAtom,
                                        recurring: false,
                                        open: true,
                                        invoiceId: invoice.id,
                                        isEstimate: (invoice.type === "estimate" || invoice.type === "offer") ? true : false
                                      })}
                                    >

                                      <div className="client-image" style={{ borderRadius: 6, display: "flex", justifyContent: "center", alignItems: "center", borderRadius: 6, height: "70px", width: "70px", minWidth: "70px", marginRight: "10px", position: "relative" }}>
                                        {invoice.clientLogo !== "" ? (
                                          <img
                                            src={`${invoice.clientLogo}`}
                                            alt="logo"
                                            style={{
                                              maxWidth: "65px",
                                              maxHeight: "65px",
                                              marginRight: "10px",
                                              position: "absolute",
                                              top: "50%",
                                              left: "50%",
                                              transform: "translate(-50%, -50%)",
                                              padding: 2,
                                              opacity: invoice.cancelled ? 0.2 : 1,
                                            }}
                                          />
                                        ) :
                                          <img src={LogoMonogram} height={25} width={25} alt={`${t("APP.name")} Logo`} style={{ width: "50px", minWidth: "50px", marginRight: "10px", opacity: invoice.cancelled ? 0.1 : 0.2, margin: "0 auto" }} />
                                        }
                                      </div>

                                      <Typography component="div" variant="body2" style={{ fontWeight: 500, opacity: invoice.cancelled && invoice.payState === "notPayed" ? 0.2 : 1, fontSize: "12px", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>{JSON.parse(invoice.clientData).company}</Typography>
                                    </Grid>

                                    {/* Second Part using lg 8*/}
                                    <Grid xs={12} lg={8}
                                      container
                                      item
                                      justifyContent="flex-start"
                                      alignItems="center"
                                      className="invoice-meta"
                                      style={{ opacity: invoice.cancelled && invoice.payState === "notPayed" ? 0.2 : 1, padding: "15px 0px", }}
                                      onClick={() => setOpenInvoiceDialogAtom({
                                        ...openInvoiceDialogAtom,
                                        recurring: false,
                                        open: true,
                                        invoiceId: invoice.id,
                                        isEstimate: (invoice.type === "estimate" || invoice.type === "offer") ? true : false
                                      })}
                                    >

                                    {/* Interval */}
                                     <Grid xs={12} lg={2}
                                        container
                                        item
                                        justifyContent="center"
                                        className="invoice-meta-item-container"
                                      >
                                        <Typography className="invoice-meta-item" style={{ textAlign: "center", fontSize: "12px" }} component="div" variant="body2">
                                          <span className="menu-heading-title">Intervall</span>
                                          <Tooltip title={`${invoice.type === "cancellation" ? "Stornobetrag" : t("PAGE_INVOICES.subtotal")} netto`}>
                                            <Grid item container justifyContent="center">
                                            <div style={{ fontWeight: "300", fontSize: "12px" }}>
                                              {{
                                                minutely: "Minütlich",
                                                "5minutely": "5Minütlich",
                                                daily: "Täglich",
                                                monthly: "Monatlich",
                                                bimonthly: "Alle 2 Monate",
                                                quarterly: "Vierteljährlich (quartalsmäßig)",
                                                biannually: "Halbjährlich",
                                                annually: "Jährlich",
                                              }[invoice.recurringFrequency] || "Unbekannt"}
                                            </div>
                                            </Grid>
                                          </Tooltip>
                                        </Typography>
                                     </Grid>

                                      {/* Reference Number */}
                                      <Grid xs={12} lg={3}
                                        container
                                        item
                                        justifyContent="center"
                                        className="invoice-meta-item-container"
                                      >
                                        <Typography className="invoice-meta-item" style={{ textAlign: "center", fontSize: "12px" }} component="div" variant="body2">
                                          <span className="menu-heading-title" style={{ paddingTop: 4 }}>Referenznummer:</span>
                                          <Tooltip title={
                                            <span>
                                              {invoice.id}
                                            </span>
                                          } arrow>
                                            <div style={{
                                              display: "inline-block",
                                              maxWidth: "170px",
                                              whiteSpace: "nowrap",
                                              overflow: "hidden",
                                              textOverflow: "ellipsis",
                                              paddingTop: 4,
                                            }}>
                                              <span style={{ fontWeight: "300" }}>
                                                {invoice.id}
                                              </span>
                                            </div>
                                          </Tooltip>
                                        </Typography>
                                      </Grid>

                                      {/* Invoice Net */}
                                      <Grid xs={12} lg={2}
                                        container
                                        item
                                        justifyContent="center"
                                        className="invoice-meta-item-container"
                                      >
                                        <Typography className="invoice-meta-item" style={{ textAlign: "center", fontSize: "12px" }} component="div" variant="body2">
                                          <span className="menu-heading-title">Nettobetrag:</span>
                                          <Tooltip title={`${invoice.type === "cancellation" ? "Stornobetrag" : t("PAGE_INVOICES.subtotal")} netto`}>
                                            <Grid item container justifyContent="center">
                                              <div style={{ fontWeight: "300", fontSize: "12px", color: invoice.type === "cancellation" ? "red" : "inherit" }}>{invoice.type === "cancellation" && "-"}{parseFloat(invoice.subtotal).toLocaleString('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 })} €</div>
                                            </Grid>
                                          </Tooltip>
                                        </Typography>
                                      </Grid>

                                      {/* Total Gross */}
                                      <Grid xs={12} lg={2}
                                        container
                                        item
                                        justifyContent="center"
                                        className="invoice-meta-item-container"
                                      >
                                        <Typography className="invoice-meta-item" style={{ textAlign: "center", fontSize: "12px" }} component="div" variant="body2">
                                          <span className="menu-heading-title">Bruttobetrag:</span>
                                          <Tooltip title={`${invoice.type === "cancellation" ? "Stornobetrag" : t("PAGE_INVOICES.subtotal")} brutto`}>
                                            <Grid item container justifyContent="center">
                                              <div style={{ fontWeight: "300", fontSize: "12px", color: invoice.type === "cancellation" ? "red" : "inherit" }}>{invoice.type === "cancellation" && "-"}{parseFloat(invoice.total).toLocaleString('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 })} €</div>
                                            </Grid>
                                          </Tooltip>
                                        </Typography>
                                      </Grid>

                                      {/* Invoices sent */}
                                      <Grid className="invoice-ref" container item xs={12} lg={2} justifyContent="center">
                                          <Chip
                                          icon={invoice.recurringRef.length > 0 ? <LibraryBooksIcon style={{  color: "white" }} /> : <LibraryBooksIcon style={{  color: "white" }}/>}
                                          label={invoice.recurringRef.length}
                                          onClick={(e) => {
                                            e.stopPropagation();
                                            setAllInvoicesByClientAtom("");
                                            setAllInvoicesByOriginalRefAtom(`${JSON.parse(invoice.clientData).company} {RefRec:${invoice.id}}`);
                                            // setRecurringRefDialogOpen(true);
                                            // setRecurringRef(invoice.recurringRef.length > 0 ? invoice.recurringRef : []);
                                            setSelectedMenuItemAtom(31);
                                            setSubMenuInvoicesOpenAtom(true);
                                            setMobileOpen(false);
                                            navigate("/invoices");

                                          }}
                                          className={"chip--invoices"}
                                          style={{
                                            cursor: 'pointer',
                                            backgroundColor: invoice.recurringRef.length > 0 ? theme.palette.primary.main :  '#c4c7df',
                                            color: "white",
                                            fontSize: '12px',
                                            fontWeight: "bold",
                                            borderRadius: 4
                                          }}
                                        />
                                      </Grid>



                                      {/* Dots */}
                                      <Grid xs={12} lg={1}
                                        container
                                        item
                                        justifyContent="flex-end"
                                        alignItems="flex-end"
                                      >
                                        <InvoicesMenuDots
                                          invoice={invoice}
                                          setOpenInvoiceDialogAtom={setOpenInvoiceDialogAtom}
                                          openInvoiceDialogAtom={openInvoiceDialogAtom}
                                          recurring={recurring}
                                          fetchData={fetchData}
                                          handleSearchClear={handleSearchClear}
                                          setCheckedItems={setCheckedItems}
                                        />
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                </Grid>
                              </Grid>
                            ))
                        }

                        {/* APPLIED FILTER after Loading does result in list of length 0 */}
                        {!values.loading && !isLoading && Object.keys(values.invoicesFiltered).length === 0 &&
                          <Typography component="div" style={{ fontSize: "12px", padding: "10px 20px" }}>{t("PAGE_INVOICES.noResult")}</Typography>
                        }
                      </>
                    )
                    }

                    {isLoading &&
                      <div style={{padding: 15}}>Wird geladen ...</div>
                    }

                    {/* NO INVOICES created yet */}
                    {!values.loading && !isLoading && dataInitial.length === 0 && type === "invoices" &&
                      <Typography component="div" style={{ fontSize: "12px", padding: "10px 20px" }}>{t("PAGE_INVOICES.noInvoicesCreated")}</Typography>
                    }

                    {/* NO ESTIMATES created yet */}
                    {!values.loading && !isLoading && dataInitial.length === 0 && type === "estimates" &&
                      <Typography component="div" style={{ fontSize: "12px", padding: "10px 20px" }}>Du hast noch keine Kostenvoranschläge oder Angebote angelegt</Typography>
                    }

                    {hasMoreInvoices && !isFetching && dataInitial.length !== 0 && (
                      <Grid container justifyContent="center">
                        <Button
                          onClick={loadMoreInvoices}
                          style={{
                            backgroundColor: theme.palette.primary.main, // Primary color
                            color: "#fff",
                            margin: "15px 0", // Smaller margin
                            padding: "8px 16px", // Smaller padding
                            fontSize: "14px", // Smaller font size
                            borderRadius: "6px", // Slightly smaller border radius
                            boxShadow: "0px 3px 6px rgba(0,0,0,0.2)",
                          }}
                          onMouseOver={(e) =>
                            (e.currentTarget.style.backgroundColor = theme.palette.primary.dark)
                          } // Darker shade on hover
                          onMouseOut={(e) =>
                            (e.currentTarget.style.backgroundColor = theme.palette.primary.main)
                          } // Reset background color when mouse leaves
                          startIcon={<RotateLeftIcon />} // Add an icon here
                        >
                          Mehr laden
                        </Button>
                      </Grid>
                    )}

                  {(!values.loading || !isLoading) && (!userAtom.clientscreated && userAtom.data.clients.length === 0)  &&
                    <UserProfileIncompleteWarning
                      headline={t("PAGE_INVOICES.h1")}
                      text={"Bitte erstelle zunächst einen Kunden."}
                      icon={<ClientsIcon color="primary" fontSize="large" style={{ marginRight: "5px" }} />}
                      link={"/clients"}
                      linkText={t("BUTTONS.back_to_clients")}
                    />
                  }

                    {(!values.loading || !isLoading) && userAtom.data.signupCompleted && (userAtom.clientscreated || userAtom.data.clients.length !== 0) && dataInitial.length === 0 && !isFetching &&
                      <Grid container style={{ padding: "20px 15px" }}>
                        <Typography variant="body2" style={{ color: "gray" }}>
                          Es wurden keine Rechnungen mit den vorgenommenen Einstellungen gefunden.
                        </Typography>
                      </Grid>
                    }
                  </div>

                  {(!isLoading) &&
                    <>
                      {/* INVOICES already created */}

                      {/* NO INVOICES created yet */}
                      {!values.loading && !isLoading && dataInitial.length === 0 && type === "invoices" &&
                        <Typography component="div" style={{ fontSize: "12px", padding: "10px 20px" }}>{t("PAGE_INVOICES.noInvoicesCreated")}</Typography>
                      }

                      {/* NO ESTIMATES created yet */}
                      {!values.loading && !isLoading && dataInitial.length === 0 && type === "estimates" &&
                        <Typography component="div" style={{ fontSize: "12px", padding: "10px 20px" }}>Du hast noch keine Kostenvoranschläge oder Angebote angelegt</Typography>
                      }

                      {/* {(values.loading || isLoading) &&
                          <Typography>Rechnungen werden geladen ...</Typography>
                        } */}
                    </>
                  }
                </div>
              </Grid>


            </Box>
          </Container>
        </React.Fragment>
      }

      {(!values.loading || !isLoading) && !userAtom.data.signupCompleted &&
        <UserProfileIncompleteWarning
          headline={t("PAGE_INVOICES.h1")}
          text={t("PAGE_INVOICES.profileIncomplete")}
          icon={<ClientsIcon color="primary" fontSize="large" style={{ marginRight: "5px" }} />}
        />
      }

      {(!values.loading || !isLoading) && userAtom.data.signupCompleted && (userAtom.clientscreated || userAtom.data.clients.length !== 0) && !isFetching &&
      <Fab color="primary" aria-label="add" className="add-invoice-fab" style={{ position: "fixed", bottom: "0", right: "0", margin: "20px", zIndex: 9 }}
        onClick={() => {
          setOpenInvoiceDialogAtom({
            ...openInvoiceDialogAtom,
            open: true,
            isEstimate: false,
            recurring: true,
          });
        }}
      >
        <AddIcon />
      </Fab>
      }

      <RecurringRefDialog
                open={recurringRefDialogOpen}
                handleClose={setRecurringRefDialogOpen}
                recurringRef={recurringRef}
                auth={auth}
              />
    </div>
  );
};

export default InvoicesRecurrings;
