export const DB_GET_INVOICES = async (setInvoicesAtom, auth, sendRequest) => {
  try {
    await sendRequest(
      `${process.env.REACT_APP_BACKEND_URL_API}/invoices/user/${auth.userId}`,
      "GET",
      null,
      {
        Authorization: "Bearer " + auth.token,
      }
    )
    // NO ERROR
    .then((res) => setInvoicesAtom(res.invoices))
  } catch (err) { 
    // ERROR
    console.log(err)
  }
};

export const DB_GET_INVOICE = async (invoiceId, auth, sendRequest) => {
  try {
    const invoiceRes = await sendRequest(
      `${process.env.REACT_APP_BACKEND_URL_API}/invoices/${invoiceId}`,
      "GET",
      null,
      {
        Authorization: "Bearer " + auth.token,
      }
    );
    return invoiceRes ? invoiceRes.invoice : null;
  } catch (err) {
    console.log(err);
    return null;
  }
};

export const DB_DELETE_INVOICE = async (
  invoice,
  auth,
  sendRequest,
  enqueueSnackbar,
  t,
  invoicesAtom,
  setInvoicesAtom,
  openInvoiceDialogAtom,
  setOpenInvoiceDialogAtom,
  setShowConfirmModal,
  handleSearchClear,
  ) => {
  try {
    await sendRequest(
      `${process.env.REACT_APP_BACKEND_URL_API}/invoices/${invoice}`,
      "DELETE",
      null,
      {
        Authorization: "Bearer " + auth.token,
      }
    )
    // NO ERROR
    .then(() => setOpenInvoiceDialogAtom({ ...openInvoiceDialogAtom, open: false, invoiceId: "" }))
    .then(() => setShowConfirmModal(false))
    .then(() => setInvoicesAtom(invoicesAtom.filter((item) => item.id !== invoice)))
    .then(() => enqueueSnackbar("Rechnung wurde gelöscht", { variant: "success" }))
    .then(() => handleSearchClear())
  } catch (err) { 
    // ERROR
    enqueueSnackbar(t("FIELDS.error"), { variant: "error" })
  }
};

