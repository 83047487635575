import React, { useState, useContext, useEffect, useRef } from "react";

// IMPORT recoil
import { useRecoilState, useRecoilValue } from 'recoil';
import {
  settingsAtomState,
  openProductDialogAtomState,
  invoicesAtomState,
  clientsAtomState,
  invoicesFilteredAtomState,
  userAtomState
} from "../_atoms";

// IMPORT global hooks & context
import { useTranslation } from "react-i18next";
import { useHttpClient } from "../shared/hooks/http-hook";
import { AuthContext } from '../shared/context/auth-context';
import { useAuth } from "../shared/hooks/auth-hook";
import { enqueueSnackbar } from 'notistack';

// IMPORT global functions
import {
  SET_VALUE,
  SET_VALUES,
  RETURN_DATE,
  RETURN_HOURS,
  RETURN_OVERDUE_DAYS,
} from '../_functions';

import {
  DB_GET_CLIENT,
  DB_GET_CLIENTS_LIST
} from '../_functions/DB_CLIENTS';

// import { DB_PATCH_SETTINGS } from '../_functions/DB_SETTINGS';


// IMPORT components
import { NumericFormat } from "react-number-format";

// IMPORT own components
import LogoMonogram from './../_assets/logo_monogram.svg';
import ProductAppBar from "./components/ProductAppBar";
import ProductBottomNavigation from "./components/ProductBottomNavigation";
import PlaylistAddCheckIcon from '@material-ui/icons/PlaylistAddCheck';
import SubjectIcon from '@material-ui/icons/Subject';
import CloseIcon from "@material-ui/icons/Close";


// IMPORT Material-UI components
import {
  Typography,
  Button,
  AppBar,
  Toolbar,
  IconButton,
  Container,
  Grid,
  Box,
  InputAdornment,
  TextField,
  CircularProgress,
  Select,
  FormControl,
  InputLabel,
  MenuItem,
  ListItemIcon,
  ListItemText,
  FormControlLabel,
  Switch,
  Chip,
  Backdrop,
  BottomNavigation,
  AccordionDetails,
  Tooltip,
  Divider,
  Fab,
  RadioGroup,
  Radio,
  FormLabel,
  Dialog,
  DialogTitle,
  DialogContent,
} from "@material-ui/core";
import { makeStyles, withStyles, useTheme } from "@material-ui/core/styles";
import Autocomplete from "@material-ui/lab/Autocomplete";
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import Alert from "@material-ui/lab/Alert";


import CustomEditor from "../invoices/components/CustomEditor"


// IMPORT Material-UI icons from custom file
import {
  AddCircle as AddCircleIcon,
  ArrowBack as ArrowBackIcon,
  CheckCircle as CheckCircleIcon,
  DeleteForever as DeleteForeverIcon,
  OpenWith as DragIcon,
  Edit as EditIcon,
  Gavel as GavelIcon,
  RecentActors as InvoicesIcon,
  Mail as MailIcon,
  Save as SaveIcon,
  Warning as WarningIcon,
  Repeat as RepeatIcon,
  Delete as DeleteIcon,
  Cancel as CancelIcon
} from '@material-ui/icons';
import InfoIcon from '@material-ui/icons/Info';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';

// IMPORT date-fns utils and localization
import DateFnsUtils from '@date-io/date-fns';
import { de } from "date-fns/locale";
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';

// IMPORT own css
import "./ProductsDIALOG.scss"

// IMPORT local utils and hooks


// import useMount from './invoice_dialog/hooks/useMount';


const useStyles = makeStyles((theme) => {
  return {
    toolbar: theme.mixins.toolbar,
    stepContent: {
      display: "flex",
      justifyContent: "center"
    },
    button: {
      marginRight: theme.spacing(1),
    },
    instructions: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    root: {
      '& > svg': {
        margin: theme.spacing(2),
      },
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
    },
    cssOutlinedInput: {
      '&$cssFocused $notchedOutline': {
        borderColor: `${theme.palette.primary.main} !important`,
      }
    },
    cssFocused: {},
    notchedOutline: {
      borderWidth: '2px',
      borderColor: "#f0f3ff !important"
    },
  };
});

const ProductsDIALOG = () => {
  // HOOKS & CONTEXT
  const { t } = useTranslation();
  const auth = useContext(AuthContext);
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const { userId } = useAuth();
  const theme = useTheme();
  const classes = useStyles();

  // GLOBAL STATE (RECOIL)
  const [openProductDialogAtom, setOpenProductDialogAtom] = useRecoilState(openProductDialogAtomState);
  const [invoicesAtom, setInvoicesAtom] = useRecoilState(invoicesAtomState);
  const [, setInvoicesFilteredAtom] = useRecoilState(invoicesFilteredAtomState);
  const [settingsAtom, setSettingsAtom] = useRecoilState(settingsAtomState);
  const [clientsAtom] = useRecoilState(clientsAtomState);
  const [userAtom] = useRecoilState(userAtomState);

  const [initialNumber, setInitialNumber] = useState("");

  const unitSuggestions = ["Stk", "Std", "h", "min", "Tag(e)", "g", "kg", "t", "cm", "m", "cm²", "m²", "m³", "lfm", "L"]; // Add your suggestions here
  const [categorySuggestions, setCategorySuggestions] = useState(["general"]);

  // LOCAL STATE
  const [values, setValues] = useState({
    type: "service",
    category: "general",
    number: null,
    name: "",
    details: false,
    detailsText: "",
    price: null, // number if filled
    vatPercent: JSON.parse(userAtom.data.country).code === "AT" ? 20 : JSON.parse(userAtom.data.country).code === "AT" ? 19 : null, // number if filled
    priceGross: null,
    quantity: 1,
    unit: "",
    discount: "none",
    discountAbsolute: null,
    discountPercent: null,
    totalNet: 0,
    totalVat: 0,
    totalGross: 0,
    _id: null,
  })

  const [errors, setErrors] = useState({})
  const [loadingNumber, setLoadingNumber] = useState(true); // Loading state
  const [loadingCategory, setLoadingCategory] = useState(true); // Loading state










  const [open, setOpen] = useState(false);

  const placeholderCategories = [
    {
      title: "Tag",
      placeholders: [
        { text: "Name", description: "Der vollständige Name des Kunden" },
        { text: "Firma", description: "Der Firmenname des Kunden" },
        { text: "Adresse", description: "Die vollständige Adresse" },
        { text: "Telefon", description: "Die Telefonnummer des Kunden" }
      ]
    },
    {
      title: "Monat",
      placeholders: [
        { text: "AKTUELLER MONAT AUSGESCHRIEBEN", description: "z.B. März, April, Mai" },
        { text: "Rechnungsdatum", description: "Das Datum der Rechnungserstellung" },
        { text: "Fälligkeitsdatum", description: "Das Datum, bis wann die Rechnung bezahlt werden muss" },
        { text: "Betrag", description: "Der Gesamtbetrag der Rechnung" }
      ]
    },
    {
      title: "Jahr",
      placeholders: [
        { text: "Datum", description: "Das aktuelle Rechnungsdatum" },
        { text: "Zeit", description: "Die aktuelle Uhrzeit" },
        { text: "Ort", description: "Der Ort, an dem die Rechnung ausgestellt wurde" },
        { text: "Unterschrift", description: "Platz für eine digitale Unterschrift" }
      ]
    }
  ];
  
  // Handle inserting placeholders into the input field
  const insertPlaceholder = (placeholder) => {
    const newValue = values.name + ` {% ${placeholder} }`;
    setValues({ ...values, name: newValue });
    setOpen(false);
  };
  






  const fetchCategories = async () => {
    try {
      // Make the request using sendRequest
      const response = await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL_API}/products/getcategories`, // API endpoint
        "POST", // HTTP method
        null, // No body for POST requests
        {
          Authorization: `Bearer ${auth.token}`, // Authorization header
        }
      );

      // Update categorySuggestions with unique values
      setCategorySuggestions((prevSuggestions) => {
        const updatedSuggestions = [...new Set(["general", ...prevSuggestions, ...response.categories])];
        return updatedSuggestions;
      });
      return response.categories;
    } catch (error) {
      console.error("Failed to fetch categories:", error);
      return [];
    }
  };

  const fetchCategoryName = async (category) => {
    const formData = new FormData();
    formData.append("category", category); // pass categoryId
    try {
      // Make the request using sendRequest
      const response = await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL_API}/products/getcategoryname`, // API endpoint
        "POST", // HTTP method
        formData, // No body for POST requests
        {
          Authorization: `Bearer ${auth.token}`, // Authorization header
        }
      );
      // Update categorySuggestions with unique values
      setValues((prevValues) => ({
        ...prevValues,
        category: response.name,
      }));
      setLoadingCategory(false);
    } catch (error) {
      setLoadingCategory(false);
      console.error("Failed to fetch categories:", error);
      return [];
    }
  };

  const fetchLatestProductNumber = async () => {
    try {
      const response = await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL_API}/products/newnumber`,
        "POST",
        null,
        {
          Authorization: "Bearer " + auth.token,
        }
      );
      setValues((prevValues) => ({
        ...prevValues,
        number: response.number || "",
      }));
      if (initialNumber === "") {
        setInitialNumber(response.number || "")
      }
      setLoadingNumber(false);
    } catch (error) {
      // console.error("Failed to fetch latest product number:", error);
      setLoadingNumber(false); // Stop loading even on error
    }
  };

  const fetchProduct = async () => {
    console.log("here")
    try {
      const response = await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL_API}/products/${openProductDialogAtom.productId}`,
        "GET",
        null,
        {
          Authorization: "Bearer " + auth.token,
        }
      );

      let res = response.data;

      console.log("res")
      console.log(res)
      setValues((prevValues) => ({
        ...prevValues,
        ...res
      }));
      setInitialNumber(res.number)
      setLoadingNumber(false);
      fetchCategoryName(response.data.category);
    } catch (error) {
      console.error("Failed to fetch product", error);
      setLoadingNumber(false); // Stop loading even on error
    }
  };


  useEffect(() => {
    fetchCategories();
    if (openProductDialogAtom.productId === "") {
      fetchLatestProductNumber();
      setLoadingCategory(false);
      setValidity(true);
    } else if (openProductDialogAtom.productId !== "") {
      fetchProduct();
      setValidity(true);
    } else {
      setLoadingNumber(false);
    }
  }, [openProductDialogAtom]);


  const [filteredOptions, setFilteredOptions] = useState(
    categorySuggestions.map((category) =>
      category === "general" ? "Allgemein" : category
    )
  );

  const handleFocus = () => {
    // Show all options when the field gains focus
    setFilteredOptions(
      categorySuggestions.map((category) =>
        category === "general" ? "Allgemein" : category
      )
    );
  };

  const [filteredUnitOptions, setFilteredUnitOptions] = useState(unitSuggestions);

  const handleUnitFocus = () => {
    // Show all options when the field gains focus
    setFilteredUnitOptions(unitSuggestions);
  };


  const [loading, setLoading] = useState(false); // Controls the dots animation
  const [validity, setValidity] = useState(null); // null (initial), true (valid), false (exists)
  const timeoutRef = useRef(null);
  const debounceRef = useRef(null);

  const handleInputChangeNumber = (e) => {
      const inputValue = e.target.value;
      setValues({ ...values, number: inputValue });
      setErrors({ ...errors, number: false });

      if (inputValue.trim() === "") {
        // Reset state for empty input
        setLoading(false);
        setValidity(null);
        clearTimeout(timeoutRef.current);
        return;
      }

      // Show dots and debounce the backend call
      setLoading(true);
      setValidity(null);

      clearTimeout(debounceRef.current);
      debounceRef.current = setTimeout(() => {
        checkNumberAvailability(inputValue);
      }, 500); // Debounce backend call (500ms delay)
  };

  const handleBlur = (e) => {
    if (e.target.value === "") {
      setValues({ ...values, number: initialNumber });
      setErrors({ ...errors, number: false });
      setValidity(true);
    }
  };

  const checkNumberAvailability = async (number) => {
    try {
      // Create form data for the request
      const formData = new FormData();
      formData.append("number", number);

      // Use sendRequest to send the request
      const response = await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL_API}/products/checknumber`,
        "POST",
        formData,
        {
          Authorization: `Bearer ${auth.token}`,
        }
      );

      // Ensure dots are shown for at least 2 seconds
      clearTimeout(timeoutRef.current);
      timeoutRef.current = setTimeout(() => {
        setLoading(false);
        if(initialNumber === number) {
          setValidity(true);
        } else {
          setValidity(response.exists ? false : true);
        }
      }, 2000);
    } catch (error) {
      console.error("Error checking product number:", error);
      // Handle errors gracefully
      setLoading(false);
      setValidity(null);
    }
  };

  useEffect(() => {
    return () => {
      clearTimeout(timeoutRef.current);
      clearTimeout(debounceRef.current);
    };
  }, []);

  useEffect(() => {
    setErrors({ ...errors, number: validity === false ? true : false })
  }, [validity]);

  const getAdornmentContent = () => {
    if (loading) {
      return (
        <div className="loading-dots">
          <span className="dot"></span>
          <span className="dot"></span>
          <span className="dot"></span>
        </div>
      );
    }
    if (validity === true) {
      return <CheckCircleIcon style={{ color: "green" }} />;
    }
    if (validity === false) {
      return <CancelIcon style={{ color: "red" }} />;
    }
    return null;
  };


  const calculateTotalPriceNet = (values) => {
    if (!isNaN(parseFloat(values.price) * parseFloat(values.quantity))) {
      const totalPrice =
        (parseFloat(values.price) * parseFloat(values.quantity)) -
        (values.discountAbsolute !== null ? parseFloat(values.discountAbsolute) : 0) -
        (values.discountPercent !== null ? (parseFloat(values.price) * parseFloat(values.quantity) * (parseFloat(values.discountPercent) / 100)) : 0);

      return (
        <Typography style={{ fontWeight: "bold", lineHeight: "1.2" }}>
          {totalPrice.toLocaleString("de-DE", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })} €
        </Typography>
      );
    }

    // Default value when the calculation is invalid
    return (
      <Typography style={{ fontWeight: "bold", lineHeight: "1.2" }}>
        0 €
      </Typography>
    );
  };

  // Use `useEffect` to update the `totalNet` value
  useEffect(() => {
    if (!isNaN(parseFloat(values.price) * parseFloat(values.quantity))) {
      const totalPrice =
        (parseFloat(values.price) * parseFloat(values.quantity)) -
        (values.discountAbsolute !== null ? parseFloat(values.discountAbsolute) : 0) -
        (values.discountPercent !== null ? (parseFloat(values.price) * parseFloat(values.quantity) * (parseFloat(values.discountPercent) / 100)) : 0);

      // Update the `totalNet` value with proper rounding
      setValues((prevValues) => ({
        ...prevValues,
        totalNet: parseFloat(totalPrice.toFixed(2)), // Round to 2 decimals
      }));
    } else {
      // Reset `totalNet` to 0 if calculation is invalid
      setValues((prevValues) => ({
        ...prevValues,
        totalNet: 0,
      }));
    }
  }, [loadingNumber, values.price, values.quantity, values.discountAbsolute, values.discountPercent, setValues]);


  const calculateVAT = (values) => {
    if (!isNaN(parseFloat(values.price) / 100 * parseFloat(values.vatPercent))) {
      const totalVat = values.totalNet / 100 * values.vatPercent

      return (
        <Typography style={{ fontWeight: "bold", lineHeight: "1.2" }}>
          {totalVat.toLocaleString("de-DE", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })} €
        </Typography>
      );
    }

    // Default value when the calculation is invalid
    return (
      <Typography style={{ fontWeight: "bold", lineHeight: "1.2" }}>
        0 €
      </Typography>
    );
  };

  // Use `useEffect` to update the `totalVat` value
  useEffect(() => {
    if (!isNaN(parseFloat(values.price) / 100 * parseFloat(values.vatPercent))) {
      const totalVat = values.totalNet / 100 * values.vatPercent

      // Update the `totalVat` value with proper rounding
      setValues((prevValues) => ({
        ...prevValues,
        totalVat: parseFloat(totalVat.toFixed(2)), // Round to 2 decimals
      }));
    } else {
      // Reset `totalVat` to 0 if calculation is invalid
      setValues((prevValues) => ({
        ...prevValues,
        totalVat: 0,
      }));
    }
  }, [loadingNumber, values.price, values.totalNet, values.quantity, values.totalVat, values.discountAbsolute, values.discountPercent, values.vatPercent, setValues]);


  const calculateGrossTotal = (values) => {
    if (!isNaN(parseFloat(values.price) * parseFloat(values.quantity))) {
      const totalGross = values.totalNet + values.totalVat;

      return (
        <Typography style={{ fontWeight: "bold", lineHeight: "1.2" }}>
          {totalGross.toLocaleString("de-DE", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })} €
        </Typography>
      );
    }

    // Default value when the calculation is invalid
    return (
      <Typography style={{ fontWeight: "bold", lineHeight: "1.2" }}>
        0 €
      </Typography>
    );
  };

  // Use `useEffect` to update the `totalGross` value
  useEffect(() => {
    if (!isNaN(parseFloat(values.price) * parseFloat(values.quantity))) {

      const totalGross = values.totalNet + values.totalVat;

      // Update the `totalGross` value with proper rounding
      setValues((prevValues) => ({
        ...prevValues,
        totalGross: parseFloat(totalGross.toFixed(2)), // Round to 2 decimals
      }));
    } else {
      // Reset `totalGross` to 0 if calculation is invalid
      setValues((prevValues) => ({
        ...prevValues,
        totalGross: 0,
      }));
    }
  }, [loadingNumber, values.price, values.quantity, values.totalNet, values.totalVat, values.discountAbsolute, values.discountPercent, values.vatPercent, setValues]);


  return (
    <React.Fragment>
      <ProductAppBar
        setOpenProductDialogAtom={setOpenProductDialogAtom}
        openProductDialogAtom={openProductDialogAtom}
        values={values}
        errors={errors}
        setErrors={setErrors}
        loadingNumber={loadingNumber}
        validity={validity} // number validity
      />

      {/* <ProductBottomNavigation
        isLoading={isLoading}
        buttonLoadingAction={buttonLoadingAction}
      /> */}

      <React.Fragment>
        <div>
          <div className="invoice-edit">
            <div className={classes.toolbar} style={{ minHeight: 45 }} />
            <Container maxWidth={false} disableGutters className="bg--1">
              <Container maxWidth="lg" style={{ paddingTop: "45px", paddingBottom: "100px" }}>
                {!loadingNumber && !loadingCategory ?
                  <form
                  // onSubmit={invoiceHandler}
                  >
                    <Box display="flex"
                      flexDirection="column"
                      margin="0 25px"
                      className="h-gap--25"
                      style={{ minHeight: "100vh" }}
                    >
                      <Box
                        display="flex"
                        flexDirection="column"
                        // padding="25px 0px"
                        className="h-gap--20"
                      >
                        <div className="headline-section-fullscreendialog-mobile">
                          <Typography variant="h6" component="h1" style={{ textTransform: "none", fontWeight: 600, color: "rgb(44, 83, 125)", marginRight: 10 }}>
                            Produkt
                          </Typography>

                          {openProductDialogAtom.productId === "" &&
                            <Grid item >
                              <div
                                style={{
                                  position: "relative",
                                  display: "flex",
                                  alignItems: "center",
                                  marginLeft: 5,
                                  marginRight: 5,
                                  width: "100%"
                                }}
                              >
                                <div>
                                  <Typography
                                    style={{
                                      padding: 8,
                                      background: "white",
                                      fontSize: 10,
                                      border: "1px solid rgb(44, 83, 125)",
                                      borderRadius: 60,
                                      color: "rgb(44, 83, 125)",
                                      fontWeight: 500,
                                    }}>
                                    Erstellen
                                  </Typography>
                                </div>
                              </div>
                            </Grid>
                          }
                        </div>

                        <Grid
                          container
                          spacing={2}
                          direction="row"
                          alignContent="flex-start"
                          alignItems="flex-start"
                          className="h-bg--white h-borderRadius--10 h-padding--50-16 box-shadow--2"
                        >
                          {/* Type */}
                          <Grid item xs={12} md={4}>
                            <FormControl fullWidth variant="outlined">
                              <InputLabel id="product-type-label">Produktart</InputLabel>
                              <Select
                                labelId="product-type-label"
                                id="product-type"
                                value={values.type}
                                onChange={(e) => setValues({ ...values, type: e.target.value })}
                                label="Produktart"
                              >
                                <MenuItem value="service">Dienstleistung</MenuItem>
                                <MenuItem value="article">Artikel (physisches Produkt)</MenuItem>
                              </Select>
                            </FormControl>
                          </Grid>

                          {/* Category Input */}
                          <Grid container item xs={12} md={4}>
                            <Autocomplete
                              freeSolo
                              fullWidth
                              options={filteredOptions} // Dynamically filtered options
                              value={values.category === "general" ? "Allgemein" : values.category}
                              onInputChange={(event, inputValue, reason) => {
                                if (reason === "input") {
                                  const actualValue = inputValue === "Allgemein" ? "general" : inputValue;
                                  setValues({ ...values, category: actualValue });
                                  setErrors({ ...errors, category: false });
                                }
                              }}
                              onChange={(event, newValue) => {
                                // Handle selection from the dropdown
                                const actualValue = newValue === "Allgemein" ? "general" : newValue;
                                setValues({ ...values, category: actualValue });
                                setErrors({ ...errors, category: false });
                              }}
                              onBlur={(e) => {
                                if (!values.category) {
                                  setValues({ ...values, category: "general" });
                                }
                                setErrors({ ...errors, category: false });
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  id="category"
                                  label="Kategorie"
                                  variant="outlined"
                                  fullWidth
                                  error={errors.category}
                                  helperText={errors.category ? "Bitte ausfüllen" : ""}
                                  onFocus={handleFocus} // Show all options on focus
                                />
                              )}
                            />
                          </Grid>

                          {/* Article number */}
                          <Grid item xs={12} md={4}>
                            <TextField
                              required
                              autoComplete="off"
                              className="input-white"
                              id="number"
                              label="Produktnummer"
                              placeholder={initialNumber}
                              variant="outlined"
                              type="text"
                              error={errors.number}
                              helperText={errors.number ? "Nummer existiert bereits" : ""}
                              value={values.number}
                              onChange={handleInputChangeNumber}
                              onBlur={handleBlur}
                              fullWidth
                              InputProps={{
                                endAdornment: getAdornmentContent(),
                              }}
                            />
                          </Grid>

                          <Grid container item xs={12}>
                            <Grid container item lg={12} alignItems="center">
                              {/* Name */}
                              {/* <Grid item xs={12}>
                                <TextField
                                  // disabled={disabledEditing}
                                  required
                                  autoComplete="off"
                                  className="input-white"
                                  id="name"
                                  label={values.type === "service" ? "Name der Leistung" : "Artikelname"}
                                  variant="outlined"
                                  type="text"
                                  value={values.name}
                                  error={errors.name}
                                  helperText={errors.name ? "Bitte ausfüllen" : ""}
                                  onChange={(e) => {
                                    setValues({ ...values, name: e.target.value })
                                    setErrors({ ...errors, name: false });
                                  }}
                                  fullWidth
                                />
                              </Grid> */}

<Grid item xs={12}>
        <TextField
          required
          autoComplete="off"
          className="input-white"
          id="name"
          label={values.type === "service" ? "Name der Leistung" : "Artikelname"}
          variant="outlined"
          type="text"
          value={values.name}
          error={errors.name}
          helperText={errors.name ? "Bitte ausfüllen" : ""}
          onChange={(e) => {
            setValues({ ...values, name: e.target.value });
            setErrors({ ...errors, name: false });
          }}
          fullWidth
          // InputProps={{
          //   endAdornment: (
          //     <InputAdornment position="end">
          //       <IconButton onClick={() => setOpen(true)} style={{ padding: "4px", width: 40, height: 40 }}>
          //         <Typography
          //           style={{
          //             fontSize: "16px",
          //             fontWeight: "bold",
          //             color: "#666",
          //             userSelect: "none"
          //           }}
          //         >
          //           {"{ }"}
          //         </Typography>
          //       </IconButton>
          //     </InputAdornment>
          //   )
          // }}
        />
      </Grid>

                              {/* Switch for Details */}
                              <Grid
                                item
                                xs={12}
                                container
                                alignItems="center"
                                justifyContent="flex-start"
                                style={{ paddingTop: 10 }}
                              >
                                <FormControlLabel
                                  control={
                                    <Switch
                                      checked={values.details}
                                      onChange={(e) => { setValues({ ...values, details: !values.details }) }}
                                      color="primary"
                                    />
                                  }
                                  label={
                                    <Typography variant="body2" style={{ opacity: values.details ? 1 : 0.6 }}>
                                      Details
                                    </Typography>
                                  }
                                />
                              </Grid>
                            </Grid>

                            {/* Details */}
                            {values.details && (
                              <Grid container item lg={12}>
                                <CustomEditor
                                  details={values.detailsText}
                                  onUpdateDetails={(newDetails) => {
                                    setValues({ ...values, detailsText: newDetails });
                                  }}
                                />
                              </Grid>
                            )}
                          </Grid>
                        </Grid>

                        <Grid
                          style={{ marginTop: 5 }}
                          container
                          spacing={2}
                          direction="row"
                          alignContent="flex-start"
                          alignItems="flex-start"
                          className="h-bg--white h-borderRadius--10 h-padding--50-16 box-shadow--2"
                        >

                          {/* USt. Input */}
                          <Grid container item md={6} lg={1}>
                            <NumericFormat
                              fullWidth
                              className="input-white"
                              id="vatPercent"
                              label="USt."
                              variant="outlined"
                              value={values.vatPercent}
                              decimalScale={0}
                              valueIsNumericString
                              customInput={TextField}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <span>%</span>
                                  </InputAdornment>
                                ),
                              }}
                              onValueChange={({ value: v }) => {
                                setValues({
                                  ...values,
                                  vatPercent: parseFloat(v),
                                  priceGross: values.price !== "" ? values.price / 100 * (100 + parseFloat(v)) : null,
                                })
                              }}
                              onBlur={() => {
                                setValues({ ...values, vatPercent: isNaN(values.vatPercent) ? 0 : values.vatPercent });
                              }}
                            />
                          </Grid>

                          {/* Preis Input (net) */}
                          <Grid container item md={6} lg={2}>
                            <NumericFormat
                              // disabled={disabledEditing}
                              fullWidth
                              required
                              className="input-white"
                              id="price"
                              label="Preis (netto)"
                              variant="outlined"
                              value={values.price}
                              prefix="€ "
                              thousandSeparator="."
                              decimalSeparator=","
                              fixedDecimalScale // Ensures decimals are always shown
                              decimalScale={2}
                              valueIsNumericString
                              customInput={TextField}
                              error={errors.price}
                              helperText={errors.price ? "Bitte ausfüllen" : ""}
                              onValueChange={({ value: v }) => {
                                setValues({
                                  ...values,
                                  price: !isNaN(parseFloat(v)) ? parseFloat(v) : "",
                                  priceGross: !isNaN(parseFloat(v)) ? parseFloat((v * (100 + values.vatPercent) / 100).toFixed(2)) : ""
                                });
                                setErrors({ ...errors, price: false, priceGross: false })
                              }}
                            />
                          </Grid>

                          {/* Price Input (gross) */}
                          <Grid container item md={6} lg={2}>
                            <NumericFormat
                              // disabled={disabledEditing}
                              fullWidth
                              required
                              className="input-white"
                              id="price"
                              label="Preis (brutto)"
                              variant="outlined"
                              value={values.priceGross}
                              prefix="€ "
                              thousandSeparator="."
                              decimalSeparator=","
                              fixedDecimalScale // Ensures decimals are always shown
                              decimalScale={2}
                              valueIsNumericString
                              customInput={TextField}
                              error={errors.priceGross}
                              helperText={errors.priceGross ? "Bitte ausfüllen" : ""}
                              onValueChange={({ value: v }) => {
                                setValues({
                                  ...values,
                                  price: !isNaN(parseFloat(v)) ? parseFloat(((v / (100 + values.vatPercent)) * 100).toFixed(2)) : "",
                                  priceGross: !isNaN(parseFloat(v)) ? parseFloat(v) : "",
                                });
                                setErrors({ ...errors, price: false, priceGross: false })
                              }}
                            />
                          </Grid>

                          {/* Quantity Input */}
                          <Grid container item md={6} lg={1}>
                            <NumericFormat
                              fullWidth
                              className="input-white"
                              id="quantity"
                              label="Menge"
                              variant="outlined"
                              value={values.quantity}
                              thousandSeparator="."
                              decimalSeparator=","
                              decimalScale={2}
                              valueIsNumericString
                              customInput={TextField}
                              onValueChange={({ value: v }) => {
                                setValues({ ...values, quantity: parseFloat(v) });
                              }}
                              onBlur={() => {
                                setValues({ ...values, quantity: isNaN(values.quantity) ? 1 : values.quantity });
                              }}
                            />
                          </Grid>

                          {/* Unit Input */}
                          <Grid container item md={6} lg={2}>
                            <Autocomplete
                              freeSolo
                              fullWidth
                              options={filteredUnitOptions} // Dynamically filtered options
                              value={values.unit}
                              // onInputChange={handleUnitInputChange} // Handle input changes
                              onInputChange={(event, newValue) => {
                                setValues({ ...values, unit: newValue });
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  id="unit"
                                  label="Einheit"
                                  variant="outlined"
                                  fullWidth
                                  onFocus={handleUnitFocus} // Show all options on focus
                                />
                              )}
                            />
                          </Grid>

                          {/* Discount */}
                          {values.discount === "none" &&
                            <Grid container item md={6} lg={4}>
                              <FormControl variant="outlined" style={{ width: "100%", minWidth: "100%" }}>
                                <InputLabel id="discount">Rabatt</InputLabel>
                                <Select
                                  // disabled={disabledEditing}
                                  labelId="discount"
                                  id="discount"
                                  name="discount"
                                  value={values.discount}
                                  onChange={(e) => {
                                    setValues({
                                      ...values,
                                      discount: e.target.value
                                    })
                                  }
                                  }
                                  label="Rabatt"
                                  fullWidth
                                >
                                  <MenuItem value={"none"}>Kein Rabatt</MenuItem>
                                  <MenuItem value={"absolute"}>Rabatt in €</MenuItem>
                                  <MenuItem value={"percent"}>Rabatt in %</MenuItem>
                                </Select>
                              </FormControl>
                            </Grid>
                          }

                          {values.discount === "absolute" &&
                            <Grid container item md={6} lg={4}>
                              <NumericFormat
                                style={{ width: "100%", minWidth: "100%" }}
                                id="discountAbsolute"
                                label="Rabatt in €"
                                variant="outlined"
                                value={values.discountAbsolute}
                                prefix="€ "
                                thousandSeparator={"."}
                                decimalSeparator={","}
                                decimalScale={2}
                                valueIsNumericString
                                fixedDecimalScale
                                customInput={TextField}
                                InputLabelProps={{
                                  classes: {
                                    root: classes.cssLabel,
                                    focused: classes.cssFocused,
                                  },
                                }}

                                InputProps={{
                                  classes: {
                                    root: classes.cssOutlinedInput,
                                    focused: classes.cssFocused,
                                    notchedOutline: classes.notchedOutline,
                                  },
                                  endAdornment: <InputAdornment position="end" style={{ height: "100%" }}>
                                    <div style={{ borderLeft: "1px solid #d7d7d7de", height: "100%", marginLeft: "15px" }}>
                                      <IconButton
                                        style={{ marginLeft: "5px", marginTop: -8 }}
                                        aria-label="delete discount"
                                        onClick={(e) => {
                                          setValues({
                                            ...values,
                                            discount: "none",
                                            discountAbsolute: null
                                          })
                                        }}
                                      >
                                        <DeleteForeverIcon fontSize="small" />
                                      </IconButton>
                                    </div>
                                  </InputAdornment>
                                }}
                                onValueChange={({ value: v }) => {
                                  setValues({
                                    ...values,
                                    discountAbsolute: isNaN(parseFloat(v)) ? null : parseFloat(v)
                                  })
                                }}
                              />
                            </Grid>
                          }

                          {values.discount === "percent" &&
                            <Grid container item md={6} lg={4}>
                              <NumericFormat
                                style={{ width: "100%", minWidth: "100%" }}
                                id="discountPercent"
                                label="Rabatt in %"
                                variant="outlined"
                                value={values.discountPercent}
                                suffix=" %"
                                thousandSeparator={"."}
                                decimalSeparator={","}
                                decimalScale={2}
                                valueIsNumericString
                                fixedDecimalScale
                                customInput={TextField}
                                InputLabelProps={{
                                  classes: {
                                    root: classes.cssLabel,
                                    focused: classes.cssFocused,
                                  },
                                }}

                                InputProps={{
                                  classes: {
                                    root: classes.cssOutlinedInput,
                                    focused: classes.cssFocused,
                                    notchedOutline: classes.notchedOutline,
                                  },
                                  endAdornment: <InputAdornment position="end" style={{ height: "100%" }}>
                                    <div style={{ borderLeft: "1px solid #d7d7d7de", height: "100%", marginLeft: "15px" }}>
                                      <IconButton
                                        style={{ marginLeft: "5px", marginTop: -8 }}
                                        aria-label="delete discount"
                                        onClick={(e) => {
                                          setValues({
                                            ...values,
                                            discount: "none",
                                            discountPercent: null
                                          })
                                        }}
                                      >
                                        <DeleteForeverIcon fontSize="small" />
                                      </IconButton>
                                    </div>
                                  </InputAdornment>
                                }}
                                onValueChange={({ value: v }) => {
                                  setValues({
                                    ...values,
                                    discountPercent: isNaN(parseFloat(v)) ? 0 : parseFloat(v)
                                  })
                                }}
                              />
                            </Grid>
                          }
                        </Grid>

                        <Grid
                          style={{ marginTop: 5 }}
                          container
                          spacing={2}
                          direction="row"
                          alignContent="flex-start"
                          alignItems="flex-start"
                          className="h-bg--white h-borderRadius--10 h-padding--50-16 box-shadow--2"
                        >
                          {/* Price Total (net)*/}
                          <Grid xs={12} sm={4}
                            container
                            item
                            justifyContent="center"
                            alignItems="center"
                          >
                            <div style={{ width: "100%", background: "rgb(240 243 249)", borderRadius: 4, display: "flex", flexDirection: "column", alignItems: "center", padding: 15 }}>
                              <Typography style={{ fontSize: "10px", textAlign: "center", lineHeight: "1.2", marginBottom: "4px" }}>
                                Gesamtpreis (netto)
                              </Typography>
                              {calculateTotalPriceNet(values)}
                            </div>
                          </Grid>

                          {/* VAT total value*/}
                          <Grid xs={12} sm={4}
                            container
                            item
                            justifyContent="center"
                            alignItems="center"
                          >
                            <div style={{ width: "100%", background: "rgb(240 243 249)", borderRadius: 4, display: "flex", flexDirection: "column", alignItems: "center", padding: 15 }}>
                              <Typography style={{ fontSize: "10px", textAlign: "center", lineHeight: "1.2", marginBottom: "4px" }}>
                                USt.
                              </Typography>
                              {calculateVAT(values)}
                            </div>
                          </Grid>

                          {/* Price Total (gross)*/}
                          <Grid xs={12} sm={4}
                            container
                            item
                            justifyContent="center"
                            alignItems="center"
                          >
                            <div style={{ width: "100%", background: "rgb(240 243 249)", borderRadius: 4, display: "flex", flexDirection: "column", alignItems: "center", padding: 15 }}>
                              <Typography style={{ fontSize: "10px", textAlign: "center", lineHeight: "1.2", marginBottom: "4px" }}>
                                Gesamtpreis (brutto)
                              </Typography>
                              {calculateGrossTotal(values)}
                            </div>
                          </Grid>
                        </Grid>
                      </Box>
                    </Box>
                  </form>
                  :
                  <div className="loading-dots" style={{ display: "flex", height: 53 }}>
                    <span className="dot"></span>
                    <span className="dot"></span>
                    <span className="dot"></span>
                  </div>
                }
              </Container>
            </Container>
          </div>
        </div>
      </React.Fragment>


 {/* Placeholder Selection Modal */}
 <Dialog open={open} onClose={() => setOpen(false)} fullWidth maxWidth="sm">
  <DialogTitle style={{ position: "relative" }}>
    Platzhalter einfügen
    <IconButton
      aria-label="close"
      onClick={() => setOpen(false)}
      style={{
        position: "absolute",
        right: 8,
        top: 8,
        color: "#666"
      }}
    >
      <CloseIcon />
    </IconButton>
  </DialogTitle>

  <DialogContent>
    <Typography variant="body1" gutterBottom style={{ display: "block", marginBottom: 10 }}>
      Alle Platzhalter beziehen sich auf das jeweilige <strong>Rechnungsdatum</strong>.
    </Typography>
    <Typography variant="body2" style={{ display: "block", fontSize: 12, color: "#666", marginBottom: 20 }}>
      <span style={{ display: "block", fontWeight: "bold" }}>
        Beispiel: Platzhalter <code><strong>{`{% AKTUELLER MONAT AUSGESCHRIEBEN }`}</strong></code>
      </span>
      Erfolgt eine wiederkehrende Verrechnung monatlich, wird der jeweilige Monatsname (z. B. Februar, März, April) in der automatisch generierten Rechnung hinzugefügt.
    </Typography>

    {/* Placeholder Categories */}
    {placeholderCategories.map((category) => (
      <div
        key={category.title}
        style={{
          border: "1px solid #ccc",
          borderRadius: 8,
          padding: 16,
          marginBottom: 24,
          backgroundColor: "#f9f9f9",
        }}
      >
        <Typography
          variant="subtitle1"
          gutterBottom
          style={{
            color: "rgb(44, 83, 125)",
            fontWeight: 600,
            fontSize: "1rem",
            marginBottom: 16,
          }}
        >
          {category.title}
        </Typography>
        <Grid container spacing={2}>
          {category.placeholders.map((placeholder) => (
            <Grid item xs={12} key={placeholder.text}>
              <div style={{ display: "flex", flexDirection: "column", alignItems: "flex-start" }}>
                <Button
                  variant="outlined"
                  size="small"
                  onClick={() => insertPlaceholder(placeholder.text)}
                  style={{
                    backgroundColor: "white", // White background
                    borderColor: "#ccc",
                    color: "#333",
                    transition: "background-color 0.2s",
                  }}
                  onMouseOver={(e) => (e.currentTarget.style.backgroundColor = "#f2f2f2")} // Light gray hover
                  onMouseOut={(e) => (e.currentTarget.style.backgroundColor = "white")} // Back to white
                >
                  {`{% ${placeholder.text} }`}
                </Button>
                <Typography
                  variant="caption"
                  style={{ fontSize: 12, color: "#666", marginTop: 4 }}
                >
                  {placeholder.description}
                </Typography>
              </div>
            </Grid>
          ))}
        </Grid>
      </div>
    ))}
  </DialogContent>
</Dialog>


    </React.Fragment>
  );
};

export default ProductsDIALOG;
