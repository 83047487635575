import React from 'react';
import { AppBar, Toolbar, IconButton, Grid, Typography, Button, Tooltip } from '@material-ui/core';
import {
  ArrowBack as ArrowBackIcon,
  Close as CloseIcon,
  PictureAsPdf as PictureAsPdfIcon,
  Save as SaveIcon,
  Mail as MailIcon,
  Repeat as RepeatIcon,
  Edit as EditIcon,
  Schedule as ScheduleIcon
} from '@material-ui/icons';
import InfoIcon from '@material-ui/icons/Info';
import CheckIcon from '@material-ui/icons/Check';
import PlayCircleFilledIcon from '@material-ui/icons/PlayCircleFilled';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import PauseCircleFilledIcon from '@material-ui/icons/PauseCircleFilled';
import PauseIcon from '@material-ui/icons/Pause';


import { useTranslation } from 'react-i18next';

import { RETURN_DATE } from '../../../_functions/DATES';
import { RETURN_HOURS } from '../../../_functions/DATES';
import { RETURN_OVERDUE_DAYS } from '../../../_functions/DATES';

const InvoiceAppBarRecurring = ({
  setOpenInvoiceDialogAtom,
  openInvoiceDialogAtom,
  selectedClient,
  isLoading,
  setSendMode,
  setOpen,
  invoiceHandler,
  values,
  saveNewInvoiceSchema,
  setSettingsAtom,
  sendMode,
  newlyCreated,
  invoiceDateError,
  dueDateError,
  invoiceNumberError,
  checkInvoiceNumberChange,
  setValues,
  handleCancelSend,
  setDisabledEditing,
  isFetching
}) => {
  const { t } = useTranslation();

  return (
    <AppBar
      elevation={0}
      style={{
        backgroundColor: "white",
        zIndex: 99999,
        boxShadow: "rgba(84, 94, 165, 0.2) 0px 1px 64px"
      }}>
      <Toolbar style={{ justifyContent: "space-between", position: 'relative' }}>
        {/* Left Section: Title */}
        <Grid container alignItems="center" className="appbar-left">
          <IconButton
            edge="start"
            style={{
              display: "flex",
              width: "35px",
              height: "35px",
              marginRight: 10,
              border: "1px solid rgba(0, 0, 0, 0.04)"
            }}
            onClick={() => {
              setOpenInvoiceDialogAtom({
                ...openInvoiceDialogAtom,
                open: false,
                invoiceId: "",
                duplicate: false
              });
            }}
            aria-label={`${t("BUTTONS.AL_close")}`}
          >
            <ArrowBackIcon color="primary" />
          </IconButton>
          <Typography variant="h6" component="h1" className="invoice-dialog-headline-appbar" style={{ textTransform: "none", fontWeight: 600, color: "rgb(44, 83, 125)", marginRight: 10 }}>
            <span className="recurring-headline-long">Wiederkehrende Rechnungslegung</span>
          </Typography>
          <Grid item className="invoice-dialog-status-appbar">
            {/* NEW */}
            {!values.loading && !selectedClient && openInvoiceDialogAtom.invoiceId === "" &&
              <div
                style={{
                  position: "relative",
                  display: "flex",
                  alignItems: "center",
                  marginLeft: 5,
                  marginRight: 5,
                  width: "100%"
                }}
              >
                <div>
                  <Typography
                    style={{
                      padding: 8,
                      background: "white",
                      fontSize: 10,
                      border: "1px solid rgb(44, 83, 125)",
                      borderRadius: 60,
                      color: "rgb(44, 83, 125)",
                      fontWeight: 500,
                    }}>
                    Erstellen
                  </Typography>
                </div>
              </div>
            }
            {!values.loading && selectedClient &&
              <div>
                {/* Draft */}
                {(values.sendState !== "sent" && values.sendState !== "scheduled" && values.recurringRef.length === 0 &&
                  (values.payState === "notPayed" || values.payState === undefined)) && (
                    <div
                      style={{
                        position: "relative",
                        display: "flex",
                        alignItems: "center",
                        marginLeft: 5,
                        marginRight: 5,
                        width: "100%"
                      }}
                    >

                      <div
                        style={{
                          // background: "rgb(229 232 239)",
                          borderLeft: "2px solid rgb(229 232 239)",
                          padding: "0px 5px",
                          paddingLeft: 10,
                          display: "flex",
                          justifyContent: "flex-start",
                          alignItems: "center",
                          position: "relative", // Added for badge positioning
                          color: "rgb(44, 83, 125)",
                          opacity: values.cancelled ? 0.5 : 1,
                        }}
                      >
                        <div style={{ position: "relative", marginTop: 5 }}>
                          <EditIcon
                            style={{
                              fontSize: "21px",
                              color: "#ffffff",
                              background: "rgb(172, 175, 197)",
                              borderRadius: 60,
                              padding: 2,
                              marginRight: 5,
                            }}
                          />
                        </div>

                        <Typography
                          component="div"
                          variant="body2"
                          style={{
                            textAlign: "left",
                            color: "inherit",
                            fontSize: "13px",
                            paddingRight: 5,
                            paddingTop: 6,
                            paddingBottom: 6,
                            lineHeight: 1.2,
                            fontWeight: 500,
                            display: "flex",
                            alignItems: "center"
                          }}
                        >
                          <span style={{
                            fontSize: 11,
                          }}>
                            Entwurf
                          </span>
                          {values.type === "cancellation" && (
                              <span style={{ fontWeight: 700, marginTop: -2, marginLeft: 5, paddingLeft: 5, borderLeft: "2px solid rgb(44 83 125 / 20%)" }}>
                                <span style={{ fontSize: 9, padding: "2px 0px 0px 0px", marginTop: -2, borderRadius: 60, display: "inline-block", }}>
                                  STORNORECHNUNG
                                </span>
                              {values.cancelledRefInvoiceNr &&
                              <span
                                style={{
                                  display: "block",
                                  overflow: "hidden",
                                  whiteSpace: "nowrap",
                                  textOverflow: "ellipsis",
                                  verticalAlign: "middle",
                                  fontSize: 9,
                                  fontWeight: "normal"
                                }}
                              >
                                Zu Nr. {JSON.parse(values.cancelledRefInvoiceNr).map(item => item.value).join("")}
                              </span>
                              }
                            </span>
                          )}
                        </Typography>

                        {(values.originalRef !== undefined) &&
                          <Tooltip title="Diese Rechnung wurde aufgrund einer wiederkehrenden Rechnungslegung erstellt.">
                            <div
                              onClick={(e) => {
                                e.stopPropagation();
                                setOpenInvoiceDialogAtom({ ...openInvoiceDialogAtom, recurring: true, open: true, invoiceId: values.originalRef, sendMode: false })
                              }}
                            >
                              <div className="recurring-info" style={{ borderRadius: "60px", display: "inline-grid", textAlign: "center", padding: "5px 5px", fontSize: "12px" }}>
                                <RepeatIcon className="recurring-info-icon" style={{ fontSize: 12 }} />
                              </div>
                            </div>
                          </Tooltip>
                        }
                      </div>

                      {values.cancelled &&
                        <div>
                          <Typography
                            style={{
                              padding: 8,
                              background: "white",
                              fontSize: 10,
                              border: "1px dashed rgb(214 216 237)",
                              borderRadius: 60,
                              color: "rgb(44, 83, 125)",
                              fontWeight: 500,
                              marginLeft: 5
                            }}>
                            Storniert
                          </Typography>
                        </div>
                      }
                    </div>
                )}

                {/* SCHEDULED / ACTIVE */}
                {(values.sendState === "scheduled" && openInvoiceDialogAtom.invoiceId !== "") &&
                  <div
                  style={{
                    position: "relative",
                    display: "flex",
                    alignItems: "center",
                    marginLeft: 5,
                    marginRight: 5,
                    width: "100%"
                  }}
                >
                    <div
                      style={{
                        background: "#ffffff",
                        borderLeft: "2px solid rgb(229 232 239)",
                        paddingLeft: 10,
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems: "center",
                        position: "relative", // Added for badge positioning
                        color: "rgb(44, 83, 125)",
                      }}
                    >
                      {/* Schedule Icon with Badge */}
                      <div style={{ position: "relative", display: "inline-block", marginTop: 10 }}>
                        <ScheduleIcon
                          style={{
                            fontSize: "22px",
                            color: "#ffffff",
                            background: "rgb(255, 183, 77)",
                            borderRadius: 60,
                            marginRight: 5,
                          }}
                        />
                        {values.sendState === "scheduled" && (
                          <FiberManualRecordIcon
                            style={{
                              position: "absolute",
                              top: -10,
                              right: -0,
                              fontSize: 18,
                              color: "rgb(76, 175, 80)",
                              background: "white",
                              borderRadius: "50%",
                            }}
                          />
                        )}
                      </div>

                      {/* Typography Content */}
                      <Typography
                        component="div"
                        variant="body2"
                        style={{
                          textAlign: "left",
                          fontSize: "11px",
                          color: "inherit",
                          paddingTop: "1px",
                        }}
                      >
                        <span style={{ fontWeight: "500" }}>
                          <span
                            style={{
                              display: "flex",
                              flexDirection: "column"
                            }}
                          >
                            <span style={{
                                                  color: "rgb(76, 175, 80)",
                                                  fontSize: 9,
                            }}>AKTIV</span>
                            <span>Nächster Versand: {`${RETURN_DATE(values.sendDate)} um ${RETURN_HOURS(values.sendDate)}`}</span>
                          </span>
                        </span>
                      </Typography>
                    </div>
                  </div>
                }

                {/* Sent */}
                {(values.recurring && values.sendState === "sent" && values.payState !== "payed" && values.sendState !== "scheduled" && openInvoiceDialogAtom.invoiceId !== "") &&
                        <div
                        style={{
                          position: "relative",
                          display: "flex",
                          alignItems: "center",
                          marginLeft: 5,
                          marginRight: 5,
                          width: "100%"
                        }}
                      >
                          <div
                            style={{
                              background: "#ffffff",
                              borderLeft: "2px solid rgb(229 232 239)",
                              paddingLeft: 10,
                              display: "flex",
                              justifyContent: "flex-start",
                              alignItems: "center",
                              position: "relative", // Added for badge positioning
                              color: "rgb(44, 83, 125)",
                            }}
                          >
                            {/* Schedule Icon with Badge */}
                            <div style={{ position: "relative", display: "inline-block", marginTop: 10 }}>
                              <MailIcon
                                 style={{
                                  fontSize: "23px",
                                  color: "#ffffff",
                                  background: "rgb(26, 123, 201)",
                                  borderRadius: 60,
                                  padding: 4,
                                  marginRight: 5,
                                }}
                              />
                                <CheckCircleIcon
                                  style={{
                                    position: "absolute",
                                    top: -9,
                                    right: -0,
                                    fontSize: 18,
                                    color: "rgb(26, 123, 201)",
                                    background: "white",
                                    borderRadius: "50%",
                                  }}
                                />
                            </div>
                
                            {/* Typography Content */}
                            <Typography
                              component="div"
                              variant="body2"
                              style={{
                                textAlign: "left",
                                fontSize: "11px",
                                color: "inherit",
                                paddingTop: "1px",
                              }}
                            >
                              <span style={{ fontWeight: "500" }}>
                                <span
                                  style={{
                                    display: "flex",
                                    flexDirection: "column"
                                  }}
                                >
                                  <span style={{
                                                        color: "rgb(26, 123, 201)",
                                                        fontSize: 9,
                                  }}>BEENDET</span>
                                  <span>Versand beendet</span>
                                </span>
                              </span>
                            </Typography>
                          </div>
                        </div>
                }

                {/* PAUSED */}
                {(values.recurringRef.length !== values.recurringStopNumber && values.sendState === "notSent" && values.recurringRef.length !== 0 && values.payState === "notPayed") &&
                    <div
                      style={{
                        position: "relative",
                        display: "flex",
                        alignItems: "center",
                        marginLeft: 5,
                        marginRight: 5,
                        width: "100%"
                      }}
                    >

                      <div
                        style={{
                          // background: "rgb(229 232 239)",
                          borderLeft: "2px solid rgb(229 232 239)",
                          padding: "0px 5px",
                          paddingLeft: 10,
                          display: "flex",
                          justifyContent: "flex-start",
                          alignItems: "center",
                          position: "relative", // Added for badge positioning
                          color: "rgb(44, 83, 125)",
                          opacity: values.cancelled ? 0.5 : 1,
                        }}
                      >
                        <div style={{ position: "relative", marginTop: 5 }}>
                          <PauseIcon
                            style={{
                              fontSize: "21px",
                              color: "white",
                              background: "rgb(172, 175, 197)",
                              borderRadius: 60,
                              padding: 2,
                              marginRight: 5,
                            }}
                          />
                        </div>

                        <Typography
                          component="div"
                          variant="body2"
                          style={{
                            textAlign: "left",
                            color: "inherit",
                            fontSize: "13px",
                            paddingRight: 5,
                            paddingTop: 6,
                            paddingBottom: 6,
                            lineHeight: 1.2,
                            fontWeight: 500,
                            display: "flex",
                            alignItems: "center"
                          }}
                        >
                          <span style={{
                            fontSize: 11,
                          }}>
                            Pausiert
                          </span>
                        </Typography>

                        {(values.originalRef !== undefined) &&
                          <Tooltip title="Diese Rechnung wurde aufgrund einer wiederkehrenden Rechnungslegung erstellt.">
                            <div
                              onClick={(e) => {
                                e.stopPropagation();
                                setOpenInvoiceDialogAtom({ ...openInvoiceDialogAtom, recurring: true, open: true, invoiceId: values.originalRef, sendMode: false })
                              }}
                            >
                              <div className="recurring-info" style={{ borderRadius: "60px", display: "inline-grid", textAlign: "center", padding: "5px 5px", fontSize: "12px" }}>
                                <RepeatIcon className="recurring-info-icon" style={{ fontSize: 12 }} />
                              </div>
                            </div>
                          </Tooltip>
                        }
                      </div>

                      {values.cancelled &&
                        <div>
                          <Typography
                            style={{
                              padding: 8,
                              background: "white",
                              fontSize: 10,
                              border: "1px dashed rgb(214 216 237)",
                              borderRadius: 60,
                              color: "rgb(44, 83, 125)",
                              fontWeight: 500,
                              marginLeft: 5
                            }}>
                            Storniert
                          </Typography>
                        </div>
                      }
                    </div>
                }



              </div>
            }
          </Grid>
        </Grid>

        {/* Right Section: Buttons and Close Icon */}
        <Grid container item direction="row" alignItems="center" justifyContent="flex-end" className="appbar-right">
          {selectedClient && !isFetching &&
            <>
              {/* SAVE */}
              {values.sendState !== "scheduled" &&
              <Button
                disabled={invoiceDateError !== "" || dueDateError !== "" || invoiceNumberError || isFetching}
                size="small"
                variant="outlined"
                color="primary"
                className="app-bar-button"
                style={{ marginRight: "10px" }}
                startIcon={<SaveIcon />}
                onClick={(e) => {
                  invoiceHandler(true);

                  if (saveNewInvoiceSchema) {
                    setSettingsAtom((prev) => ({
                      ...prev,
                      invoicesNumberItems: values.invoiceNr,
                    }));
                  }
                }}
              >
                <span className="app-bar-button-label">Speichern</span>
              </Button>
              }

              {/* PDF PREVIEW */}
              <Button
                disabled={invoiceDateError !== "" || dueDateError !== "" || invoiceNumberError || isFetching}
                size="small"
                variant="outlined"
                color="primary"
                className="app-bar-button"
                style={{ marginRight: "10px" }}
                startIcon={<PictureAsPdfIcon />}
                onClick={() => {
                  setSendMode(false);
                  setOpen(true);
                  invoiceHandler(false);

                  if (saveNewInvoiceSchema) {
                    setSettingsAtom((prev) => ({
                      ...prev,
                      invoicesNumberItems: values.invoiceNr,
                    }));
                  }
                }}
              >
                <span className="app-bar-button-label">PDF Vorschau</span>
              </Button>

              {/* SEND */}
              {values.sendState !== "scheduled" &&
              <Button
                disabled={invoiceDateError !== "" || dueDateError !== "" || invoiceNumberError || isFetching}
                size="small"
                variant="contained"
                color="primary"
                className="app-bar-button app-bar-button--send"
                startIcon={<FiberManualRecordIcon />}
                onClick={(e) => {
                  invoiceHandler(false);
                  setOpen(true);
                  setSendMode(true)

                  if (saveNewInvoiceSchema) {
                    setSettingsAtom((prev) => ({
                      ...prev,
                      invoicesNumberItems: values.invoiceNr,
                    }));
                  }
                }}
              >
                <span className="app-bar-button-label">Aktivieren</span>
              </Button>
              }
            </>
          }

          {selectedClient && isFetching &&
          <div className="loading-dots">
            <span className="dot"></span>
            <span className="dot"></span>
            <span className="dot"></span>
          </div>
          }

          {/* CLOSE */}
          <IconButton
            edge="end"
            color="inherit"
            className="invoice-dialog-close-appbar"
            style={{
              marginLeft: "10px",
              display: "flex",
              width: "35px",
              height: "35px",
              border: "1px solid rgba(0, 0, 0, 0.04)"
            }}
            onClick={() => {
              setOpenInvoiceDialogAtom({
                ...openInvoiceDialogAtom,
                open: false,
                invoiceId: "",
                duplicate: false
              });
            }}
            aria-label={`${t("BUTTONS.AL_close")}`}
          >
            <CloseIcon color="primary" />
          </IconButton>
        </Grid>
      </Toolbar>
    </AppBar>
  );
};

export default InvoiceAppBarRecurring;
