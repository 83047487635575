import React, { useEffect, useState, useContext, useRef } from "react";

// IMPORT hooks & context
import { useTranslation } from "react-i18next";
import { useHttpClient } from "../shared/hooks/http-hook";
import { AuthContext } from "../shared/context/auth-context";

// IMPORT recoil
import {
  useRecoilState,
} from 'recoil';
import userAtomState from "../_atoms/userAtom";
import clientsAtomState from "../_atoms/clientsAtom";
import openInvoiceDialogAtomState from "../_atoms/openInvoiceDialogAtom";
import settingsAtomState from "../_atoms/settingsAtom";
import recurringActivatedAtomState from "../_atoms/recurringActivatedAtom";
import allInvoicesByClientAtomState from "../_atoms/allInvoicesByClientAtom"
import allInvoicesByOriginalRefAtomState from "../_atoms/allInvoicesByOriginalRefAtom";

import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import GetAppIcon from '@material-ui/icons/GetApp';
import DescriptionIcon from '@material-ui/icons/Description';

// IMPORT components
import { useSnackbar } from 'notistack';

// IMPORT components MATERIAL UI
import {
  Typography,
  Box,
  Container,
  Grid,
  Tooltip,
  Button,
  Checkbox,
  FormControlLabel
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useTheme } from '@material-ui/core/styles';
import Fab from '@material-ui/core/Fab';
import Hidden from '@material-ui/core/Hidden';

// IMPORT icons MATERIAL UI
import ClientsIcon from "@material-ui/icons/RecentActors";
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';
import FilterListIcon from '@material-ui/icons/FilterList';
import ImportExportIcon from '@material-ui/icons/ImportExport';
import EditIcon from '@material-ui/icons/Edit';
import ScheduleIcon from '@material-ui/icons/Schedule';
import MailIcon from '@material-ui/icons/Mail';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import AddIcon from '@material-ui/icons/Add';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import GavelIcon from '@material-ui/icons/Gavel';
import SearchIcon from '@material-ui/icons/Search';
import ErrorIcon from '@material-ui/icons/Error';
import WatchLaterIcon from '@material-ui/icons/WatchLater';
import RepeatIcon from '@material-ui/icons/Repeat';
import PlaylistAddCheckIcon from '@material-ui/icons/PlaylistAddCheck';
import SubjectIcon from '@material-ui/icons/Subject';
import RotateLeftIcon from '@material-ui/icons/RotateLeft';
import InfoIcon from '@material-ui/icons/Info';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import CancelPresentationIcon from '@material-ui/icons/CancelPresentation';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import CancelIcon from '@material-ui/icons/Cancel';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import EuroSymbolIcon from '@material-ui/icons/EuroSymbol';
import CheckIcon from '@material-ui/icons/Check';
import SaveIcon from "@material-ui/icons/Save";



import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Popover from '@material-ui/core/Popover';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { de } from 'date-fns/locale';

// IMPORT own functions
import { SET_VALUE } from '../_functions/SET_VALUE';
import { DB_PATCH_SETTINGS } from '../_functions/DB_SETTINGS';
import { RETURN_DATE } from '../_functions/DATES';
import { RETURN_HOURS } from '../_functions/DATES';
import { RETURN_OVERDUE_DAYS } from '../_functions/DATES';

// IMPORT own components
import UserProfileIncompleteWarning from "../user/components/UserProfileIncompleteWarning";
import InvoicesSearch from "./components/InvoicesSearch";
import InvoicesFilter from "./components/InvoicesFilter";
import InvoicesSort from "./components/InvoicesSort";
import InvoicesSettings from "./components/InvoicesSettings";
import InvoicesMenuDots from "./components/InvoicesMenuDots";
import LogoMonogram from './../_assets/logo_monogram.svg';
import DateRange from "../shared/components/DateRange/DateRange";
import InvoicesBulkEdit from "./components/InvoicesBulkEdit";
import RecurringRefDialog from './components/RecurringRefDialog'
import PayMenuPopover from "./components/PayMenuPopover";

// IMPORT own CSS
import "./Invoices.scss"
import { Euro, FlashOnTwoTone } from "@material-ui/icons";

// Ant and moment
import { ConfigProvider, DatePicker, TimePicker, Space } from 'antd';
import deDE from 'antd/es/locale/de_DE';
import dayjs from 'dayjs';
import 'dayjs/locale/de';

dayjs.locale('de'); // Set German locale for dayjs

const useStyles = makeStyles((theme) => ({
  // necessary for content to be below app bar
  toolbar: {
    height: '0px',
    '@media (max-width: 959px)': {
      minHeight: '45px',
      height: '45px',
    },
    '@media (max-width: 599px)': {
      minHeight: '45px',
      height: '45px',
    },
    '@media (max-width: 455px)': {
      minHeight: '45px',
      height: '45px',
    },
  },
  table: {
    minWidth: 650,
  },
}));


const Invoices = ({ type }) => {
  // HOOKS & CONTEXT
  const { t } = useTranslation();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();
  const { isLoading, error, sendRequest } = useHttpClient();
  const auth = useContext(AuthContext);


  // GLOBAL STATE (RECOIL)
  const [userAtom] = useRecoilState(userAtomState);
  const [openInvoiceDialogAtom, setOpenInvoiceDialogAtom] = useRecoilState(openInvoiceDialogAtomState);
  const [clientsAtom, setClientsAtom] = useRecoilState(clientsAtomState);
  const [recurringActivatedAtom, setRecurringActivatedAtom] = useRecoilState(recurringActivatedAtomState);
  const [settingsAtom, setSettingsAtom] = useRecoilState(settingsAtomState);
  const [allInvoicesByClientAtom, setAllInvoicesByClientAtom] = useRecoilState(allInvoicesByClientAtomState);
  const [allInvoicesByOriginalRefAtom, setAllInvoicesByOriginalRefAtom] = useRecoilState(allInvoicesByOriginalRefAtomState);


  // LOCAL STATE (GLOBAL DEPENDENCY monitoring temporay CHANGES until SAVED)
  const [settings, setSettings] = useState(settingsAtom);
  const [checkedItems, setCheckedItems] = useState([]);
  const [shownInvoices, setShownInvoices] = useState(0);
  const [totalInvoices, setTotalInvoices] = useState(0);
  const [selectedInvoice, setSelectedInvoice] = useState(null);
  const [bulkEdit, setBulkEdit] = useState(false);
  
  const handlePopoverOpen = (event, invoice) => {
    setPayMenuAnchorEl(event.currentTarget);
    setSelectedInvoice(invoice); // Set the clicked invoice
  };


  // LOCAL STATE (PURE)
  const [recurring, setRecurring] = useState(false);
  const [values, setValues] = useState({
    submenuOpen: false, // values: false, search, filter, sort, settings
    invoicesFiltered: {},
    searchInput: "",
    chipDirectChange: false,
    save: false,
    loading: true,
  });
  const [recurringRef, setRecurringRef] = useState([]);
  const [recurringRefDialogOpen, setRecurringRefDialogOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [invoicePayed, setInvoicePayed] = useState("");


  // Data variable
  const [dataInitial, setDataInitial] = useState([]);

  // Infinite Scroll Variables
  const INITIAL_ITEMS = 20; // Load 10 items initially
  const [offset, setOffset] = useState(0); // Track the current offset for fetching
  const [hasMoreInvoices, setHasMoreInvoices] = useState(true); // To know if more invoices exist
  const [isFetching, setIsFetching] = useState(false); // Track if a fetch is in progress

  // Search Variables
  const [searchTerm, setSearchTerm] = useState(
    allInvoicesByClientAtom !== "" ? `${allInvoicesByClientAtom}` :
      allInvoicesByOriginalRefAtom !== "" ? `${allInvoicesByOriginalRefAtom}` :
        ""
  );

  /***
  FETCH
  ***/
  const fetchData = async (currentOffset, limit, isInitialLoad = false, searchTerm = "", newRecurring) => {
    try {
      const storedUserData = localStorage.getItem('userData');
      if (!storedUserData) {
        throw new Error('No user data found in local storage');
      }

      const resUser = JSON.parse(storedUserData);

      setIsFetching(true); // Set fetching flag to true

      const response = await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL_API}/invoices/user/${resUser.userId}?limit=${limit}&offset=${currentOffset}&searchTerm=${searchTerm}&sortField=${settingsAtom.invoicesSortField}&sortOrder=${settingsAtom.invoicesSortOrder}&recurring=${JSON.stringify(newRecurring)}`,
        'GET',
        null,
        { Authorization: 'Bearer ' + resUser.token }
      );

      setShownInvoices(response.shownInvoices)
      setTotalInvoices(response.totalInvoices)

      if (response && response.invoices) {
        if (response.invoices.length < limit) {
          setHasMoreInvoices(false); // No more invoices to fetch
        }

        if (isInitialLoad) {
          setDataInitial(response.invoices); // Replace existing data for initial load
        } else {
          // Append new invoices, making sure no duplicates
          setDataInitial(prevInvoices => [
            ...prevInvoices,
            ...response.invoices,
          ]);
        }
      }

      setIsFetching(false);
    } catch (err) {
      setIsFetching(false);
      console.error('Error fetching invoices:', err);
    }
  };

  /***
  INITIAL LOADING
  ***/
  useEffect(() => {
    // Initial load when the page loads or search term/recurring changes
    setOffset(0); // Reset offset
    fetchData(0, INITIAL_ITEMS, true, searchTerm); // Fetch the first 5 invoices with updated recurring value
  }, [searchTerm]); // Trigger on recurring or search term change

  // Store scroll position before refetching
  const [scrollPos, setScrollPos] = useState(0);

  // Save scroll position before dialog opens
  useEffect(() => {
    if (openInvoiceDialogAtom.open) {
      setScrollPos(window.scrollY); // Save current scroll position
    }
  }, [openInvoiceDialogAtom.open]);

  // Refetch all invoices and restore scroll position when the dialog closes
  useEffect(() => {
    if (!openInvoiceDialogAtom.open) {
      fetchData(0, dataInitial.length, true, searchTerm); // Refetch all data to ensure updates
      setTimeout(() => {
        window.scrollTo(0, scrollPos); // Restore scroll position
      }, 0);
    }
  }, [openInvoiceDialogAtom.open]);

  // Load more invoices when the user clicks the "Load More" button
  const loadMoreInvoices = () => {
    if (!isFetching && hasMoreInvoices) {
      const limit = INITIAL_ITEMS;
      const newOffset = offset + limit;
      setOffset(newOffset); // Update the offset
      fetchData(newOffset, limit, false, searchTerm); // Fetch more data based on new offset
    }
  };

  /***
  SEARCH
  ***/
  // Handle search input
  const handleSearch = (e) => {
    const term = e.target.value;
    setSearchTerm(term);

    // Scroll to top when the search term is updated
    window.scrollTo({
      top: 0,
    });

    if (term !== "") {
      // Add logic here to filter invoices or trigger a new fetch based on the search term
    } else {
      handleSearchClear(); // Clear search when input is empty
    }
  };

  const handleSearchClear = () => {
    window.scrollTo({
      top: 0,
    });

    setSearchTerm("");
    setDataInitial([]);
    setOffset(0); // Reset offset for new search
    setHasMoreInvoices(true); // Allow more invoices to load

    // Use setTimeout to wait for the state updates to complete
    setTimeout(() => {
      fetchData(0, INITIAL_ITEMS, true, ""); // Fetch the first 5 invoices with cleared search term and respect recurring state
    }, 0);
  };

  // Trigger fetch with search term when the user types
  useEffect(() => {
    if (searchTerm !== '') {
      setOffset(0); // Reset offset for new search
      fetchData(0, INITIAL_ITEMS, true, searchTerm); // Pass recurring state along with search term
      setHasMoreInvoices(true); // Allow more invoices to load
    } else {
      // If search is cleared, load initial invoices again respecting the recurring state
      fetchData(0, INITIAL_ITEMS, true, "");
    }
  }, [searchTerm]); // Also listen for recurring state changes

  /***
  CHANGE SETTINGS
  ***/
  const handleChange = (e) => {
    SET_VALUE(e, setSettings);
  };

  // ON SAVE: 1) Update settingsAtom
  const updateSettingsHandler = () => {
    // GLOBAL changes
    // setSettingsAtom(settings);

    setSettingsAtom((prevSettings) => {
      const { invoicesDateRange, dateRangeStartValue, dateRangeEndValue, useDateRange, ...restPrevSettings } = prevSettings;

      return {
        ...restPrevSettings, // Update all other fields
        ...settings,         // Apply new settings
        dateRangeStartValue, // Keep these fields unchanged, because they are not change in settings but in settingsAtom
        dateRangeEndValue,
        useDateRange,
        invoicesDateRange
      };
    });

    // Save variable is only needed to trigger submenuClose when NOTHING changes in settings and save button is clicked
    SET_VALUE({ target: { id: "save", value: true } }, setValues);

    setDataInitial([]);
    setOffset(0); // Reset offset for new search
    setHasMoreInvoices(true); // Allow more invoices to load on scroll
    window.scrollTo({
      top: 0,
    });
  };

  // ON SAVE: 2) DB: PATCH SETTINGS
  useEffect(() => {
    if (auth) {
      DB_PATCH_SETTINGS(settingsAtom, auth, sendRequest, enqueueSnackbar, t, false);

      SET_VALUE({
        target: {
          "submenuOpen": false,
          "chipDirectChange": false,
          "save": false,
        }
      }, setValues, "multi")
    }
  }, [auth.token, settingsAtom, values.save]);

  // ON CANCEL:
  const cancelSettingsHandler = () => {
    // Re-set original values
    setSettings(settingsAtom);
    SET_VALUE({ target: { id: "submenuOpen", value: false } }, setValues);
  };

  useEffect(() => {
    setDataInitial([]);
    setOffset(0); // Reset offset for new search
    setHasMoreInvoices(true); // Allow more invoices to load on scroll
    fetchData(0, INITIAL_ITEMS, true, searchTerm); // Fetch filtered data with cleared search term
  }, [settingsAtom]);


  // Bulk Edit refetch
  useEffect(() => {
    if(bulkEdit) {
      setDataInitial([]);
      setOffset(0); // Reset offset for new search
      setHasMoreInvoices(true); // Allow more invoices to load on scroll
      fetchData(0, INITIAL_ITEMS, true, searchTerm); // Fetch filtered data with cleared search term
      setBulkEdit(false)
    }
  }, [bulkEdit]);



  const [payMenuAnchorEl, setPayMenuAnchorEl] = useState(null);
  const [payMenuValues, setPayMenuValues] = useState({ payDate: null, payState: "sent" });
  const [payDateError, setPayDateError] = useState("");

  const validateDate = (selectedDate, invoiceDate) => {
    if (!selectedDate) {
      setPayDateError("Bitte ausfüllen");
      return false;
    }

    if (isNaN(new Date(selectedDate).getTime())) {
      setPayDateError("Bitte ausfüllen");
      return false;
    }

    setPayDateError(""); // Clear error if all validations pass
    return true;
  };

  const handleDateChange = (date, invoiceDate) => {
    setPayMenuValues((prev) => ({ ...prev, payDate: date }));
    validateDate(date, invoiceDate);
  };





  const openPayMenu = (event) => {
    event.stopPropagation();
    setPayMenuAnchorEl(event.currentTarget);
  };

  const closePayMenu = (e) => {
    setPayMenuAnchorEl(null);

    // Delay the payDate update by 1.5 seconds
    setTimeout(() => {
      setPayMenuValues({ ...payMenuValues, payDate: new Date().toString() });
      setPayDateError("")
    }, 500);
  };

  const isPayMenuOpen = Boolean(payMenuAnchorEl);

  const activeFilterCount = [
    settings.invoicesFiltersStatusDraft,
    settings.invoicesFiltersStatusScheduled,
    settings.invoicesFiltersStatusSent,
    settings.invoicesFiltersStatusPayed,
    settings.invoicesFiltersStatusOverdue,
  ].filter(Boolean).length;

  const downloadPdf = async (invoiceId) => {
    try {
      const formData = new FormData();
      formData.append("userId", auth.userId);
      formData.append("invoiceId", invoiceId);

      const response = await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL_API}/invoices/downloadinvoice`,
        "POST",
        formData,
        {
          Authorization: "Bearer " + auth.token,
        }
      );

      const base64String = response.pdfBase64;
      
      // Convert base64 to Blob
      const byteCharacters = atob(base64String);
      const byteNumbers = new Array(byteCharacters.length).fill().map((_, i) => byteCharacters.charCodeAt(i));
      const byteArray = new Uint8Array(byteNumbers);
      const blob = new Blob([byteArray], { type: 'application/pdf' });

      // Create a download link and trigger the download
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = response.filename; // Set the filename here
      link.click();  // Trigger the download
    } catch (error) {
      console.error('Error fetching the PDF:', error);
    }
  };

  return (
    <div>
      <div className={classes.toolbar} />

      <div className="mobile-menu-section" style={{ borderBottom: "1px solid rgb(237, 239, 255)", position: "sticky", top: 47, zIndex: 10, width: "100%", padding: "2px 4px" }}>
        <DateRange
          type={type === "invoices" ? "invoices" : "estimates"}
          mobile={true}
          setCheckedItems={setCheckedItems}
        />
        <InvoicesFilter
          settings={settings}
          settingsAtom={settingsAtom}
          handleChange={handleChange}
          cancelSettingsHandler={cancelSettingsHandler}
          updateSettingsHandler={updateSettingsHandler}
          setValues={setValues}
          SET_VALUE={SET_VALUE}
          setSettings={setSettings}
          values={values}
          type={type}
          recurring={recurring}
          setOffset={setOffset}
          fetchData={fetchData}
          INITIAL_ITEMS={INITIAL_ITEMS}
          searchTerm={searchTerm}
          setHasMoreInvoices={setHasMoreInvoices}
          setSettingsAtom={setSettingsAtom}
          mobile={true}
          setCheckedItems={setCheckedItems}
        />

        <InvoicesSort
          settings={settings}
          handleChange={handleChange}
          cancelSettingsHandler={cancelSettingsHandler}
          updateSettingsHandler={updateSettingsHandler}
          setValues={setValues}
          SET_VALUE={SET_VALUE}
          setSettings={setSettings}
          values={values}
          type={type}
          recurring={false}
          mobile={true}
          setCheckedItems={setCheckedItems}
        />

        <InvoicesSettings
          settings={settings}
          handleChange={handleChange}
          cancelSettingsHandler={cancelSettingsHandler}
          updateSettingsHandler={updateSettingsHandler}
          setValues={setValues}
          SET_VALUE={SET_VALUE}
          setSettings={setSettings}
          values={values}
        />
      </div>

      <div className="not-mobile-menu-section box-shadow--1" style={{ position: "sticky", top: 0, zIndex: 9, width: "100%", }}>
        <Grid container className="subpage-header" item direction="row" alignItems="center" alignContent="center" style={{ paddingBottom: 0 }}>
          <Grid className="main-headline-wrapper" item container direction="row" alignItems="center" style={{ marginRight: "10px", marginBottom: 10 }}>
            <Grid item >
              <Grid item container alignItems="center">
                <div style={{borderRadius: 4, width: 30, height: 30, background: "white", marginRight: "15px", background: "linear-gradient(to right top, rgb(38, 108, 189), rgb(36, 115, 192), rgb(33, 150, 243), #00bca7, #00bf72)", marginRight: 8, marginTop: -1, marginLeft: -1, display: "flex", justifyContent: "center", alignItems: "center"}}>
                    <DescriptionIcon style={{color: "white"}}/>
                </div>
                <Typography variant="h6" component="h1" style={{ fontWeight: "600" }} className="headline">Rechnungen</Typography>

                {/* {(!values.loading || !isLoading) && userAtom.data.signupCompleted && (userAtom.clientscreated || userAtom.data.clients.length !== 0) && !isFetching && */}
                {userAtom.data.clients.length !== 0 &&
                  <Hidden smDown>
                    <Button
                      variant="contained"
                      color="primary"
                      size="small"
                      startIcon={<AddCircleIcon className="MuiSvgIcon-root" fontSize="large" />}
                      className="add-button" // Add the custom class
                      style={{ height: 30 }}
                      onClick={
                        type === "invoices"
                          ? () =>
                            setOpenInvoiceDialogAtom({
                              ...openInvoiceDialogAtom,
                              open: true,
                              isEstimate: false,
                              recurring: false,
                              sendMode: false,
                            })
                          : () =>
                            setOpenInvoiceDialogAtom({
                              ...openInvoiceDialogAtom,
                              open: true,
                              isEstimate: true,
                              recurring: false,
                              sendMode: false,
                            })
                      }
                    >
                      Erstellen
                    </Button>
                  </Hidden>
                }
              </Grid>
            </Grid>
          </Grid>

          <Grid
            item
            container
            alignItems="center"
            style={{ display: "flex", height: "48px" }} // Set consistent height for all children
          >
            <DateRange
              type={type === "invoices" ? "invoices" : "estimates"}
              mobile={false}
              setCheckedItems={setCheckedItems}
            />

            <InvoicesSearch
              handleSearchClear={handleSearchClear}
              handleSearch={handleSearch}
              type={type}
              searchTerm={searchTerm}
              allInvoicesByClientAtom={allInvoicesByClientAtom}
              setAllInvoicesByClientAtom={setAllInvoicesByClientAtom}
              allInvoicesByOriginalRefAtom={allInvoicesByOriginalRefAtomState}
              setAllInvoicesByOriginalRefAtom={setAllInvoicesByOriginalRefAtom}
              setCheckedItems={setCheckedItems}
            />

            <InvoicesSettings
              settings={settings}
              handleChange={handleChange}
              cancelSettingsHandler={cancelSettingsHandler}
              updateSettingsHandler={updateSettingsHandler}
              setValues={setValues}
              SET_VALUE={SET_VALUE}
              setSettings={setSettings}
              values={values}
            />
            {/* <InvoicesSettings
              settings={settings}
              handleChange={handleChange}
              cancelSettingsHandler={cancelSettingsHandler}
              updateSettingsHandler={updateSettingsHandler}
              setValues={setValues}
              SET_VALUE={SET_VALUE}
              setSettings={setSettings}
              values={values}
            /> */}
          </Grid>
        </Grid>

        <Grid container item xs={12} alignItems="center" className="chips" style={{ borderBottom: "1px solid rgb(237, 239, 255)", paddingBottom: 8 }}>

          {/* <InvoicesBulkEdit
          mobile={false}
        />         */}
          <InvoicesFilter
            settings={settings}
            settingsAtom={settingsAtom}
            handleChange={handleChange}
            cancelSettingsHandler={cancelSettingsHandler}
            updateSettingsHandler={updateSettingsHandler}
            setValues={setValues}
            SET_VALUE={SET_VALUE}
            setSettings={setSettings}
            values={values}
            type={type}
            recurring={recurring}
            setOffset={setOffset}
            fetchData={fetchData}
            INITIAL_ITEMS={INITIAL_ITEMS}
            searchTerm={searchTerm}
            setHasMoreInvoices={setHasMoreInvoices}
            setSettingsAtom={setSettingsAtom}
            mobile={false}
            setCheckedItems={setCheckedItems}
          />

          <InvoicesSort
            settings={settings}
            handleChange={handleChange}
            cancelSettingsHandler={cancelSettingsHandler}
            updateSettingsHandler={updateSettingsHandler}
            setValues={setValues}
            SET_VALUE={SET_VALUE}
            setSettings={setSettings}
            values={values}
            type={type}
            recurring={false}
            mobile={false}
            setCheckedItems={setCheckedItems}
          />

        </Grid>

      </div>

      {userAtom.data.signupCompleted &&
        <React.Fragment>
          <Container maxWidth={false} disableGutters className="bg--1" style={{ paddingBottom: 70 }}>
            <div className="count-entries" style={{ display: "flex", justifyContent: "flex-start" }}>
              <div style={{ display: "flex", marginRight: 5 }}>
                {!isLoading ? (
                  <span>
                    <strong>{dataInitial.length} von {shownInvoices} Rechnungen geladen</strong>
                  </span>
                ) : (<span> Wird gelanden ... </span>)}
                {(hasMoreInvoices && !isFetching) && (<span className="load-more-small" style={{ display: "flex", paddingLeft: 5, paddingRight: 5 }} onClick={loadMoreInvoices}><RotateLeftIcon style={{ zoom: 0.7, paddingTop: 1 }} /><span style={{ paddingTop: 1 }}>MEHR LADEN</span></span>)}
              </div>
              {/* <div>
                {<span><span style={{ paddingLeft: 5, paddingTop: 1 }}>{settingsAtom.useDateRange && "| Zeitraum eingeschränkt"} {searchTerm !== "" && `| Suchbegriff: ${searchTerm}`} {activeFilterCount > 0 && "| Statusfilter gesetzt"}</span></span>}
              </div> */}

<div>
  <span>
    {settingsAtom.useDateRange && (
      <>
        <span style={{ color: "rgb(227, 18, 202)", fontWeight: "bold", marginRight: 4 }}>|</span>
        Zeitraum eingeschränkt
      </>
    )}
    {searchTerm !== "" && (
      <>
        <span style={{ color: "rgb(227, 18, 202)", fontWeight: "bold", marginLeft: 8, marginRight: 4 }}>|</span>
        Suchbegriff: {searchTerm}
      </>
    )}
    {activeFilterCount > 0 && (
      <>
        <span style={{ color: "rgb(227, 18, 202)", fontWeight: "bold", marginLeft: 8, marginRight: 4 }}>|</span>
        Statusfilter gesetzt
      </>
    )}
  </span>
</div>

            </div>
            {/* <div style={{marginLeft: 50, width: "100%", paddingTop: 20, paddingBottom: 0, marginBottom: -5, fontSize: 12, color: "rgb(44, 83, 125)", opacity: 0.5}}><strong>Anzahl: 99 von 102</strong> (Aufgrund von Zeitraum-, Filter- bzw. Sucheinschränkung werden <strong>3 Rechnungen</strong> nicht angezeigt)</div> */}
            <Box
              display="flex"
              direction="column"
              className="list-box"
            >
              <Grid container item xs={12} >
                <div className="box-shadow--1 invoices-list">
                  <Grid
                    item
                    className="sticky-nav box-shadow--1 h-padding--5-5 tool-menu"
                    style={{ top: 149 }}
                  >

                    <Grid container>
                      <Grid
                        container
                        alignItems="center"
                        justifyContent="space-between"
                        item
                        xs={12}
                      >


                        <Grid container item xs={12} justifyContent="space-between">

                          <Grid xs={12} lg={2} container item justifyContent="center" alignItems="center" style={{ paddingLeft: 15 }}>
                            <div
                              style={{ position: "absolute", left: 5 }}
                              onClick={(e) => {
                                e.stopPropagation(); // Prevent parent click event
                              }}
                            >
                              <InvoicesBulkEdit
                                dataInitial={dataInitial}
                                checkedItems={checkedItems}
                                setCheckedItems={setCheckedItems}
                                setBulkEdit={setBulkEdit}
                              />
                            </div>
                            <Typography style={{ marginRight: 5 }}>Status</Typography>
                          </Grid>

                          <Grid xs={12} lg={2}
                            container
                            item
                            justifyContent="center"
                            alignItems="center"
                            alignContent="center"
                            className="table-headline"
                            style={{ background: settings.invoicesSortField === "invoiceCompany" && "rgb(237, 239, 255)", borderRadius: 6 }}
                            onClick={() => {
                              setSettings({ ...settings, invoicesSortField: "invoiceCompany", invoicesSortOrder: settings.invoicesSortOrder === "asc" ? "desc" : "asc" });
                              setSettingsAtom({ ...settingsAtom, invoicesSortField: "invoiceCompany", invoicesSortOrder: settingsAtom.invoicesSortOrder === "asc" ? "desc" : "asc" })
                            }}
                          >
                            <div style={{ display: "flex", alignItems: "flex-start" }}>
                              <Typography style={{ paddingLeft: 15 }}>Kunde</Typography>
                              {settingsAtom.invoicesSortField === "invoiceCompany" && settings.invoicesSortOrder === "desc" ? <ArrowDownwardIcon style={{ zoom: 0.6, marginLeft: 5 }} /> : ""}
                              {settingsAtom.invoicesSortField === "invoiceCompany" && settings.invoicesSortOrder === "asc" ? <ArrowUpwardIcon style={{ zoom: 0.6, marginLeft: 5 }} /> : ""}
                            </div>
                          </Grid>

                          <Grid xs={12} lg={8} container item justifyContent="flex-start" alignItems="center" >
                            <Grid xs={4} md={2}
                              container
                              item
                              justifyContent="center"
                              alignItems="center"
                              className="table-headline"
                              style={{ background: settings.invoicesSortField === "invoiceNumber" && "rgb(237, 239, 255)" }}
                              onClick={() => {
                                setSettings({ ...settings, invoicesSortField: "invoiceNumber", invoicesSortOrder: settings.invoicesSortOrder === "asc" ? "desc" : "asc" });
                                setSettingsAtom({ ...settingsAtom, invoicesSortField: "invoiceNumber", invoicesSortOrder: settingsAtom.invoicesSortOrder === "asc" ? "desc" : "asc" })
                              }}
                            >
                              <div style={{ display: "flex", alignItems: "center", justifyContent: "center", padding: "5px 10px" }}>
                                <Typography
                                  style={{
                                    textAlign: "center",
                                    whiteSpace: "normal",
                                    wordBreak: "break-word",
                                  }}
                                >
                                  <span class="table-lable-invoicenumber-large">Rechnungsnummer</span>
                                  <span class="table-lable-invoicenumber-small">R-Nr</span>
                                </Typography>
                                {settingsAtom.invoicesSortField === "invoiceNumber" && settings.invoicesSortOrder === "desc" && (
                                  <ArrowDownwardIcon style={{ zoom: 0.6, marginLeft: 5 }} />
                                )}
                                {settingsAtom.invoicesSortField === "invoiceNumber" && settings.invoicesSortOrder === "asc" && (
                                  <ArrowUpwardIcon style={{ zoom: 0.6, marginLeft: 5 }} />
                                )}
                              </div>
                            </Grid>

                            <Grid xs={12} md={2}
                              container
                              item
                              justifyContent="center"
                              alignItems="center"
                              className="table-headline"
                              style={{ background: settings.invoicesSortField === "invoiceNet" && "rgb(237, 239, 255)", borderRadius: 6 }}
                              onClick={() => {
                                setSettings({ ...settings, invoicesSortField: "invoiceNet", invoicesSortOrder: settings.invoicesSortOrder === "asc" ? "desc" : "asc" });
                                setSettingsAtom({ ...settingsAtom, invoicesSortField: "invoiceNet", invoicesSortOrder: settingsAtom.invoicesSortOrder === "asc" ? "desc" : "asc" })
                              }}
                            >
                              <div style={{ display: "flex", alignItems: "center", justifyContent: "center", padding: "5px 10px" }}>
                                <Typography
                                  style={{
                                    textAlign: "center",
                                    whiteSpace: "normal",
                                    wordBreak: "break-word",
                                  }}
                                >
                                  Netto
                                </Typography>
                                {settingsAtom.invoicesSortField === "invoiceNet" && settings.invoicesSortOrder === "desc" && (
                                  <ArrowDownwardIcon style={{ zoom: 0.6, marginLeft: 5 }} />
                                )}
                                {settingsAtom.invoicesSortField === "invoiceNet" && settings.invoicesSortOrder === "asc" && (
                                  <ArrowUpwardIcon style={{ zoom: 0.6, marginLeft: 5 }} />
                                )}
                              </div>
                            </Grid>

                            <Grid xs={12} md={2}
                              container
                              item
                              justifyContent="center"
                              alignItems="center"
                              className="table-headline"
                              style={{ background: settings.invoicesSortField === "invoiceGross" && "rgb(237, 239, 255)", borderRadius: 6 }}
                              onClick={() => {
                                setSettings({ ...settings, invoicesSortField: "invoiceGross", invoicesSortOrder: settings.invoicesSortOrder === "asc" ? "desc" : "asc" });
                                setSettingsAtom({ ...settingsAtom, invoicesSortField: "invoiceGross", invoicesSortOrder: settingsAtom.invoicesSortOrder === "asc" ? "desc" : "asc" })
                              }}
                            >
                              <div style={{ display: "flex", alignItems: "center", justifyContent: "center", padding: "5px 10px" }}>
                                <Typography
                                  style={{
                                    textAlign: "center",
                                    whiteSpace: "normal",
                                    wordBreak: "break-word",
                                  }}
                                >
                                  Brutto
                                </Typography>
                                {settingsAtom.invoicesSortField === "invoiceGross" && settings.invoicesSortOrder === "desc" && (
                                  <ArrowDownwardIcon style={{ zoom: 0.6, marginLeft: 5 }} />
                                )}
                                {settingsAtom.invoicesSortField === "invoiceGross" && settings.invoicesSortOrder === "asc" && (
                                  <ArrowUpwardIcon style={{ zoom: 0.6, marginLeft: 5 }} />
                                )}
                              </div>
                            </Grid>

                            <Grid xs={4} md={2}
                              container
                              item
                              justifyContent="center"
                              alignItems="center"
                              className="table-headline"
                              style={{ background: settings.invoicesSortField === "invoiceDate" && "rgb(237, 239, 255)", borderRadius: 6 }}
                              onClick={() => {
                                setSettings({ ...settings, invoicesSortField: "invoiceDate", invoicesSortOrder: settings.invoicesSortOrder === "asc" ? "desc" : "asc" });
                                setSettingsAtom({ ...settingsAtom, invoicesSortField: "invoiceDate", invoicesSortOrder: settingsAtom.invoicesSortOrder === "asc" ? "desc" : "asc" })
                              }}
                            >
                              <div style={{ display: "flex", alignItems: "center", justifyContent: "center", padding: "5px 10px" }}>
                                <Typography
                                  style={{
                                    textAlign: "center",
                                    whiteSpace: "normal",
                                    wordBreak: "break-word",
                                  }}
                                >
                                  <span class="table-lable-invoicedate-large">Rechnungsdatum</span>
                                  <span class="table-lable-invoicedate-small">R-Datum</span>
                                </Typography>
                                {settingsAtom.invoicesSortField === "invoiceDate" && settings.invoicesSortOrder === "desc" && (
                                  <ArrowDownwardIcon style={{ zoom: 0.6, marginLeft: 5 }} />
                                )}
                                {settingsAtom.invoicesSortField === "invoiceDate" && settings.invoicesSortOrder === "asc" && (
                                  <ArrowUpwardIcon style={{ zoom: 0.6, marginLeft: 5 }} />
                                )}
                              </div>
                            </Grid>

                            {type === "invoices" &&
                              <Grid xs={4} md={2}
                                container
                                item
                                justifyContent="center"
                                className="table-headline"
                                style={{ background: settings.invoicesSortField === "invoiceDueDate" && "rgb(237, 239, 255)", borderRadius: 6 }}
                                onClick={() => {
                                  setSettings({ ...settings, invoicesSortField: "invoiceDueDate", invoicesSortOrder: settings.invoicesSortOrder === "asc" ? "desc" : "asc" });
                                  setSettingsAtom({ ...settingsAtom, invoicesSortField: "invoiceDueDate", invoicesSortOrder: settingsAtom.invoicesSortOrder === "asc" ? "desc" : "asc" })
                                }}
                              >
                                <div style={{ display: "flex", alignItems: "center", justifyContent: "center", padding: "5px 10px" }}>
                                  <Typography
                                    style={{
                                      textAlign: "center",
                                      whiteSpace: "normal",
                                      wordBreak: "break-word",
                                    }}
                                  >
                                    Fälligkeit
                                  </Typography>
                                  {settingsAtom.invoicesSortField === "invoiceDueDate" && settings.invoicesSortOrder === "desc" && (
                                    <ArrowDownwardIcon style={{ zoom: 0.6, marginLeft: 5 }} />
                                  )}
                                  {settingsAtom.invoicesSortField === "invoiceDueDate" && settings.invoicesSortOrder === "asc" && (
                                    <ArrowUpwardIcon style={{ zoom: 0.6, marginLeft: 5 }} />
                                  )}
                                </div>
                              </Grid>
                            }

                            {/* Optionen */}
                            <Grid container item xs={12} md={2} justifyContent="center"></Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <div style={{ minHeight: "100vh" }}>
                    {/* INVOICES already created */}

                    {dataInitial.length !== 0 && (
                      <>
                        {/* APPLIED FILTER after Loading doesn't result in list of length 0 */}
                        {Object.keys(dataInitial).length !== 0 &&
                          (dataInitial)
                            .map((invoice, i) => (
                              <Grid container key={i}>
                                <Grid
                                  container
                                  alignItems="center"
                                  justifyContent="space-between"
                                  item
                                  xs={12}
                                  className="h-bg--white user-clients"
                                  style={{
                                    background: (checkedItems.includes(invoice._id) || selectedInvoice === invoice._id) ? "#c2d5f1" : "white",
                                    borderBottom: "1px solid #e2ecfd",
                                    // background: recurring ? "#fbf8ff" : "inherit",
                                    borderLeft:
                                      (invoice.sendState !== "sent" && invoice.sendState !== "scheduled" && (invoice.payState === "notPayed" || invoice.payState === undefined)) ? invoice.cancelled ? `5px solid rgba(172, 175, 197, 0.5)` : `5px solid rgb(172 175 197)` :
                                        (invoice.sendState === "scheduled" && invoice.payState === "notPayed") ? `5px solid ${theme.palette.warning.light}` :
                                          (invoice.sendState === "sent" && invoice.payState === "notPayed") ? invoice.cancelled ? `5px solid rgba(26, 123, 201, 0.5)` : `5px solid ${theme.palette.primary.dark}` :
                                            `5px solid ${theme.palette.success.light}`
                                  }}
                                >


                                  <Grid container item xs={12} justifyContent="space-between" className="table-section"
                                    onClick={() => setOpenInvoiceDialogAtom({
                                      ...openInvoiceDialogAtom,
                                      recurring: false,
                                      open: true,
                                      invoiceId: invoice.id,
                                      isEstimate: (invoice.type === "estimate" || invoice.type === "offer") ? true : false,
                                      sendMode: false,
                                    })}
                                  >
                                    {/* First Part using lg 4 */}
                                    {/* Status */}
                                    <Grid xs={12} lg={2}
                                      container
                                      item
                                      alignItems="center"
                                      justifyContent="flex-start"
                                      className="invoice-status"
                                      style={{ padding: "5px 0", justifyContent: "flex-start" }}
                                    >
                                      <div
                                        className="invoice-checkbox"
                                        style={{ position: "absolute", left: 408, width: 30 }}
                                        onClick={(e) => {
                                          e.stopPropagation(); // Prevent parent click event
                                          // setCheckedItems((prev) => {
                                          //   if (prev.includes(invoice._id)) {
                                          //     // If `invoice._id` is already in the array, remove it
                                          //     return prev.filter((id) => id !== invoice._id);
                                          //   } else {
                                          //     // Otherwise, add `invoice._id` to the array
                                          //     return [...prev, invoice._id];
                                          //   }
                                          // });
                                        }}
                                      >
                                        <FormControlLabel
                                          control={
                                            <Checkbox
                                              checked={checkedItems.includes(invoice._id)}
                                              onChange={(e) => {
                                                e.stopPropagation(); // Prevent parent click event
                                                setCheckedItems((prev) => {
                                                  if (prev.includes(invoice._id)) {
                                                    // If `invoice._id` is already in the array, remove it
                                                    return prev.filter((id) => id !== invoice._id);
                                                  } else {
                                                    // Otherwise, add `invoice._id` to the array
                                                    return [...prev, invoice._id];
                                                  }
                                                });
                                              }}
                                              color="primary"
                                              className="checkbox-invoices"
                                              style={{ zoom: 0.8, display: checkedItems.includes(invoice._id) ? "block" : "none" }}
                                            />
                                          }
                                        />
                                      </div>

                                      <div className="invoice-checkbox fallback-icon" style={{ position: "absolute", left: 408, width: 30, display: checkedItems.includes(invoice._id) ? "none" : "inherit" }}>
                                        <DescriptionIcon color="primary" style={{marginLeft: -4, height: 22, width: 22}} height={17} width={17}/> 
                                      </div>

                                      {/* Draft */}
                                      {(invoice.sendState !== "sent" && invoice.sendState !== "scheduled" &&
                                        (invoice.payState === "notPayed" || invoice.payState === undefined)) && (
                                          <div
                                            style={{
                                              position: "relative",
                                              display: "inline-block",
                                              marginLeft: 5,
                                              marginRight: 5,
                                            }}
                                          >                                        
                                              <div
                                                style={{
                                                  background: invoice.cancelled ? "#f5f5f5" : "rgb(229 232 239)",
                                                  padding: "0px 5px",
                                                  borderRadius: 60,
                                                  display: "flex",
                                                  justifyContent: "flex-start",
                                                  alignItems: "center",
                                                  position: "relative", // Added for badge positioning
                                                  marginBottom: 1,
                                                  color: "rgb(44, 83, 125)",
                                                  opacity: invoice.cancelled ? 0.5 : 1,
                                                }}
                                              >
                                                <div style={{ position: "relative", marginTop: 5 }}>
                                                  <EditIcon
                                                    style={{
                                                      fontSize: "21px",
                                                      color: "#ffffff",
                                                      background: "rgb(172, 175, 197)",
                                                      borderRadius: 60,
                                                      padding: 2,
                                                      marginRight: 5,
                                                    }}
                                                  />
                                                </div>

                                                {/* Typography Content */}
                                                <Typography
                                                  component="div"
                                                  variant="body2"
                                                  style={{
                                                    textAlign: "left",
                                                    color: "inherit",
                                                    fontSize: "13px",
                                                    paddingTop: "1px",
                                                    lineHeight: 1.2,
                                                    fontWeight: 500,
                                                    paddingTop: 6,
                                                    paddingBottom: 7,
                                                    display: "flex",
                                                    alignItems: "center"
                                                  }}
                                                >
                                                  <span style={{
                                                    fontSize: 11,
                                                    paddingRight: 5
                                                  }}>
                                                    Entwurf
                                                  </span>

                                                  {invoice.type === "cancellation" && (
                                                    <span style={{ fontWeight: 700, marginTop: -2, paddingRight: 5, paddingLeft: 5, borderLeft: "2px solid rgb(44 83 125 / 20%)" }}>
                                                      <span style={{ fontSize: 9, padding: "2px 0px 0px 0px", marginTop: -2, borderRadius: 60, display: "inline-block", }}>
                                                      STORNORECHNUNG
                                                      </span>
                                                      {invoice.cancelledRefInvoiceNr &&
                                                      <span
                                                        style={{
                                                          display: "block",
                                                          overflow: "hidden",
                                                          whiteSpace: "nowrap",
                                                          textOverflow: "ellipsis",
                                                          verticalAlign: "middle",
                                                          fontSize: 9,
                                                          fontWeight: "normal"
                                                        }}
                                                      >
                                                        Zu Nr. {invoice.cancelledRefInvoiceNr}
                                                      </span>
                                                      }
                                                    </span>
                                                  )}
                                                </Typography>

                                                {(invoice.originalRef !== undefined) &&
                                                  <Tooltip title="Diese Rechnung wurde aufgrund einer wiederkehrenden Rechnungslegung erstellt.">
                                                    <div
                                                      // onClick={(e) => {
                                                      //   e.stopPropagation();
                                                      //   setOpenInvoiceDialogAtom({ ...openInvoiceDialogAtom, recurring: true, open: true, invoiceId: invoice.originalRef, sendMode: false })
                                                      // }}
                                                    >
                                                      <div className="recurring-info" style={{ borderRadius: "60px", display: "inline-grid", textAlign: "center", padding: "5px 5px", fontSize: "12px" }}>
                                                        <RepeatIcon className="recurring-info-icon" style={{ fontSize: 12 }} />
                                                      </div>
                                                    </div>
                                                  </Tooltip>
                                                }
                                              </div>
                                              

                                            {invoice.cancelled &&
                                              <div>
                                                <Typography
                                                  style={{
                                                    paddingTop: 2,
                                                    paddingBottom: 2,
                                                    fontSize: 9,
                                                    border: "1px dashed rgb(214 216 237)",
                                                    borderRadius: 60,
                                                    paddingLeft: 5,
                                                    paddingRight: 5,
                                                    color: "rgb(44, 83, 125)",
                                                    fontWeight: 500
                                                  }}>
                                                  Storniert
                                                </Typography>
                                              </div>
                                            }
                                          </div>
                                        )}

                                      {/* Scheduled */}
                                      {(invoice.sendState === "scheduled" && invoice.payState === "notPayed") && (
                                        <div style={{ position: "relative", display: "inline-block", marginLeft: 5, marginRight: 5 }}
                                        >
                                          <Tooltip title={`Wird am ${RETURN_DATE(invoice.sendDate)} um ${RETURN_HOURS(invoice.sendDate)} gesendet`} arrow>
                                            <div
                                              style={{
                                                background: "#ffe6c3",
                                                padding: "0px 5px",
                                                borderRadius: 60,
                                                display: "flex",
                                                justifyContent: "flex-start",
                                                alignItems: "center",
                                                position: "relative", // Added for badge positioning
                                                marginBottom: 1,
                                                color: "rgb(44, 83, 125)",
                                              }}
                                            >
                                              <div style={{ position: "relative", marginTop: 5 }}>
                                                <ScheduleIcon
                                                  style={{
                                                    fontSize: "21px",
                                                    color: "#ffffff",
                                                    background: "rgb(255, 183, 77)",
                                                    borderRadius: 60,
                                                    marginRight: 5,
                                                  }}
                                                />
                                              </div>
                                                <Typography
                                                  component="div"
                                                  variant="body2"
                                                  style={{
                                                    textAlign: "left",
                                                    fontSize: "13px",
                                                    color: "inherit",
                                                    paddingTop: 5,
                                                    paddingBottom: 5,
                                                    paddingRight: 5,
                                                    display: "flex",
                                                    alignItems: "center",
                                                  }}
                                                >
                                                  <span style={{ fontWeight: "500", fontSize: 11, lineHeight: 1.3 }}>
                                                    {`Versand geplant`}
                                                    <span><br/></span>
                                                    {RETURN_DATE(invoice.sendDate)} um {RETURN_HOURS(invoice.sendDate)}
                                                  </span>

                                                  {invoice.type === "cancellation" && (
                                                    <span style={{ fontWeight: 700, marginTop: -2, paddingLeft: 5, marginLeft: 5, borderLeft: "2px solid rgb(44 83 125 / 20%)" }}>
                                                      <span style={{ fontSize: 9, padding: "2px 0px 0px 0px", marginTop: -2, borderRadius: 60, display: "inline-block", }}>
                                                      STORNORECHNUNG
                                                      </span>
                                                      {invoice.cancelledRefInvoiceNr &&
                                                      <span
                                                        style={{
                                                          display: "block",
                                                          overflow: "hidden",
                                                          whiteSpace: "nowrap",
                                                          textOverflow: "ellipsis",
                                                          verticalAlign: "middle",
                                                          fontSize: 9,
                                                          fontWeight: "normal",
                                                          marginTop: -1,
                                                        }}
                                                      >
                                                        Zu Nr. {invoice.cancelledRefInvoiceNr}
                                                      </span>
                                                      }
                                                    </span>
                                                  )}
                                                  </Typography>
                                            </div>
                                          </Tooltip>
                                        </div>
                                      )}

                                      {/* Sent */}
                                      {(invoice.sendState === "sent" && invoice.payState === "notPayed") && (
                                        <div style={{ position: "relative", display: "inline-block", marginLeft: 5, marginRight: 5 }}>

                                          <div
                                            style={{
                                              background: invoice.cancelled ? "#f5f5f5" : "#d1e5ff",
                                              padding: "0px 5px",
                                              borderRadius: 60,
                                              display: "flex",
                                              justifyContent: "flex-start",
                                              alignItems: "center",
                                              position: "relative", // Added for badge positioning
                                              paddingTop: 5,
                                              paddingBottom: 5,
                                              color: "rgb(44, 83, 125)",
                                              opacity: invoice.cancelled ? 0.5 : 1,
                                            }}
                                          >

                                            <div style={{ position: "relative", marginTop: 5 }}>
                                              <MailIcon
                                                style={{
                                                  fontSize: "23px",
                                                  color: "#ffffff",
                                                  background: "rgb(26, 123, 201)",
                                                  borderRadius: 60,
                                                  padding: 4,
                                                  marginRight: 5,
                                                }}
                                              />
                                            </div>

                                            {/* Typography Content */}
                                            <Typography
                                              component="div"
                                              variant="body2"
                                              style={{
                                                textAlign: "left",
                                                fontSize: "13px",
                                                color: "inherit",
                                                paddingTop: "1px",
                                                lineHeight: 1.2,
                                                display: "flex",
                                                alignItems: "center"
                                              }}
                                            >
                                              <span
                                                style={{
                                                  fontWeight: "500",
                                                  fontSize: 11,
                                                  lineHeight: 1.3,
                                                  paddingRight: 5,
                                                }}
                                              >
                                                {`Gesendet`}<br/>{`${RETURN_DATE(invoice.sendDate)}`}
                                              </span>

                                              {invoice.type === "cancellation" && (
                                                    <span style={{ fontWeight: 700, marginTop: -2, paddingLeft: 5, paddingRight: 5, borderLeft: "2px solid rgb(44 83 125 / 20%)" }}>
                                                      <span style={{ fontSize: 9, padding: "2px 0px 0px 0px", marginTop: -2, borderRadius: 60, display: "inline-block", }}>
                                                        STORNORECHNUNG
                                                      </span>
                                                      {invoice.cancelledRefInvoiceNr &&
                                                      <span
                                                        style={{
                                                          display: "block",
                                                          overflow: "hidden",
                                                          whiteSpace: "nowrap",
                                                          textOverflow: "ellipsis",
                                                          verticalAlign: "middle",
                                                          fontSize: 9,
                                                          fontWeight: "normal"
                                                        }}
                                                      >
                                                        Zu Nr. {invoice.cancelledRefInvoiceNr}
                                                      </span>
                                                      }
                                                    </span>
                                                  )}
                                            </Typography>

                                            {(invoice.originalRef !== undefined) &&
                                                <Tooltip title="Diese Rechnung wurde aufgrund einer wiederkehrenden Rechnungslegung gesendet.">
                                                  <div
                                                    // onClick={(e) => {
                                                    //   e.stopPropagation();
                                                    //   setOpenInvoiceDialogAtom({ ...openInvoiceDialogAtom, recurring: true, open: true, invoiceId: invoice.originalRef, sendMode: false })
                                                    // }}
                                                  >
                                                    <div className="recurring-info" style={{ borderRadius: "60px", display: "inline-grid", textAlign: "center", padding: "5px 5px", fontSize: "12px" }}>
                                                      <RepeatIcon className="recurring-info-icon" style={{ fontSize: 12 }} />
                                                    </div>
                                                  </div>
                                                </Tooltip>
                                              }
                                          </div>

                                          {invoice.cancelled &&
                                              <div>
                                                <Typography
                                                  style={{
                                                    paddingTop: 2,
                                                    paddingBottom: 2,
                                                    fontSize: 9,
                                                    border: "1px dashed rgb(214 216 237)",
                                                    borderRadius: 60,
                                                    paddingLeft: 5,
                                                    paddingRight: 5,
                                                    color: "rgb(44, 83, 125)",
                                                    fontWeight: 500
                                                  }}>
                                                  Storniert
                                                </Typography>
                                              </div>
                                            }

                                          {!invoice.cancelled && (RETURN_OVERDUE_DAYS(invoice.dueDate) > 0 && invoice.payState === "notPayed") && (
                                            <div>
                                              <Typography
                                                style={{
                                                  paddingTop: 2,
                                                  paddingBottom: 2,
                                                  fontSize: 9,
                                                  borderRadius: 60,
                                                  paddingLeft: 5,
                                                  paddingRight: 5,
                                                  color: "rgb(44, 83, 125)",
                                                  // border: "1px solid rgb(237, 239, 255)",
                                                  fontWeight: 500,
                                                  display: "flex",
                                                  alignItems: "center",
                                                  marginTop: 3,
                                                }}>
                                                  <InfoIcon fontSize="small" style={{zoom: 0.8, marginRight: 3, color: "rgb(183, 28, 28)"}}/>
                                                {`${RETURN_OVERDUE_DAYS(invoice.dueDate)} Tag${RETURN_OVERDUE_DAYS(invoice.dueDate) > 1 ? "e" : ""} überfällig!`}
                                              </Typography>
                                            </div>
                                          )}
                                        </div>
                                      )}

                                      {/* Payed */}
                                      {invoice.payState === "payed" && (
                                        <div style={{ position: "relative", display: "contents", marginLeft: 5, marginRight: 5 }}>

                                            <div
                                              style={{
                                                background: "rgb(190, 241, 192)",
                                                padding: "0px 5px",
                                                borderRadius: 60,
                                                display: "flex",
                                                justifyContent: "flex-start",
                                                alignItems: "center",
                                                position: "relative", // Added for badge positioning
                                                paddingTop: 5,
                                                paddingBottom: 5,
                                                color: "rgb(44, 83, 125)",
                                                opacity: 1,
                                              }}
                                            >

                                              <div style={{ position: "relative", marginTop: 5 }}>
                                                <CheckIcon
                                                  style={{
                                                    fontSize: "23px",
                                                    color: "#ffffff",
                                                    background: "rgb(76, 175, 80)",
                                                    borderRadius: 60,
                                                    padding: 4,
                                                    marginRight: 5,
                                                  }}
                                                />
                                              </div>

                                              {/* Typography Content */}
                                              <Typography
                                                component="div"
                                                variant="body2"
                                                style={{
                                                  textAlign: "left",
                                                  fontSize: "13px",
                                                  color: "inherit",
                                                  paddingTop: "1px",
                                                  lineHeight: 1.2,
                                                  display: "flex",
                                                  alignItems: "center"
                                                }}
                                              >
                                                <span
                                                  style={{
                                                    fontWeight: "500",
                                                    fontSize: 11,
                                                    lineHeight: 1.3,
                                                    paddingRight: 5,
                                                  }}
                                                >
                                                  {`Bezahlt`}<br/>{`${RETURN_DATE(invoice.payDate)}`}
                                                </span>

                                                {invoice.type === "cancellation" && (
                                                    <span style={{ fontWeight: 700, marginTop: -2, paddingLeft: 5, paddingRight: 5, borderLeft: "2px solid rgb(44 83 125 / 20%)" }}>
                                                      <span style={{ fontSize: 9, padding: "2px 0px 0px 0px", marginTop: -2, borderRadius: 60, display: "inline-block", }}>
                                                        STORNORECHNUNG
                                                      </span>
                                                      {invoice.cancelledRefInvoiceNr &&
                                                      <span
                                                        style={{
                                                          display: "block",
                                                          overflow: "hidden",
                                                          whiteSpace: "nowrap",
                                                          textOverflow: "ellipsis",
                                                          verticalAlign: "middle",
                                                          fontSize: 9,
                                                          fontWeight: "normal"
                                                        }}
                                                      >
                                                        Zu Nr. {invoice.cancelledRefInvoiceNr}
                                                      </span>
                                                      }
                                                    </span>
                                                  )}
                                              </Typography>

                                              {(invoice.originalRef !== undefined) &&
                                                  <Tooltip title="Diese Rechnung wurde aufgrund einer wiederkehrenden Rechnungslegung gesendet.">
                                                    <div
                                                      // onClick={(e) => {
                                                      //   e.stopPropagation();
                                                      //   setOpenInvoiceDialogAtom({ ...openInvoiceDialogAtom, recurring: true, open: true, invoiceId: invoice.originalRef, sendMode: false })
                                                      // }}
                                                    >
                                                      <div className="recurring-info" style={{ borderRadius: "60px", display: "inline-grid", textAlign: "center", padding: "5px 5px", fontSize: "12px" }}>
                                                        <RepeatIcon className="recurring-info-icon" style={{ fontSize: 12 }} />
                                                      </div>
                                                    </div>
                                                  </Tooltip>
                                              }
                                            </div>

                                            {(invoice.cancelledRef !== undefined && invoice.type !== "cancellation") && (
                                                  <Typography
                                                  style={{
                                                    display: "inline-block",  // Ensures the border wraps around the entire text block
                                                    paddingTop: 2,
                                                    paddingBottom: 2,
                                                    fontSize: 9,
                                                    borderRadius: 60,
                                                    paddingLeft: 5,
                                                    paddingRight: 5,
                                                    color: "rgb(44, 83, 125)",
                                                    fontWeight: 500,
                                                    whiteSpace: "normal", // Ensures wrapping still works
                                                    width: "100%"
                                                  }}
                                                >
                                                  {invoice.cancelledRefInvoiceNr
                                                    ? `Stornorechnung erstellt: Nr. ${invoice.cancelledRefInvoiceNr}`
                                                    : "Stornorechnung erstellt"}
                                                </Typography>
                                                )}
                                        </div>
                                      )}


                                    </Grid>

                                    {/* Client */}
                                    <Grid xs={12} lg={2}
                                      container
                                      item
                                      alignItems="center"
                                      className="image-name-company"
                                      style={{ padding: "5px", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", flexWrap: "nowrap" }}
                                      onClick={() => setOpenInvoiceDialogAtom({
                                        ...openInvoiceDialogAtom,
                                        recurring: false,
                                        open: true,
                                        invoiceId: invoice.id,
                                        isEstimate: (invoice.type === "estimate" || invoice.type === "offer") ? true : false,
                                        sendMode: false
                                      })}
                                    >
                                      <div className="client-image" style={{ borderRadius: 6, display: "flex", justifyContent: "center", alignItems: "center", borderRadius: 6, height: "70px", width: "70px", minWidth: "70px", marginRight: "10px", position: "relative" }}>
                                        {invoice.clientLogo !== "" ? (
                                          <img
                                            src={`${invoice.clientLogo}`}
                                            alt="logo"
                                            style={{
                                              maxWidth: "65px",
                                              maxHeight: "65px",
                                              marginRight: "10px",
                                              position: "absolute",
                                              top: "50%",
                                              left: "50%",
                                              transform: "translate(-50%, -50%)",
                                              padding: 2,
                                              opacity: invoice.cancelled ? 0.2 : 1,
                                            }}
                                          />
                                        ) :
                                          <img src={LogoMonogram} height={25} width={25} alt={`${t("APP.name")} Logo`} style={{ width: "50px", minWidth: "50px", marginRight: "10px", opacity: invoice.cancelled ? 0.1 : 0.2, margin: "0 auto" }} />
                                        }
                                      </div>

                                      <Tooltip title={JSON.parse(invoice.clientData).company} arrow>
                                        <Typography component="div" variant="body2" style={{ opacity: invoice.cancelled && invoice.payState === "notPayed" ? 0.2 : 1, fontSize: "13px", fontWeight: "500", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>{JSON.parse(invoice.clientData).company}</Typography>
                                      </Tooltip>
                                    </Grid>

                                    {/* Second Part using lg 8*/}
                                    <Grid xs={12} lg={8}
                                      container
                                      item
                                      justifyContent="flex-start"
                                      alignItems="center"
                                      className="invoice-meta"
                                      style={{ opacity: invoice.cancelled && invoice.payState === "notPayed" ? 0.2 : 1, padding: "15px 0px", }}
                                      onClick={() => setOpenInvoiceDialogAtom({
                                        ...openInvoiceDialogAtom,
                                        recurring: false,
                                        open: true,
                                        invoiceId: invoice.id,
                                        isEstimate: (invoice.type === "estimate" || invoice.type === "offer") ? true : false,
                                        sendMode: false
                                      })}
                                    >

                                      {/* Invoice Number */}
                                      <Grid xs={12} lg={2}
                                        container
                                        item
                                        justifyContent="center"
                                        className="invoice-meta-item-container"
                                      >
                                        <Typography className="invoice-meta-item" style={{ textAlign: "center", fontSize: "12px" }} component="div" variant="body2">
                                          <span className="menu-heading-title" style={{ paddingTop: 4 }}>Rechnungsnummer:</span>
                                          <Tooltip title={
                                            <span>
                                              {JSON.parse(invoice.invoiceNr).map((item, i) => (
                                                <span key={i}>
                                                  {item.value}
                                                </span>
                                              ))}
                                            </span>
                                          } arrow>
                                            <div style={{
                                              display: "inline-block",
                                              maxWidth: "100px",
                                              whiteSpace: "nowrap",
                                              overflow: "hidden",
                                              textOverflow: "ellipsis"
                                            }}>
                                              <span style={{ fontWeight: "300" }}>
                                                {JSON.parse(invoice.invoiceNr).map((item, i) => (
                                                  <span key={i} style={{ fontSize: "13px", fontWeight: "300" }}>
                                                    {item.type === "continuousNumber" ? (
                                                      <span style={{
                                                        display: "inline-block",
                                                        background: "rgb(237, 239, 255)",
                                                        height: "17px",
                                                        color: "inherit",
                                                        paddingLeft: 3,
                                                        paddingRight: 3,
                                                        borderRadius: "3px",
                                                        marginTop: 3.3,
                                                        marginLeft: "1px",
                                                        marginRight: "1px",
                                                        fontSize: "13px",
                                                        fontWeight: "300"
                                                      }}>{item.value}</span>
                                                    ) : (
                                                      <span>{item.value}</span>
                                                    )}
                                                  </span>
                                                ))}
                                              </span>
                                            </div>
                                          </Tooltip>
                                        </Typography>
                                      </Grid>

                                      {/* Invoice Net */}
                                      <Grid xs={12} lg={2}
                                        container
                                        item
                                        justifyContent="center"
                                        className="invoice-meta-item-container"
                                      >
                                        <Typography className="invoice-meta-item" style={{ textAlign: "center", fontSize: "12px" }} component="div" variant="body2">
                                          <span className="menu-heading-title">Nettobetrag:</span>
                                          <Grid item container justifyContent="center">
                                            <div style={{ fontWeight: "300", fontSize: "12px", color: invoice.type === "cancellation" ? "red" : "inherit" }}>{invoice.type === "cancellation" && "-"}{parseFloat(invoice.subtotal).toLocaleString('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 })} €</div>
                                          </Grid>
                                        </Typography>
                                      </Grid>

                                      {/* Total Gross */}
                                      <Grid xs={12} lg={2}
                                        container
                                        item
                                        justifyContent="center"
                                        className="invoice-meta-item-container"
                                      >
                                        <Typography className="invoice-meta-item" style={{ textAlign: "center", fontSize: "12px" }} component="div" variant="body2">
                                          <span className="menu-heading-title">Bruttobetrag:</span>
                                          <Grid item container justifyContent="center">
                                            <div style={{ fontWeight: "300", fontSize: "12px", color: invoice.type === "cancellation" ? "red" : "inherit" }}>{invoice.type === "cancellation" && "-"}{parseFloat(invoice.total).toLocaleString('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 })} €</div>
                                          </Grid>
                                        </Typography>
                                      </Grid>

                                      {/* Invoice Date */}
                                      <Grid xs={4} lg={2}
                                        container
                                        item
                                        justifyContent="center"
                                        className="invoice-meta-item-container"
                                      >
                                        <Typography className="invoice-meta-item" style={{ textAlign: "center", fontSize: "12px" }} component="div" variant="body2">
                                          <span className="menu-heading-title">Rechnungsdatum:</span>
                                          <Grid item container justifyContent="center">
                                            <div style={{ fontWeight: "300", fontSize: "12px" }}>{RETURN_DATE(invoice.invoiceDate)}</div>
                                          </Grid>
                                        </Typography>
                                      </Grid>

                                      {/* Due Date */}
                                      {type === "invoices" &&
                                        <Grid xs={4} lg={2}
                                          container
                                          item
                                          justifyContent="center"
                                          className="invoice-meta-item-container"
                                        >
                                          <Typography className="invoice-meta-item" style={{ textAlign: "center", fontSize: "12px" }} component="div" variant="body2">
                                            <span className="menu-heading-title">Fälligkeitsdatum:</span>
                                            <Grid item container justifyContent="center">
                                              <div style={{ fontWeight: "300", fontSize: "12px" }}>{RETURN_DATE(invoice.dueDate)}</div>
                                            </Grid>
                                          </Typography>
                                        </Grid>
                                      }

                                      {/* Small icons: Send, Payment, Download */}
                                      <Grid xs={1}
                                        container
                                        item
                                        justifyContent="flex-end"
                                        alignItems="center"
                                        className="invoice-ref"
                                      >
                                        <>
                                          {/* SMALL ICON SEND */}
                                          <div style={{ width: 26 }}>
                                            {invoice.sendState !== "sent" && invoice.sendState !== "scheduled" && invoice.payState !== "payed" && !invoice.cancelled &&
                                              <>

                                                <Tooltip title="Versenden">
                                                  <div
                                                    className={"chip--send"}
                                                    style={{ transform: "scale(1.3)", display: "inline-block" }}
                                                    onClick={(e) => {
                                                      e.stopPropagation(); // Prevent parent click event
                                                      setOpenInvoiceDialogAtom({
                                                        ...openInvoiceDialogAtom,
                                                        recurring: false,
                                                        open: true,
                                                        invoiceId: invoice.id,
                                                        isEstimate: false,
                                                        sendMode: true,
                                                      })
                                                    }}
                                                  >
                                                    <div
                                                      className="send-info"
                                                      style={{
                                                        borderRadius: "60px",
                                                        display: "inline-grid",
                                                        textAlign: "center",
                                                        padding: "3px 4px 3px 3px",
                                                        fontSize: "12px",
                                                      }}
                                                    >
                                                      <MailIcon
                                                        className="send-info-icon"
                                                        style={{ fontSize: 12 }}
                                                      />
                                                    </div>
                                                  </div>
                                                </Tooltip>

                                              </>
                                            }
                                          </div>

                                          {/* SMALL ICON MARK AS PAYED */}
                                          <div style={{ width: 26 }}>
                                            {!invoice.cancelled && invoice.payState !== "payed" && invoice.sendState !== "scheduled" && (
                                              <>
                                                <Tooltip title='Auf "Bezahlt" setzen'>
                                                  <div
                                                    className={"chip--download"}
                                                    style={{ transform: "scale(1.3)", display: "inline-block" }}
                                                    onClick={(e) => {
                                                      e.stopPropagation(); // Prevent parent click event
                                                      openPayMenu(e);
                                                      setInvoicePayed(invoice._id)
                                                      setSelectedInvoice(invoice)
                                                    }}
                                                  >
                                                    <div
                                                      className="payed-info"
                                                      style={{
                                                        borderRadius: "60px",
                                                        display: "inline-grid",
                                                        textAlign: "center",
                                                        padding: "3px 4px 3px 3px",
                                                        fontSize: "12px",
                                                      }}
                                                    >
                                                      <CheckCircleIcon
                                                        className="payed-info-icon"
                                                        style={{ fontSize: 12 }}
                                                      />
                                                    </div>
                                                  </div>
                                                </Tooltip>

                                                {selectedInvoice &&
                                                  <PayMenuPopover
                                                    isOpen={isPayMenuOpen}
                                                    anchorEl={payMenuAnchorEl}
                                                    onClose={closePayMenu}
                                                    payMenuValues={payMenuValues}
                                                    setPayMenuValues={setPayMenuValues}
                                                    payDateError={payDateError}
                                                    handleDateChange={handleDateChange}
                                                    invoice={selectedInvoice} // Pass the dynamically selected invoice
                                                    setDataInitial={setDataInitial}
                                                    sendRequest={sendRequest}
                                                    auth={auth}
                                                    invoicePayed={selectedInvoice?._id} // Pass the selected invoice ID
                                                  />
                                                }
                                              </>
                                            )}
                                          </div>

                                          {/* SMALL ICON PDF DOWNLOAD */}
                                          {!invoice.cancelled &&
                                          <div style={{width: 26}}>
                                            <>
                                            <Tooltip title="PDF Herunterladen">
                                              <div
                                                className={"chip--download"}
                                                style={{ transform: "scale(1.3)", display: "inline-block"}}
                                                onClick={(e) => {
                                                  e.stopPropagation(); // Prevent parent click event
                                                  downloadPdf(invoice._id);
                                                }}
                                              >
                                                <div
                                                  className="download-info"
                                                  style={{
                                                    borderRadius: "60px",
                                                    display: "inline-grid",
                                                    textAlign: "center",
                                                    padding: "3px 4px 3px 3px",
                                                    fontSize: "12px",
                                                  }}
                                                >
                                                  <GetAppIcon
                                                    className="download-info-icon"
                                                    style={{ fontSize: 12 }}
                                                  />
                                                </div>
                                              </div>
                                            </Tooltip>
                                            </>
                                          </div>
                                          }
                                        </>



                                      </Grid>

                                      {/* Dots */}
                                      <Grid xs={12} lg={1}
                                        container
                                        item
                                        justifyContent="flex-end"
                                        alignItems="flex-end"
                                      >
                                        <InvoicesMenuDots
                                          invoice={invoice}
                                          setOpenInvoiceDialogAtom={setOpenInvoiceDialogAtom}
                                          openInvoiceDialogAtom={openInvoiceDialogAtom}
                                          recurring={recurring}
                                          fetchData={fetchData}
                                          handleSearchClear={handleSearchClear}
                                          openPayMenu={openPayMenu}
                                          setInvoicePayed={setInvoicePayed}
                                          setSelectedInvoice={setSelectedInvoice}
                                          checkedItems={checkedItems}
                                          setCheckedItems={setCheckedItems}
                                          setDataInitial={setDataInitial}
                                        />
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                </Grid>
                              </Grid>
                            ))
                        }

                        {/* APPLIED FILTER after Loading does result in list of length 0 */}
                        {!values.loading && !isLoading && Object.keys(values.invoicesFiltered).length === 0 &&
                          <Typography component="div" style={{ fontSize: "12px", padding: "10px 20px" }}>{t("PAGE_INVOICES.noResult")}</Typography>
                        }
                      </>
                    )
                    }
                    {(isLoading && searchTerm === "") &&
                      <div style={{ padding: 15 }}>Wird geladen ...</div>
                    }


                    {/* NO INVOICES created yet */}
                    {!values.loading && !isLoading && dataInitial.length === 0 && type === "invoices" &&
                      <Typography component="div" style={{ fontSize: "12px", padding: "10px 20px" }}>{t("PAGE_INVOICES.noInvoicesCreated")}</Typography>
                    }


                    {hasMoreInvoices && !isFetching && dataInitial.length !== 0 && (
                      <Grid container justifyContent="center">
                        <Button
                          onClick={loadMoreInvoices}
                          style={{
                            backgroundColor: theme.palette.primary.main, // Primary color
                            color: "#fff",
                            margin: "15px 0", // Smaller margin
                            padding: "8px 16px", // Smaller padding
                            fontSize: "14px", // Smaller font size
                            borderRadius: "6px", // Slightly smaller border radius
                            boxShadow: "0px 3px 6px rgba(0,0,0,0.2)",
                          }}
                          onMouseOver={(e) =>
                            (e.currentTarget.style.backgroundColor = theme.palette.primary.dark)
                          } // Darker shade on hover
                          onMouseOut={(e) =>
                            (e.currentTarget.style.backgroundColor = theme.palette.primary.main)
                          } // Reset background color when mouse leaves
                          startIcon={<RotateLeftIcon />} // Add an icon here
                        >
                          Mehr laden
                        </Button>
                      </Grid>
                    )}

                    {(!values.loading || !isLoading) && (!userAtom.clientscreated && userAtom.data.clients.length === 0) &&
                      <UserProfileIncompleteWarning
                        headline={t("PAGE_INVOICES.h1")}
                        text={"Bitte erstelle zunächst einen Kunden."}
                        icon={<ClientsIcon color="primary" fontSize="large" style={{ marginRight: "5px" }} />}
                        link={"/clients"}
                        linkText={t("BUTTONS.back_to_clients")}
                      />
                    }

                    {(!values.loading || !isLoading) && userAtom.data.signupCompleted && (userAtom.clientscreated || userAtom.data.clients.length !== 0) && dataInitial.length === 0 && !isFetching &&
                      <Grid container style={{ padding: "20px 15px" }}>
                        <Typography variant="body2" style={{ color: "gray" }}>
                          Es wurden keine Rechnungen mit den vorgenommenen Einstellungen gefunden.
                        </Typography>
                      </Grid>
                    }
                  </div>

                  {(!isLoading) &&
                    <>
                      {/* INVOICES already created */}

                      {/* NO INVOICES created yet */}
                      {!values.loading && !isLoading && dataInitial.length === 0 && type === "invoices" &&
                        <Typography component="div" style={{ fontSize: "12px", padding: "10px 20px" }}>{t("PAGE_INVOICES.noInvoicesCreated")}</Typography>
                      }

                      {/* NO ESTIMATES created yet */}
                      {!values.loading && !isLoading && dataInitial.length === 0 && type === "estimates" &&
                        <Typography component="div" style={{ fontSize: "12px", padding: "10px 20px" }}>Du hast noch keine Kostenvoranschläge oder Angebote angelegt</Typography>
                      }
                    </>
                  }
                </div>
              </Grid>
            </Box>
          </Container>
        </React.Fragment>
      }

      {/* {(!values.loading || !isLoading) && !userAtom.data.signupCompleted &&
        <UserProfileIncompleteWarning
          headline={t("PAGE_INVOICES.h1")}
          text={t("PAGE_INVOICES.profileIncomplete")}
          icon={<ClientsIcon color="primary" fontSize="large" style={{ marginRight: "5px" }} />}
        />
      } */}

      {(!values.loading || !isLoading) && userAtom.data.signupCompleted && (userAtom.clientscreated || userAtom.data.clients.length !== 0) && !isFetching &&

        <Fab color="primary" aria-label="add" className="add-invoice-fab" style={{ position: "fixed", bottom: "0", right: "0", margin: "20px", zIndex: 9 }}
          onClick={
            type === "invoices" ?
              () => setOpenInvoiceDialogAtom({
                ...openInvoiceDialogAtom,
                open: true,
                isEstimate: false,
                recurring: false,
                sendMode: false,
              })
              : () => setOpenInvoiceDialogAtom({
                ...openInvoiceDialogAtom,
                open: true,
                isEstimate: true,
                recurring: false,
                sendMode: false
              })
          }
        >
          <AddIcon />
        </Fab>
      }
    </div>
  );
};

export default Invoices;
