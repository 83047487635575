import React, { useEffect, useState, useContext } from "react";

// IMPORT hooks & context
import { useTranslation } from "react-i18next";
import { useHttpClient } from "../../shared/hooks/http-hook";
import { AuthContext } from "../../shared/context/auth-context";
import { useAuth } from "../../shared/hooks/auth-hook";
import { useNavigate } from "react-router-dom";

// IMPORT recoil
import {
  useRecoilState,
} from 'recoil';
import userAtomState from "../../_atoms/userAtom";
import clientsAtomState from "../../_atoms/clientsAtom";
import openClientDialogAtomState from "../../_atoms/openClientDialogAtom";
import settingsAtomState from "../../_atoms/settingsAtom";
import allInvoicesByClientAtomState from "../../_atoms/allInvoicesByClientAtom"
import allInvoicesByOriginalRefAtomState from "../../_atoms/allInvoicesByOriginalRefAtom";

import selectedMenuItemAtomState from "../../_atoms/selectedMenuItemAtom";
import subMenuOpenAtomState from "../../_atoms/subMenuOpenAtom";
import subMenuInvoicesOpenAtomState from "../../_atoms/subMenuInvoicesOpenAtom";
import mobileOpenState from "../../_atoms/mobileOpenAtom";

// IMPORT components
import { useSnackbar } from 'notistack';

// IMPORT components MATERIAL UI
import {
  Typography,
  Box,
  Container,
  Grid,
  Tooltip,
  Chip,
  Collapse,
  IconButton,
  Fab,
  Button
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useTheme } from '@material-ui/core/styles';
import Fade from '@material-ui/core/Fade';


// IMPORT icons MATERIAL UI
import ClientsIcon from "@material-ui/icons/RecentActors";
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import FilterListIcon from '@material-ui/icons/FilterList';
import ImportExportIcon from '@material-ui/icons/ImportExport';
import AddIcon from '@material-ui/icons/Add';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';
import EditIcon from '@material-ui/icons/Edit';
import ScheduleIcon from '@material-ui/icons/Schedule';
import MailIcon from '@material-ui/icons/Mail';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import GavelIcon from '@material-ui/icons/Gavel';
import SearchIcon from '@material-ui/icons/Search';
import ErrorIcon from '@material-ui/icons/Error';
import WatchLaterIcon from '@material-ui/icons/WatchLater';
import RepeatIcon from '@material-ui/icons/Repeat';
import PlaylistAddCheckIcon from '@material-ui/icons/PlaylistAddCheck';
import SubjectIcon from '@material-ui/icons/Subject';
import RotateLeftIcon from '@material-ui/icons/RotateLeft';
import InfoIcon from '@material-ui/icons/Info';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import CancelPresentationIcon from '@material-ui/icons/CancelPresentation';
import CancelIcon from '@material-ui/icons/Cancel';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import EuroSymbolIcon from '@material-ui/icons/EuroSymbol';
import CloseIcon from '@material-ui/icons/Close';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';

import Hidden from '@material-ui/core/Hidden';


// IMPORT own functions
import { SET_VALUE } from './../../_functions/SET_VALUE';
import { DB_GET_CLIENTS } from './../../_functions/DB_CLIENTS';
import { DB_PATCH_SETTINGS } from './../../_functions/DB_SETTINGS';

// IMPORT own components
import UserProfileIncompleteWarning from "../../user/components/UserProfileIncompleteWarning";
import ClientsSearch from "../components/ClientsSearch";
import LogoMonogram from './../../_assets/logo_monogram.svg';
import ClientsSort from "../components/ClientsSort";
import ClientsFilter from "../components/ClientsFilter";
import ClientsMenuDots from "../components/ClientsMenuDots";
import ClientsSettings from "../components/ClientsSettings";

// IMPORT own CSS
import "./UserClients.scss"

const useStyles = makeStyles((theme) => ({
  // necessary for content to be below app bar
  toolbar: {
    height: '0px',
    '@media (max-width: 959px)': {
      minHeight: '45px',
      height: '45px',
    },
    '@media (max-width: 599px)': {
      minHeight: '45px',
      height: '45px',
    },
    '@media (max-width: 455px)': {
      minHeight: '45px',
      height: '45px',
    },
  },
  table: {
    minWidth: 650,
  },
}));

const UserClients = () => {
  // HOOKS & CONTEXT
  const { t } = useTranslation();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();
  const { isLoading, error, sendRequest } = useHttpClient();
  const auth = useContext(AuthContext);
  const { userId } = useAuth();
  const navigate = useNavigate();

  // GLOBAL STATE (RECOIL)
  const [userAtom] = useRecoilState(userAtomState);
  const [openClientDialogAtom, setOpenClientDialogAtom] = useRecoilState(openClientDialogAtomState);
  const [clientsAtom, setClientsAtom] = useRecoilState(clientsAtomState);
  const [settingsAtom, setSettingsAtom] = useRecoilState(settingsAtomState);
  const [allInvoicesByClientAtom, setAllInvoicesByClientAtom] = useRecoilState(allInvoicesByClientAtomState);
  const [allInvoicesByOriginalRefAtom, setAllInvoicesByOriginalRefAtom] = useRecoilState(allInvoicesByOriginalRefAtomState);
  const [selectedMenuItemAtom, setSelectedMenuItemAtom] = useRecoilState(selectedMenuItemAtomState);
  const [subMenuOpenAtom, setSubMenuOpenAtom] = useRecoilState(subMenuOpenAtomState);
  const [subMenuInvoicesOpenAtom, setSubMenuInvoicesOpenAtom] = useRecoilState(subMenuInvoicesOpenAtomState);
  const [mobileOpen, setMobileOpen] = useRecoilState(mobileOpenState);


  // LOCAL STATE (GLOBAL DEPENDENCY monitoring temporay CHANGES until SAVED)
  const [settings, setSettings] = useState(settingsAtom);

  // LOCAL STATE (PURE)
  const [draggableSortItems, setDraggableSortItems] = useState([]);
  const [values, setValues] = useState({
    submenuOpen: false, // values: false, search, filter, sort, settings
    clientsFiltered: {},
    searchInput: "",
    chipDirectChange: false,
    save: false
  });

  // Data variable
  const [dataInitial, setDataInitial] = useState([]);

  // Infinite Scroll Variables
  const INITIAL_ITEMS = 20; // Load 10 items initially
  const [offset, setOffset] = useState(0); // Track the current offset for fetching
  const [hasMoreInvoices, setHasMoreInvoices] = useState(true); // To know if more invoices exist
  const [isFetching, setIsFetching] = useState(false); // Track if a fetch is in progress

  // Search Variables
  const [searchTerm, setSearchTerm] = useState('');

  /***
  FETCH
  ***/
  const fetchData = async (currentOffset, limit, isInitialLoad = false, searchTerm = "", newRecurring) => {
    try {
      const storedUserData = localStorage.getItem('userData');
      if (!storedUserData) {
        throw new Error('No user data found in local storage');
      }

      const resUser = JSON.parse(storedUserData);

      setIsFetching(true); // Set fetching flag to true

      const response = await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL_API}/clients/user/${resUser.userId}?limit=${limit}&offset=${currentOffset}&searchTerm=${searchTerm}&sortField=${settingsAtom.clientsSortField}&sortOrder=${settingsAtom.clientsSortOrder}`,
        'GET',
        null,
        { Authorization: 'Bearer ' + resUser.token }
      );

      if (response && response.clients) {
        if (response.clients.length < limit) {
          setHasMoreInvoices(false); // No more invoices to fetch
        }

        if (isInitialLoad) {
          setDataInitial(response.clients); // Replace existing data for initial load
        } else {
          // Append new invoices, making sure no duplicates
          setDataInitial(prevInvoices => [
            ...prevInvoices,
            ...response.clients,
          ]);
        }
      }

      setIsFetching(false);
    } catch (err) {
      setIsFetching(false);
      console.error('Error fetching invoices:', err);
    }
  };

  /***
  INITIAL LOADING
  ***/
  useEffect(() => {
    // Initial load when the page loads or search term/recurring changes
    setOffset(0); // Reset offset
    fetchData(0, INITIAL_ITEMS, true, searchTerm); // Fetch the first 5 invoices with updated recurring value
  }, [searchTerm]); // Trigger on recurring or search term change

  // Store scroll position before refetching
  const [scrollPos, setScrollPos] = useState(0);

  // Save scroll position before dialog opens
  useEffect(() => {
    if (openClientDialogAtom.open) {
      setScrollPos(window.scrollY); // Save current scroll position
    }
  }, [openClientDialogAtom.open]);

  // Refetch all invoices and restore scroll position when the dialog closes
  useEffect(() => {
    if (!openClientDialogAtom.open) {
      fetchData(0, dataInitial.length, true, searchTerm); // Refetch all data to ensure updates
      setTimeout(() => {
        window.scrollTo(0, scrollPos); // Restore scroll position
      }, 0);
    }
  }, [openClientDialogAtom.open]);

  // Load more invoices when the user clicks the "Load More" button
  const loadMoreInvoices = () => {
    if (!isFetching && hasMoreInvoices) {
      const limit = INITIAL_ITEMS;
      const newOffset = offset + limit;
      setOffset(newOffset); // Update the offset
      fetchData(newOffset, limit, false, searchTerm); // Fetch more data based on new offset
    }
  };

  /***
  SEARCH
  ***/
  // Handle search input
  const handleSearch = (e) => {
    const term = e.target.value;
    setSearchTerm(term);

    // Scroll to top when the search term is updated
    window.scrollTo({
      top: 0,
    });

    if (term !== "") {
      // Add logic here to filter invoices or trigger a new fetch based on the search term
    } else {
      handleSearchClear(); // Clear search when input is empty
    }
  };

  const handleSearchClear = () => {
    window.scrollTo({
      top: 0,
    });

    setSearchTerm("");
    setDataInitial([]);
    setOffset(0); // Reset offset for new search
    setHasMoreInvoices(true); // Allow more invoices to load

    // Use setTimeout to wait for the state updates to complete
    setTimeout(() => {
      fetchData(0, INITIAL_ITEMS, true, ""); // Fetch the first 5 invoices with cleared search term and respect recurring state
    }, 0);
  };

  // Trigger fetch with search term when the user types
  useEffect(() => {
    if (searchTerm !== '') {
      setOffset(0); // Reset offset for new search
      fetchData(0, INITIAL_ITEMS, true, searchTerm); // Pass recurring state along with search term
      setHasMoreInvoices(true); // Allow more invoices to load
    } else {
      // If search is cleared, load initial invoices again respecting the recurring state
      fetchData(0, INITIAL_ITEMS, true, "");
    }
  }, [searchTerm]); // Also listen for recurring state changes




  /* 
    FIRST PAGE LOAD
  */
  // FILTER: Client Status



  // SORT: Invoice number
  // if (settingsAtom.clientsSortNumber) {
  //   const returnValue = (item, type) => JSON.parse(item.clientNumber).find(obj => { return obj.type === type }).value;

  //   if (settingsAtom.clientsSortNumberOption === "ascending") {
  //     clientsFilteredInitial = Array.from(clientsFilteredInitial).sort((a, b) => {
  //       a = returnValue(a, "continuousNumber");
  //       b = returnValue(b, "continuousNumber");
  //       return (a < b) ? -1 : 1
  //     })

  //   } else if (settingsAtom.clientsSortNumberOption === "descending") {
  //     clientsFilteredInitial = Array.from(clientsFilteredInitial).sort((a, b) => {
  //       a = returnValue(a, "continuousNumber");
  //       b = returnValue(b, "continuousNumber");
  //       return (a > b) ? -1 : 1
  //     })
  //   }
  // }


  // useEffect(() => {
  //   // Only fetch from DB the first time the clients page is loaded
  //   clientsAtom.length === 0 && DB_GET_CLIENTS(setClientsAtom, auth, sendRequest);
  // }, [userId, auth.token]);

  // useEffect(() => {
  //   SET_VALUE({ target: { id: "clientsFiltered", value: clientsFilteredInitial } }, setValues);
  // }, [JSON.stringify(clientsFilteredInitial)]); // stringifying object dependency prevents infitive loop

  const handleChange = (e) => {
    SET_VALUE(e, setSettings);
  };

  // ON SAVE: 1) Update settingsAtom
  const updateSettingsHandler = () => {
    // GLOBAL changes
    setSettingsAtom(settings);

    // Save variable is only needed to trigger submenuClose when NOTHING changes in settings and save button is clicked
    SET_VALUE({ target: { id: "save", value: true } }, setValues);

    setDataInitial([]);
    setOffset(0); // Reset offset for new search
    setHasMoreInvoices(true); // Allow more invoices to load on scroll
    window.scrollTo({
      top: 0,
    });
  };

  // ON SAVE: 2) DB: PATCH SETTINGS
  useEffect(() => {
    if ((auth.token && values.submenuOpen !== false) || (auth.token && values.chipDirectChange === true)) {
      DB_PATCH_SETTINGS(settingsAtom, auth, sendRequest, enqueueSnackbar, t, false);

      SET_VALUE({
        target: {
          "submenuOpen": false,
          "chipDirectChange": false,
          "save": false,
        }
      }, setValues, "multi")
    }
  }, [auth.token, settingsAtom, values.save]);

  // ON CANCEL:
  const cancelSettingsHandler = () => {
    // Re-set original values
    setSettings(settingsAtom);
    SET_VALUE({ target: { id: "submenuOpen", value: false } }, setValues);
  };

  useEffect(() => {
    setDataInitial([]);
    setOffset(0); // Reset offset for new search
    setHasMoreInvoices(true); // Allow more invoices to load on scroll
    fetchData(0, INITIAL_ITEMS, true, searchTerm); // Fetch filtered data with cleared search term
  }, [settingsAtom]);

  // Add to Sort Chips
  useEffect(() => {
    let i = []

    if (settingsAtom.clientsSortCreation) {
      i.push("clientsSortCreation")
    }
    if (settingsAtom.clientsSortNumber) {
      i.push("clientsSortNumber")
    }
    if (settingsAtom.clientsSortCompany) {
      i.push("clientsSortCompany")
    }

    setDraggableSortItems(i)
  }, [settingsAtom]);

  // const onSortEnd = (oldIndex, newIndex) => {
  //   setDraggableSortItems((array) => arrayMove(array, oldIndex, newIndex));
  // };

  return (
    <div>
      <div className={classes.toolbar} />

      <div className="mobile-menu-section" style={{ borderBottom: "1px solid rgb(237, 239, 255)", position: "sticky", top: 47, zIndex: 10, width: "100%", padding: "2px 4px" }}>
          <ClientsFilter
            settings={settings}
            settingsAtom={settingsAtom}
            handleChange={handleChange}
            cancelSettingsHandler={cancelSettingsHandler}
            updateSettingsHandler={updateSettingsHandler}
            setValues={setValues}
            SET_VALUE={SET_VALUE}
            setSettings={setSettings}
            values={values}
            setOffset={setOffset}
            fetchData={fetchData}
            INITIAL_ITEMS={INITIAL_ITEMS}
            searchTerm={searchTerm}
            setHasMoreInvoices={setHasMoreInvoices}
            setSettingsAtom={setSettingsAtom}
            mobile={true}
          />

          <ClientsSort
            settings={settings}
            handleChange={handleChange}
            cancelSettingsHandler={cancelSettingsHandler}
            updateSettingsHandler={updateSettingsHandler}
            setValues={setValues}
            SET_VALUE={SET_VALUE}
            setSettings={setSettings}
            values={values}
            recurring={false}
            mobile={true}
          />

        {/* <InvoicesSettings
          settings={settings}
          handleChange={handleChange}
          cancelSettingsHandler={cancelSettingsHandler}
          updateSettingsHandler={updateSettingsHandler}
          setValues={setValues}
          SET_VALUE={SET_VALUE}
          setSettings={setSettings}
          values={values}
        /> */}
      </div>

      <div className="not-mobile-menu-section" style={{ position: "sticky", top: 0, zIndex: 9, width: "100%" }}>
        <Grid container className="subpage-header" item direction="row" alignItems="center" alignContent="center" style={{ paddingBottom: 0 }}>
          <Grid className="main-headline-wrapper" item container direction="row" alignItems="center" style={{ marginRight: "10px", marginBottom: 10 }}>
            <Grid item >
              <Grid item container alignItems="center">
                <ClientsIcon color="primary" fontSize="large" style={{ width: 30, height: 30, background: "white", marginRight: "15px" }} />

                <Typography variant="body1" component="div" style={{ fontSize: 18, fontWeight: "700" }} className="headline">Kunden</Typography>

                <Hidden smDown>
                  <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    startIcon={<AddCircleIcon className="MuiSvgIcon-root" fontSize="large" />}
                    className="add-button" // Add the custom class
                    onClick={() => {
                      setOpenClientDialogAtom({
                        ...openClientDialogAtom,
                        open: true,
                      });
                    }}
                  >
                    Erstellen
                  </Button>
                </Hidden>
              </Grid>
            </Grid>
          </Grid>

          <Grid
            item
            container
            alignItems="center"
            style={{ display: "flex", height: "48px" }} // Set consistent height for all children
          >

            <ClientsSearch
              handleSearchClear={handleSearchClear}
              handleSearch={handleSearch}
              searchTerm={searchTerm}

            />
          </Grid>
        </Grid>


        <Grid container item xs={12} alignItems="center" className="chips" style={{ borderBottom: "1px solid rgb(237, 239, 255)" }}>
          <ClientsFilter
            settings={settings}
            settingsAtom={settingsAtom}
            handleChange={handleChange}
            cancelSettingsHandler={cancelSettingsHandler}
            updateSettingsHandler={updateSettingsHandler}
            setValues={setValues}
            SET_VALUE={SET_VALUE}
            setSettings={setSettings}
            values={values}
            setOffset={setOffset}
            fetchData={fetchData}
            INITIAL_ITEMS={INITIAL_ITEMS}
            searchTerm={searchTerm}
            setHasMoreInvoices={setHasMoreInvoices}
            setSettingsAtom={setSettingsAtom}
          />

          <ClientsSort
            settings={settings}
            handleChange={handleChange}
            cancelSettingsHandler={cancelSettingsHandler}
            updateSettingsHandler={updateSettingsHandler}
            setValues={setValues}
            SET_VALUE={SET_VALUE}
            setSettings={setSettings}
            values={values}
            recurring={false}
          />
        </Grid>
      </div>










































      {userAtom.data.signupCompleted &&
        <React.Fragment>
          <Container maxWidth={false} disableGutters className="bg--1" style={{ paddingBottom: 70 }}>
            <Box
              display="flex"
              direction="column"
              className="list-box"
            >
              <Grid container item xs={12} >
                <div className="box-shadow--1 invoices-list">
                  <Grid
                    item
                    className="sticky-nav box-shadow--1 h-padding--5-5 tool-menu"
                    style={{ top: 149 }}
                  >
                    <Grid container>
                      <Grid
                        container
                        alignItems="center"
                        justifyContent="space-between"
                        item
                        xs={12}
                      >
                        <Grid container item xs={12} justifyContent="space-between">

                          {/* STATUS */}
                          <Grid xs={12} lg={2} container item justifyContent="flex-start" alignItems="center" style={{ paddingLeft: 15 }}>
                            <Typography>Status</Typography>
                          </Grid>

                          {/* CLIENT */}
                          <Grid xs={12} lg={2}
                            container
                            item
                            justifyContent="flex-start"
                            alignItems="center"
                            alignContent="center"
                            className="table-headline"
                            style={{ background: settings.clientsSortField === "clientCompany" && "rgb(237, 239, 255)", borderRadius: 6 }}
                            onClick={() => {
                              setSettings({ ...settings, clientsSortField: "clientCompany", clientsSortOrder: settings.clientsSortOrder === "asc" ? "desc" : "asc" });
                              setSettingsAtom({ ...settingsAtom, clientsSortField: "clientCompany", clientsSortOrder: settingsAtom.clientsSortOrder === "asc" ? "desc" : "asc" })
                            }}
                          >
                            <div style={{ display: "flex", alignItems: "flex-start" }}>
                              <Typography style={{ paddingLeft: 15 }}>Kunde</Typography>
                              {settingsAtom.clientsSortField === "clientCompany" && settings.clientsSortOrder === "desc" ? <ArrowDownwardIcon style={{ zoom: 0.6, marginLeft: 5 }} /> : ""}
                              {settingsAtom.clientsSortField === "clientCompany" && settings.clientsSortOrder === "asc" ? <ArrowUpwardIcon style={{ zoom: 0.6, marginLeft: 5 }} /> : ""}
                            </div>
                          </Grid>

                          <Grid xs={12} lg={8} container item justifyContent="flex-start" alignItems="center" >

                            {/* CLIENT NUMBER */}
                            <Grid xs={4} md={2}
                              container
                              item
                              justifyContent="center"
                              alignItems="center"
                              className="table-headline"
                              style={{ background: settings.clientsSortField === "clientNumber" && "rgb(237, 239, 255)" }}
                              onClick={() => {
                                setSettings({ ...settings, clientsSortField: "clientNumber", clientsSortOrder: settings.clientsSortOrder === "asc" ? "desc" : "asc" });
                                setSettingsAtom({ ...settingsAtom, clientsSortField: "clientNumber", clientsSortOrder: settingsAtom.clientsSortOrder === "asc" ? "desc" : "asc" })
                              }}
                            >
                              <div style={{ display: "flex", alignItems: "center", justifyContent: "center", padding: "5px 10px" }}>
                                <Typography
                                  style={{
                                    textAlign: "center",
                                    whiteSpace: "normal",
                                    wordBreak: "break-word",
                                  }}
                                >
                                  Kundennummer
                                </Typography>
                                {settingsAtom.clientsSortField === "clientNumber" && settings.clientsSortOrder === "desc" && (
                                  <ArrowDownwardIcon style={{ zoom: 0.6, marginLeft: 5 }} />
                                )}
                                {settingsAtom.clientsSortField === "clientNumber" && settings.clientsSortOrder === "asc" && (
                                  <ArrowUpwardIcon style={{ zoom: 0.6, marginLeft: 5 }} />
                                )}
                              </div>
                            </Grid>

                            {/* CLIENT TYPE */}
                            <Grid xs={12} md={2}
                              container
                              item
                              justifyContent="center"
                              alignItems="center"
                            >
                              <div style={{ display: "flex", alignItems: "center", justifyContent: "center", padding: "5px 10px" }}>
                                <Typography
                                  style={{
                                    textAlign: "center",
                                    whiteSpace: "normal",
                                    wordBreak: "break-word",
                                  }}
                                >
                                  Kategorie
                                </Typography>
                              </div>
                            </Grid>

                            {/* PLACEHOLDER */}
                            <Grid xs={12} md={2}
                              container
                              item
                              justifyContent="center"
                              alignItems="center"
                              style={{ background: settings.invoicesSortField === "invoiceGross" && "rgb(237, 239, 255)", borderRadius: 6 }}
                            >
                              <div style={{ display: "flex", alignItems: "center", justifyContent: "center", padding: "5px 10px" }}>
                                <Typography
                                  style={{
                                    textAlign: "center",
                                    whiteSpace: "normal",
                                    wordBreak: "break-word",
                                  }}
                                >
                                  
                                </Typography>
                                {settingsAtom.invoicesSortField === "invoiceGross" && settings.invoicesSortOrder === "desc" && (
                                  <ArrowDownwardIcon style={{ zoom: 0.6, marginLeft: 5 }} />
                                )}
                                {settingsAtom.invoicesSortField === "invoiceGross" && settings.invoicesSortOrder === "asc" && (
                                  <ArrowUpwardIcon style={{ zoom: 0.6, marginLeft: 5 }} />
                                )}
                              </div>
                            </Grid>

                            {/* ESTIMATES */}
                            <Grid xs={4} md={2}
                              container
                              item
                              justifyContent="center"
                              alignItems="center"
                              style={{ background: settings.invoicesSortField === "invoiceDate" && "rgb(237, 239, 255)", borderRadius: 6 }}
                            >
                              <div style={{ display: "flex", alignItems: "center", justifyContent: "center", padding: "5px 10px" }}>
                                <Typography
                                  style={{
                                    textAlign: "center",
                                    whiteSpace: "normal",
                                    wordBreak: "break-word",
                                  }}
                                >
                                  {/* KVs/Angebote */}
                                </Typography>
                                {settingsAtom.invoicesSortField === "invoiceDate" && settings.invoicesSortOrder === "desc" && (
                                  <ArrowDownwardIcon style={{ zoom: 0.6, marginLeft: 5 }} />
                                )}
                                {settingsAtom.invoicesSortField === "invoiceDate" && settings.invoicesSortOrder === "asc" && (
                                  <ArrowUpwardIcon style={{ zoom: 0.6, marginLeft: 5 }} />
                                )}
                              </div>
                            </Grid>

                            {/* INVOICES */}
                            <Grid xs={4} md={2}
                              container
                              item
                              justifyContent="center"
                              style={{ background: settings.invoicesSortField === "invoiceDueDate" && "rgb(237, 239, 255)", borderRadius: 6 }}
                            >
                              <div style={{ display: "flex", alignItems: "center", justifyContent: "center", padding: "5px 10px" }}>
                                <Typography
                                  style={{
                                    textAlign: "center",
                                    whiteSpace: "normal",
                                    wordBreak: "break-word",
                                  }}
                                >
                                 Rechnungen
                                </Typography>
                                {settingsAtom.invoicesSortField === "invoiceDueDate" && settings.invoicesSortOrder === "desc" && (
                                  <ArrowDownwardIcon style={{ zoom: 0.6, marginLeft: 5 }} />
                                )}
                                {settingsAtom.invoicesSortField === "invoiceDueDate" && settings.invoicesSortOrder === "asc" && (
                                  <ArrowUpwardIcon style={{ zoom: 0.6, marginLeft: 5 }} />
                                )}
                              </div>
                            </Grid>

                            {/* Optionen */}
                            <Grid container item xs={12} md={2} justifyContent="center">
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <div style={{ minHeight: "100vh" }}>
                    {dataInitial.length !== 0 && (
                      <>
                        {/* APPLIED FILTER after Loading doesn't result in list of length 0 */}
                        {Object.keys(dataInitial).length !== 0 &&
                          (dataInitial)
                            .map((client, i) => (
                              <Grid container key={i}>
                                <Grid
                                  container
                                  alignItems="center"
                                  justifyContent="space-between"
                                  item
                                  xs={12}
                                  className="h-bg--white user-clients"
                                  style={{
                                    borderBottom: "1px solid #e2ecfd",
                                    borderLeft:
                                      client.status === "active" ? `5px solid rgb(76, 175, 80)` :
                                        client.status === "inactive" ? `5px solid rgb(183, 28, 28)` :
                                          `5px solid rgb(255, 183, 77)`
                                  }}
                                >

                                  <Grid container item xs={12} justifyContent="space-between" className="table-section"
                                    onClick={() => setOpenClientDialogAtom({
                                      ...openClientDialogAtom,
                                      open: true,
                                      clientId: client.id
                                   })}
                                  >
                                    {/* First Part using lg 4 */}
                                    {/* Status */}
                                    <Grid xs={12} lg={2}
                                      container
                                      item
                                      alignItems="center"
                                      justifyContent="flex-start"
                                      className="invoice-status"
                                      style={{ padding: "5px 0" }}
                                    >

                                      {/* Active */}
                                      {client.status === "active" && (
                                          <div
                                            style={{
                                              position: "relative",
                                              display: "inline-block",
                                              marginLeft: 5,
                                              marginRight: 5,
                                              width: "100%"
                                            }}
                                          >
                                            {/* Draft Tooltip */}
                                            <Tooltip title="Kundenstatus" arrow>
                                              <div
                                                style={{
                                                  background: "white",
                                                  padding: "5px 10px",
                                                  borderRadius: 60,
                                                  display: "flex",
                                                  justifyContent: "flex-start",
                                                  alignItems: "center",
                                                  position: "relative", // Added for badge positioning
                                                }}
                                              >
                                              <div style={{ position: "relative", marginTop: 5 }}>
                                                  <FiberManualRecordIcon
                                                    style={{
                                                      fontSize: "21px",
                                                      color: "rgb(76, 175, 80)",
                                                      background: "rgb(76, 175, 80)",
                                                      borderRadius: 60,
                                                      padding: 2,
                                                      marginRight: 5,
                                                      opacity: 1,
                                                    }}
                                                  />
                                                </div>
                                                {/* Typography Content */}
                                                <Typography
                                                  component="div"
                                                  variant="body2"
                                                  style={{
                                                    textAlign: "left",
                                                    color: "inherit",
                                                    fontSize: "13px",
                                                    paddingTop: "1px",
                                                    lineHeight: 1.2
                                                  }}
                                                >
                                                  <span style={{
                                                    fontSize: 11,
                                                    fontWeight: 500,
                                                    opacity: 1
                                                  }}>
                                                    Aktiv
                                                  </span>
                                                </Typography>
                                              </div>
                                            </Tooltip>
                                          </div>
                                      )}

                                      {/* Inactive */}
                                      {client.status === "inactive" && (
                                          <div
                                            style={{
                                              position: "relative",
                                              display: "inline-block",
                                              marginLeft: 5,
                                              marginRight: 5,
                                              width: "100%"
                                            }}
                                          >
                                            {/* Draft Tooltip */}
                                            <Tooltip title="Kundenstatus" arrow>
                                              <div
                                                style={{
                                                  background: "white",
                                                  padding: "5px 10px",
                                                  borderRadius: 60,
                                                  display: "flex",
                                                  justifyContent: "flex-start",
                                                  alignItems: "center",
                                                  position: "relative", // Added for badge positioning
                                                }}
                                              >
                                              <div style={{ position: "relative", marginTop: 5 }}>
                                                  <FiberManualRecordIcon
                                                    style={{
                                                      fontSize: "21px",
                                                      color: "rgb(183, 28, 28)",
                                                      background: "rgb(183, 28, 28)",
                                                      borderRadius: 60,
                                                      padding: 2,
                                                      marginRight: 5,
                                                      opacity: 1,
                                                    }}
                                                  />
                                                </div>
                                                {/* Typography Content */}
                                                <Typography
                                                  component="div"
                                                  variant="body2"
                                                  style={{
                                                    textAlign: "left",
                                                    color: "inherit",
                                                    fontSize: "13px",
                                                    paddingTop: "1px",
                                                    lineHeight: 1.2
                                                  }}
                                                >
                                                  <span style={{
                                                    fontSize: 11,
                                                    fontWeight: 500,
                                                    opacity: 1
                                                  }}>
                                                    Inaktiv
                                                  </span>
                                                </Typography>
                                              </div>
                                            </Tooltip>
                                          </div>
                                      )}

                                      {/* Prospect */}
                                      {client.status === "prospect" && (
                                          <div
                                            style={{
                                              position: "relative",
                                              display: "inline-block",
                                              marginLeft: 5,
                                              marginRight: 5,
                                              width: "100%"
                                            }}
                                          >
                                            {/* Draft Tooltip */}
                                            <Tooltip title="Kundenstatus" arrow>
                                              <div
                                                style={{
                                                  background: "white",
                                                  padding: "5px 10px",
                                                  borderRadius: 60,
                                                  display: "flex",
                                                  justifyContent: "flex-start",
                                                  alignItems: "center",
                                                  position: "relative", // Added for badge positioning
                                                }}
                                              >
                                              <div style={{ position: "relative", marginTop: 5 }}>
                                                  <FiberManualRecordIcon
                                                    style={{
                                                      fontSize: "21px",
                                                      color: "rgb(255, 183, 77)",
                                                      background: "rgb(255, 183, 77)",
                                                      borderRadius: 60,
                                                      padding: 2,
                                                      marginRight: 5,
                                                      opacity: 1,
                                                    }}
                                                  />
                                                </div>
                                                {/* Typography Content */}
                                                <Typography
                                                  component="div"
                                                  variant="body2"
                                                  style={{
                                                    textAlign: "left",
                                                    color: "inherit",
                                                    fontSize: "13px",
                                                    paddingTop: "1px",
                                                    lineHeight: 1.2
                                                  }}
                                                >
                                                  <span style={{
                                                    fontSize: 11,
                                                    fontWeight: 500,
                                                    opacity: 1
                                                  }}>
                                                    Möglicher Kunde<br/>(in Verhandlung)
                                                  </span>
                                                </Typography>
                                              </div>
                                            </Tooltip>
                                          </div>
                                      )}
                                    </Grid>

                                    {/* Client */}
                                    <Grid xs={12} lg={2}
                                      container
                                      item
                                      alignItems="center"
                                      className="image-name-company"
                                      style={{ padding: "5px", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", flexWrap: "nowrap" }}
                                      onClick={() => setOpenClientDialogAtom({
                                        ...openClientDialogAtom,
                                        open: true,
                                        clientId: client.id
                                     })}
                                    >

                                      <div className="client-image" style={{ borderRadius: 6, display: "flex", justifyContent: "center", alignItems: "center", background: "white", borderRadius: 6, height: "70px", width: "70px", minWidth: "70px", marginRight: "10px", position: "relative" }}>
                                        {client.logo !== "" ? (
                                          <img
                                            src={`${client.logo}`}
                                            alt="logo"
                                            style={{
                                              maxWidth: "65px",
                                              maxHeight: "65px",
                                              marginRight: "10px",
                                              position: "absolute",
                                              top: "50%",
                                              left: "50%",
                                              transform: "translate(-50%, -50%)",
                                              padding: 2,
                                              opacity: 1,
                                            }}
                                          />
                                        ) :
                                          <img src={LogoMonogram} height={25} width={25} alt={`${t("APP.name")} Logo`} style={{ width: "50px", minWidth: "50px", marginRight: "10px", opacity: 0.3, margin: "0 auto" }} />
                                        }
                                      </div>

                                      <Typography component="div" variant="body2" style={{ opacity: 1, fontSize: "13px", fontWeight: "500", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>{client.company}</Typography>
                                    </Grid>

                                    {/* Second Part using lg 8*/}
                                    <Grid xs={12} lg={8}
                                      container
                                      item
                                      justifyContent="flex-start"
                                      alignItems="center"
                                      className="invoice-meta"
                                      style={{ opacity: 1, padding: "15px 0px", }}
                                      onClick={() => setOpenClientDialogAtom({
                                        ...openClientDialogAtom,
                                        open: true,
                                        clientId: client.id
                                     })}
                                    >

                                      {/* Client Number */}
                                      <Grid xs={12} lg={2}
                                        container
                                        item
                                        justifyContent="center"
                                        className="invoice-meta-item-container"
                                      >
                                        <Typography className="invoice-meta-item" style={{ textAlign: "center", fontSize: "12px" }} component="div" variant="body2">
                                          <span className="menu-heading-title" style={{ paddingTop: 4 }}>Kundennummer:</span>
                                          <Tooltip title={
                                            <span>
                                              {JSON.parse(client.clientNumber).map((item, i) => (
                                                <span key={i}>
                                                  {item.value}
                                                </span>
                                              ))}
                                            </span>
                                          } arrow>
                                            <div style={{
                                              display: "inline-block",
                                              maxWidth: "100px",
                                              whiteSpace: "nowrap",
                                              overflow: "hidden",
                                              textOverflow: "ellipsis"
                                            }}>
                                              <span style={{ fontWeight: "300" }}>
                                                {JSON.parse(client.clientNumber).map((item, i) => (
                                                  <span key={i} style={{ fontSize: "13px", fontWeight: "300" }}>
                                                    {item.type === "continuousNumber" ? (
                                                      <span style={{
                                                        display: "inline-block",
                                                        background: "rgb(237, 239, 255)",
                                                        height: "17px",
                                                        color: "inherit",
                                                        paddingLeft: 3,
                                                        paddingRight: 3,
                                                        borderRadius: "3px",
                                                        marginTop: 3.3,
                                                        marginLeft: "1px",
                                                        marginRight: "1px",
                                                        fontSize: "13px",
                                                        fontWeight: "300"
                                                      }}>{item.value}</span>
                                                    ) : (
                                                      <span>{item.value}</span>
                                                    )}
                                                  </span>
                                                ))}
                                              </span>
                                            </div>
                                          </Tooltip>
                                        </Typography>
                                      </Grid>

                                      {/* Client Category */}
                                      <Grid xs={12} lg={2}
                                        container
                                        item
                                        justifyContent="center"
                                        className="invoice-meta-item-container"
                                      >
                                        <Typography className="invoice-meta-item" style={{ textAlign: "center", fontSize: "12px" }} component="div" variant="body2">
                                          <span className="menu-heading-title">Kategorie:</span>
                                          <Grid item container justifyContent="center">
                                            <div style={{ fontWeight: "300", fontSize: "12px" }}>{client.category === "company" ? "Unternehmen/Organisation" : "Privatperson"}</div>
                                          </Grid>
                                        </Typography>
                                      </Grid>

                                      {/* Placeholder */}
                                      <Grid xs={12} lg={2}
                                        container
                                        item
                                        justifyContent="center"
                                      >
                                      </Grid>

                                      {/* Placeholder */}
                                      <Grid xs={4} lg={2}
                                        container
                                        item
                                        justifyContent="center"
                                      >
                                      </Grid>

                                      {/* Invoices */}
                                      <Grid xs={4} lg={2}
                                        container
                                        item
                                        justifyContent="center"
                                        className="invoice-ref"
                                      >
                                         <Chip
                                          disabled={client.invoices <= 0}
                                          icon={<LibraryBooksIcon style={{  color: '#ffffff' }} />}
                                          label={client.invoices}
                                          onClick={(e) => {
                                            e.stopPropagation();
                                            setAllInvoicesByOriginalRefAtom("");
                                            setAllInvoicesByClientAtom(`${client.company} {Ref:${client.id}}`);
                                            setSelectedMenuItemAtom(31);
                                            setSubMenuInvoicesOpenAtom(true);
                                            setMobileOpen(false);
                                            navigate("/invoices");
                                          }}
                                          className={client.invoices > 0 ? "chip--invoices active" : "chip--invoices"}
                                          style={{
                                            cursor: 'pointer',
                                            backgroundColor: client.invoices > 0 ? theme.palette.primary.main : '#c4c7df',
                                            color: '#ffffff',
                                            fontSize: '11px',
                                            fontWeight: "bold",
                                            borderRadius: 4
                                          }}
                                        />
                                      </Grid>

                                      {/* Placeholder */}
                                      <Grid xs={1}
                                        container
                                        item
                                        justifyContent="center"
                                        alignItems="center"
                                      >
                                      </Grid>

                                      {/* Dots */}
                                      <Grid xs={12} lg={1}
                                        container
                                        item
                                        justifyContent="flex-end"
                                        alignItems="flex-end"
                                      >
                                                                   <ClientsMenuDots
                              client={client}
                              setOpenClientDialogAtom={setOpenClientDialogAtom}
                              openClientDialogAtom={openClientDialogAtom}
                              fetchData={fetchData}
                              handleSearchClear={handleSearchClear}
                            />
                                      </Grid>

                                    </Grid>

                                  </Grid>
                                </Grid>
                              </Grid>
                            ))
                        }
                      </>
                    )
                    }
                    {(isLoading && searchTerm === "") &&
                      <div style={{ padding: 15 }}>Wird geladen ...</div>
                    }

                    {hasMoreInvoices && !isFetching && dataInitial.length !== 0 && (
                      <Grid container justifyContent="center">
                        <Button
                          onClick={loadMoreInvoices}
                          style={{
                            backgroundColor: theme.palette.primary.main, // Primary color
                            color: "#fff",
                            margin: "15px 0", // Smaller margin
                            padding: "8px 16px", // Smaller padding
                            fontSize: "14px", // Smaller font size
                            borderRadius: "6px", // Slightly smaller border radius
                            boxShadow: "0px 3px 6px rgba(0,0,0,0.2)",
                          }}
                          onMouseOver={(e) =>
                            (e.currentTarget.style.backgroundColor = theme.palette.primary.dark)
                          } // Darker shade on hover
                          onMouseOut={(e) =>
                            (e.currentTarget.style.backgroundColor = theme.palette.primary.main)
                          } // Reset background color when mouse leaves
                          startIcon={<RotateLeftIcon />} // Add an icon here
                        >
                          Mehr laden
                        </Button>
                      </Grid>
                    )}

                    {(!values.loading || !isLoading) && userAtom.data.signupCompleted && clientsAtom.length !== 0 && dataInitial.length === 0 && !isFetching &&
                      <Grid container style={{ padding: "20px 15px" }}>
                        <Typography variant="body2" style={{ color: "gray" }}>
                          Es wurden keine Kunden mit den vorgenommenen Einstellungen gefunden.
                        </Typography>
                      </Grid>
                    }

                    {/* {(!values.loading || !isLoading) && !userAtom.data.signupCompleted &&
                      <UserProfileIncompleteWarning
                        headline={t("PAGE_INVOICES.h1")}
                        text={t("PAGE_INVOICES.profileIncomplete")}
                        icon={<ClientsIcon color="primary" fontSize="large" style={{ marginRight: "5px" }} />}
                      />
                    } */}
                  </div>
                </div>
              </Grid>
            </Box>
          </Container>
        </React.Fragment>
      }




























      <Fab color="primary" aria-label="add" className="add-invoice-fab" style={{ position: "fixed", bottom: "0", right: "0", margin: "20px", zIndex: 9 }}
        onClick={(e) => {
          setOpenClientDialogAtom({
            ...openClientDialogAtom,
            open: true,
          });
        }}
      >
        <AddIcon />
      </Fab>
    </div>
  );
};

export default UserClients;
