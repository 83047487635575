import React, { useState, useEffect, useContext } from "react";

// IMPORT recoil
import {
  useRecoilState,
} from 'recoil';
import settingsAtomState from "../../_atoms/settingsAtom";
import userAtomState from "../../_atoms/userAtom";
import settingsTabAtomState from "../../_atoms/settingsTabAtom";



import { useTranslation } from "react-i18next";

import "./Settings.scss"

// IMPORT components MATERIAL UI
import {
  Typography,
  Button,
  Box,
  TextField,
  Container,
  Grid,
  InputAdornment,
  makeStyles,
  Chip,
  FormHelperText,
  Switch,
  List, ListItem, ListItemText
} from "@material-ui/core";
import Fade from '@material-ui/core/Fade';


import MailOutlineIcon from '@material-ui/icons/MailOutline';

import { DB_PATCH_SETTINGS } from '../../_functions/DB_SETTINGS';

// IMPORT components
import { useSnackbar } from 'notistack';

import { useHttpClient } from "../../shared/hooks/http-hook";
import { AuthContext } from "../../shared/context/auth-context";

import { useAuth } from "../../shared/hooks/auth-hook";

import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';

import InputLabel from '@material-ui/core/InputLabel';


import IconButton from "@material-ui/core/IconButton";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import SettingsIcon from '@material-ui/icons/Settings';
import SaveIcon from '@material-ui/icons/Save';
import InfoIcon from '@material-ui/icons/Info';
import CheckCircleIcon from "@material-ui/icons/CheckCircle";

import ButtonLoading from "../../shared/components/UIElements/ButtonLoading";
import { Alert } from '@material-ui/lab';









import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import PhoneIcon from '@material-ui/icons/Phone';
import FavoriteIcon from '@material-ui/icons/Favorite';
import PersonPinIcon from '@material-ui/icons/PersonPin';
import HelpIcon from '@material-ui/icons/Help';
import ShoppingBasket from '@material-ui/icons/ShoppingBasket';
import ThumbDown from '@material-ui/icons/ThumbDown';
import ThumbUp from '@material-ui/icons/ThumbUp';



const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: '100%',
    backgroundColor: "transparent",
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 250,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  toolbar: {
    height: '0px',
    '@media (max-width: 959px)': {
      height: '62px',
    },
    '@media (max-width: 599px)': {
      height: '45px',
    },
    '@media (max-width: 455px)': {
      height: '55px',
    },
  },
}));

export default function SettingsGeneral() {

  const [settingsTabAtom, setSettingsTabAtom] = useRecoilState(settingsTabAtomState);


  const [fixed, setFixed] = useState(false);

  const handleFixedChange = (event) => {
    setFixed(event.target.checked);
  };


  // GLOBAL STATE (RECOIL)
  const [settingsAtom, setSettingsAtom] = useRecoilState(settingsAtomState);
  const [userAtom, setUserAtom] = useRecoilState(userAtomState);


  // HOOKS & CONTEXT
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const auth = useContext(AuthContext);

  const [showPassword, setShowPassword] = React.useState(false);

  const [values, setValues] = useState("");
  const [cc, setCc] = useState({
    state: "",
    isEmail: false,
  });
  const [ccItems, setCcItems] = useState([]);
  const [bcc, setBcc] = useState({
    state: "",
    isEmail: false,
  });
  const [bccItems, setBccItems] = useState([]);

  const [smtpHostError, setSmtpHostError] = useState(false);
  const [smtpUserError, setSmtpUserError] = useState(false);
  const [smtpPassError, setSmtpPassError] = useState(false);
  const [smtpFromError, setSmtpFromError] = useState(false);
  const [smtpEmailError, setSmtpEmailError] = useState(false);
  const [smtpPortError, setSmtpPortError] = useState(false);


  const [testEmail, setTestEmail] = useState("neutral");

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  useEffect(() => {
    let passInfo = settingsAtom.invoicesEmailPassInfo ? "password_is_already_set" : ""
    setValues({ ...values, ...settingsAtom, invoicesEmailPass: passInfo })
    setCcItems(JSON.parse(settingsAtom.cc))
    setBccItems(JSON.parse(settingsAtom.bcc))
    testSMTP()
  }, [settingsAtom]);

  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
    testSMTP();
  };


  const handleChangeCc = (event) => {
    setCc({
      state: event.target.value,
      isEmail: (/^\S+@\S+\.\S+$/).test(event.target.value) ? true : false
    })
  };

  const handleChangeBcc = (event) => {
    setBcc({
      state: event.target.value,
      isEmail: (/^\S+@\S+\.\S+$/).test(event.target.value) ? true : false
    })
  };

  const addCc = () => {
    setCcItems((items) => [
      ...items,
      cc.state
    ]);
    setCc({
      state: "",
      isEmail: false,
    });
  };

  const addBcc = () => {
    setBccItems((items) => [
      ...items,
      bcc.state
    ]);
    setBcc({
      state: "",
      isEmail: false,
    });
  };

  const handleDeleteCc = (index) => {
    setCcItems((current) =>
      current.filter((item, i) => {
        return i !== index;
      })
    );
  };

  const handleDeleteBcc = (index) => {
    setBccItems((current) =>
      current.filter((item, i) => {
        return i !== index;
      })
    );
  };

  const testSMTP = () => {
    if (
      ((/^\S+@\S+\.\S+$/).test(values.invoicesEmailAddress) && values.invoicesEmailAddress !== undefined) &&
      (values.invoicesEmailHost !== "" && values.invoicesEmailHost !== undefined) &&
      (values.invoicesEmailUser !== "" && values.invoicesEmailUser !== undefined) &&
      (values.invoicesEmailPass !== "" && values.invoicesEmailPass !== undefined) &&
      (values.invoicesEmailAddress !== "" && values.invoicesEmailAddress !== undefined) &&
      (values.invoicesEmailFrom !== "" && values.invoicesEmailFrom !== undefined)
    ) {
      return true
    } else {
      return false
    }
  };


  const save = async () => {
    if ((values.invoicesEmailServer === "own" && testSMTP())
      || values.invoicesEmailServer === "download"
      || values.invoicesEmailServer === "dagowert"
    ) {
      setSettingsAtom({ ...values, cc: JSON.stringify(ccItems), bcc: JSON.stringify(bccItems), invoicesEmailPassInfo: values.invoicesEmailPass === "" ? false : true });

      if (auth.token) {
        DB_PATCH_SETTINGS({ ...values, cc: JSON.stringify(ccItems), bcc: JSON.stringify(bccItems) }, auth, sendRequest, enqueueSnackbar, t, true);
      }

      setSmtpHostError(false)
      setSmtpUserError(false)
      setSmtpPassError(false)
      setSmtpEmailError(false)
      setSmtpFromError(false)
      setSmtpPortError(false)
      setTestEmail("neutral")
    } else {
      if (values.invoicesEmailHost === "" || values.invoicesEmailHost === undefined) {
        setSmtpHostError(true)
      }
      if (values.invoicesEmailUser === "" || values.invoicesEmailUser === undefined) {
        setSmtpUserError(true)
      }
      if (values.invoicesEmailPass === "" || values.invoicesEmailPass === undefined) {
        setSmtpPassError(true)
      }
      if (values.invoicesEmailAddress === "" || values.invoicesEmailAddress === undefined || !(/^\S+@\S+\.\S+$/).test(values.invoicesEmailAddress)) {
        setSmtpEmailError(true)
      }
      if (values.invoicesEmailFrom === "" || values.invoicesEmailFrom === undefined) {
        setSmtpFromError(true)
      }
      if (values.invoicesEmailPort === "" || values.invoicesEmailPort === undefined) {
        setSmtpPortError(true)
      }
      return
    }
  };

  const sendSmtpTest = () => {
    (async () => {
      try {

        const formData = new FormData();
        formData.append("host", values.invoicesEmailHost);
        formData.append("user", values.invoicesEmailUser);
        formData.append("pass", values.invoicesEmailPass);
        formData.append("from", values.invoicesEmailFrom);
        formData.append("email", values.invoicesEmailAddress);
        formData.append("port", values.invoicesEmailPort);
        formData.append("emailto", userAtom.data.email);
        formData.append("id", values._id);


        const res = await sendRequest(
          `${process.env.REACT_APP_BACKEND_URL_API}/invoices/testsmtp`,
          "POST",
          formData,
          {
            // "Content-Type": "application/json",
            Authorization: "Bearer " + auth.token,
          }
        )
          .then((res) => setTestEmail("success"))
        // .then(() => setEmailSent(true))
        // .then(() => setZoom(!zoom))
        // .then(() => invoiceHandler("sent", sendDate, jsonString))


      } catch (err) {
        setTestEmail("error")
      }
    })();

  }

  const [selectedType, setSelectedType] = useState(null);

  const handleSelectedType = (type) => {
    setSelectedType(type);
  };


  return (
    <div>
      <Container maxWidth={false} disableGutters className="bg--1 settings-container" style={{marginLeft: 0, maxWidth: 1900}}>
        <Box
          display="flex"
          flexDirection="column"
          className="list-box"
        >

          <Box display="flex"
            flexDirection="column"
            justifyContent="center"
            margin="0 25px"
            className="h-gap--25"
          >

            <Box
              display="flex"
              flexDirection="column"
              padding="25px 0px"
              className="h-gap--20"
            >
              <Grid
                container
                spacing={5}
                direction="row"
                alignContent="flex-start"
                alignItems="flex-start"
                className="h-bg--white h-borderRadius--10 h-padding--50-16 box-shadow--2"
              >

                {values !== "" &&
                  <Grid container spacing={3}>
                    <Grid container item lg={12}>
                      <Grid container item lg={12}>

                        {/* TYPE */}
                        <div style={{ marginBottom: 20 }}>
  <Typography
    style={{
      fontSize: 14,
      fontWeight: "bold",
      textAlign: "left",
      marginBottom: 10,
      display: "block",
    }}
  >
    DagoWert verwenden für
  </Typography>
  <Grid container spacing={2}>
    <Grid item xs={6}>
      <Box
        onClick={() => handleSelectedType("rechnungen")}
        style={{
          border: selectedType === "rechnungen" ? "1px solid green" : "1px solid rgb(237, 239, 255)",
          borderRadius: 4,
          padding: "20px",
          cursor: "pointer",
          background: selectedType === "rechnungen" ? "rgb(237, 239, 255)" : "white",
          position: "relative",
          display: "flex",
          flexDirection: "column",
          alignItems: "left",
          height: "100%",
        }}
      >
        {selectedType === "rechnungen" && (
          <CheckCircleIcon
            style={{
              color: "green",
              position: "absolute",
              top: 10,
              right: 10,
            }}
          />
        )}
        <Typography
          style={{
            fontSize: 16,
            fontWeight: "bold",
            marginBottom: 10,
            textAlign: "left",
          }}
        >
          Rechnungen
        </Typography>
        <Typography style={{ fontSize: 12, fontWeight: "normal", textAlign: "left", fontWeight: "bold" }}>
          Einfaches Erstellen und Versenden von:
        </Typography>
        <ul style={{ fontSize: 12, marginTop: 5, paddingLeft: 15, textAlign: "left" }}>
          <li>Rechnungen</li>
          <li>Wiederkehrenden Rechnungen</li>
          <li>Kostenvoranschlägen / Angeboten</li>
          <li>Mahnungen</li>
          <li>Gutschriften</li>
          <li>Stornierungen</li>
          <li>Rechnungskorrekturen</li>
        </ul>
      </Box>
    </Grid>
    <Grid item xs={6}>
      <Box
        onClick={() => handleSelectedType("buchhaltung")}
        style={{
          border: "1px solid rgb(237, 239, 255)",
          borderRadius: 4,
          padding: "20px",
          cursor: "pointer",
          background: selectedType === "buchhaltung" ? "rgb(237, 239, 255)" : "white",
          position: "relative",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          alignItems: "center",
          height: "100%",
        }}
      >
        {selectedType === "buchhaltung" && (
          <CheckCircleIcon
            style={{
              color: "green",
              position: "absolute",
              top: 10,
              right: 10,
            }}
          />
        )}
        <Typography
          style={{
            fontSize: 14,
            fontWeight: "bold",
            marginBottom: 4,
          }}
        >
          Buchhaltung
        </Typography>
        <Typography style={{ fontSize: 12, fontWeight: "normal", textAlign: "center" }}>
          Rechnungen erstellen und versenden, Ausgaben erfassen (Einnahmen-Ausgaben-Rechnung)
        </Typography>
      </Box>
    </Grid>
  </Grid>
</div>


                        {/* FIXED */}
                        <Grid container item lg={12}>
                          <FormControlLabel
                            control={
                              <Switch
                                checked={fixed}
                                onChange={handleFixedChange}
                                color="primary" // Change the color as needed
                              />
                            }
                            label={
                              <>
                              {!fixed &&
                              <Typography style={{ fontWeight: 'bold' }}>
                                Dokumente automatisch festschreiben (empfohlen)<span style={{marginLeft: 5, color: "red", fontSize: 12}}><strong>Deaktiviert</strong></span>
                              </Typography>
                              }

                              {fixed &&
                              <Typography style={{ fontWeight: 'bold' }}>
                                Dokumente automatisch festschreiben (empfohlen)<span style={{marginLeft: 5, color: "green", fontSize: 12}}><strong>Aktiviert</strong></span>
                              </Typography>
                              }
                              </>
                            }
                          />
                        </Grid>
                        
                        <>
  <Grid
    item
    container
    direction="column"
    style={{
      background: "#fffaf5",
      padding: "15px 25px",
      borderRadius: 6,
    }}
  >
    <div style={{ display: 'flex', alignItems: 'flex-start' }}>
      <InfoIcon style={{ fontSize: 16, marginRight: 4, marginTop: 2.5, color: "rgb(44, 83, 125)"}} />
      <div>
        <div>
      <Typography style={{ fontSize: 12, lineHeight: "1.5" }}>
        Werden Rechnungen festgeschrieben, wird das Erfordernis der <strong>"Unveränderbarkeit"</strong>, gemäß den Anforderungen einer ordnungsgemäßen Buchführung, eingehalten.
      </Typography>
      </div>
      <div>
      <Typography style={{ fontSize: 12, lineHeight: "1.5" }}>
          Durch automatisches Feschreiben, muss nicht jedes Dokument (z.B. Rechnung) einzeln festgeschrieben werden.
        </Typography>
        </div>
        </div>
    </div>

    {fixed && (
      <div style={{ marginTop: 15 }}>
        <Typography>
          Durch Aktivierung dieser Option wird die Unveränderbarkeit von Rechnungen berücksichtigt. Dabei ist Folgendes zu beachten:
        </Typography>
      </div>
    )}

    {fixed && (
      <div style={{ marginTop: -10 }}>
        <ul style={{ paddingLeft: 16 }}>
          <li>
            <strong>Bearbeiten:</strong> Nach dem Versand ist ein Bearbeiten einer Rechnung nur mehr als Korrektur möglich. Der Zusatztext "Korrektur" wird auf einer bearbeiteten Rechnung angezeigt.
          </li>
          <li>
            <strong>Löschen:</strong> Das Löschen einer Rechnung ist nicht möglich. Jedoch ke
          </li>
          <li>asdf</li>
        </ul>
      </div>
    )}
  </Grid>
</>

                        
                      </Grid>
                    </Grid>
                  </Grid>
                }


              </Grid>
            </Box>



            <Grid container item lg={12} spacing={2} direction="column" alignItems="center">

              <Grid item>
                <ButtonLoading
                  loading={isLoading}
                  disabled={isLoading}
                  type="submit"
                  onClick={() => save()}
                  fullWidth
                  size="large"
                  className="button--loading"
                  startIcon={<SaveIcon style={{ marginRight: "5px" }} />}
                >
                  {t("BUTTONS.save")}
                </ButtonLoading>
              </Grid>
            </Grid>


          </Box>
        </Box>
      </Container>
    </div>
  );
}
