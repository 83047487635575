import React, { useState, useRef } from "react";
import { EditorState, convertToRaw, ContentState, Modifier, SelectionState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
  Typography,
  IconButton,
  Grid,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

const CustomEditor = ({ details = "", onUpdateDetails, disabled }) => {
  const editorRef = useRef(null);
  const [editorState, setEditorState] = useState(() => {
    try {
      const contentBlock = htmlToDraft(details);
      const contentState = ContentState.createFromBlockArray(
        contentBlock.contentBlocks,
        contentBlock.entityMap
      );
      return EditorState.createWithContent(contentState);
    } catch (error) {
      return EditorState.createEmpty();
    }
  });

  const [isFocused, setIsFocused] = useState(false);
  const [open, setOpen] = useState(false);
  const lastSelectionRef = useRef(null);

  const placeholderCategories = [
    {
      title: "MONAT",
      sections: [
        {
          title: "Vormonat",
          description: "Platzhalter für den Monat vor dem aktuellen Monat",
          placeholders: [
            { 
              text: "VORMONAT", 
              description: (
                <>
                  z.B. Rechnungsdatum 02.03.{new Date().getFullYear()} | Eingefügt wird: <strong>02</strong>
                </>
              )
            },
            { 
              text: "VORMONAT AUSGESCHRIEBEN", 
              description: (
                <>
                  z.B. Rechnungsdatum 02.03.{new Date().getFullYear()} | Eingefügt wird: <strong>Februar</strong>
                </>
              )
            },
            { 
              text: "VORMONAT ZEITRAUM", 
              description: (
                <>
                  z.B. Rechnungsdatum 02.03.{new Date().getFullYear()} | Eingefügt wird: <strong>01.02.{new Date().getFullYear()} - 28.02.{new Date().getFullYear()}</strong>
                </>
              )
            },
          ]
        },
        {
          title: "Aktueller Monat",
          description: "Platzhalter für den aktuellen Monat",
          placeholders: [
            { 
              text: "MONAT", 
              description: (
                <>
                  z.B. Rechnungsdatum 02.03.{new Date().getFullYear()} | Eingefügt wird: <strong>03</strong>
                </>
              )
            },
            { 
              text: "MONAT AUSGESCHRIEBEN", 
              description: (
                <>
                  z.B. Rechnungsdatum 02.03.{new Date().getFullYear()} | Eingefügt wird: <strong>März</strong>
                </>
              )
            },
            { 
              text: "MONAT ZEITRAUM", 
              description: (
                <>
                  z.B. Rechnungsdatum 02.03.{new Date().getFullYear()} | Eingefügt wird: <strong>01.03.{new Date().getFullYear()} - 31.03.{new Date().getFullYear()}</strong>
                </>
              )
            },
          ]
        },
        {
          title: "Folgemonat",
          description: "Platzhalter für den Monat nach dem aktuellen Monat",
          placeholders: [
            { 
              text: "FOLGEMONAT", 
              description: (
                <>
                  z.B. Rechnungsdatum 02.03.{new Date().getFullYear()} | Eingefügt wird: <strong>04</strong>
                </>
              )
            },
            { 
              text: "FOLGEMONAT AUSGESCHRIEBEN", 
              description: (
                <>
                  z.B. Rechnungsdatum 02.03.{new Date().getFullYear()} | Eingefügt wird: <strong>April</strong>
                </>
              )
            },
            { 
              text: "FOLGEMONAT ZEITRAUM", 
              description: (
                <>
                  z.B. Rechnungsdatum 02.03.{new Date().getFullYear()} | Eingefügt wird: <strong>01.04.{new Date().getFullYear()} - 30.04.{new Date().getFullYear()}</strong>
                </>
              )
            },
          ]
        }
      ]
    },
    {
      title: "QUARTAL",
      sections: [
        {
          title: "Vorquartal",
          description: "Platzhalter für das Quartal vor dem aktuellen Quartal",
          placeholders: [
            { 
              text: "VORQUARTAL", 
              description: (
                <>
                  z.B. Rechnungsdatum 02.03.{new Date().getFullYear()} | Eingefügt wird: <strong>Quartal 4/{new Date().getFullYear() - 1}</strong>
                </>
              )
            },
            { 
              text: "VORQUARTAL ZEITRAUM", 
              description: (
                <>
                  z.B. Rechnungsdatum 02.03.{new Date().getFullYear()} | Eingefügt wird: <strong>01.10.{new Date().getFullYear() - 1} - 31.12.{new Date().getFullYear() - 1}</strong>
                </>
              )
            },
          ]
        },
        {
          title: "Aktuelles Quartal",
          description: "Platzhalter für das aktuelle Quartal",
          placeholders: [
            { 
              text: "QUARTAL", 
              description: (
                <>
                  z.B. Rechnungsdatum 02.03.{new Date().getFullYear()} | Eingefügt wird: <strong>Quartal 1/{new Date().getFullYear()}</strong>
                </>
              )
            },
            { 
              text: "QUARTAL ZEITRAUM", 
              description: (
                <>
                  z.B. Rechnungsdatum 02.03.{new Date().getFullYear()} | Eingefügt wird: <strong>01.01.{new Date().getFullYear()} - 31.03.{new Date().getFullYear()}</strong>
                </>
              )
            },
          ]
        },
        {
          title: "Folgequartal",
          description: "Platzhalter für das Quartal nach dem aktuellen Quartal",
          placeholders: [
            { 
              text: "FOLGEQUARTAL", 
              description: (
                <>
                  z.B. Rechnungsdatum 02.03.{new Date().getFullYear()} | Eingefügt wird: <strong>Quartal 2/{new Date().getFullYear()}</strong>
                </>
              )
            },
            { 
              text: "FOLGEQUARTAL ZEITRAUM", 
              description: (
                <>
                  z.B. Rechnungsdatum 02.03.{new Date().getFullYear()} | Eingefügt wird: <strong>01.04.{new Date().getFullYear()} - 30.06.{new Date().getFullYear()}</strong>
                </>
              )
            },
          ]
        }
      ]
    },
    {
      title: "JAHR",
      sections: [
        {
          title: "Vorjahr",
          description: "Platzhalter für das Jahr vor dem aktuellen Jahr",
          placeholders: [
            { 
              text: "VORJAHR", 
              description: (
                <>
                  z.B. Rechnungsdatum 02.03.{new Date().getFullYear()} | Eingefügt wird: <strong>{new Date().getFullYear() - 1}</strong>
                </>
              )
            },
            { 
              text: "VORJAHR ZEITRAUM", 
              description: (
                <>
                  z.B. Rechnungsdatum 02.03.{new Date().getFullYear()} | Eingefügt wird: <strong>01.01.{new Date().getFullYear() - 1} - 31.12.{new Date().getFullYear() - 1}</strong>
                </>
              )
            },
          ]
        },
        {
          title: "Aktuelles Jahr",
          description: "Platzhalter für das aktuelle Jahr",
          placeholders: [
            { 
              text: "AKTUELLES JAHR", 
              description: (
                <>
                  z.B. Rechnungsdatum 02.03.{new Date().getFullYear()} | Eingefügt wird: <strong>{new Date().getFullYear()}</strong>
                </>
              )
            },
            { 
              text: "AKTUELLES JAHR ZEITRAUM", 
              description: (
                <>
                  z.B. Rechnungsdatum 02.03.{new Date().getFullYear()} | Eingefügt wird: <strong>01.01.{new Date().getFullYear()} - 31.12.{new Date().getFullYear()}</strong>
                </>
              )
            },
          ]
        },
        {
          title: "Folgejahr",
          description: "Platzhalter für das Jahr nach dem aktuellen Jahr",
          placeholders: [
            { 
              text: "FOLGEJAHR", 
              description: (
                <>
                  z.B. Rechnungsdatum 02.03.{new Date().getFullYear()} | Eingefügt wird: <strong>{new Date().getFullYear() + 1}</strong>
                </>
              )
            },
            { 
              text: "FOLGEJAHR ZEITRAUM", 
              description: (
                <>
                  z.B. Rechnungsdatum 02.03.{new Date().getFullYear()} | Eingefügt wird: <strong>01.01.{new Date().getFullYear() + 1} - 31.12.{new Date().getFullYear() + 1}</strong>
                </>
              )
            },
          ]
        }
      ]
    },
  ]

 
  const insertPlaceholder = async (placeholderText) => {
    const placeholder = `{% ${placeholderText} %}`;

    // Get current content
    let currentContent = editorState.getCurrentContent();

    // Get selection or create one at the end if no previous interaction
    let selection = lastSelectionRef.current || editorState.getSelection();

    // Check if content is empty
    const isEmpty = !currentContent.hasText();

    // If the selection is at the start and we have content, move to the end
    if (selection.getStartOffset() === 0 && currentContent.hasText()) {
      const lastBlock = currentContent.getLastBlock();
      const lastBlockKey = lastBlock.getKey();
      const lastBlockLength = lastBlock.getLength();

      selection = new SelectionState({
        anchorKey: lastBlockKey,
        anchorOffset: lastBlockLength,
        focusKey: lastBlockKey,
        focusOffset: lastBlockLength,
      });

      // Add a space if we're moving to the end of existing content
      if (!isEmpty) {
        currentContent = Modifier.insertText(
          currentContent,
          selection,
          ' '
        );
        selection = currentContent.getSelectionAfter();
      }
    } else {
      // Original space check for normal insertion
      const block = currentContent.getBlockForKey(selection.getStartKey());
      const text = block.getText();
      const startOffset = selection.getStartOffset();
      const previousText = text.slice(Math.max(0, startOffset - 3), startOffset);
      const needsSpaceBefore = startOffset > 0 && !previousText.endsWith('%}') && !previousText.endsWith(' ');

      if (needsSpaceBefore) {
        currentContent = Modifier.insertText(
          currentContent,
          selection,
          ' '
        );
        selection = currentContent.getSelectionAfter();
      }
    }

    // Insert the placeholder text with space after
    const newContent = Modifier.insertText(
      currentContent,
      selection,
      placeholder + ' '
    );

    const newSelection = newContent.getSelectionAfter();
    lastSelectionRef.current = newSelection;

    const newEditorState = EditorState.push(
      editorState,
      newContent,
      'insert-characters'
    );

    const stateWithNewCursor = EditorState.forceSelection(
      newEditorState,
      newSelection
    );

    setEditorState(stateWithNewCursor);

    const newHtml = draftToHtml(convertToRaw(newContent));
    onUpdateDetails(newHtml);

    return stateWithNewCursor;
  };

  const handleEditorChange = (newState) => {
    setEditorState(newState);
    // Store the latest selection
    lastSelectionRef.current = newState.getSelection();
    const newHtml = draftToHtml(convertToRaw(newState.getCurrentContent()));
    if (newHtml !== details) {
      onUpdateDetails(newHtml);
    }
  };

  const handleCloseDialog = () => {
    if (editorRef.current) {
      const editor = editorRef.current.editor;
      if (editor) {
        setOpen(false);
        setTimeout(() => {
          editor.focus();

          // Get the last block and its length
          const currentContent = editorState.getCurrentContent();
          const lastBlock = currentContent.getLastBlock();
          const lastBlockKey = lastBlock.getKey();
          const lastBlockLength = lastBlock.getLength();

          // Create selection at the end
          const endSelection = new SelectionState({
            anchorKey: lastBlockKey,
            anchorOffset: lastBlockLength,
            focusKey: lastBlockKey,
            focusOffset: lastBlockLength,
          });

          // Update editor state with cursor at the end
          const newState = EditorState.forceSelection(
            editorState,
            endSelection
          );
          setEditorState(newState);

          // Store this position
          lastSelectionRef.current = endSelection;
        }, 50);
      }
    }
  };
  return (
    <div
    style={{
      border: `2px solid ${isFocused ? '#2196f3' : '#f0f3ff'}`,  // Changes from light blue to darker blue on focus
      borderRadius: "4px",
      width: "100%",
      transition: "border-color 0.2s ease",  // Smooth transition for the color change
    }}
    onFocus={() => setIsFocused(true)}
    onBlur={() => setIsFocused(false)}
  >
      <Editor
        ref={editorRef}
        editorState={editorState}
        onEditorStateChange={handleEditorChange}
        toolbar={{
          options: ["inline", "list"],
          inline: { options: ["bold"] },
          list: { options: ["unordered", "ordered"] },
        }}
        toolbarCustomButtons={[
          <IconButton
            key="placeholder"
            onClick={() => setOpen(true)}
            className="rdw-option-wrapper"
            style={{
              background: "white",
              marginTop: 1,
              borderRadius: 6,
              padding: 0,
              marginLeft: 5,
              width: "auto",
              height: 31,
            }}
          >
            <Typography style={{ fontSize: 17, color: "black", paddingLeft: 10, fontWeight: 400, paddingRight: 10, marginTop: -2 }}>
              {"{"}
              <span style={{ fontSize: 12, }}>% Platzhalter %</span>
              {"}"}
            </Typography>

          </IconButton>,
        ]}
        readOnly={disabled}
        placeholder="Details beschreiben ..."
        wrapperClassName="editor-wrapper"
        editorClassName="editor editor-invoice-details"
      />

<Dialog open={open} onClose={handleCloseDialog} fullWidth maxWidth="sm">
        <DialogTitle style={{ position: "relative" }}>
          Platzhalter einfügen
          <IconButton
            aria-label="close"
            onClick={handleCloseDialog}
            style={{
              position: "absolute",
              right: 8,
              top: 8,
              color: "#666",
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>

        <DialogContent>
          <Typography
            variant="body1"
            gutterBottom
            style={{ display: "block", marginBottom: 10 }}
          >
            Alle Platzhalter beziehen sich auf das jeweilige <strong>Rechnungsdatum</strong>.
          </Typography>
          {placeholderCategories.map((category) => (
            <div
              key={category.title}
              style={{
                border: "1px solid #ccc",
                borderRadius: 8,
                padding: 16,
                marginBottom: 24,
                backgroundColor: "#f9f9f9",
              }}
            >
              <Typography
                variant="subtitle1"
                gutterBottom
                style={{
                  color: "rgb(44, 83, 125)",
                  fontWeight: 600,
                  fontSize: "1rem",
                  marginBottom: 16,
                }}
              >
                {category.title}
              </Typography>
              
              {category.sections ? (
                category.sections.map((section) => (
                  <div 
                    key={section.title}
                    style={{
                      marginBottom: 20,
                      padding: 12,
                      backgroundColor: "#f0f0f0",
                      borderRadius: 6,
                    }}
                  >
                    <Typography
                      variant="subtitle2"
                      gutterBottom
                      style={{
                        color: "rgb(44, 83, 125)",
                        fontWeight: 500,
                        marginBottom: 12,
                      }}
                    >
                      {section.title}
                    </Typography>
                    {/* <Typography
                      variant="caption"
                      style={{
                        display: 'block',
                        color: "#666",
                        marginBottom: 8,
                      }}
                    >
                      {section.description}
                    </Typography> */}
                    <Grid container spacing={2}>
                      {section.placeholders.map((placeholder) => (
                        <Grid item xs={12} key={placeholder.text}>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "flex-start",
                            }}
                          >
                            <Button
                              variant="outlined"
                              size="small"
                              onClick={async () => {
                                const newState = await insertPlaceholder(placeholder.text);
                                const finalSelection = newState.getSelection();
                                lastSelectionRef.current = finalSelection;

                                setTimeout(() => {
                                  if (editorRef.current) {
                                    const editor = editorRef.current.editor;
                                    if (editor) {
                                      setOpen(false);
                                      setTimeout(() => {
                                        editor.focus();
                                        setEditorState(
                                          EditorState.forceSelection(newState, finalSelection)
                                        );
                                      }, 50);
                                    }
                                  }
                                }, 0);
                              }}
                              style={{
                                backgroundColor: "white",
                                borderColor: "#ccc",
                                color: "#333",
                                transition: "background-color 0.2s",
                              }}
                              onMouseOver={(e) =>
                                (e.currentTarget.style.backgroundColor = "#f2f2f2")
                              }
                              onMouseOut={(e) =>
                                (e.currentTarget.style.backgroundColor = "white")
                              }
                            >
                              {`{% ${placeholder.text} %}`}
                            </Button>
                            <Typography
                              variant="caption"
                              style={{
                                fontSize: 12,
                                color: "#666",
                                marginTop: 4,
                              }}
                            >
                              {placeholder.description}
                            </Typography>
                          </div>
                        </Grid>
                      ))}
                    </Grid>
                  </div>
                ))
              ) : (
                <Grid container spacing={2}>
                  {category.placeholders.map((placeholder) => (
                    <Grid item xs={12} key={placeholder.text}>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "flex-start",
                        }}
                      >
                        <Button
                          variant="outlined"
                          size="small"
                          onClick={async () => {
                            const newState = await insertPlaceholder(placeholder.text);
                            const finalSelection = newState.getSelection();
                            lastSelectionRef.current = finalSelection;

                            setTimeout(() => {
                              if (editorRef.current) {
                                const editor = editorRef.current.editor;
                                if (editor) {
                                  setOpen(false);
                                  setTimeout(() => {
                                    editor.focus();
                                    setEditorState(
                                      EditorState.forceSelection(newState, finalSelection)
                                    );
                                  }, 50);
                                }
                              }
                            }, 0);
                          }}
                          style={{
                            backgroundColor: "white",
                            borderColor: "#ccc",
                            color: "#333",
                            transition: "background-color 0.2s",
                          }}
                          onMouseOver={(e) =>
                            (e.currentTarget.style.backgroundColor = "#f2f2f2")
                          }
                          onMouseOut={(e) =>
                            (e.currentTarget.style.backgroundColor = "white")
                          }
                        >
                          {`{% ${placeholder.text} %}`}
                        </Button>
                        <Typography
                          variant="caption"
                          style={{
                            fontSize: 12,
                            color: "#666",
                            marginTop: 4,
                          }}
                        >
                          {placeholder.description}
                        </Typography>
                      </div>
                    </Grid>
                  ))}
                </Grid>
              )}
            </div>
          ))}
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default CustomEditor;