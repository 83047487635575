import React, { useContext } from 'react';
import { AppBar, Toolbar, IconButton, Grid, Typography, Button } from '@material-ui/core';
import { ArrowBack as ArrowBackIcon, LibraryBooks as LibraryBooksIcon, Repeat as RepeatIcon, Gavel as GavelIcon } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';
import RedeemIcon from '@material-ui/icons/Redeem';
import CloseIcon from '@material-ui/icons/Close';
import SaveIcon from '@material-ui/icons/Save';

import { useHttpClient } from "../../shared/hooks/http-hook";
import { AuthContext } from '../../shared/context/auth-context';
import { useAuth } from "../../shared/hooks/auth-hook";
import { enqueueSnackbar } from 'notistack';

const ProductAppBar = ({
  setOpenProductDialogAtom,
  openProductDialogAtom,
  values,
  errors,
  setErrors,
  loadingNumber,
  validity // number
}) => {
  const auth = useContext(AuthContext);
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const { userId } = useAuth();
  const { t } = useTranslation();

  const validateFields = (values) => {
    const newErrors = {};
  
    // Validate fields using a switch statement
    const validateField = (field, value) => {
      switch (field) {
        case "category":
          if (!value?.trim()) {
            newErrors[field] = true;
          }
          break;
        case "number":
          if (!value?.trim() || !validity) {
            newErrors[field] = true;
          }
          break;
        case "name":
          if (!value?.trim()) {
            newErrors[field] = true;
          }
          break;
        case "price":
          if (value === null || value === "") {
            newErrors[field] = true;
          }
          break;
       case "priceGross":
          if (value === null || value === "") {
            newErrors[field] = true;
          }
          break;
        default:
          break;
      }
    };
  
    // Define required fields
    const requiredFields = ["category", "number", "name", "price", "priceGross"];
    requiredFields.forEach((field) => validateField(field, values[field]));
  
    return newErrors;
  };



  // SAVE or UPDATE PRODUCT
  const productHandler = async () => {

    /***
     *** ERROR VALIDATION
     ***/ 
    // Validate values
    const errors = validateFields(values);

    // Check if there are errors
    if (Object.keys(errors).length > 0) {
      setErrors(errors); // Update errors state
      enqueueSnackbar("Produkt konnte nicht gespeichert werden", { variant: "error" });
      return; // Stop execution if errors exist
    }

    // Clear errors if validation passes
    setErrors({});

    /***
     *** PREPARE FORM DATA
     ***/ 
    const formData = new FormData();
    formData.append("type", values.type);
    formData.append("category", values.category);
    formData.append("categoryLevel", 1);
    formData.append("number", values.number);
    formData.append("name", values.name);
    formData.append("details", values.details);
    formData.append("detailsText", values.detailsText);
    formData.append("vatPercent", parseFloat(values.vatPercent).toFixed(2));
    formData.append("price", parseFloat(values.price).toFixed(2));
    formData.append("priceGross", parseFloat(values.priceGross).toFixed(2));
    formData.append("quantity", parseFloat(values.quantity).toFixed(2));
    formData.append("unit", values.unit);
    formData.append("discount", values.discount);
    formData.append("discountAbsolute", values.discountAbsolute === null ? 0 : parseFloat(values.discountAbsolute).toFixed(2));
    formData.append("discountPercent", values.discountPercent === null ? 0 : parseFloat(values.discountPercent).toFixed(2));
    formData.append("totalNet", values.totalNet);
    formData.append("totalVat", values.totalVat);
    formData.append("totalGross", values.totalGross);
    formData.append("productId", values._id);
    formData.append("creationDate", values.creationDate || new Date ().toString());

    try {
      // Determine the request method and URL based on whether invoiceId exists
      const url = openProductDialogAtom.productId
        ? `${process.env.REACT_APP_BACKEND_URL_API}/products/${openProductDialogAtom.productId}` // Update invoice
        : `${process.env.REACT_APP_BACKEND_URL_API}/products`; // Create new invoice

      const method = openProductDialogAtom.productId ? "PATCH" : "POST";

      // Send the formData to the backend using sendRequest
      const response = await sendRequest(
        url,
        method,
        formData,
        {
          Authorization: "Bearer " + auth.token,
        }
      );

      setOpenProductDialogAtom({
        ...openProductDialogAtom,
        productId: response.productId
      })
      enqueueSnackbar("Produkt wurde gespeichert", { variant: "success" });
    } catch (error) {
    }
  };

  return (
    <AppBar
      elevation={0}
      style={{
        backgroundColor: "white",
        zIndex: 99999,
        boxShadow: "rgba(84, 94, 165, 0.2) 0px 1px 64px"
      }}>

      <Toolbar style={{ justifyContent: "space-between", position: 'relative' }}>
        {/* Left Section: Title */}
        <Grid container alignItems="center" className="appbar-left">
          <IconButton
            edge="start"
            style={{
              display: "flex",
              width: "35px",
              height: "35px",
              marginRight: 10,
              border: "1px solid rgba(0, 0, 0, 0.04)"
            }}
            onClick={() => {
              setOpenProductDialogAtom({
                ...openProductDialogAtom,
                open: false,
                productId: "",
              });
            }}
            aria-label={`${t("BUTTONS.AL_close")}`}
          >
            <ArrowBackIcon color="primary" />
          </IconButton>
          <Typography variant="h6" component="h1" className="invoice-dialog-headline-appbar" style={{ textTransform: "none", fontWeight: 600, color: "rgb(44, 83, 125)", marginRight: 10 }}>
            Produkt
          </Typography>
          {openProductDialogAtom.productId === "" &&
          <Grid item className="invoice-dialog-status-appbar">
            <div
              style={{
                position: "relative",
                display: "flex",
                alignItems: "center",
                marginLeft: 5,
                marginRight: 5,
                width: "100%"
              }}
            >
              <div>
                <Typography
                  style={{
                    padding: 8,
                    background: "white",
                    fontSize: 10,
                    border: "1px solid rgb(44, 83, 125)",
                    borderRadius: 60,
                    color: "rgb(44, 83, 125)",
                    fontWeight: 500,
                  }}>
                  Erstellen
                </Typography>
              </div>
            </div>
          </Grid>
          }
        </Grid>

        {/* Right Section: Buttons and Close Icon */}
        <Grid container item direction="row" alignItems="center" justifyContent="flex-end" className="appbar-right">
          <>
            {/* SAVE */}
            <Button
              disabled={loadingNumber}
              size="small"
              variant="contained"
              color="primary"
              className="app-bar-button"
              style={{ marginRight: -5 }}
              startIcon={<SaveIcon />}
                onClick={(e) => {
                  productHandler();
                }}
            >
              <span className="app-bar-button-label">Speichern</span>
            </Button>
          </>

          {/* CLOSE */}
          <IconButton
            edge="end"
            color="inherit"
            className="invoice-dialog-close-appbar"
            style={{
              marginLeft: "10px",
              display: "flex",
              width: "35px",
              height: "35px",
              border: "1px solid rgba(0, 0, 0, 0.04)"
            }}
            onClick={() => {
              setOpenProductDialogAtom({
                ...openProductDialogAtom,
                open: false,
                productId: "",
              });
            }}
            aria-label={`${t("BUTTONS.AL_close")}`}
          >
            <CloseIcon color="primary" />
          </IconButton>
        </Grid>
      </Toolbar>
    </AppBar>
  );
};

export default ProductAppBar;
