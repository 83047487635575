import React, { useState, useEffect, useContext, useRef } from 'react';
import {
  useRecoilState,
} from 'recoil';
import settingsAtomState from "../../../_atoms/settingsAtom";
import { useTranslation } from "react-i18next";
import { useHttpClient } from "../../../shared/hooks/http-hook";
import { AuthContext } from "../../../shared/context/auth-context";
import { useAuth } from "../../../shared/hooks/auth-hook";
import { useSnackbar } from 'notistack';

import {
  Grid,
  Typography,
  Tooltip,
  IconButton,
  Menu,
  MenuItem,
  Collapse,
  Checkbox,
} from '@material-ui/core';
import {
  AddCircle as AddCircleIcon,
  ExpandMore,
  ExpandLess,
  CompareArrows as CompareArrowsIcon,
  MoreVert as MoreVertIcon,
  Today as TodayIcon,
  TextFields as TextFieldsIcon,
} from '@material-ui/icons';
import InfoIcon from '@material-ui/icons/Info';
import EditIcon from '@material-ui/icons/Edit';
import SettingsIcon from '@material-ui/icons/Settings';
import { RETURN_YEAR, RETURN_MONTH, RETURN_DAY } from '../../../_functions';
import { useTheme } from '@material-ui/core/styles';
import { DB_PATCH_SETTINGS } from '../../../_functions/DB_SETTINGS';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from "@material-ui/icons/Error";

const InvoiceNr = ({
  values,
  handleOpenPicker,
  changeContinuousNumber,
  handleClick,
  anchorEl,
  openInvoiceNrMenuDots,
  handleClose,
  addElementOpen,
  setAddElementOpen,
  setValues,
  setOpenInvoiceNumberEdit,
  openInvoiceDialogAtom,
  saveNewInvoiceSchema,
  setSaveNewInvoiceSchema,
  disabledEditing,
  invoiceDateError,
  isFetching,
  setIsFetching,
}) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { isLoading, error, sendRequest } = useHttpClient();
  const auth = useContext(AuthContext);
  const [settingsAtom, setSettingsAtom] = useRecoilState(settingsAtomState);


  const [initialInvoiceNr, setInitialInvoiceNr] = useState(
    (values.invoiceNr ? JSON.parse(values.invoiceNr) : []).filter(
      (item) => item.type !== 'continuousNumber'
    )
  );
  const [hasInvoiceNumberChanged, setHasInvoiceNumberChanged] = useState(false);
  const [edit, setEdit] = useState(false);

  useEffect(() => {
    const currentInvoiceNr = (values.invoiceNr ? JSON.parse(values.invoiceNr) : []).filter(
      (item) => item.type !== 'continuousNumber'
    );

    const elementsChanged = currentInvoiceNr.length !== initialInvoiceNr.length;

    const textElementsChanged = currentInvoiceNr.some((item, index) => {
      return (
        item.type === 'text' &&
        item.value !== (initialInvoiceNr[index] ? initialInvoiceNr[index].value : undefined)
      );
    });

    if (elementsChanged || textElementsChanged) {
      setHasInvoiceNumberChanged(true);
    }
  }, [values.invoiceNr, initialInvoiceNr]);




  const invoiceLabels = {
    invoiceNumber: 'Rechnungsnummer',
    estimateNumber: 'Kostenvoranschlag Nr.',
    offerNumber: 'Angebot Nr.',
  };

  const tooltips = {
    continuousNumber: 'Fortlaufende Nummer',
    fixedText: 'Fixer Text',
    yearInvoice: 'Jahr (des Rechnungsdatums)',
    yearEstimate: 'Datum (Jahr)',
    monthInvoice: 'Monat (des Rechnungsdatums)',
    monthEstimate: 'Datum (Monat)',
    dayInvoice: 'Tag (des Rechnungsdatums)',
    dayEstimate: 'Datum (Tag)',
  };

  const menuLabels = {
    addElement: 'Element hinzufügen',
    addElementText: 'Fixer Text (Text, Zahl oder Sonderzeichen)',
    yearLabelInvoice: 'Jahr des Rechnungsdatums',
    yearLabelEstimate: 'Datum (Jahr)',
    monthLabelInvoice: 'Monat des Rechnungsdatums',
    monthLabelEstimate: 'Datum (Monat)',
    dayLabelInvoice: 'Tag des Rechnungsdatums',
    dayLabelEstimate: 'Datum (Tag)',
    arrangeRemoveElements: 'Elemente anordnen / entfernen',
  };

  //*** *
  // Invoice Number check (if it already exists)
  // **/
  const debounceTimeoutRef = useRef(null);
  const [isText, setIsText] = useState(false);

  const [fetchComplete, setFetchComplete] = useState(false);
  const [previousInvoiceNr, setPreviousInvoiceNr] = useState(
    (values.invoiceNr ? JSON.parse(values.invoiceNr) : []).map((item) => item.value).join("")
  );
  const [suggestedInvoiceNr, setSuggestedInvoiceNr] = useState(
    (values.invoiceNr ? JSON.parse(values.invoiceNr) : []).map((item) => item.value).join("")
  );
  const [isInvoiceNrValid, setIsInvoiceNrValid] = useState(null); // null: not checked, true: valid, false: 
  
  // Refs for timeouts
  const animationTimeoutRef = useRef(null);
  const fetchTimeoutRef = useRef(null);

  // Trigger the loading animation
  const triggerLoadingAnimation = (delay = isText ? 0 : 2000) => {
    setIsFetching(true);
    setFetchComplete(false);

    // Clear any existing animation timeout
    if (animationTimeoutRef.current) {
      clearTimeout(animationTimeoutRef.current);
    }

    // Set a new animation timeout
    animationTimeoutRef.current = setTimeout(() => {
      setIsFetching(false);
      setFetchComplete(true);
      setIsText(false);
    }, delay);
  };

  // Trigger the invoice number check
  const triggerCheckInvoiceNumber = async (invoiceNr) => {
    // Bypass check if the current invoice number matches the suggested invoice number
    if (invoiceNr === suggestedInvoiceNr) {
      setIsInvoiceNrValid(true); // Always valid if it matches
      return;
    }

    try {
      const response = await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL_API}/invoices/checkinvoicenumber`,
        "POST",
        JSON.stringify({ invoiceNr, userId: auth.userId }),
        {
          "Content-Type": "application/json",
          Authorization: "Bearer " + auth.token,
        }
      );

      if (response.exists) {
        setIsInvoiceNrValid(false);
      } else {
        setIsInvoiceNrValid(true);
      }
    } catch (err) {
      console.error("Error checking invoice number:", err);
      setIsInvoiceNrValid(false);
    }
  };

  useEffect(() => {
    const currentInvoiceNr = (values.invoiceNr ? JSON.parse(values.invoiceNr) : [])
      .map((item) => item.value)
      .join("");

    // Start the loading animation
    triggerLoadingAnimation();

    // Clear any existing timeout for the fetch
    if (fetchTimeoutRef.current) {
      clearTimeout(fetchTimeoutRef.current);
    }

    // Set a new timeout for the delayed fetch request
    fetchTimeoutRef.current = setTimeout(async () => {
      await triggerCheckInvoiceNumber(currentInvoiceNr);

      // End the loading animation after the fetch completes
      setPreviousInvoiceNr(currentInvoiceNr);
    }, 0);

    // Cleanup on component unmount or if `values.invoiceNr` changes again
    return () => {
      if (fetchTimeoutRef.current) {
        clearTimeout(fetchTimeoutRef.current);
      }
      if (animationTimeoutRef.current) {
        clearTimeout(animationTimeoutRef.current);
      }
    };
  }, [values.invoiceNr, previousInvoiceNr, suggestedInvoiceNr]);

  return (
    <>
    {!values.recurring &&
      <Grid
        item
        container
        xs={12}
        md={12}
        style={{
          border: '2px solid rgb(240, 243, 255)',
          background: "rgb(240, 243, 255)",
          minHeight: '56px',
          borderRadius: '4px',
          paddingTop: "10px",
          paddingBottom: '10px',
          margin: "0px 0px 0px 0px"
        }}
      >
        <Grid item xs={12} md={12}>
          <Grid
            item
            style={{ marginLeft: '14px', wordBreak: 'break-all' }}
          >
            <Typography>
              {!values.recurring &&
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <span
                      className="invoiceNrHeadline"
                      style={{ opacity: disabledEditing ? 0.2 : 1, fontSize: '10px', marginRight: 5, display: "block", color: !isInvoiceNrValid && fetchComplete && isInvoiceNrValid !== null ? "rgb(183, 28, 28)" : "inherit" }}
                    >
                      {`${values.type === 'invoice' || values.type === 'reminder' || values.type === 'reminderRequest' || values.type === "cancellation"
                        ? invoiceLabels.invoiceNumber
                        : values.type === 'estimate'
                          ? invoiceLabels.estimateNumber
                          : invoiceLabels.offerNumber
                        }:`}
                    </span>

                    <span style={{ opacity: disabledEditing ? 0.2 : 1, fontSize: '14px', fontWeight: 300 }}>
                      {(values.invoiceNr ? JSON.parse(values.invoiceNr) : []).map(
                        (item) => item.value
                      ).join('')}
                    </span>
                  </div>

                  {invoiceDateError === "" &&
                    <span style={{ marginLeft: 15, fontSize: 10 }}>
                      <IconButton
                        style={{
                          marginTop: 4,
                          padding: 7, // Reduce padding for smaller size
                          background: !edit ? "#ffffff" : theme.palette.primary.main
                        }}
                        onClick={() => setEdit(!edit)} // Replace with your edit handler function
                      >
                        <EditIcon style={{ fontSize: '15px', color: !edit ? theme.palette.primary.main : "#ffffff" }} />
                      </IconButton>
                      {/* Bearbeiten */}
                    </span>
                  }
                </div>
              }

              {/* {values.recurring && (
                <>
                  <span
                    className="invoiceNrHeadline"
                    style={{
                      display: 'flex',
                      flexDirection: 'column', // Stack items vertically
                      alignItems: 'flex-start', // Align items to the start of the container
                      fontSize: '14px',
                      color: "rgba(0,0,0,0.87)",
                      opacity: (isLoading || disabledEditing) ? 0.5 : 1,
                    }}
                  >
                    <div style={{ fontSize: 11 }}>Rechnungsnummer</div>
                    <div style={{ fontSize: 11, display: "flex", flexDirection: "row" }}>
                      <InfoIcon style={{ marginTop: 3, fontSize: 11, color: theme.palette.primary.main, marginRight: 8 }} />
                      <span style={{ fontSize: 11 }}>Die fortlaufende Nummer wird automatisch generiert.</span>
                      <span style={{ marginTop: -14, marginLeft: 15, fontSize: 10 }} onClick={() => setEdit(!edit)}>
                        <IconButton
                          style={{
                            padding: 7, // Reduce padding for smaller size
                            background: !edit ? "#ffffff" : theme.palette.primary.main,
                          }}
                          onClick={() => setEdit(!edit)} // Replace with your edit handler function
                        >
                          <EditIcon style={{ fontSize: '15px', color: !edit ? theme.palette.primary.main : "#ffffff" }} />
                        </IconButton>
                      </span>
                    </div>
                  </span>
                </>

              )} */}

            </Typography>
          </Grid>

          {edit && invoiceDateError === "" &&
            <Grid
              container
              style={{
                marginBottom: '5px',
                paddingLeft: '11px',
                borderRadius: '4px',
                display: "flex",
                // justifyContent: "space-between",
                marginTop: 15
              }}
            >

              <Grid container item style={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}>
                {(values.invoiceNr ? JSON.parse(values.invoiceNr) : []).map((item, i) => (
                  <Grid
                    key={item.id}
                    item
                    className="invoiceNrItem--v2"
                    style={{ marginRight: 1, marginBottom: 2, opacity: disabledEditing ? 0.2 : 1 }}
                  >
                    {/* Continuous Number */}
                    {item.type === "continuousNumber" && (
                      <Tooltip title={tooltips.continuousNumber} aria-label="Continuous Number">
                        <Grid
                          container
                          item
                          direction="row"
                          justifyContent="space-between"
                          style={{
                            background: !values.recurring ? "rgb(240, 243, 255)" : "rgb(251 239 255)",
                            border: "1px solid rgb(240, 243, 255)",
                            borderRadius: 2,
                          }}
                        >
                          <Grid item style={{ textAlign: "center", width: "100%" }}>
                            <Typography>
                              <input
                                disabled={(values.recurring || disabledEditing)}
                                className="badge-input--invoicenr"
                                defaultValue={!values.recurring ? item.value || "" : "Fortlaufende Nummer"}
                                placeholder="Nr. ..."
                                onChange={(e) => {
                                  e.persist();
                                  triggerLoadingAnimation();
                                  e.target.value.length !== 0 &&
                                    e.target.setAttribute("size", e.target.value.length);
                                  setValues((prevValues) => {
                                    const updatedInvoiceNr = JSON.parse(prevValues.invoiceNr || "[]");
                                    updatedInvoiceNr[i] = { ...item, value: e.target.value };
                                    return {
                                      ...prevValues,
                                      invoiceNr: JSON.stringify(updatedInvoiceNr),
                                    };
                                  });
                                }}
                                style={{
                                  opacity: isLoading ? 0.5 : "inherit",
                                  width: "100%",
                                  fontSize: "14px",
                                  textAlign: "center",
                                  paddingTop: "4px",
                                  paddingBottom: "2px",
                                  color: "rgba(0, 0, 0, 0.87)",
                                  fontFamily: "Inter, sans-serif",
                                  fontWeight: 300,
                                  background: "#ffffff",
                                }}
                                size={JSON.stringify(item.value).length}
                              />
                            </Typography>
                          </Grid>
                        </Grid>
                      </Tooltip>
                    )}

                    {item.type === "text" && (
                      <Tooltip title={tooltips.fixedText} aria-label="Text">
                        <Grid
                          container
                          item
                          direction="row"
                          justifyContent="space-between"
                          style={{
                            background: "rgb(240, 243, 255)",
                            border: "1px solid rgb(240, 243, 255)",
                            borderRadius: 2,
                          }}
                        >
                          <Grid item style={{ width: "100%", textAlign: "center" }}>
                            <Typography>
                              <input
                                disabled={isLoading || disabledEditing}
                                className="badge-input--invoicenr"
                                defaultValue={item.value || ""}
                                placeholder="Text ..."
                                onChange={(e) => {
                                  e.persist();
                                  triggerLoadingAnimation();
                                  e.target.value.length !== 0 &&
                                    e.target.setAttribute("size", e.target.value.length + 2);

                                  // Update the local state and debounce the global update
                                  const newValue = e.target.value;

                                  // Clear existing timeout
                                  if (debounceTimeoutRef.current) {
                                    clearTimeout(debounceTimeoutRef.current);
                                  }

                                  // Set a new timeout
                                  debounceTimeoutRef.current = setTimeout(() => {
                                    setValues((prevValues) => {
                                      const updatedInvoiceNr = JSON.parse(prevValues.invoiceNr || "[]");
                                      updatedInvoiceNr[i] = { ...item, value: newValue };
                                      return {
                                        ...prevValues,
                                        invoiceNr: JSON.stringify(updatedInvoiceNr),
                                      };
                                    });
                                    setIsText(true);
                                  }, 2000);
                                }}
                                style={{
                                  opacity: isLoading ? 0.5 : "inherit",
                                  width: "100%",
                                  fontSize: "14px",
                                  textAlign: "center",
                                  paddingTop: "4px",
                                  paddingBottom: "2px",
                                  color: "rgba(0, 0, 0, 0.87)",
                                  fontFamily: "Inter, sans-serif",
                                  fontWeight: 300,
                                }}
                                size={JSON.stringify(item.value).length}
                              />
                            </Typography>
                          </Grid>
                        </Grid>
                      </Tooltip>
                    )}

                    {/* Year */}
                    {item.type === "year" && (
                      <Tooltip
                        title={!openInvoiceDialogAtom.isEstimate ? tooltips.yearInvoice : tooltips.yearEstimate}
                        aria-label="Year"
                      >
                        <Grid
                          container
                          item
                          direction="row"
                          justifyContent="space-between"
                          style={{
                            marginTop: 1,
                            background: "rgb(230, 229, 237)",
                            border: "1px solid rgb(230, 229, 237)",
                            borderRadius: 3,
                            lineHeight: 1.51,
                            paddingTop: 3,
                            marginLeft: 1,
                          }}
                        >
                          <Grid item style={{ width: "100%", textAlign: "center" }}>
                            <Typography>
                              <span
                                style={{
                                  opacity: isLoading ? 0.5 : "inherit",
                                  width: "100%",
                                  textAlign: "center",
                                  paddingLeft: "15px",
                                  paddingRight: "15px",
                                }}
                              >
                                {new Date(values.invoiceDate).getFullYear()}
                              </span>
                            </Typography>
                          </Grid>
                        </Grid>
                      </Tooltip>
                    )}

                    {/* Month */}
                    {item.type === "month" && (
                      <Tooltip
                        title={!openInvoiceDialogAtom.isEstimate ? tooltips.monthInvoice : tooltips.monthEstimate}
                        aria-label="Month"
                      >
                        <Grid
                          container
                          item
                          direction="row"
                          justifyContent="space-between"
                          style={{
                            marginTop: 1,
                            background: "rgb(230, 229, 237)",
                            border: "1px solid rgb(230, 229, 237)",
                            borderRadius: 3,
                            lineHeight: 1.51,
                            paddingTop: 3,
                            marginLeft: 1,
                          }}
                        >
                          <Grid item style={{ width: "100%", textAlign: "center" }}>
                            <Typography>
                              <span
                                style={{
                                  opacity: isLoading ? 0.5 : "inherit",
                                  width: "100%",
                                  textAlign: "center",
                                  paddingLeft: "15px",
                                  paddingRight: "15px",
                                }}
                              >
                                {new Date(values.invoiceDate).getMonth() + 1}
                              </span>
                            </Typography>
                          </Grid>
                        </Grid>
                      </Tooltip>
                    )}

                    {/* Day */}
                    {item.type === "day" && (
                      <Tooltip
                        title={!openInvoiceDialogAtom.isEstimate ? tooltips.dayInvoice : tooltips.dayEstimate}
                        aria-label="Day"
                      >
                        <Grid
                          container
                          item
                          direction="row"
                          justifyContent="space-between"
                          style={{
                            marginTop: 1,
                            background: "rgb(230, 229, 237)",
                            border: "1px solid rgb(230, 229, 237)",
                            borderRadius: 3,
                            lineHeight: 1.51,
                            paddingTop: 3,
                          }}
                        >
                          <Grid item style={{ width: "100%", textAlign: "center" }}>
                            <Typography>
                              <span
                                style={{
                                  opacity: isLoading ? 0.5 : "inherit",
                                  width: "100%",
                                  textAlign: "center",
                                  paddingLeft: "15px",
                                  paddingRight: "15px",
                                }}
                              >
                                {new Date(values.invoiceDate).getDate()}
                              </span>
                            </Typography>
                          </Grid>
                        </Grid>
                      </Tooltip>
                    )}
                  </Grid>
                ))}

                {/* Loading Dots and Green Check */}
                <div style={{ display: "flex", alignItems: "center", marginLeft: 5 }}>
                  {isFetching ? (
                    <div className="loading-dots">
                      <span className="dot"></span>
                      <span className="dot"></span>
                      <span className="dot"></span>
                    </div>
                  ) : fetchComplete && isInvoiceNrValid !== null ? (
                    isInvoiceNrValid ? (
                      <CheckCircleIcon style={{ color: "green", fontSize: "20px" }} />
                    ) : (
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <ErrorIcon style={{ color: "#b71c1c", fontSize: "20px", marginRight: "8px" }} />
                        <span style={{ color: "#b71c1c", fontSize: "12px", paddingTop: 3 }}>Rechnungsnummer ist bereits vergeben</span>
                      </div>
                    )
                  ) : null}

                </div>
              </Grid>


              {!disabledEditing &&
                <Grid container item alignContent="center">
                  <span
                    style={{
                      display: "block",
                      paddingTop: 15,
                      // paddingBottom: 5,
                      fontSize: '14px',
                      color: theme.palette.primary.main,
                      fontWeight: 300,
                      alignItems: 'center',
                      whiteSpace: 'nowrap',
                      cursor: 'pointer',
                      marginRight: 20
                    }}
                    onClick={handleClick}
                  >
                    <IconButton
                      disabled={isLoading}
                      aria-label="settings"
                      size="small"
                      style={{
                        marginTop: -3,
                        zoom: 0.6,
                        color: theme.palette.primary.main,
                        marginRight: 5,
                      }}
                    >
                      <SettingsIcon />
                    </IconButton>
                    Schema ändern
                  </span>
                </Grid>
              }

              {(hasInvoiceNumberChanged && !values.recurring) &&
                <Grid container alignContent="center">
                  <div
                    onClick={() => setSaveNewInvoiceSchema(!saveNewInvoiceSchema)}
                    style={{
                      display: 'flex',
                      alignItems: 'center', // Vertically center the items
                      cursor: 'pointer',
                      paddingTop: 5,
                      paddingBottom: 5,
                      fontSize: '14px',
                      fontWeight: 'bold',
                      whiteSpace: 'nowrap',
                      marginTop: -5,
                    }}
                  >
                    <Checkbox
                      checked={saveNewInvoiceSchema}
                      onChange={() => setSaveNewInvoiceSchema(!saveNewInvoiceSchema)}
                      disabled={isLoading}
                      color="primary"
                      inputProps={{ 'aria-label': 'Apply schema to future invoices' }}
                      style={{
                        marginRight: 5,
                        marginLeft: -8,
                        marginTop: 4,
                        display: 'flex',
                        alignItems: 'center',
                      }}
                      onClick={(e) => e.stopPropagation()} // Prevents the parent div from also triggering
                    />
                    <span style={{ fontSize: 14, fontWeight: 300, lineHeight: 'normal', marginLeft: -2, marginTop: 5 }}>Neues Schema auf alle zukünftigen Rechnungen anwenden</span>
                  </div>
                </Grid>

              }
            </Grid>
          }
        </Grid>
      </Grid>
}

      <Menu
        id="invoiceNr-menuDots"
        anchorEl={anchorEl}
        open={openInvoiceNrMenuDots}
        onClose={handleClose}
        PaperProps={{
          elevation: 1,
        }}
      >
        <Typography
          style={{
            marginLeft: '15px',
            fontWeight: 'bold',
            fontSize: '15px',
            marginRight: '5px',
          }}
        >
          Rechnungsnummer Schema
        </Typography>
        <MenuItem
          style={{ fontSize: '13px', marginRight: '5px' }}
          onClick={() => setAddElementOpen(!addElementOpen)}
        >
          <AddCircleIcon fontSize="small" style={{ color: theme.palette.primary.main, marginRight: '5px' }} />
          {menuLabels.addElement}
          {!addElementOpen ? (
            <ExpandMore fontSize="small" style={{ color: theme.palette.primary.main, marginLeft: '5px' }} />
          ) : (
            <ExpandLess fontSize="small" style={{ color: theme.palette.primary.main, marginLeft: '5px' }} />
          )}
        </MenuItem>

        <Collapse in={addElementOpen} timeout="auto" unmountOnExit>
          <div style={{ marginLeft: '25px' }}>
            <MenuItem
              style={{ fontSize: '13px', marginRight: '5px' }}
              onClick={() => {
                setValues({
                  ...values,
                  invoiceNr: JSON.stringify([
                    ...JSON.parse(values.invoiceNr),
                    {
                      id: (JSON.parse(values.invoiceNr)).reduce(
                        (acc, item) => Math.max(acc, item.id),
                        0
                      ) + 1,
                      type: 'text',
                      value: '',
                    },
                  ]),
                });
                handleClose();
                setAddElementOpen(false);
              }}
            >
              <TextFieldsIcon fontSize="small" style={{ color: theme.palette.primary.main, marginRight: '5px' }} />
              {menuLabels.addElementText}
            </MenuItem>
          </div>

          {!JSON.parse(values.invoiceNr).some((item) => item.type === 'year') && (
            <div style={{ marginLeft: '25px' }}>
              <MenuItem
                style={{ fontSize: '13px', marginRight: '5px' }}
                onClick={() => {
                  setValues({
                    ...values,
                    invoiceNr: JSON.stringify([
                      ...JSON.parse(values.invoiceNr),
                      {
                        id: (JSON.parse(values.invoiceNr)).reduce(
                          (acc, item) => Math.max(acc, item.id),
                          0
                        ) + 1,
                        type: 'year',
                        value: RETURN_YEAR(values.invoiceDate),
                      },
                    ]),
                  });
                  handleClose();
                  setAddElementOpen(false);
                }}
              >
                <TodayIcon fontSize="small" style={{ color: theme.palette.primary.main, marginRight: '5px' }} />
                {values.type === 'invoice' || values.type === 'reminder' || values.type === 'reminderRequest'
                  ? menuLabels.yearLabelInvoice
                  : menuLabels.yearLabelEstimate}
              </MenuItem>
            </div>
          )}

          {!JSON.parse(values.invoiceNr).some((item) => item.type === 'month') && (
            <div style={{ marginLeft: '25px' }}>
              <MenuItem
                style={{ fontSize: '13px', marginRight: '5px' }}
                onClick={() => {
                  setValues({
                    ...values,
                    invoiceNr: JSON.stringify([
                      ...JSON.parse(values.invoiceNr),
                      {
                        id: (JSON.parse(values.invoiceNr)).reduce(
                          (acc, item) => Math.max(acc, item.id),
                          0
                        ) + 1,
                        type: 'month',
                        value: RETURN_MONTH(values.invoiceDate),
                      },
                    ]),
                  });
                  handleClose();
                  setAddElementOpen(false);
                }}
              >
                <TodayIcon fontSize="small" style={{ color: theme.palette.primary.main, marginRight: '5px' }} />
                {values.type === 'invoice' || values.type === 'reminder' || values.type === 'reminderRequest'
                  ? menuLabels.monthLabelInvoice
                  : menuLabels.monthLabelEstimate}
              </MenuItem>
            </div>
          )}

          {!JSON.parse(values.invoiceNr).some((item) => item.type === 'day') && (
            <div style={{ marginLeft: '25px' }}>
              <MenuItem
                style={{ fontSize: '13px', marginRight: '5px' }}
                onClick={() => {
                  setValues({
                    ...values,
                    invoiceNr: JSON.stringify([
                      ...JSON.parse(values.invoiceNr),
                      {
                        id: (JSON.parse(values.invoiceNr)).reduce(
                          (acc, item) => Math.max(acc, item.id),
                          0
                        ) + 1,
                        type: 'day',
                        value: RETURN_DAY(values.invoiceDate),
                      },
                    ]),
                  });
                  handleClose();
                  setAddElementOpen(false);
                }}
              >
                <TodayIcon fontSize="small" style={{ color: theme.palette.primary.main, marginRight: '5px' }} />
                {values.type === 'invoice' || values.type === 'reminder' || values.type === 'reminderRequest'
                  ? menuLabels.dayLabelInvoice
                  : menuLabels.dayLabelEstimate}
              </MenuItem>
            </div>
          )}
        </Collapse>

        <MenuItem
          disabled={!JSON.parse(values.invoiceNr).length > 1}
          style={{ fontSize: '13px', marginRight: '5px' }}
          onClick={() => {
            setOpenInvoiceNumberEdit(true);
            setValues((prevValues) => ({
              ...prevValues,
              invoiceNr: JSON.stringify(JSON.parse(prevValues.invoiceNr)),
            }));
            handleClose();
          }}
        >
          <CompareArrowsIcon fontSize="small" style={{ color: theme.palette.primary.main, marginRight: '5px' }} />
          {menuLabels.arrangeRemoveElements}
        </MenuItem>
      </Menu>
    </>
  );
};

export default InvoiceNr;
