import React, { useState, useContext, useEffect } from "react";
// IMPORT hooks & context
import { useTranslation } from "react-i18next";
import { useHttpClient } from "../../shared/hooks/http-hook";
import { AuthContext } from "../../shared/context/auth-context";

// IMPORT recoil
import { useRecoilState } from "recoil";
import invoicesAtomState from "../../_atoms/invoicesAtom";

// IMPORT components MATERIAL UI
import {
  IconButton,
  Menu,
  MenuItem,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Slide,
  Modal,
  Typography,
  ListItemIcon,
  ListItemText,
  FormControl,
  FormControlLabel,
  Switch
} from "@material-ui/core";

// IMPORT icons MATERIAL UI
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import EditIcon from "@material-ui/icons/Edit";
import VisibilityIcon from "@material-ui/icons/Visibility";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import CancelPresentationIcon from "@material-ui/icons/CancelPresentation";
import CloseIcon from "@material-ui/icons/Close";
import GetAppIcon from '@material-ui/icons/GetApp';
import ReplayIcon from '@material-ui/icons/Replay';
import MailIcon from '@material-ui/icons/Mail';
import ExposureIcon from '@material-ui/icons/Exposure';

import { MuiPickersUtilsProvider, KeyboardDatePicker } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import de from "date-fns/locale/de";

// IMPORT functions
import { DB_DELETE_INVOICE } from "./../../_functions/DB_INVOICES";

// IMPORT components
import { useSnackbar } from "notistack";
import ConfirmModal from "../../shared/components/UIElements/Modal";
import { CheckCircleOutline, CheckCircle, DeleteForever } from "@material-ui/icons";


const ITEM_HEIGHT = 48;

export default function InvoicesMenuDots({
  invoice,
  setOpenInvoiceDialogAtom,
  openInvoiceDialogAtom,
  recurring,
  handleSearchClear,
  openPayMenu,
  setInvoicePayed,
  setSelectedInvoice,
  checkedItems,
  setCheckedItems,
  setDataInitial,
}) {
  // HOOKS & CONTEXT
  const { t } = useTranslation();
  const auth = useContext(AuthContext);
  const { sendRequest } = useHttpClient();
  const { enqueueSnackbar } = useSnackbar();

  // GLOBAL STATE (RECOIL)
  const [invoicesAtom, setInvoicesAtom] = useRecoilState(invoicesAtomState);

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const downloadPdf = async () => {
    try {
      const formData = new FormData();
      formData.append("userId", auth.userId);
      formData.append("invoiceId", invoice._id);

      const response = await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL_API}/invoices/downloadinvoice`,
        "POST",
        formData,
        {
          Authorization: "Bearer " + auth.token,
        }
      );

      const base64String = response.pdfBase64;
      
      // Convert base64 to Blob
      const byteCharacters = atob(base64String);
      const byteNumbers = new Array(byteCharacters.length).fill().map((_, i) => byteCharacters.charCodeAt(i));
      const byteArray = new Uint8Array(byteNumbers);
      const blob = new Blob([byteArray], { type: 'application/pdf' });

      // Create a download link and trigger the download
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = response.filename; // Set the filename here
      link.click();  // Trigger the download
    } catch (error) {
      console.error('Error fetching the PDF:', error);
    }
  };

  const handleClick = (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
    setCheckedItems([invoice._id])
  };

  const handleClose = (event) => {
    event.stopPropagation();
    setAnchorEl(null);
    setCheckedItems([])
  };

  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [showConfirmModalDuplicate, setShowConfirmModalDuplicate] = useState(false);
  const [showConfirmModalCancel, setShowConfirmModalCancel] = useState(false);
  const [showConfirmModalCancelWithCredit, setShowConfirmModalCancelWithCredit] = useState(false);

  const [isPayed, setIsPayed] = useState(true)
  const [showConfirmModalPay, setShowConfirmModalPay] = useState(false);
  const [payMenuValues, setPayMenuValues] = useState({ payDate: invoice.payDate, payState: "payed" });
  const [payDateError, setPayDateError] = useState("");

  const validateDate = (selectedDate, invoiceDate) => {
    if (!selectedDate) {
      setPayDateError("Bitte ausfüllen");
      return false;
    }

    if (isNaN(new Date(selectedDate).getTime())) {
      setPayDateError("Bitte ausfüllen");
      return false;
    }

    setPayDateError(""); // Clear error if all validations pass
    return true;
  };

  const handleDateChange = (date, invoiceDate) => {
    setPayMenuValues((prev) => ({ ...prev, payDate: date }));
    validateDate(date, invoiceDate);
  };


  const showDeleteWarningHandler = () => {
    setShowConfirmModal(true);
  };

  const cancelDeleteHandler = (event) => {
    event.stopPropagation();
    setShowConfirmModal(false);
  };

  const confirmDeleteHandler = (invoice, event) => {
    event.stopPropagation();
    DB_DELETE_INVOICE(
      invoice,
      auth,
      sendRequest,
      enqueueSnackbar,
      t,
      invoicesAtom,
      setInvoicesAtom,
      openInvoiceDialogAtom,
      setOpenInvoiceDialogAtom,
      setShowConfirmModal,
      handleSearchClear
    );
  };

  const duplicateInvoice = async (invoiceId) => {
    try {
      const formData = new FormData();
      formData.append("invoiceId", invoiceId);

      const url = recurring
        ? `${process.env.REACT_APP_BACKEND_URL_API}/invoicesrecurrings/duplicate`
        : `${process.env.REACT_APP_BACKEND_URL_API}/invoices/duplicate`;

      const response = await sendRequest(url, "POST", formData, {
        Authorization: "Bearer " + auth.token,
      });

      // setOpenInvoiceDialogAtom((prevState) => ({
      //   ...prevState,
      //   open: true,
      //   invoiceId: response.invoiceId,
      //   recurring: recurring,
      //   duplicate: true,
      //   type: "invoice",
      // }));
      handleSearchClear();
      enqueueSnackbar("Rechnung wurde dupliziert", { variant: "success" });
    } catch (error) {
      console.error("Failed to copy invoice:", error);
    }
  };

  const cancelInvoice = async (invoiceId, cancel) => {
    try {
      const formData = new FormData();
      formData.append("invoiceId", invoiceId);

      const response = await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL_API}/invoices/cancel`,
        "POST",
        formData,
        {
          Authorization: "Bearer " + auth.token,
        }
      );

      {cancel ?
        enqueueSnackbar("Rechnung wurde storniert", { variant: "success" })
        : enqueueSnackbar("Stornierung wurde aufgehoben", { variant: "success" })
      };
      handleSearchClear();
    } catch (error) {
      console.error("Failed to cancel invoice:", error);
    }
  };

  const cancelInvoiceWithCredit = async (invoiceId, cancel) => {
    try {
      const formData = new FormData();
      formData.append("invoiceId", invoiceId);

      const response = await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL_API}/invoices/cancel`,
        "POST",
        formData,
        {
          Authorization: "Bearer " + auth.token,
        }
      );

      enqueueSnackbar("Rechnung storniert und Gutschrift erstellt", { variant: "success" })
      handleSearchClear();
    } catch (error) {
      console.error("Failed to cancel invoice:", error);
    }
  };


  return (
    <>
      <IconButton
        aria-label="more"
        aria-controls={invoice.id}
        aria-haspopup="true"
        color="primary"
        onClick={(e) => {
          handleClick(e);
          e.stopPropagation();
          setOpenInvoiceDialogAtom({ ...openInvoiceDialogAtom, open: false })
        }}
        style={{ marginRight: "5px" }}
      >
        <MoreHorizIcon />
      </IconButton>
      <Menu
        id={invoice.id}
        anchorEl={anchorEl}
        open={open}
        onClose={(e) => {
          handleClose(e);
          e.stopPropagation();
          setOpenInvoiceDialogAtom({ ...openInvoiceDialogAtom, open: false })
          setCheckedItems([])
        }}
        PaperProps={{
          elevation: 1,
          style: {
            // maxHeight: ITEM_HEIGHT * 4.5,
            width: "auto",
          },
        }}
      >
        {/* Header with MoreHorizIcon and CloseIcon */}
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            padding: "5px 10px",
            borderBottom: "1px solid #ddd",
            cursor: "default", // Ensure the cursor does not indicate a clickable area
          }}
          onClick={(e) => e.stopPropagation()} // Prevent event propagation in the header
        >
          <IconButton
            style={{ marginRight: "auto" }}
            size="small"
            disabled
          >
            <MoreHorizIcon fontSize="small" color="primary" />
          </IconButton>
          <IconButton
            onClick={(e) => handleClose(e)}
            size="small"
          >
            <CloseIcon fontSize="small" color="primary"/>
          </IconButton>
        </div>

        {/* MENU ITEMS */}
        {/* Edit */}
        <MenuItem
          style={{ fontSize: "13px", marginLeft: -10 }}
          onClick={(e) => {
            setOpenInvoiceDialogAtom({
              ...openInvoiceDialogAtom,
              open: true,
              invoiceId: invoice.id,
              ...(invoice.recurring ? { recurring: true } : { recurring: false }),
            });
            handleClose(e);
          }}
        >
          <ListItemIcon>
                <VisibilityIcon color="primary" fontSize="small" style={{ marginRight: "5px" }} />
              </ListItemIcon>
          <ListItemText primary="Ansehen / Bearbeiten" />
        </MenuItem>

        {/* Download */}
        {!recurring &&
        <MenuItem
          style={{ fontSize: "13px", marginLeft: -10 }}
          onClick={(e) => {
            // setOpenInvoiceDialogAtom({
            //   ...openInvoiceDialogAtom,
            //   open: true,
            //   invoiceId: invoice.id,
            //   ...(invoice.recurring ? { recurring: true } : { recurring: false }),
            // });
            downloadPdf();
            handleClose(e);
          }}
        >
          <ListItemIcon>
                <GetAppIcon color="primary" fontSize="small" style={{ marginRight: "5px" }} />
              </ListItemIcon>
          <ListItemText primary="PDF Herunterladen" />
        </MenuItem>
        }

        {/* Send */}
        {!recurring && invoice.sendState === "notSent" && invoice.payState !== "payed" &&
        <MenuItem
          style={{ fontSize: "13px", marginLeft: -10 }}
          onClick={(e) => {
            e.stopPropagation(); // Prevent parent click event
            setOpenInvoiceDialogAtom({
              ...openInvoiceDialogAtom,
              recurring: false,
              open: true,
              invoiceId: invoice.id,
              isEstimate: false,
              sendMode: true,
            })
          }}
        >
          <ListItemIcon>
                <MailIcon color="primary" fontSize="small" style={{ marginRight: "5px" }} />
              </ListItemIcon>
          <ListItemText primary="Versenden" />
        </MenuItem>
        }
        
        {/* Duplicate */}
        <MenuItem 
          style={{ fontSize: "13px", marginLeft: -10 }}
          onClick={(e) => {
            setShowConfirmModalDuplicate(true);
            handleClose(e);
          }}
        >
          <ListItemIcon>
              <FileCopyIcon color="primary" fontSize="small" style={{ marginRight: "5px" }} />
            </ListItemIcon>
        <ListItemText primary="Duplizieren" />
        </MenuItem>

        {/* Set to payed */}
        {!recurring && !invoice.cancelled &&  invoice.sendState !== "scheduled" &&
        <MenuItem
            style={{ fontSize: "13px", marginLeft: -10 }}
            // onClick={(e) => {
            //   openPayMenu(e);
            //   setInvoicePayed(invoice._id)
            //   setSelectedInvoice(invoice)
            // }}
            onClick={(e) => {
              setShowConfirmModalPay(true)
              handleClose(e);
            }}
          >
            <ListItemIcon>
                <CheckCircle color="primary" fontSize="small" style={{ marginRight: "5px" }} />
              </ListItemIcon>
            <ListItemText primary={invoice.payState === "notPayed" ? 'Auf "Bezahlt" setzen' : "Zahldatum / Zahlstatus ändern"} />
        </MenuItem>
        }

        {/* Cancel */}
        {!recurring && invoice.sendState !== "scheduled" && (invoice.payState !== "payed" || (invoice.payState === "payed" && invoice.cancelled)) &&
        <MenuItem
          style={{ fontSize: "13px", marginLeft: -10 }}
          onClick={(e) => {
            setShowConfirmModalCancel(true)
            handleClose(e);
          }}
        >
          <ListItemIcon>
            {!invoice.cancelled ?
            <CancelPresentationIcon color="primary" fontSize="small" style={{ marginRight: "5px" }} />
            : <ReplayIcon color="primary" fontSize="small" style={{ marginRight: "5px" }} />
            }
          </ListItemIcon>
          <ListItemText primary={!invoice.cancelled ? "Stornieren" : "Stornierung aufheben"} />
        </MenuItem>
        }

        {/* Cancel with credit (with Stornorechnung)*/}
        {/* {invoice.payState === "payed" && invoice.cancelledRef === undefined && (
          <MenuItem
            style={{ fontSize: "13px", marginLeft: -10 }}
            onClick={(e) => {
              setShowConfirmModalCancelWithCredit(true)
              handleClose(e);
            }}
          >
            <ListItemIcon>
              <ExposureIcon color="primary" fontSize="small" style={{ marginRight: "5px" }} />
            </ListItemIcon>
            <ListItemText primary={"Stornorechnung erstellen"} />
          </MenuItem>
        )} */}

        {/* Delete */}
        {!recurring &&
        <MenuItem
          style={{ fontSize: "13px", marginLeft: -10 }}
          onClick={(e) => {
            e.stopPropagation();
            showDeleteWarningHandler(invoice.id);
            handleClose(e);
          }}
        >
          <ListItemIcon>
                <DeleteForeverIcon color="primary" fontSize="small" style={{ marginRight: "5px" }} />
              </ListItemIcon>
          <ListItemText primary="Löschen" />
        </MenuItem>
        }
      </Menu>

      {/* MODAL duplicate */}
      <Modal
        open={showConfirmModalDuplicate}
        onClick={(e) => {
          e.stopPropagation(); // Prevent backdrop click propagation
          setShowConfirmModalDuplicate(false)
        }}
      >
        <Dialog
          onClick={(e) => e.stopPropagation()}
          open={showConfirmModalDuplicate}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle id="alert-dialog-slide-title">{!recurring ? "Rechnung duplizieren?" : "Duplizieren?"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              {!recurring &&
              <>
              <span>Die bestehende Rechnung</span>
              <span style={{ marginLeft: 5, marginRight: 5 }}>
              {JSON.parse(invoice.invoiceNr).map((item, i) => (
                <span key={i} style={{ fontWeight: "500"}}>
                  {item.type === "continuousNumber" ? (
                    <span style={{
                      display: "inline-block",
                      background: "rgb(237, 239, 255)",
                      height: "19px",
                      color: "inherit",
                      paddingLeft: 3,
                      paddingRight: 3,
                      borderRadius: "3px",
                      marginTop: 1.2,
                      marginLeft: "1px",
                      marginRight: "1px",
                      fontWeight: "500"
                    }}>{item.value}</span>
                  ) : (
                    <span>{item.value}</span>
                  )}
                </span>
              ))}
              </span>
              <span>mit allen Inhalten kopieren. Die duplizierte Rechnung hat folgende (anpassbare) Eigenschaften:</span>
              <ul style={{paddingLeft: 19, fontWeight: 500, color: "rgb(44, 83, 125)"}}>
                <li><span>Status: Entwurf</span></li>
                <li><span>Rechnungsdatum entspricht aktuellem Datum</span></li>
                <li><span>Neue fortlaufende Nummer</span></li>
              </ul>
              </>
              }

              {recurring &&
              <span>Die bestehende wiederkehrende Rechnungslegung kopieren. Das Duplikat bekommt den Status <span style={{fontWeight: 500, color: "rgb(44, 83, 125)"}}>"Entwurf"</span>.</span>
              }
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              size="small"
              variant="outlined"
              color="primary"
              style={{ marginRight: "10px" }}
              onClick={() => setShowConfirmModalDuplicate(false)}
            >
              {t("BUTTONS.cancel")}
            </Button>
            <Button
              size="small"
              variant="contained"
              color="primary"
              onClick={(e) => duplicateInvoice(invoice.id)}
              startIcon={<DeleteForeverIcon />}
            >
              Duplizieren
            </Button>
          </DialogActions>
        </Dialog>
      </Modal>

      {/* MODAL delete */}
      <Modal
        open={showConfirmModal}
        onClick={(e) => {
          e.stopPropagation(); // Prevent backdrop click propagation
          cancelDeleteHandler(e);
        }}
      >
        <Dialog
          onClick={(e) => e.stopPropagation()}
          open={showConfirmModal}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle id="alert-dialog-slide-title">Rechnung löschen?</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              <span style={{ fontWeight: 500, color: "red" }}>Das Löschen einer Rechnung kann nicht mehr rückgängig gemacht werden.</span><br /><br />Soll diese Rechnung mit der Rechnungsnummer
              {JSON.parse(invoice.invoiceNr).map((item, i) => (
                <span key={i} style={{ fontWeight: "500" }}>
                  {item.type === "continuousNumber" ? (
                    <span style={{
                      display: "inline-block",
                      background: "rgb(237, 239, 255)",
                      height: "19px",
                      color: "inherit",
                      paddingLeft: 3,
                      paddingRight: 3,
                      borderRadius: "3px",
                      marginTop: 1.2,
                      marginLeft: "1px",
                      marginRight: "1px",
                      fontWeight: "500"
                    }}>{item.value}</span>
                  ) : (
                    <span>{item.value}</span>
                  )}
                </span>
              ))} endgültig gelöscht werden?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              size="small"
              variant="outlined"
              color="primary"
              style={{ marginRight: "10px" }}
              onClick={(e) => cancelDeleteHandler(e)}
            >
              {t("BUTTONS.cancel")}
            </Button>
            <Button
              size="small"
              variant="contained"
              color="primary"
              onClick={(e) => confirmDeleteHandler(invoice.id, e)}
              startIcon={<DeleteForeverIcon />}
            >
              Ja, Endgültig löschen
            </Button>
          </DialogActions>
        </Dialog>
      </Modal>

      {/* MODAL cancel */}
      <Modal
        open={showConfirmModalCancel}
        onClick={(e) => {
          e.stopPropagation(); // Prevent backdrop click propagation
          setShowConfirmModalCancel(false)
        }}
      >
        <Dialog
          onClick={(e) => e.stopPropagation()}
          open={showConfirmModalCancel}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle id="alert-dialog-slide-title">{!invoice.cancelled ? "Rechnung stornieren?" : "Stornierung aufheben?"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              {!invoice.cancelled &&
              <>
              Soll die Rechnung mit der Rechnungsnummer
              {JSON.parse(invoice.invoiceNr).map((item, i) => (
                <span key={i} style={{ fontWeight: "500" }}>
                  {item.type === "continuousNumber" ? (
                    <span style={{
                      display: "inline-block",
                      background: "rgb(237, 239, 255)",
                      height: "19px",
                      color: "inherit",
                      paddingLeft: 3,
                      paddingRight: 3,
                      borderRadius: "3px",
                      marginTop: 1.2,
                      marginLeft: "1px",
                      marginRight: "1px",
                      fontWeight: "500"
                    }}>{item.value}</span>
                  ) : (
                    <span>{item.value}</span>
                  )}
                </span>
              ))} storniert werden?
              </>
              }

              {invoice.cancelled &&
              <>
              Soll die Stornierung der Rechnung mit der Rechnungsnummer
              {JSON.parse(invoice.invoiceNr).map((item, i) => (
                <span key={i} style={{ fontWeight: "500" }}>
                  {item.type === "continuousNumber" ? (
                    <span style={{
                      display: "inline-block",
                      background: "rgb(237, 239, 255)",
                      height: "19px",
                      color: "inherit",
                      paddingLeft: 3,
                      paddingRight: 3,
                      borderRadius: "3px",
                      marginTop: 1.2,
                      marginLeft: "1px",
                      marginRight: "1px",
                      fontWeight: "500"
                    }}>{item.value}</span>
                  ) : (
                    <span>{item.value}</span>
                  )}
                </span>
              ))} aufgehoben werden?
              </>
              }
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              size="small"
              variant="outlined"
              color="primary"
              style={{ marginRight: "10px" }}
              onClick={(e) => setShowConfirmModalCancel(false)
              }
            >
              {t("BUTTONS.cancel")}
            </Button>
            <Button
              size="small"
              variant="contained"
              color="primary"
              onClick={(e) => cancelInvoice(invoice.id, !invoice.cancelled ? true : false)}
              startIcon={!invoice.cancelled ? <DeleteForeverIcon /> : <ReplayIcon />}
            >
              {!invoice.cancelled ? "Stornieren" : "Stornierung aufheben"}
            </Button>
          </DialogActions>
        </Dialog>
      </Modal>

      {/* MODAL cancel with credit */}
      <Modal
        open={showConfirmModalCancelWithCredit}
        onClick={(e) => {
          e.stopPropagation(); // Prevent backdrop click propagation
          setShowConfirmModalCancelWithCredit(false)
        }}
      >
        <Dialog
          onClick={(e) => e.stopPropagation()}
          open={showConfirmModalCancelWithCredit}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle id="alert-dialog-slide-title">Stornorechnung erstellen?</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              {!invoice.cancelled &&
              <>
              Soll eine Stornorechnung zur Rechnung mit der Rechnungsnummer
              {JSON.parse(invoice.invoiceNr).map((item, i) => (
                <span key={i} style={{ fontWeight: "500" }}>
                  {item.type === "continuousNumber" ? (
                    <span style={{
                      display: "inline-block",
                      background: "rgb(237, 239, 255)",
                      height: "19px",
                      color: "inherit",
                      paddingLeft: 3,
                      paddingRight: 3,
                      borderRadius: "3px",
                      marginTop: 1.2,
                      marginLeft: "1px",
                      marginRight: "1px",
                      fontWeight: "500"
                    }}>{item.value}</span>
                  ) : (
                    <span>{item.value}</span>
                  )}
                </span>
              ))} erstellt werden?
              
              <span><br/><br/><strong>Die Stornorechnung:</strong></span>
              <ul style={{paddingLeft: 19, fontWeight: 500, color: "rgb(44, 83, 125)"}}>
                <li><span>Erhält eine neue Rechnungsnummer</span></li>
                <li><span>Enhält alle Angaben der ursprünglichen Rechnung, allerdings mit Minus-Beträgen</span></li>
              </ul>
              <span><strong>Hinweis:</strong> Die ursprüngliche Rechnung bleibt unverändert.</span>

              </>
              }
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              size="small"
              variant="outlined"
              color="primary"
              style={{ marginRight: "10px" }}
              onClick={(e) => setShowConfirmModalCancelWithCredit(false)
              }
            >
              {t("BUTTONS.cancel")}
            </Button>
            <Button
              size="small"
              variant="contained"
              color="primary"
              onClick={(e) => cancelInvoiceWithCredit(invoice.id)}
              startIcon={!invoice.cancelled ? <DeleteForeverIcon /> : <ReplayIcon />}
            >
              Stornorechnung erstellen
            </Button>
          </DialogActions>
        </Dialog>
      </Modal>

      {/* MODAL pay */}
      <Modal
        open={showConfirmModalPay}
        onClick={(e) => {
          e.stopPropagation(); // Prevent backdrop click propagation
          setShowConfirmModalPay(false)
        }}
      >
        <Dialog
          onClick={(e) => e.stopPropagation()}
          open={showConfirmModalPay}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
              <DialogTitle
      id="alert-dialog-slide-title"
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        marginRight: "40px" // Ensures the close icon doesn't overlap
      }}
    >
      <Typography variant="h6">
        {invoice.payState === "notPayed" ? 'Auf "Bezahlt" setzen' : "Zahldatum / Zahlstatus ändern"}
      </Typography>

      {/* Close Button - Positioned to the Right */}
      <IconButton
        aria-label="close"
        onClick={() => setShowConfirmModalPay(false)}
        style={{
          position: "absolute",
          right: 8,
          top: 8,
        }}
      >
        <CloseIcon />
      </IconButton>
    </DialogTitle>
          <DialogContent>
                    <FormControlLabel
                      control={
            
                          <span> {/* Necessary to fix tooltip positioning */}
                            <Switch
                              checked={isPayed}
                              onChange={()=>setIsPayed(!isPayed)}
                              onClick={(e) => e.stopPropagation()} // Prevent modal from closing
                              color="primary"
                            />
                          </span>
                      }
                      label={
                          <span style={{ fontSize: 14, cursor: 'pointer' }}>Bezahlt</span>
                      }
                    />
            <DialogContentText id="alert-dialog-slide-description">
                      {isPayed &&
                      <Typography
                        variant="body2"
                        style={{ fontSize: 14, margin: "16px 0" }}
                      >
                        Datum wählen und speichern.<br /><br />
                        {invoice.payState === "notPayed" && <span>Die Rechnung
                        <span style={{marginLeft: 3, marginRight: 3}}>
                        {JSON.parse(invoice.invoiceNr).map((item, i) => (
                          <span key={i} style={{fontWeight: "500" }}>
                            {item.type === "continuousNumber" ? (
                              <span
                                style={{
                                  display: "inline-block",
                                  background: "rgb(237, 239, 255)",
                                  height: "19px",
                                  color: "inherit",
                                  paddingLeft: 3,
                                  paddingRight: 3,
                                  borderRadius: "3px",
                                  marginTop: 1.2,
                                  marginLeft: "1px",
                                  marginRight: "1px",
                                  fontWeight: "500",
                                }}
                              >
                                {item.value}
                              </span>
                            ) : (
                              <span>{item.value}</span>
                            )}
                          </span>
                        ))}
                        </span>
                        <br />
                        wird damit auf "bezahlt" gesetzt.
                        </span>}
              
                        {invoice.payState === "payed" && <span>Das Bezahldatum der Rechnung
                        <span style={{marginLeft: 3, marginRight: 3}}>
                        {JSON.parse(invoice.invoiceNr).map((item, i) => (
                          <span key={i} style={{fontWeight: "500" }}>
                            {item.type === "continuousNumber" ? (
                              <span
                                style={{
                                  display: "inline-block",
                                  background: "rgb(237, 239, 255)",
                                  height: "19px",
                                  color: "inherit",
                                  paddingLeft: 3,
                                  paddingRight: 3,
                                  borderRadius: "3px",
                                  marginTop: 1.2,
                                  marginLeft: "1px",
                                  marginRight: "1px",
                                  fontWeight: "500",
                                }}
                              >
                                {item.value}
                              </span>
                            ) : (
                              <span>{item.value}</span>
                            )}
                          </span>
                        ))}
                        </span>
                        <br />
                        wird dadurch entsprechend geändert.
                        </span>}
                      </Typography>
                      }
              
                      {isPayed &&
                      <MuiPickersUtilsProvider locale={de} utils={DateFnsUtils}>
                        <KeyboardDatePicker
                          fullWidth
                          inputVariant="filled"
                          id="payDate"
                          format="dd.MM.yyyy"
                          placeholder="DD.MM.YYYY"
                          label="Bezahldatum"
                          value={payMenuValues.payDate}
                          error={!!payDateError}
                          helperText={payDateError || " "}
                          onChange={(date) => handleDateChange(date, invoice.invoiceDate)}
                          KeyboardButtonProps={{
                            "aria-label": "change date",
                            style: { color: "rgb(44, 83, 125)" }
                          }}
                          okLabel="OK"
                          cancelLabel="Abbrechen"
                        />
                      </MuiPickersUtilsProvider>
                      }
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              size="small"
              variant="outlined"
              color="primary"
              style={{ marginRight: "10px" }}
              onClick={(e) => setShowConfirmModalPay(false)
              }
            >
              {t("BUTTONS.cancel")}
            </Button>
            <Button
              size="small"
              variant="contained"
              color="primary"
              // onClick={(e) => cancelInvoiceWithCredit(invoice.id)}
              onClick={async (e) => {
                e.stopPropagation(); // Prevent parent click event
  
                if (!payMenuValues.payDate && isPayed) {
                  return;
                }

                if (!payMenuValues.payDate && !isPayed) {
                  setPayMenuValues({...payMenuValues, payDate: new Date().toString()})
                  return;
                }
  
                // Update local state
                setDataInitial((prevData) =>
                  prevData.map((item) =>
                    item._id === invoice._id
                      ? {
                        ...item,
                        payState: isPayed ? "payed" : "notPayed", // Update payState
                        payDate: payMenuValues.payDate.toString(), // Apply selected date
                      }
                      : item
                  )
                );
  
                // Prepare FormData for the PATCH request
                const formData = new FormData();
                formData.append("payState", isPayed ? "payed" : "notPayed");
                formData.append("payDate", payMenuValues.payDate.toString());
  
                try {
                  // Send PATCH request to update the backend
                  const url = `${process.env.REACT_APP_BACKEND_URL_API}/invoices/${invoice._id}`;
                  const method = "PATCH";
  
                  await sendRequest(
                    url,
                    method,
                    formData,
                    {
                      Authorization: "Bearer " + auth.token,
                    }
                  );
                  setShowConfirmModalPay(false);
                  enqueueSnackbar('Rechnung wurde auf "Bezahlt" gesetzt.', { variant: "success" });
                } catch (error) {
                  enqueueSnackbar('Änderungen konnten nicht gespeichert werden', { variant: "error" });
                }
              }}
              startIcon={!invoice.cancelled ? <DeleteForeverIcon /> : <ReplayIcon />}
            >
              Speichern
            </Button>
          </DialogActions>
        </Dialog>
      </Modal>
    </>
  );
}
