import { atom } from "recoil";
import { recoilPersist } from 'recoil-persist'

const { persistAtom } = recoilPersist()

const settingsTabAtom = atom({
  key: "settingsTab",
  default: 0,
  effects_UNSTABLE: [persistAtom],
});

export default settingsTabAtom