import React, { useEffect, useState, useContext, useRef } from "react";

// IMPORT hooks & context
import { useTranslation } from "react-i18next";
import { useHttpClient } from "../shared/hooks/http-hook";
import { AuthContext } from "../shared/context/auth-context";

// IMPORT recoil
import {
  useRecoilState,
} from 'recoil';
import userAtomState from "../_atoms/userAtom";
import clientsAtomState from "../_atoms/clientsAtom";
import openInvoiceDialogAtomState from "../_atoms/openInvoiceDialogAtom";
import settingsAtomState from "../_atoms/settingsAtom";
import recurringActivatedAtomState from "../_atoms/recurringActivatedAtom";
import allInvoicesByClientAtomState from "../_atoms/allInvoicesByClientAtom"
import allInvoicesByOriginalRefAtomState from "../_atoms/allInvoicesByOriginalRefAtom";
import openProductDialogAtomState from "../_atoms/openProductDialogAtom";

import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import GetAppIcon from '@material-ui/icons/GetApp';

import ProductIconWhite from '../_assets/icon_product_white.svg';
import ProductIconDark from '../_assets/icon_product_dark.svg';


// IMPORT components
import { useSnackbar } from 'notistack';

// IMPORT components MATERIAL UI
import {
  Typography,
  Box,
  Container,
  Grid,
  Tooltip,
  Button,
  Checkbox,
  FormControlLabel,
  Icon
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useTheme } from '@material-ui/core/styles';
import Fab from '@material-ui/core/Fab';
import Hidden from '@material-ui/core/Hidden';

// IMPORT icons MATERIAL UI
import ClientsIcon from "@material-ui/icons/RecentActors";
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';
import FilterListIcon from '@material-ui/icons/FilterList';
import ImportExportIcon from '@material-ui/icons/ImportExport';
import EditIcon from '@material-ui/icons/Edit';
import ScheduleIcon from '@material-ui/icons/Schedule';
import MailIcon from '@material-ui/icons/Mail';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import AddIcon from '@material-ui/icons/Add';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import GavelIcon from '@material-ui/icons/Gavel';
import SearchIcon from '@material-ui/icons/Search';
import ErrorIcon from '@material-ui/icons/Error';
import WatchLaterIcon from '@material-ui/icons/WatchLater';
import RepeatIcon from '@material-ui/icons/Repeat';
import PlaylistAddCheckIcon from '@material-ui/icons/PlaylistAddCheck';
import SubjectIcon from '@material-ui/icons/Subject';
import RotateLeftIcon from '@material-ui/icons/RotateLeft';
import InfoIcon from '@material-ui/icons/Info';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import CancelPresentationIcon from '@material-ui/icons/CancelPresentation';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import CancelIcon from '@material-ui/icons/Cancel';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import EuroSymbolIcon from '@material-ui/icons/EuroSymbol';
import CheckIcon from '@material-ui/icons/Check';
import SaveIcon from "@material-ui/icons/Save";



import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Popover from '@material-ui/core/Popover';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { de } from 'date-fns/locale';

// IMPORT own functions
import { SET_VALUE } from '../_functions/SET_VALUE';
import { DB_PATCH_SETTINGS } from '../_functions/DB_SETTINGS';
import { RETURN_DATE } from '../_functions/DATES';
import { RETURN_HOURS } from '../_functions/DATES';
import { RETURN_OVERDUE_DAYS } from '../_functions/DATES';

// IMPORT own components
import UserProfileIncompleteWarning from "../user/components/UserProfileIncompleteWarning";
import ProductsSearch from "./components/ProductsSearch";
import ProductsSort from "./components/ProductsSort";
// import InvoicesSettings from "./components/InvoicesSettings";
import ProductsMenuDots from "./components/ProductsMenuDots";
import LogoMonogram from './../_assets/logo_monogram.svg';
// import DateRange from "../shared/components/DateRange/DateRange";
import ProductsBulkEdit from "./components/ProductsBulkEdit";
// import RecurringRefDialog from './components/RecurringRefDialog'
// import PayMenuPopover from "./components/PayMenuPopover";

// IMPORT own CSS
import "./Products.scss"
import { Euro, FlashOnTwoTone } from "@material-ui/icons";

// Ant and moment
import { ConfigProvider, DatePicker, TimePicker, Space } from 'antd';
import deDE from 'antd/es/locale/de_DE';
import dayjs from 'dayjs';
import 'dayjs/locale/de';

dayjs.locale('de'); // Set German locale for dayjs

const useStyles = makeStyles((theme) => ({
  // necessary for content to be below app bar
  toolbar: {
    height: '0px',
    '@media (max-width: 959px)': {
      minHeight: '45px',
      height: '45px',
    },
    '@media (max-width: 599px)': {
      minHeight: '45px',
      height: '45px',
    },
    '@media (max-width: 455px)': {
      minHeight: '45px',
      height: '45px',
    },
  },
  table: {
    minWidth: 650,
  },
}));


const Products = ({ type }) => {
  // HOOKS & CONTEXT
  const { t } = useTranslation();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();
  const { isLoading, error, sendRequest } = useHttpClient();
  const auth = useContext(AuthContext);


  // GLOBAL STATE (RECOIL)
  const [userAtom] = useRecoilState(userAtomState);
  const [openInvoiceDialogAtom, setOpenInvoiceDialogAtom] = useRecoilState(openInvoiceDialogAtomState);
  const [clientsAtom, setClientsAtom] = useRecoilState(clientsAtomState);
  const [recurringActivatedAtom, setRecurringActivatedAtom] = useRecoilState(recurringActivatedAtomState);
  const [settingsAtom, setSettingsAtom] = useRecoilState(settingsAtomState);
  const [allInvoicesByClientAtom, setAllInvoicesByClientAtom] = useRecoilState(allInvoicesByClientAtomState);
  const [allInvoicesByOriginalRefAtom, setAllInvoicesByOriginalRefAtom] = useRecoilState(allInvoicesByOriginalRefAtomState);
  const [openProductDialogAtom, setOpenProductDialogAtom] = useRecoilState(openProductDialogAtomState);


  // LOCAL STATE (GLOBAL DEPENDENCY monitoring temporay CHANGES until SAVED)
  const [settings, setSettings] = useState(settingsAtom);
  const [checkedItems, setCheckedItems] = useState([]);
  const [shownInvoices, setShownInvoices] = useState(0);
  const [totalInvoices, setTotalInvoices] = useState(0);
  const [selectedInvoice, setSelectedInvoice] = useState(null);
  const [bulkEdit, setBulkEdit] = useState(false);
  const [categorySuggestions, setCategorySuggestions] = useState(["general"]);
  
  
  const handlePopoverOpen = (event, invoice) => {
    setPayMenuAnchorEl(event.currentTarget);
    setSelectedInvoice(invoice); // Set the clicked invoice
  };


  // LOCAL STATE (PURE)
  const [recurring, setRecurring] = useState(false);
  const [values, setValues] = useState({
    submenuOpen: false, // values: false, search, filter, sort, settings
    invoicesFiltered: {},
    searchInput: "",
    chipDirectChange: false,
    save: false,
    loading: true,
  });
  const [recurringRef, setRecurringRef] = useState([]);
  const [recurringRefDialogOpen, setRecurringRefDialogOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [invoicePayed, setInvoicePayed] = useState("");


  // Data variable
  const [dataInitial, setDataInitial] = useState([]);

  // Infinite Scroll Variables
  const INITIAL_ITEMS = 20; // Load 10 items initially
  const [offset, setOffset] = useState(0); // Track the current offset for fetching
  const [hasMoreInvoices, setHasMoreInvoices] = useState(true); // To know if more invoices exist
  const [isFetching, setIsFetching] = useState(false); // Track if a fetch is in progress

  // Search Variables
  const [searchTerm, setSearchTerm] = useState(
    allInvoicesByClientAtom !== "" ? `${allInvoicesByClientAtom}` :
      allInvoicesByOriginalRefAtom !== "" ? `${allInvoicesByOriginalRefAtom}` :
        ""
  );

  /***
  FETCH
  ***/
  const fetchData = async (currentOffset, limit, isInitialLoad = false, searchTerm = "", newRecurring) => {
    try {
      const storedUserData = localStorage.getItem('userData');
      if (!storedUserData) {
        throw new Error('No user data found in local storage');
      }

      const resUser = JSON.parse(storedUserData);

      setIsFetching(true); // Set fetching flag to true

      const response = await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL_API}/products/user/${resUser.userId}?limit=${limit}&offset=${currentOffset}&searchTerm=${searchTerm}&sortField=${settingsAtom.productsSortField}&sortOrder=${settingsAtom.productsSortOrder}`,
        'GET',
        null,
        { Authorization: 'Bearer ' + resUser.token }
      );


      setShownInvoices(response.shownInvoices)
      setTotalInvoices(response.totalInvoices)

      if (response && response.invoices) {
        if (response.invoices.length < limit) {
          setHasMoreInvoices(false); // No more invoices to fetch
        }

        if (isInitialLoad) {
          setDataInitial(response.invoices); // Replace existing data for initial load
        } else {
          // Append new invoices, making sure no duplicates
          setDataInitial(prevInvoices => [
            ...prevInvoices,
            ...response.invoices,
          ]);
        }
      }

      setIsFetching(false);
    } catch (err) {
      setIsFetching(false);
      console.error('Error fetching invoices:', err);
    }
  };

  /***
  INITIAL LOADING
  ***/
  useEffect(() => {
    // Initial load when the page loads or search term/recurring changes
    setOffset(0); // Reset offset
    fetchData(0, INITIAL_ITEMS, true, searchTerm); // Fetch the first 5 invoices with updated recurring value
  }, [searchTerm, openProductDialogAtom]); // Trigger on recurring or search term change

  // Store scroll position before refetching
  const [scrollPos, setScrollPos] = useState(0);

  // Save scroll position before dialog opens
  useEffect(() => {
    if (openInvoiceDialogAtom.open) {
      setScrollPos(window.scrollY); // Save current scroll position
    }
  }, [openInvoiceDialogAtom.open]);

  // Refetch all invoices and restore scroll position when the dialog closes
  useEffect(() => {
    if (!openInvoiceDialogAtom.open) {
      fetchData(0, dataInitial.length, true, searchTerm); // Refetch all data to ensure updates
      setTimeout(() => {
        window.scrollTo(0, scrollPos); // Restore scroll position
      }, 0);
    }
  }, [openInvoiceDialogAtom.open]);

  // Load more invoices when the user clicks the "Load More" button
  const loadMoreInvoices = () => {
    if (!isFetching && hasMoreInvoices) {
      const limit = INITIAL_ITEMS;
      const newOffset = offset + limit;
      setOffset(newOffset); // Update the offset
      fetchData(newOffset, limit, false, searchTerm); // Fetch more data based on new offset
    }
  };

  /***
  SEARCH
  ***/
  // Handle search input
  const handleSearch = (e) => {
    const term = e.target.value;
    setSearchTerm(term);

    // Scroll to top when the search term is updated
    window.scrollTo({
      top: 0,
    });

    if (term !== "") {
      // Add logic here to filter invoices or trigger a new fetch based on the search term
    } else {
      handleSearchClear(); // Clear search when input is empty
    }
  };

  const handleSearchClear = () => {
    window.scrollTo({
      top: 0,
    });

    setSearchTerm("");
    setDataInitial([]);
    setOffset(0); // Reset offset for new search
    setHasMoreInvoices(true); // Allow more invoices to load

    // Use setTimeout to wait for the state updates to complete
    setTimeout(() => {
      fetchData(0, INITIAL_ITEMS, true, ""); // Fetch the first 5 invoices with cleared search term and respect recurring state
    }, 0);
  };

  // Trigger fetch with search term when the user types
  useEffect(() => {
    if (searchTerm !== '') {
      setOffset(0); // Reset offset for new search
      fetchData(0, INITIAL_ITEMS, true, searchTerm); // Pass recurring state along with search term
      setHasMoreInvoices(true); // Allow more invoices to load
    } else {
      // If search is cleared, load initial invoices again respecting the recurring state
      fetchData(0, INITIAL_ITEMS, true, "");
    }
  }, [searchTerm]); // Also listen for recurring state changes



  // const fetchCategories = async () => {
  //   try {
  //     // Make the request using sendRequest
  //     const response = await sendRequest(
  //       `${process.env.REACT_APP_BACKEND_URL_API}/products/getcategories`, // API endpoint
  //       "POST", // HTTP method
  //       null, // No body for POST requests
  //       {
  //         Authorization: `Bearer ${auth.token}`, // Authorization header
  //       }
  //     );

  //     // Update categorySuggestions with unique values
  //     setCategorySuggestions((prevSuggestions) => {
  //       const updatedSuggestions = [...new Set(["general", ...prevSuggestions, ...response.categories])];
  //       return updatedSuggestions;
  //     });
  //     return response.categories;
  //   } catch (error) {
  //     console.error("Failed to fetch categories:", error);
  //     return [];
  //   }
  // };

  //   // Trigger fetch with search term when the user types
  //   useEffect(() => {
  //     fetchCategories();
  //   }, []); // Also listen for recurring state changes
  
  

  /***
  CHANGE SETTINGS
  ***/
  const handleChange = (e) => {
    SET_VALUE(e, setSettings);
  };

  // ON SAVE: 1) Update settingsAtom
  const updateSettingsHandler = () => {
    // GLOBAL changes
    // setSettingsAtom(settings);

    setSettingsAtom((prevSettings) => {
      const { invoicesDateRange, dateRangeStartValue, dateRangeEndValue, useDateRange, ...restPrevSettings } = prevSettings;

      return {
        ...restPrevSettings, // Update all other fields
        ...settings,         // Apply new settings
        dateRangeStartValue, // Keep these fields unchanged, because they are not change in settings but in settingsAtom
        dateRangeEndValue,
        useDateRange,
        invoicesDateRange
      };
    });

    // Save variable is only needed to trigger submenuClose when NOTHING changes in settings and save button is clicked
    SET_VALUE({ target: { id: "save", value: true } }, setValues);

    setDataInitial([]);
    setOffset(0); // Reset offset for new search
    setHasMoreInvoices(true); // Allow more invoices to load on scroll
    window.scrollTo({
      top: 0,
    });
  };

  // ON SAVE: 2) DB: PATCH SETTINGS
  useEffect(() => {
    if (auth) {
      DB_PATCH_SETTINGS(settingsAtom, auth, sendRequest, enqueueSnackbar, t, false);

      SET_VALUE({
        target: {
          "submenuOpen": false,
          "chipDirectChange": false,
          "save": false,
        }
      }, setValues, "multi")
    }
  }, [auth.token, settingsAtom, values.save]);

  // ON CANCEL:
  const cancelSettingsHandler = () => {
    // Re-set original values
    setSettings(settingsAtom);
    SET_VALUE({ target: { id: "submenuOpen", value: false } }, setValues);
  };

  useEffect(() => {
    setDataInitial([]);
    setOffset(0); // Reset offset for new search
    setHasMoreInvoices(true); // Allow more invoices to load on scroll
    fetchData(0, INITIAL_ITEMS, true, searchTerm); // Fetch filtered data with cleared search term
  }, [settingsAtom]);


  // Bulk Edit refetch
  useEffect(() => {
    console.log("herebulk")
    if(bulkEdit) {
      setDataInitial([]);
      setOffset(0); // Reset offset for new search
      setHasMoreInvoices(true); // Allow more invoices to load on scroll
      fetchData(0, INITIAL_ITEMS, true, searchTerm); // Fetch filtered data with cleared search term
      setBulkEdit(false)
    }
  }, [bulkEdit]);

  const [payMenuAnchorEl, setPayMenuAnchorEl] = useState(null);
  const [payMenuValues, setPayMenuValues] = useState({ payDate: null, payState: "sent" });
  const [payDateError, setPayDateError] = useState("");

  const validateDate = (selectedDate, invoiceDate) => {
    if (!selectedDate) {
      setPayDateError("Bitte ausfüllen");
      return false;
    }

    if (isNaN(new Date(selectedDate).getTime())) {
      setPayDateError("Bitte ausfüllen");
      return false;
    }

    setPayDateError(""); // Clear error if all validations pass
    return true;
  };

  const handleDateChange = (date, invoiceDate) => {
    setPayMenuValues((prev) => ({ ...prev, payDate: date }));
    validateDate(date, invoiceDate);
  };





  const openPayMenu = (event) => {
    event.stopPropagation();
    setPayMenuAnchorEl(event.currentTarget);
  };

  const closePayMenu = () => {
    setPayMenuAnchorEl(null);

    // Delay the payDate update by 1.5 seconds
    setTimeout(() => {
      setPayMenuValues({ ...payMenuValues, payDate: new Date().toString() });
      setPayDateError("")
    }, 500);
  };

  const isPayMenuOpen = Boolean(payMenuAnchorEl);

  const activeFilterCount = [
    settings.invoicesFiltersStatusDraft,
    settings.invoicesFiltersStatusScheduled,
    settings.invoicesFiltersStatusSent,
    settings.invoicesFiltersStatusPayed,
    settings.invoicesFiltersStatusOverdue,
  ].filter(Boolean).length;


  return (
    <div>
      <div className={classes.toolbar} />

      <div className="mobile-menu-section" style={{ borderBottom: "1px solid rgb(237, 239, 255)", position: "sticky", top: 47, zIndex: 10, width: "100%", padding: "2px 4px" }}>
        {/* <DateRange
          type={type === "invoices" ? "invoices" : "estimates"}
          mobile={true}
          setCheckedItems={setCheckedItems}
        />
        <InvoicesFilter
          settings={settings}
          settingsAtom={settingsAtom}
          handleChange={handleChange}
          cancelSettingsHandler={cancelSettingsHandler}
          updateSettingsHandler={updateSettingsHandler}
          setValues={setValues}
          SET_VALUE={SET_VALUE}
          setSettings={setSettings}
          values={values}
          type={type}
          recurring={recurring}
          setOffset={setOffset}
          fetchData={fetchData}
          INITIAL_ITEMS={INITIAL_ITEMS}
          searchTerm={searchTerm}
          setHasMoreInvoices={setHasMoreInvoices}
          setSettingsAtom={setSettingsAtom}
          mobile={true}
          setCheckedItems={setCheckedItems}
        />

        <InvoicesSort
          settings={settings}
          handleChange={handleChange}
          cancelSettingsHandler={cancelSettingsHandler}
          updateSettingsHandler={updateSettingsHandler}
          setValues={setValues}
          SET_VALUE={SET_VALUE}
          setSettings={setSettings}
          values={values}
          type={type}
          recurring={false}
          mobile={true}
          setCheckedItems={setCheckedItems}
        />

        <InvoicesSettings
          settings={settings}
          handleChange={handleChange}
          cancelSettingsHandler={cancelSettingsHandler}
          updateSettingsHandler={updateSettingsHandler}
          setValues={setValues}
          SET_VALUE={SET_VALUE}
          setSettings={setSettings}
          values={values}
        /> */}

        <ProductsSort
          settings={settings}
          handleChange={handleChange}
          cancelSettingsHandler={cancelSettingsHandler}
          updateSettingsHandler={updateSettingsHandler}
          setValues={setValues}
          SET_VALUE={SET_VALUE}
          setSettings={setSettings}
          values={values}
          type={type}
          recurring={false}
          mobile={true}
          setCheckedItems={setCheckedItems}
        />
      </div>

      <div className="not-mobile-menu-section box-shadow--1" style={{ position: "sticky", top: 0, zIndex: 9, width: "100%", }}>
        <Grid container className="subpage-header" item direction="row" alignItems="center" alignContent="center" style={{ paddingBottom: 0 }}>
          <Grid className="main-headline-wrapper" item container direction="row" alignItems="center" style={{ marginRight: "10px", marginBottom: 10 }}>
            <Grid item >
              <Grid item container alignItems="center">
                <Icon style={{borderRadius: 4, width: 30, height: 30, background: "white", marginRight: "15px", background: "linear-gradient(to right top, rgb(38, 108, 189), rgb(36, 115, 192), rgb(33, 150, 243), #00bca7, #00bf72)", marginRight: 8, marginTop: -1, marginLeft: -1, display: "flex", justifyContent: "center", alignItems: "center"}}>
                    <img src={ProductIconWhite} height={17} width={17} alt={`${t("APP.name")} Logo`} />
                </Icon>
                <Typography variant="h6" component="h1" color="primary" style={{fontWeight: 600}}>Produkte</Typography>

                {/* {(!values.loading || !isLoading) && userAtom.data.signupCompleted && (userAtom.clientscreated || userAtom.data.clients.length !== 0) && !isFetching && */}
                {userAtom.data.clients.length !== 0 &&
                  <Hidden smDown>
                    <Button
                      variant="contained"
                      color="primary"
                      size="small"
                      startIcon={<AddCircleIcon className="MuiSvgIcon-root" fontSize="large" />}
                      className="add-button" // Add the custom class
                      style={{ height: 30 }}
                      onClick={(e)=>{
                        setOpenProductDialogAtom({
                        ...openProductDialogAtom,
                        productId: "",
                        open: true,
                        });
                      }}
                    >
                      Erstellen
                    </Button>
                  </Hidden>
                }
              </Grid>
            </Grid>
          </Grid>

          <Grid
            item
            container
            alignItems="center"
            style={{ display: "flex", height: "48px" }} // Set consistent height for all children
          >
            {/* <DateRange
              type={type === "invoices" ? "invoices" : "estimates"}
              mobile={false}
              setCheckedItems={setCheckedItems}
            /> */}

            <ProductsSearch
              handleSearchClear={handleSearchClear}
              handleSearch={handleSearch}
              type={type}
              searchTerm={searchTerm}
              allInvoicesByClientAtom={allInvoicesByClientAtom}
              setAllInvoicesByClientAtom={setAllInvoicesByClientAtom}
              allInvoicesByOriginalRefAtom={allInvoicesByOriginalRefAtomState}
              setAllInvoicesByOriginalRefAtom={setAllInvoicesByOriginalRefAtom}
              setCheckedItems={setCheckedItems}
            />
            

            {/* <InvoicesSettings
              settings={settings}
              handleChange={handleChange}
              cancelSettingsHandler={cancelSettingsHandler}
              updateSettingsHandler={updateSettingsHandler}
              setValues={setValues}
              SET_VALUE={SET_VALUE}
              setSettings={setSettings}
              values={values}
            /> */}
          </Grid>
        </Grid>

        <Grid container item xs={12} alignItems="center" className="chips" style={{ borderBottom: "1px solid rgb(237, 239, 255)", paddingBottom: 8 }}>



          {/* <ProductsBulkEdit
          mobile={false}
        />         */}
          {/* <InvoicesFilter
            settings={settings}
            settingsAtom={settingsAtom}
            handleChange={handleChange}
            cancelSettingsHandler={cancelSettingsHandler}
            updateSettingsHandler={updateSettingsHandler}
            setValues={setValues}
            SET_VALUE={SET_VALUE}
            setSettings={setSettings}
            values={values}
            type={type}
            recurring={recurring}
            setOffset={setOffset}
            fetchData={fetchData}
            INITIAL_ITEMS={INITIAL_ITEMS}
            searchTerm={searchTerm}
            setHasMoreInvoices={setHasMoreInvoices}
            setSettingsAtom={setSettingsAtom}
            mobile={false}
            setCheckedItems={setCheckedItems}
          />

           */}

          <ProductsSort
            settings={settings}
            handleChange={handleChange}
            cancelSettingsHandler={cancelSettingsHandler}
            updateSettingsHandler={updateSettingsHandler}
            setValues={setValues}
            SET_VALUE={SET_VALUE}
            setSettings={setSettings}
            values={values}
            type={type}
            recurring={false}
            mobile={false}
            setCheckedItems={setCheckedItems}
          />

        </Grid>

      </div>

      {userAtom.data.signupCompleted &&
        <React.Fragment>
          <Container maxWidth={false} disableGutters className="bg--1" style={{ paddingBottom: 70 }}>
            <div className="count-entries" style={{ display: "flex", justifyContent: "flex-start" }}>
              <div style={{ display: "flex" }}>
                {!isLoading ? (
                  <span>
                    <strong>{dataInitial.length} von {shownInvoices} Produkten geladen</strong>
                  </span>
                ) : (<span> Wird gelanden ... </span>)}
                {(hasMoreInvoices && !isFetching) && (<span className="load-more-small" style={{ display: "flex", paddingLeft: 5, paddingRight: 5 }} onClick={loadMoreInvoices}><RotateLeftIcon style={{ zoom: 0.7, paddingTop: 1 }} /><span style={{ paddingTop: 1 }}>MEHR LADEN</span></span>)}
              </div>
              {/* <div>
                {<span><span style={{ paddingLeft: 5, paddingTop: 1 }}>{settingsAtom.useDateRange && "| Zeitraum eingeschränkt"} {searchTerm !== "" && `| Suchbegriff: ${searchTerm}`} {activeFilterCount > 0 && "| Statusfilter gesetzt"}</span></span>}
              </div> */}
            </div>
            {/* <div style={{marginLeft: 50, width: "100%", paddingTop: 20, paddingBottom: 0, marginBottom: -5, fontSize: 12, color: "rgb(44, 83, 125)", opacity: 0.5}}><strong>Anzahl: 99 von 102</strong> (Aufgrund von Zeitraum-, Filter- bzw. Sucheinschränkung werden <strong>3 Rechnungen</strong> nicht angezeigt)</div> */}
            <Box
              display="flex"
              direction="column"
              className="list-box"
            >
              <Grid container item xs={12} >
                <div className="box-shadow--1 invoices-list">
                  <Grid
                    item
                    className="sticky-nav box-shadow--1 h-padding--5-5 tool-menu"
                    style={{ top: 149 }}
                  >

                    <Grid container>
                      <Grid
                        container
                        alignItems="center"
                        justifyContent="space-between"
                        item
                        xs={12}
                      >
                        <Grid container item xs={12} justifyContent="space-between">
                          <Grid xs={12} lg={3} container item justifyContent="center" alignItems="center" style={{ paddingLeft: 15 }}>
                            <div
                              style={{ position: "absolute", left: 5 }}
                              onClick={(e) => {
                                e.stopPropagation(); // Prevent parent click event
                              }}
                            >
                              <ProductsBulkEdit
                                dataInitial={dataInitial}
                                checkedItems={checkedItems}
                                setCheckedItems={setCheckedItems}
                                setBulkEdit={setBulkEdit}
                              />
                            </div>
                            <Typography style={{ marginRight: 5 }}>Produkt</Typography>
                          </Grid>

                          <Grid xs={12} lg={9} container item justifyContent="flex-start" alignItems="center" >
                            {/* Type */}
                            {/* <Grid xs={4} md={2}
                              container
                              item
                              justifyContent="center"
                              alignItems="center"
                            >
                              <div style={{ display: "flex", alignItems: "center", justifyContent: "center", padding: "5px 10px" }}>
                                <Typography
                                  style={{
                                    textAlign: "center",
                                    whiteSpace: "normal",
                                    wordBreak: "break-word",
                                  }}
                                >
                                  <span class="table-lable-invoicenumber-large">Typ</span>
                                  <span class="table-lable-invoicenumber-small">Typ</span>
                                </Typography>
                              </div>
                            </Grid> */}

                            {/* Category */}
                            <Grid xs={12} md={3}
                              container
                              item
                              justifyContent="center"
                              alignItems="center"
                            >
                              <div style={{ display: "flex", alignItems: "center", justifyContent: "center", padding: "5px 10px" }}>
                                <Typography
                                  style={{
                                    textAlign: "center",
                                    whiteSpace: "normal",
                                    wordBreak: "break-word",
                                  }}
                                >
                                  Kategorie
                                </Typography>
                              </div>
                            </Grid>

                            {/* Number */}
                            <Grid xs={12} md={2}
                              container
                              item
                              justifyContent="center"
                              alignItems="center"
                              className="table-headline"
                              style={{ background: settings.productsSortField === "number" && "rgb(237, 239, 255)", borderRadius: 6 }}
                              onClick={() => {
                                setSettings({ ...settings, productsSortField: "number", productsSortOrder: settings.productsSortOrder === "asc" ? "desc" : "asc" });
                                setSettingsAtom({ ...settingsAtom, productsSortField: "number", productsSortOrder: settingsAtom.productsSortOrder === "asc" ? "desc" : "asc" })
                              }}
                            >
                              <div style={{ display: "flex", alignItems: "center", justifyContent: "center", padding: "5px 10px" }}>
                                <Typography
                                  style={{
                                    textAlign: "center",
                                    whiteSpace: "normal",
                                    wordBreak: "break-word",
                                  }}
                                >
                                  Nummer
                                </Typography>
                                {settingsAtom.productsSortField === "number" && settings.productsSortOrder === "desc" && (
                                  <ArrowDownwardIcon style={{ zoom: 0.6, marginLeft: 5 }} />
                                )}
                                {settingsAtom.productsSortField === "number" && settings.productsSortOrder === "asc" && (
                                  <ArrowUpwardIcon style={{ zoom: 0.6, marginLeft: 5 }} />
                                )}
                              </div>
                            </Grid>

                            {/* Net */}
                            <Grid xs={4} md={2}
                              container
                              item
                              justifyContent="center"
                              alignItems="center"
                              className="table-headline"
                              style={{ background: settings.productsSortField === "net" && "rgb(237, 239, 255)", borderRadius: 6 }}
                              onClick={() => {
                                setSettings({ ...settings, productsSortField: "net", productsSortOrder: settings.productsSortOrder === "asc" ? "desc" : "asc" });
                                setSettingsAtom({ ...settingsAtom, productsSortField: "net", productsSortOrder: settingsAtom.productsSortOrder === "asc" ? "desc" : "asc" })
                              }}
                            >
                              <div style={{ display: "flex", alignItems: "center", justifyContent: "center", padding: "5px 10px" }}>
                                <Typography
                                  style={{
                                    textAlign: "center",
                                    whiteSpace: "normal",
                                    wordBreak: "break-word",
                                  }}
                                >
                                  <span class="table-lable-invoicedate-large">Preis (netto)</span>
                                </Typography>
                                {settingsAtom.productsSortField === "net" && settings.productsSortOrder === "desc" && (
                                  <ArrowDownwardIcon style={{ zoom: 0.6, marginLeft: 5 }} />
                                )}
                                {settingsAtom.productsSortField === "net" && settings.productsSortOrder === "asc" && (
                                  <ArrowUpwardIcon style={{ zoom: 0.6, marginLeft: 5 }} />
                                )}
                              </div>
                            </Grid>
                            
                            {/* Gross */}
                            <Grid xs={4} md={2}
                              container
                              item
                              justifyContent="center"
                              className="table-headline"
                              style={{ background: settings.productsSortField === "gross" && "rgb(237, 239, 255)", borderRadius: 6 }}
                              onClick={() => {
                                setSettings({ ...settings, productsSortField: "gross", productsSortOrder: settings.productsSortOrder === "asc" ? "desc" : "asc" });
                                setSettingsAtom({ ...settingsAtom, productsSortField: "gross", productsSortOrder: settingsAtom.productsSortOrder === "asc" ? "desc" : "asc" })
                              }}
                            >
                              <div style={{ display: "flex", alignItems: "center", justifyContent: "center", padding: "5px 10px" }}>
                                <Typography
                                  style={{
                                    textAlign: "center",
                                    whiteSpace: "normal",
                                    wordBreak: "break-word",
                                  }}
                                >
                                  Preis (brutto)
                                </Typography>
                                {settingsAtom.productsSortField === "gross" && settings.productsSortOrder === "desc" && (
                                  <ArrowDownwardIcon style={{ zoom: 0.6, marginLeft: 5 }} />
                                )}
                                {settingsAtom.productsSortField === "gross" && settings.productsSortOrder === "asc" && (
                                  <ArrowUpwardIcon style={{ zoom: 0.6, marginLeft: 5 }} />
                                )}
                              </div>
                            </Grid>

                            {/* Revenue net */}
                            <Grid xs={4} md={2}
                              container
                              item
                              justifyContent="center"
                              className="table-headline"
                              // style={{ background: settings.productsSortField === "gross" && "rgb(237, 239, 255)", borderRadius: 6 }}
                              // onClick={() => {
                              //   setSettings({ ...settings, productsSortField: "gross", productsSortOrder: settings.productsSortOrder === "asc" ? "desc" : "asc" });
                              //   setSettingsAtom({ ...settingsAtom, productsSortField: "gross", productsSortOrder: settingsAtom.productsSortOrder === "asc" ? "desc" : "asc" })
                              // }}
                            >
                              <div style={{ display: "flex", alignItems: "center", justifyContent: "center", padding: "5px 10px" }}>
                                <Typography
                                  style={{
                                    textAlign: "center",
                                    whiteSpace: "normal",
                                    wordBreak: "break-word",
                                  }}
                                >
                                  Umsatz (netto)
                                </Typography>
                                {/* {settingsAtom.productsSortField === "gross" && settings.productsSortOrder === "desc" && (
                                  <ArrowDownwardIcon style={{ zoom: 0.6, marginLeft: 5 }} />
                                )}
                                {settingsAtom.productsSortField === "gross" && settings.productsSortOrder === "asc" && (
                                  <ArrowUpwardIcon style={{ zoom: 0.6, marginLeft: 5 }} />
                                )} */}
                              </div>
                            </Grid>


                            {/* Menu Dots */}
                            <Grid container item xs={12} md={1} justifyContent="center"></Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <div style={{ minHeight: "100vh" }}>
                    {/* INVOICES already created */}

                    {dataInitial.length !== 0 && (
                      <>
                        {/* APPLIED FILTER after Loading doesn't result in list of length 0 */}
                        {Object.keys(dataInitial).length !== 0 &&
                          (dataInitial)
                            .map((item, i) => (
                              <Grid container key={i}>
                                <Grid
                                  container
                                  alignItems="center"
                                  justifyContent="space-between"
                                  item
                                  xs={12}
                                  className="h-bg--white user-clients"
                                  style={{
                                    background: checkedItems.includes(item._id) ? "#c2d5f1" : "white",
                                    borderBottom: "1px solid #e2ecfd",
                                    // background: recurring ? "#fbf8ff" : "inherit",
                                    // borderLeft: "1px solid grey"
                                  }}
                                >


                                  <Grid container item xs={12} justifyContent="space-between" className="table-section"
                                    onClick={(e)=>{
                                      setOpenProductDialogAtom({
                                      ...openProductDialogAtom,
                                      productId: item._id,
                                      open: true,
                                      });
                                    }}
                                  >
                                    {/* First Part using lg 4 */}
                                    {/* Name */}
                                    <Grid xs={12} lg={3}
                                      container
                                      item
                                      alignItems="center"
                                      justifyContent="flex-start"
                                      className="invoice-status product-name"
                                      style={{ padding: "5px 0", paddingLeft: 47, justifyContent: "flex-start" }}
                                    >
                                      <div
                                        className="invoice-checkbox"
                                        style={{ position: "absolute", left: 408, width: 30 }}
                                        onClick={(e) => {
                                          e.stopPropagation(); // Prevent parent click event
                                          // setCheckedItems((prev) => {
                                          //   if (prev.includes(item._id)) {
                                          //     // If `invoice._id` is already in the array, remove it
                                          //     return prev.filter((id) => id !== item._id);
                                          //   } else {
                                          //     // Otherwise, add `invoice._id` to the array
                                          //     return [...prev, item._id];
                                          //   }
                                          // });
                                        }}
                                      >
                                        <FormControlLabel
                                          control={
                                            <Checkbox
                                              checked={checkedItems.includes(item._id)}
                                              onChange={(e) => {
                                                e.stopPropagation(); // Prevent parent click event
                                                setCheckedItems((prev) => {
                                                  if (prev.includes(item._id)) {
                                                    // If `invoice._id` is already in the array, remove it
                                                    return prev.filter((id) => id !== item._id);
                                                  } else {
                                                    // Otherwise, add `invoice._id` to the array
                                                    return [...prev, item._id];
                                                  }
                                                });
                                              }}
                                              color="primary"
                                              className="checkbox-invoices"
                                              style={{ zoom: 0.8, display: checkedItems.includes(item._id) ? "block" : "none" }}
                                            />
                                          }
                                        />
                                      </div>
                                      <div className="invoice-checkbox fallback-icon" style={{ position: "absolute", left: 408, width: 30, display: checkedItems.includes(item._id) ? "none" : "inherit" }}>
                                        <Icon style={{marginRight: "15px", marginRight: 8, marginLeft: -5, display: "flex", justifyContent: "center", alignItems: "center"}}>
                                            <img src={ProductIconDark} height={17} width={17} alt={`Produkt`} />
                                        </Icon>  
                                      </div>

                                      <Tooltip title={item.name} arrow>
                                        <Typography component="div" variant="body2" style={{ fontSize: "13px", fontWeight: "500", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>{item.name}</Typography>
                                      </Tooltip>
                                    </Grid>

                                    {/* Second Part using lg 10*/}
                                    <Grid xs={12} lg={9}
                                      container
                                      item
                                      justifyContent="flex-start"
                                      alignItems="center"
                                      className="invoice-meta"
                                      style={{ padding: "15px 0px", }}
                                      onClick={(e)=>{
                                        setOpenProductDialogAtom({
                                        ...openProductDialogAtom,
                                        productId: item._id,
                                        open: true,
                                        });
                                      }}
                                    >

                                      {/* Type */}
                                      {/* <Grid xs={4} lg={2}
                                        container
                                        item
                                        justifyContent="center"
                                        className="invoice-meta-item-container"
                                      >
                                        <Typography className="invoice-meta-item" style={{ textAlign: "center", fontSize: "12px" }} component="div" variant="body2">
                                          <span className="menu-heading-title">Typ:</span>
                                          <span>
                                            <div style={{ fontWeight: "300", fontSize: "12px" }}>{item.type === "service" ? "Dienstleistung" : "Artikel"}</div>
                                          </span>
                                        </Typography>
                                      </Grid> */}

                                      {/* Category */}
                                      <Grid xs={12} lg={3}
                                        container
                                        item
                                        justifyContent="center"
                                        className="invoice-meta-item-container"
                                      >
                                        <Typography className="invoice-meta-item" style={{ maxWidth: "100%", textAlign: "center", fontSize: "12px" }} component="div" variant="body2">
                                          <span className="menu-heading-title">Kategorie:</span>
                                          <span style={{maxWidth: "90%",  whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis"}}>
                                            <div style={{ fontWeight: "300", fontSize: "12px",  whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis"}}>{item.categoryName === "general" ? "Allgemein" : item.categoryName}</div>
                                          </span>
                                        </Typography>
                                      </Grid>

                                      {/* Number */}
                                      <Grid xs={12} lg={2}
                                        container
                                        item
                                        justifyContent="center"
                                        className="invoice-meta-item-container"
                                      >
                                        <Typography className="invoice-meta-item" style={{ textAlign: "center", fontSize: "12px" }} component="div" variant="body2">
                                          <span className="menu-heading-title" style={{ paddingTop: 4 }}>Nummer:</span>
                                          <Tooltip title={
                                            <span>
                                              {item.number}
                                            </span>
                                          } arrow>
                                            <div style={{
                                              display: "inline-block",
                                              maxWidth: "100px",
                                              whiteSpace: "nowrap",
                                              overflow: "hidden",
                                              textOverflow: "ellipsis"
                                            }}>
                                              <span style={{
                                                        display: "inline-block",
                                                        background: "rgb(237, 239, 255)",
                                                        height: "17px",
                                                        color: "inherit",
                                                        paddingLeft: 3,
                                                        paddingRight: 3,
                                                        borderRadius: "3px",
                                                        marginTop: 3.3,
                                                        marginLeft: "1px",
                                                        marginRight: "1px",
                                                        fontSize: "13px",
                                                        fontWeight: "300"
                                                      }}>
                                                {item.number}
                                              </span>
                                            </div>
                                          </Tooltip>
                                        </Typography>
                                      </Grid>

                                      {/* Net */}
                                      <Grid xs={12} lg={2}
                                        container
                                        item
                                        justifyContent="center"
                                        className="invoice-meta-item-container"
                                      >
                                        <Typography className="invoice-meta-item" style={{ textAlign: "center", fontSize: "12px" }} component="div" variant="body2">
                                          <span className="menu-heading-title">Preis (netto):</span>
                                          <span>
                                            <div style={{ fontWeight: "300", fontSize: "12px", color: "inherit" }}>{parseFloat(item.totalNet).toLocaleString('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 })} €</div>
                                          </span>
                                        </Typography>
                                      </Grid>

                                      {/* Gross */}
                                      <Grid xs={12} lg={2}
                                        container
                                        item
                                        justifyContent="center"
                                        className="invoice-meta-item-container"
                                      >
                                        <Typography className="invoice-meta-item" style={{ textAlign: "center", fontSize: "12px" }} component="div" variant="body2">
                                          <span className="menu-heading-title">Preis (brutto):</span>
                                          <span>
                                            <div style={{ fontWeight: "300", fontSize: "12px", color: "inherit" }}>{parseFloat(item.totalGross).toLocaleString('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 })} €</div>
                                          </span>
                                        </Typography>
                                      </Grid>

                                      {/* Revenue */}
                                      <Grid xs={12} lg={2}
                                        container
                                        item
                                        justifyContent="center"
                                        className="invoice-meta-item-container"

                                      >
                                        <Typography className="invoice-meta-item" style={{ textAlign: "center", fontSize: "12px" }} component="div" variant="body2">
                                          <span className="menu-heading-title">Umsatz (netto): </span>
                                          <span>
                                            <div style={{ fontWeight: "300", fontSize: "12px", color: "inherit" }}>{parseFloat(item.totalDiscountedTotal).toLocaleString('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 })} €</div>
                                          </span>
                                        </Typography>
                                      </Grid>

                                      {/* Dots */}
                                      <Grid xs={12} lg={1}
                                        container
                                        item
                                        justifyContent="flex-end"
                                        alignItems="flex-end"
                                      >
                                        <ProductsMenuDots
                                          item={item}
                                          setOpenProductDialogAtom={setOpenProductDialogAtom}
                                          openProductDialogAtom={openProductDialogAtom}
                                          recurring={recurring}
                                          fetchData={fetchData}
                                          handleSearchClear={handleSearchClear}
                                          openPayMenu={openPayMenu}
                                          setInvoicePayed={setInvoicePayed}
                                          setSelectedInvoice={setSelectedInvoice}
                                          setBulkEdit={setBulkEdit}
                                          checkedItems={checkedItems}
                                          setCheckedItems={setCheckedItems}
                                        />
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                </Grid>
                              </Grid>
                            ))
                        }

                        {/* APPLIED FILTER after Loading does result in list of length 0 */}
                        {!values.loading && !isLoading && Object.keys(values.invoicesFiltered).length === 0 &&
                          <Typography component="div" style={{ fontSize: "12px", padding: "10px 20px" }}>{t("PAGE_INVOICES.noResult")}</Typography>
                        }
                      </>
                    )
                    }
                    {(isLoading && searchTerm === "") &&
                      <div style={{ padding: 15 }}>Wird geladen ...</div>
                    }


                    {/* NO INVOICES created yet */}
                    {!values.loading && !isLoading && dataInitial.length === 0 && type === "invoices" &&
                      <Typography component="div" style={{ fontSize: "12px", padding: "10px 20px" }}>{t("PAGE_INVOICES.noInvoicesCreated")}</Typography>
                    }


                    {hasMoreInvoices && !isFetching && dataInitial.length !== 0 && (
                      <Grid container justifyContent="center">
                        <Button
                          onClick={loadMoreInvoices}
                          style={{
                            backgroundColor: theme.palette.primary.main, // Primary color
                            color: "#fff",
                            margin: "15px 0", // Smaller margin
                            padding: "8px 16px", // Smaller padding
                            fontSize: "14px", // Smaller font size
                            borderRadius: "6px", // Slightly smaller border radius
                            boxShadow: "0px 3px 6px rgba(0,0,0,0.2)",
                          }}
                          onMouseOver={(e) =>
                            (e.currentTarget.style.backgroundColor = theme.palette.primary.dark)
                          } // Darker shade on hover
                          onMouseOut={(e) =>
                            (e.currentTarget.style.backgroundColor = theme.palette.primary.main)
                          } // Reset background color when mouse leaves
                          startIcon={<RotateLeftIcon />} // Add an icon here
                        >
                          Mehr laden
                        </Button>
                      </Grid>
                    )}

                    {(!values.loading || !isLoading) && (!userAtom.clientscreated && userAtom.data.clients.length === 0) &&
                      <UserProfileIncompleteWarning
                        headline={t("PAGE_INVOICES.h1")}
                        text={"Bitte erstelle zunächst einen Kunden."}
                        icon={<ClientsIcon color="primary" fontSize="large" style={{ marginRight: "5px" }} />}
                        link={"/clients"}
                        linkText={t("BUTTONS.back_to_clients")}
                      />
                    }

                    {(!values.loading || !isLoading) && userAtom.data.signupCompleted && (userAtom.clientscreated || userAtom.data.clients.length !== 0) && dataInitial.length === 0 && !isFetching &&
                      <Grid container style={{ padding: "20px 15px" }}>
                        <Typography variant="body2" style={{ color: "gray" }}>
                          Es wurden keine Produkte mit den vorgenommenen Einstellungen gefunden.
                        </Typography>
                      </Grid>
                    }
                  </div>

                  {(!isLoading) &&
                    <>
                      {/* INVOICES already created */}

                      {/* NO INVOICES created yet */}
                      {!values.loading && !isLoading && dataInitial.length === 0 && type === "invoices" &&
                        <Typography component="div" style={{ fontSize: "12px", padding: "10px 20px" }}>{t("PAGE_INVOICES.noInvoicesCreated")}</Typography>
                      }

                      {/* NO ESTIMATES created yet */}
                      {!values.loading && !isLoading && dataInitial.length === 0 && type === "estimates" &&
                        <Typography component="div" style={{ fontSize: "12px", padding: "10px 20px" }}>Du hast noch keine Kostenvoranschläge oder Angebote angelegt</Typography>
                      }
                    </>
                  }
                </div>
              </Grid>
            </Box>
          </Container>
        </React.Fragment>
      }

      {/* {(!values.loading || !isLoading) && !userAtom.data.signupCompleted &&
        <UserProfileIncompleteWarning
          headline={t("PAGE_INVOICES.h1")}
          text={t("PAGE_INVOICES.profileIncomplete")}
          icon={<ClientsIcon color="primary" fontSize="large" style={{ marginRight: "5px" }} />}
        />
      } */}

      {(!values.loading || !isLoading) && userAtom.data.signupCompleted && (userAtom.clientscreated || userAtom.data.clients.length !== 0) && !isFetching &&

        <Fab color="primary" aria-label="add" className="add-invoice-fab" style={{ position: "fixed", bottom: "0", right: "0", margin: "20px", zIndex: 9 }}
          onClick={(e)=>{
            setOpenProductDialogAtom({
            ...openProductDialogAtom,
            productId: "",
            open: true,
            });
          }}
        >
          <AddIcon />
        </Fab>
      }
    </div>
  );
};

export default Products;
