import React, { useState, useContext } from "react";
// IMPORT hooks & context
import { useTranslation } from "react-i18next";
import { useHttpClient } from "../../shared/hooks/http-hook";
import { AuthContext } from "../../shared/context/auth-context";

// IMPORT recoil
import { useRecoilState } from "recoil";
import invoicesAtomState from "../../_atoms/invoicesAtom";

// IMPORT components MATERIAL UI
import {
  IconButton,
  Menu,
  MenuItem,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Slide,
  Modal,
  Typography,
  ListItemIcon,
  ListItemText
} from "@material-ui/core";

// IMPORT icons MATERIAL UI
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import EditIcon from "@material-ui/icons/Edit";
import VisibilityIcon from "@material-ui/icons/Visibility";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import CancelPresentationIcon from "@material-ui/icons/CancelPresentation";
import CloseIcon from "@material-ui/icons/Close";
import GetAppIcon from '@material-ui/icons/GetApp';
import ReplayIcon from '@material-ui/icons/Replay';


// IMPORT functions
import { DB_DELETE_INVOICE } from "./../../_functions/DB_INVOICES";

// IMPORT components
import { useSnackbar } from "notistack";
import ConfirmModal from "../../shared/components/UIElements/Modal";
import { CheckCircleOutline, CheckCircle, DeleteForever } from "@material-ui/icons";


const ITEM_HEIGHT = 48;

export default function ProductsMenuDots({
  item,
  setOpenProductDialogAtom,
  openProductDialogAtom,
  recurring,
  handleSearchClear,
  openPayMenu,
  setInvoicePayed,
  setSelectedInvoice,
  setBulkEdit,
  checkedItems,
  setCheckedItems,
}) {
  // HOOKS & CONTEXT
  const { t } = useTranslation();
  const auth = useContext(AuthContext);
  const { sendRequest } = useHttpClient();
  const { enqueueSnackbar } = useSnackbar();

  // GLOBAL STATE (RECOIL)
  const [invoicesAtom, setInvoicesAtom] = useRecoilState(invoicesAtomState);

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
    setCheckedItems([item._id])
  };

  const handleClose = (event) => {
    event.stopPropagation();
    setAnchorEl(null);
    setCheckedItems([])
  };

  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [showConfirmModalDuplicate, setShowConfirmModalDuplicate] = useState(false);
  const [showConfirmModalCancel, setShowConfirmModalCancel] = useState(false);

  const showDeleteWarningHandler = () => {
    setShowConfirmModal(true);
  };

  const cancelDeleteHandler = (event) => {
    event.stopPropagation();
    setShowConfirmModal(false);
  };

  const confirmDeleteHandler = (product, event) => {
    event.stopPropagation();
    const deleteProduct = async () => {
      try {
        const formData = new FormData();
        formData.append("userId", auth.userId);
        formData.append("productId", product); // stringified Array of invoice ids
    

        // Send the request to the backend
        const response = await sendRequest(
          `${process.env.REACT_APP_BACKEND_URL_API}/products/deleteproduct`,
          "POST",
          formData,
          {
            Authorization: "Bearer " + auth.token,
          }
        );
        enqueueSnackbar("Produkt wurde gelöscht", { variant: "success" });
      } catch (error) {
      }
    };
    deleteProduct();
    setShowConfirmModal(false);
    setBulkEdit(true);
  };

  const duplicateProduct = async (id) => {
    try {
      const formData = new FormData();
      formData.append("productId", id);

      const url = `${process.env.REACT_APP_BACKEND_URL_API}/products/duplicate`

      const response = await sendRequest(
        url,
        "POST",
        formData,
        {
          Authorization: "Bearer " + auth.token,
        }
      );
      handleSearchClear();
      enqueueSnackbar("Produkt wurde dupliziert", { variant: "success" });
    } catch (error) {
      console.error("Failed to copy invoice:", error);
    }
  };

  return (
    <>
      <IconButton
        aria-label="more"
        aria-controls={item._id}
        aria-haspopup="true"
        color="primary"
        onClick={(e) => {
          handleClick(e);
          e.stopPropagation();
          setOpenProductDialogAtom({ ...openProductDialogAtom, open: false })

        }}
        style={{ marginRight: "5px" }}
      >
        <MoreHorizIcon />
      </IconButton>
      <Menu
        id={item._id}
        anchorEl={anchorEl}
        open={open}
        onClose={(e) => {
          handleClose(e);
          e.stopPropagation();
          setOpenProductDialogAtom({ ...openProductDialogAtom, open: false })
          setCheckedItems([])
        }}
        PaperProps={{
          elevation: 1,
          style: {
            // maxHeight: ITEM_HEIGHT * 4.5,
            width: "auto",
          },
        }}
      >
        {/* Header with MoreHorizIcon and CloseIcon */}
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            padding: "5px 10px",
            borderBottom: "1px solid #ddd",
            cursor: "default", // Ensure the cursor does not indicate a clickable area
          }}
          onClick={(e) => e.stopPropagation()} // Prevent event propagation in the header
        >
          <IconButton
            style={{ marginRight: "auto" }}
            size="small"
            disabled
          >
            <MoreHorizIcon fontSize="small" color="primary" />
          </IconButton>
          <IconButton
            onClick={(e) => handleClose(e)}
            size="small"
          >
            <CloseIcon fontSize="small" color="primary"/>
          </IconButton>
        </div>

        {/* Menu Items */}
        {/* View / Edit */}
        <MenuItem
          style={{ fontSize: "13px", marginLeft: -10 }}
          onClick={(e) => {
            setOpenProductDialogAtom({
              ...openProductDialogAtom,
              open: true,
              productId: item._id,
            });
            handleClose(e);
          }}
        >
          <ListItemIcon>
                <VisibilityIcon color="primary" fontSize="small" style={{ marginRight: "5px" }} />
              </ListItemIcon>
          <ListItemText primary="Ansehen / Bearbeiten" />
        </MenuItem>
        
        {/* Duplicate */}
          <MenuItem
            style={{ fontSize: "13px", marginLeft: -10 }}
            onClick={(e) => {
              setShowConfirmModalDuplicate(true);
              handleClose(e);
            }}
          >
            <ListItemIcon>
                <FileCopyIcon color="primary" fontSize="small" style={{ marginRight: "5px" }} />
              </ListItemIcon>
          <ListItemText primary="Duplizieren" />
          </MenuItem>

        {/* Delete */}
        <MenuItem
          style={{ fontSize: "13px", marginLeft: -10 }}
          onClick={(e) => {
            e.stopPropagation();
            showDeleteWarningHandler(item._id);
            handleClose(e);
          }}
        >
          <ListItemIcon>
                <DeleteForeverIcon color="primary" fontSize="small" style={{ marginRight: "5px" }} />
              </ListItemIcon>
          <ListItemText primary="Löschen" />
        </MenuItem>
      </Menu>

      

      {/* MODAL duplicate */}
      <Modal
        open={showConfirmModalDuplicate}
        onClick={(e) => {
          e.stopPropagation(); // Prevent backdrop click propagation
          setShowConfirmModalDuplicate(false)
        }}
      >
        <Dialog
          open={showConfirmModalDuplicate}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle id="alert-dialog-slide-title">Produkt duplizieren?</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              Soll das Produkt mit der Produktnummer <strong>{item.number}</strong> dupliziert werden?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              size="small"
              variant="outlined"
              color="primary"
              style={{ marginRight: "10px" }}
              onClick={() => setShowConfirmModalDuplicate(false)}
            >
              {t("BUTTONS.cancel")}
            </Button>
            <Button
              size="small"
              variant="contained"
              color="primary"
              onClick={(e) => duplicateProduct(item._id)}
              startIcon={<DeleteForeverIcon />}
            >
              Duplizieren
            </Button>
          </DialogActions>
        </Dialog>
      </Modal>

      {/* MODAL delete */}
      <Modal
        open={showConfirmModal}
        onClick={(e) => {
          e.stopPropagation(); // Prevent backdrop click propagation
          cancelDeleteHandler(e);
        }}
      >
        <Dialog
          open={showConfirmModal}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle id="alert-dialog-slide-title">Produkt löschen?</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              <span style={{ fontWeight: 500, color: "red" }}>Das Löschen eines Produkts kann nicht mehr rückgängig gemacht werden.</span><br /><br />Soll dieses Produkt mit der Produktnummer <strong>{item.number}</strong> endgültig gelöscht werden?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              size="small"
              variant="outlined"
              color="primary"
              style={{ marginRight: "10px" }}
              onClick={(e) => cancelDeleteHandler(e)}
            >
              {t("BUTTONS.cancel")}
            </Button>
            <Button
              size="small"
              variant="contained"
              color="primary"
              onClick={(e) => confirmDeleteHandler(item._id, e)}
              startIcon={<DeleteForeverIcon />}
            >
              Ja, Endgültig löschen
            </Button>
          </DialogActions>
        </Dialog>
      </Modal>

    </>
  );
}
