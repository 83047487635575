import React, { useState, useEffect } from "react";

// IMPORT components MATERIAL UI
import {
  Typography,
  Grid,
  IconButton,
  Button,
  FormControl,
  TextField,
  FormHelperText
} from "@material-ui/core";

// IMPORT icons MATERIAL UI
import ScheduleIcon from '@material-ui/icons/Schedule';
import SaveIcon from '@material-ui/icons/Save';

import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import CloseIcon from '@material-ui/icons/Close';
import SendIcon from '@material-ui/icons/Send';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';

import DateFnsUtils from '@date-io/date-fns';
import { de } from "date-fns/locale";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);


export default function InvoicesScheduleSend({
  values,
  setValues,
  sendState,
  sendDate,
  send,
  isLoading,
  sendOption,
  setEmailSent,
  setOpenInvoiceDialogAtom,
  openInvoiceDialogAtom,
  sendOptionManualSent,
  recurring,
  setSendMode,
  sendMode,
  emailScheduled,
  recipients,
  handleTimeBlurManual,
  validateInputsManual,
  handleManualSendSaveAndClose,
}) {

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    setValues((prev) => ({
      ...prev,
      sendDate: new Date().toString(),
    }));
  };



  /***
   ** Set Schedule Date and Time
   ***/
  const [time, setTime] = useState((new Date(Date.now() + 10 * 60 * 1000)).toTimeString().slice(0, 5)); // Holds the time input
  const [dateError, setDateError] = useState(false); // Tracks error for date
  const [overallDateError, setOverallDateError] = useState(false);
  const [timeError, setTimeError] = useState(false); // Tracks error for time

  const handleTimeChange = (event) => {
    setTimeError(false);
    setDateError(false);
    setOverallDateError(false);
    let value = event.target.value;
  
    // Remove any non-numeric characters except a colon
    value = value.replace(/[^0-9:]/g, "");
  
    // Ensure only one colon exists
    const colonIndex = value.indexOf(":");
    if (colonIndex !== -1) {
      // Remove any additional colons beyond the first
      value = value.slice(0, colonIndex + 1) + value.slice(colonIndex + 1).replace(/:/g, "");
    }
  
    // Automatically add colon after the first two digits if no colon exists yet
    if (value.length === 3 && colonIndex === -1) {
      value = value.slice(0, 2) + ":" + value.slice(2);
    }
  
    // Handle deletion of the colon when backspace is pressed
    if (event.nativeEvent.inputType === "deleteContentBackward" && colonIndex === 2 && value.length === 3) {
      value = value.slice(0, 2); // Remove the colon
    }
  
    // Limit input to HH:MM format (5 characters max)
    if (value.length > 5) {
      value = value.slice(0, 5);
    }
  
    setTime(value);
  
    // Validate time format (HH:MM, 24-hour format)
    const timeRegex = /^([01]\d|2[0-3]):([0-5]\d)$/;
    if (timeRegex.test(value)) {
      // Extract hours and minutes
      const [hours, minutes] = value.split(":").map(Number);
  
      // Check if sendDate is null and initialize it with the current date if needed
      let updatedDate = values.sendDate ? new Date(values.sendDate) : new Date();
  
      // Update the time on the sendDate
      updatedDate.setHours(hours);
      updatedDate.setMinutes(minutes);
  
      // Update the values state with the new sendDate
      setValues({
        ...values,
        sendDate: updatedDate.toString(), // Keep the updated sendDate as a string
      });
      setDateError(false);
    }
  };
  
  const handleTimeBlur = () => {
    let updatedTime = time;
  
    if(updatedTime !== null) {
      if (!updatedTime.trim()) {
        // If time is empty, set it to the current time plus 10 minutes
        const now = new Date();
        now.setMinutes(now.getMinutes() + 10); // Add 10 minutes
        const hours = now.getHours().toString().padStart(2, "0"); // Format hours
        const minutes = now.getMinutes().toString().padStart(2, "0"); // Format minutes
        updatedTime = null;
      } else {
        // Add leading 0 if there’s a single character before the colon
        if (updatedTime.includes(":") && updatedTime.indexOf(":") === 1) {
          updatedTime = "0" + updatedTime;
        }
    
        // Add trailing 0 if there’s a single character after the colon
        const parts = updatedTime.split(":");
        if (parts[1] && parts[1].length === 1) {
          updatedTime = parts[0] + ":0" + parts[1];
        }
      }
    
      // Update time in the current component
      setTime(updatedTime);
    
      // Safely update the time part of values.sendDate
      try {
        const currentDate = values.sendDate ? new Date(values.sendDate) : new Date(); // Convert string to Date object
    
        // Check if the date is valid
        if (isNaN(currentDate.getTime())) {
          console.error("Invalid date format in values.sendDate");
          return; // Exit early if date is invalid
        }
    
        // Extract hours and minutes from updatedTime
        const [hours, minutes] = updatedTime !== null && updatedTime.split(":").map(Number);
    
        // Update hours and minutes in the Date object
        currentDate.setHours(hours || 0); // Default to 0 if hours are NaN
        currentDate.setMinutes(minutes || 0); // Default to 0 if minutes are NaN
    
        // Update values.sendDate with the new date-time string
        setValues({
          ...values,
          sendDate: currentDate.toString(), // Use ISO format for consistency
        });
      } catch (error) {
        console.error("Error updating sendDate:", error);
      }
    }
  };
  
  const handleSchedule = () => {
    // Use a timeout to allow state updates to take effect before validating inputs
    setTimeout(() => {
      const isValid = validateInputs(); // Validate inputs when "Planen" is clicked

      if (isValid) {
        send("scheduled", values.sendDate, sendOption); // Execute main logic
        setOpen(false); // Close dialog
      }
    }, 0);
  };

  const validateInputs = () => {
    let isValid = true;
  
    // Validate date
    const date = new Date(values.sendDate);
    if (!values.sendDate || values.sendDate === null || isNaN(date.getTime())) {
      setDateError(true); // Show error message for date
      isValid = false;
    } else {
      setDateError(false); // Clear error if valid
    }
  
    // Validate time (HH:MM, 24-hour format) and check for exact length
    const timeRegex = /^([01]\d|2[0-3]):([0-5]\d)$/;
    if (!timeRegex.test(time) || time.length !== 5) {
      setTimeError(true); // Show error message for time
      isValid = false;
    } else {
      setTimeError(false); // Clear error if valid
    }
  
    // Overall validation: Check if sendDate is greater than or equal to the current date
    const now = new Date();
    if (date < now && values.sendDate && time) {
      setOverallDateError(true); // Show error below all inputs
      isValid = false;
    } else {
      setOverallDateError(false); // Clear error if valid
    }
  
    return isValid;
  };
  

  return (
    <div>
      {sendOption === "dagowert" && !recurring && !emailScheduled &&
        <Button
          style={{ opacity: JSON.stringify(recipients) === "[]" ? 0.1 : 1, marginRight: 5 }} disabled={isLoading || JSON.stringify(recipients) === "[]"}
          className="button-send-dialog-schedule"
          size="small"
          variant="contained"
          onClick={() => {
            handleClickOpen();
            setValues((prev) => ({
              ...prev,
              sendDate: new Date(new Date().setMinutes(new Date().getMinutes() + 10)).toString(), // add 10 Minutes Convert back to string and update
            }));
            setTimeError(false);
          }}
          startIcon={<ScheduleIcon />}
        >
          Später senden (Planen)
        </Button>
      }

      {recurring && !emailScheduled &&
        // <Button disabled={isLoading} size="small" variant="outlined" style={{ marginRight: "5px" }} onClick={handleClickOpen} startIcon={<ScheduleIcon />}  >
        //   {sendOption === "manual" ? "Nächste Erstellung planen" : "Nächsten Versand planen"}
        // </Button>
        <Button style={{ opacity: JSON.stringify(recipients) === "[]" ? 0.5 : 1 }} disabled={isLoading || JSON.stringify(recipients) === "[]"} className="button-send-dialog-send" size="small" variant="contained" color="primary" onClick={handleClickOpen}>
        {sendOption === "manual" ? "Weiter" : "Weiter"}
      </Button>
      }

      {sendOption === "manual" && !values.recurring &&
        <>
          {sendOptionManualSent &&
            <Button
              style={{ marginRight: 5 }}
              variant="contained"
              color="primary"
              startIcon={<SaveIcon />}
              onClick={() => {
                handleTimeBlurManual();
                setTimeout(() => {
                  const isValid = validateInputsManual(); // Validate inputs when "Planen" is clicked
                  if (isValid) {
                    handleManualSendSaveAndClose();
                  }
                }, 0);
                setTimeout(() => {
                  const isValid = validateInputsManual(); // Validate inputs when "Planen" is clicked
                  if (isValid) {
                    setOpenInvoiceDialogAtom({
                      ...openInvoiceDialogAtom,
                      open: false,
                      invoiceId: "",
                      duplicate: false
                    });
                  }
                }, 0);
                
              }} // Attach the click handler
            >
              Speichern und schließen
            </Button>
          }
        </>
      }

      {sendOption === "dagowert" && !values.recurring && !emailScheduled &&
        <Button style={{ opacity: JSON.stringify(recipients) === "[]" ? 0.5 : 1 }} disabled={isLoading || JSON.stringify(recipients) === "[]"} className="button-send-dialog-send" size="small" variant="contained" color="primary" onClick={() => { send() }} startIcon={<SendIcon />}>
          Jetzt senden
        </Button>
      }

      <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
        <DialogTitle id="customized-dialog-title">
          <ScheduleIcon style={{ marginRight: 5, background: "rgb(255, 183, 77)", borderRadius: 60, color: "#ffffff" }} />

          <div
            style={{
              fontSize: 14,
              flexGrow: 1,
              display: "flex",
              alignItems: "center",
              color: "rgb(44, 83, 125)",
            }}
          >
            {recurring && !emailScheduled && sendOption === "dagowert" && "Nächsten Versand planen"}
            {recurring && !emailScheduled && sendOption === "manual" && "Nächste Erstellung planen"}
            {!recurring && "Später senden (Versand planen)"}
          </div>

          {/* Close Icon */}
          <IconButton
            aria-label="close"
            onClick={handleClose}
            style={{
              position: "absolute",
              right: "8px",
              color: "rgba(0, 0, 0, 0.54)", // Optional: matches Material UI default color
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          {recurring && !emailScheduled && sendOption === "dagowert" &&
            <div>
              <p>Mit dem Festlegen des nächsten Versandzeitpunkts wird diese wiederkehrende Rechnungslegung <strong><span style={{color: "green"}}>aktiviert</span></strong>. Die <strong>nächste Rechnung</strong> wird zum unten gewählten Zeitpunkt versendet. Weitere Rechnungen werden abhängig vom gewählten Intervall gesendet.</p>
            </div>
          }

          {recurring && !emailScheduled && sendOption === "manual" &&
            <div>
              <p>Mit dem Festlegen des nächsten Erstellungszeitpunkts wird diese wiederkehrende Rechnungslegung <strong><span style={{color: "green"}}>aktiviert</span></strong>. Die <strong>nächste Rechnung</strong> wird zum unten gewählten Zeitpunkt als Entwurf erstellt. Weitere Rechnungen werden abhängig vom Intervall erstellt.</p>
            </div>
          }

          <MuiPickersUtilsProvider locale={de} utils={DateFnsUtils}>
            <Grid item xs={12} style={{ width: 400, maxWidth: "100%" }} className="datepicker">
              {/* Date Picker */}
              <KeyboardDatePicker
                fullWidth
                inputVariant="filled"
                id="sendDate"
                placeholder="DD.MM.YYYY"
                format="dd.MM.yyyy"
                label="Datum"
                minDate={new Date()} // Restrict selection to today or future dates
                value={values.sendDate ? new Date(values.sendDate) : null} // Ensure the value is a Date or null
                error={false}
                helperText={""}
                onChange={(date) => {
                  setDateError(false);
                  setTimeError(false);
                  setOverallDateError(false);
                  if (date && !isNaN(date.getTime())) {
                    // Ensure the selected date is today or in the future
                    const today = new Date();
                    today.setHours(0, 0, 0, 0); // Normalize time to midnight for comparison
                    const selectedDate = new Date(date);
                    selectedDate.setHours(0, 0, 0, 0);

                    // Extract the time from the `time` state

                    if(time !== null && (/^([01]\d|2[0-3]):([0-5]\d)$/).test(time)) {
                      let [hours, minutes] = time.split(":").map(Number);

                      // If time is empty, fallback to current time plus 10 minutes
                      if (time === "" || time === null) {
                        const now = new Date();
                        hours = now.getHours();
                        minutes = now.getMinutes() + 10;
                        if (minutes >= 60) {
                          hours += Math.floor(minutes / 60);
                          minutes = minutes % 60;
                        }
                      }

                      // Apply the extracted or computed time to the selected date
                      date.setHours(hours);
                      date.setMinutes(minutes);
                    } else {
                      // If time is empty, fallback to current time plus 10 minutes
                        const now = new Date();
                        let hours = now.getHours();
                        let minutes = now.getMinutes() + 10;
                        if (minutes >= 60) {
                          hours += Math.floor(minutes / 60);
                          minutes = minutes % 60;
                        }
  
                      // Apply the extracted or computed time to the selected date
                      date.setHours(hours);
                      date.setMinutes(minutes);
                    }


                    // Update sendDate with the new date and time
                    setValues({
                      ...values,
                      sendDate: date.toString(), // Update sendDate as a string
                    });
                  } else {
                    // Handle invalid or empty input
                    // setDateError(true); // Set error if the input is invalid
                    setValues({
                      ...values,
                      sendDate: null, // Set to null to show the placeholder
                    });
                  }
                }}
                KeyboardButtonProps={{
                  "aria-label": "change date",
                  style: { color: "rgb(44, 83, 125)" }, // Primary color
                }}
                okLabel="OK"
                cancelLabel="Abbrechen"
              />
              {dateError && <FormHelperText error>Bitte ein gültiges Datum eingeben.</FormHelperText>}


              {/* Time Input Field */}
              <FormControl fullWidth style={{ marginTop: "16px" }} error={timeError}>
                <TextField
                  fullWidth
                  id="time-input"
                  label="Uhrzeit"
                  variant="filled"
                  value={time}
                  onChange={handleTimeChange}
                  onBlur={handleTimeBlur}
                  placeholder="HH:MM"
                  inputProps={{
                    maxLength: 5, // Limit to 5 characters (e.g., "14:24")
                  }}
                />
                {timeError && <FormHelperText>Bitte eine gültige Uhrzeit eingeben.</FormHelperText>}
              </FormControl>

              {/* Overall Date Validation Error */}
              {overallDateError && (
                <FormHelperText error style={{ marginTop: "16px" }}>
                  Der Versandzeitpunkt (Datum und Uhrzeit) muss in der Zukunft liegen.
                </FormHelperText>
              )}
            </Grid>
          </MuiPickersUtilsProvider>

        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            size="small"
            color="primary"
            autoFocus
            onClick={handleSchedule}
            startIcon={sendOption === "dagowert" ? !recurring ? <ScheduleIcon /> : <FiberManualRecordIcon style={{color: "#8eeb8e"}}/> : !recurring ? <SaveIcon /> : <FiberManualRecordIcon style={{color: "#8eeb8e"}}/>}
          >
            {recurring && sendOption === "dagowert" && "Jetzt Aktivieren"}
            {recurring && sendOption === "manual" && "Jetzt Aktivieren"}
            {!recurring && "Planen"}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
