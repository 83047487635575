import React, { useEffect, useState } from "react";
import {
  Popover,
  Typography,
  IconButton,
  Button,
  Switch,
  FormControl,
  FormControlLabel
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import SaveIcon from "@material-ui/icons/Save";
import { MuiPickersUtilsProvider, KeyboardDatePicker } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import de from "date-fns/locale/de";
import { useSnackbar } from "notistack";

const PayMenuPopover = ({
  isOpen,
  anchorEl,
  onClose,
  payMenuValues,
  setPayMenuValues,
  payDateError,
  handleDateChange,
  invoice,
  setDataInitial,
  sendRequest,
  auth,
  invoicePayed,
}) => {

  const { enqueueSnackbar } = useSnackbar();

  const [isPayed, setIsPayed] = useState(true)
  
  useEffect(() => {
    if (isOpen && !payMenuValues.payDate) {
      // Set the initial date only when the popover opens
      setPayMenuValues((prev) => ({
        ...prev,
        payDate: new Date(),
      }));
    }
  }, [isOpen]); // Run only when isOpen changes

  return (
    <Popover
      open={isOpen}
      elevation={1}
      anchorEl={anchorEl}
      onClose={(e) => {
        e.stopPropagation(); // Prevent parent click event
        onClose();
      }}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
      PaperProps={{
        onClick: (e) => {
          e.stopPropagation(); // Prevent parent click events from propagating to the invoice
        },
      }}
    >
      <div style={{ padding: 16, maxWidth: 400 }}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography variant="h6" style={{marginRight: 5}} >
            {invoice.payState === "notPayed" ? 'Auf "Bezahlt" setzen' : "Zahldatum / Zahlstatus ändern"}
          </Typography>
          <IconButton
            onClick={(e) => {
              e.stopPropagation(); // Prevent parent click event
              onClose();
            }}
            size="small"
          >
            <CloseIcon />
          </IconButton>
        </div>


        <Typography
          variant="body2"
          style={{ fontSize: 14, margin: "16px 0" }}
        >
          Datum wählen und speichern.<br /><br />
          {invoice.payState === "notPayed" && <span>Die Rechnung
          <span style={{marginLeft: 3, marginRight: 3}}>
          {JSON.parse(invoice.invoiceNr).map((item, i) => (
            <span key={i} style={{fontWeight: "500" }}>
              {item.type === "continuousNumber" ? (
                <span
                  style={{
                    display: "inline-block",
                    background: "rgb(237, 239, 255)",
                    height: "19px",
                    color: "inherit",
                    paddingLeft: 3,
                    paddingRight: 3,
                    borderRadius: "3px",
                    marginTop: 1.2,
                    marginLeft: "1px",
                    marginRight: "1px",
                    fontWeight: "500",
                  }}
                >
                  {item.value}
                </span>
              ) : (
                <span>{item.value}</span>
              )}
            </span>
          ))}
          </span>
          <br />
          wird damit auf "bezahlt" gesetzt.
          </span>}

          {invoice.payState === "payed" && <span>Das Bezahldatum der Rechnung
          <span style={{marginLeft: 3, marginRight: 3}}>
          {JSON.parse(invoice.invoiceNr).map((item, i) => (
            <span key={i} style={{fontWeight: "500" }}>
              {item.type === "continuousNumber" ? (
                <span
                  style={{
                    display: "inline-block",
                    background: "rgb(237, 239, 255)",
                    height: "19px",
                    color: "inherit",
                    paddingLeft: 3,
                    paddingRight: 3,
                    borderRadius: "3px",
                    marginTop: 1.2,
                    marginLeft: "1px",
                    marginRight: "1px",
                    fontWeight: "500",
                  }}
                >
                  {item.value}
                </span>
              ) : (
                <span>{item.value}</span>
              )}
            </span>
          ))}
          </span>
          <br />
          wird dadurch entsprechend geändert.
          </span>}
        </Typography>

        <MuiPickersUtilsProvider locale={de} utils={DateFnsUtils}>
          <KeyboardDatePicker
            fullWidth
            inputVariant="filled"
            id="payDate"
            format="dd.MM.yyyy"
            placeholder="DD.MM.YYYY"
            label="Bezahldatum"
            value={payMenuValues.payDate}
            error={!!payDateError}
            helperText={payDateError || " "}
            onChange={(date) => handleDateChange(date, invoice.invoiceDate)}
            KeyboardButtonProps={{
              "aria-label": "change date",
              style: { color: "rgb(44, 83, 125)" }
            }}
            okLabel="OK"
            cancelLabel="Abbrechen"
            PopoverProps={{
              onClick: (e) => e.stopPropagation(), // Prevent clicks inside datepicker from propagating
            }}
          />
        </MuiPickersUtilsProvider>

        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: 16,
          }}
        >
          <Button
            size="small"
            variant="outlined"
            color="primary"
            onClick={(e) => {
              e.stopPropagation(); // Prevent parent click event
              onClose();
            }}
          >
            Abbrechen
          </Button>
          <Button
            disabled={payDateError !== ""}
            size="small"
            variant="contained"
            color="primary"
            startIcon={<SaveIcon />} // Add the Save icon to the left
            onClick={async (e) => {
              e.stopPropagation(); // Prevent parent click event

              if (!payMenuValues.payDate) {
                alert("Bitte wählen Sie ein Bezahldatum aus.");
                return;
              }

              // Close the menu
              onClose();

              // Update local state
              setDataInitial((prevData) =>
                prevData.map((item) =>
                  item._id === invoicePayed
                    ? {
                      ...item,
                      payState: isPayed ? "payed" : "notPayed", // Update payState
                      payDate: payMenuValues.payDate.toString(), // Apply selected date
                    }
                    : item
                )
              );

              // Prepare FormData for the PATCH request
              const formData = new FormData();
              formData.append("payState", isPayed ? "payed" : "notPayed");
              formData.append("payDate", payMenuValues.payDate.toString());

              try {
                // Send PATCH request to update the backend
                const url = `${process.env.REACT_APP_BACKEND_URL_API}/invoices/${invoicePayed}`;
                const method = "PATCH";

                await sendRequest(
                  url,
                  method,
                  formData,
                  {
                    Authorization: "Bearer " + auth.token,
                  }
                );
                enqueueSnackbar('Rechnung wurde auf "Bezahlt" gesetzt.', { variant: "success" });
              } catch (error) {
                enqueueSnackbar('Änderungen konnten nicht gespeichert werden', { variant: "error" });
              }
            }}
          >
            Speichern
          </Button>
        </div>
      </div>
    </Popover>
  );
};

export default PayMenuPopover;
